import Axios from 'axios'
import { Config } from 'common'
import { $Actions } from 'modules/store/crossSliceReducer'
import { AppThunkAction } from './asyncAction.type'
import {
  ResponseUploadFile,
  showNotiDuplicateUploadMedia,
  showNotiUploadResult,
} from './mediaUpload.noti'

const LIMIT_GB = 1.5 * 1024 * 1024 * 1024

type FileChunk = {
  files: File[]
  size: number
}

export const asyncActions = {
  uploadMedia: (
    manufacturerId: string,
    files: File[]
  ): AppThunkAction => async (dispatch, getState) => {
    if (files.length <= 0) {
      return
    }
    const { mediaList$, mediaUpload } = getState()
    const duplicateFiles: File[] = []

    const chunkFileList = files
      .filter(file => {
        // 동일한 파일명은 업로드 불가
        const { name } = file
        const index = mediaList$.findIndex(
          media =>
            media.fileName === name && media.manufacturerId === manufacturerId
        )
        if (index <= -1) {
          return true
        }
        duplicateFiles.push(file)
        return false
      })
      .filter(file => {
        // 동일한 파일명은 업로드 불가(업로드 중)
        const { name } = file
        const index = mediaUpload.findIndex(
          media =>
            media.name === name && media.manufacturerId === manufacturerId
        )
        if (index <= -1) {
          return true
        }
        duplicateFiles.push(file)
        return false
      })
      .sort((a, b) => a.size - b.size)
      .reduce<FileChunk[]>((chunkList, file) => {
        if (file) {
          const currentSize = file.size

          if (currentSize >= LIMIT_GB) {
            // file size 가 커서 1개만 따로 올려야함.
            chunkList.push({ files: [file], size: currentSize })
          } else {
            if (chunkList.length <= 0) {
              chunkList.push({ files: [], size: 0 })
            }
            const lastChuck = chunkList[chunkList.length - 1]
            const lastChuckSize = lastChuck.size
            if (lastChuckSize >= LIMIT_GB) {
              //  마지막 chuck 가 용량이 이미 초과되어 새로운 chuck 생성해서 업로드
              chunkList.push({ files: [file], size: currentSize })
            } else if (lastChuckSize + currentSize >= LIMIT_GB) {
              // 마지막 chunk 와 현재 파일이 합쳐질 경우 용량이 Limit 을 초과 해서 새로운 chunk로 변경
              chunkList.push({ files: [file], size: currentSize })
            } else {
              chunkList[chunkList.length - 1].size = lastChuckSize + currentSize
              chunkList[chunkList.length - 1].files.push(file)
            }
          }
        }
        return chunkList
      }, [])

    if (duplicateFiles.length >= 1) {
      showNotiDuplicateUploadMedia(duplicateFiles)
    }

    chunkFileList.forEach(({ files: chunckFiles }, i) => {
      const form = new FormData()
      dispatch($Actions.uploadFiles(manufacturerId, chunckFiles))
      chunckFiles.forEach(file => form.append('media', file))

      setTimeout(async () => {
        try {
          const { data } = await Axios.request<ResponseUploadFile>({
            method: 'POST',
            url: `${Config.httpGraphql.replace(
              /\/graphql/,
              ''
            )}/file/mediaupload`,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            params: { manufacturerId },
            data: form,
          })
          showNotiUploadResult(data)
        } catch (e) {}
        dispatch($Actions.resultFiles(manufacturerId, chunckFiles))
      }, i * 1000)
    })
  },
}
