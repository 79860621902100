import { notification, Typography } from 'antd'
import { ReactMemoEx } from 'modules/view'
import React from 'react'
import styled from 'styled-components'
import { v1 as uuid } from 'uuid'

type ErrorULProps = {
  files: File[]
  // onForceUpload: (files: File[]) => void
}
const { Paragraph } = Typography

const ParagraphLi = styled(Paragraph)`
  margin-bottom: 0.25rem !important;
`
const ErrorUL = ReactMemoEx((props: ErrorULProps) => {
  const { files } = props
  // const onClickForceUpload = useCallback(() => {
  //   notification.close(notiKey)
  //   onForceUpload(files)
  // }, [notiKey])

  return (
    <>
      <Paragraph>
        <ul>
          {files.map(({ name }, i) => (
            <li key={i}>
              <ParagraphLi ellipsis copyable>
                {name}
              </ParagraphLi>
            </li>
          ))}
        </ul>
      </Paragraph>
    </>
  )
})

/**
 * show error files
 * @param files
 */
export default function showErroFiles(
  files: File[]
  // onForceUpload: (files: File[]) => void
) {
  if (files.length <= 0) {
    return
  }
  const key = uuid()
  notification.open({
    key,
    type: 'error',
    duration: 0,
    message: '업로드 실패: 비디오 인식 불가',
    description: <ErrorUL files={files} />,
  })
}
