import { Col, Form, Input, Row, Select } from 'antd'
import {
  FieldData,
  GeneratorModalIndex,
  MutationAddGroupArgs,
  isErrorByNetwork,
} from 'common'
import { useMutationAddGroup } from 'modules/graphql'
import { useReduxSelector, isSystemUser } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo } from 'react'
import ContainerFormLayout from './ContainerFormLayout'
import EmptyContainer from './EmptyContainer'
import { notiErrorNetwork, notiError, notiSuccess, isEqualTitle } from './util'

const ERROR_TITLE = '그룹 생성 실패'

const FormGroupBody = () => {
  const [form] = Form.useForm()
  const [addGroup, { loading }] = useMutationAddGroup()
  const groupList = useReduxSelector(({ groupList }) => groupList)
  const manufacturerList = useReduxSelector(
    ({ manufacturerList }) => manufacturerList
  )

  const fields = useMemo(() => {
    return (
      <Row gutter={[32, 16]}>
        <Col xxl={8} xl={8} md={12} sm={24} xs={24}>
          <Form.Item
            name="manufacturerId"
            label="제조사"
            rules={[
              {
                required: true,
                message: '제조사를 선택해주세요.',
              },
            ]}
          >
            <Select>
              {manufacturerList.map(({ id, title }) => (
                <Select.Option key={id} value={id} children={title} />
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xxl={8} xl={8} md={12} sm={24} xs={24}>
          <Form.Item
            name="title"
            label="그룹명"
            rules={[
              {
                required: true,
                message: '그룹명을 입력하세요!',
              },
            ]}
          >
            <Input placeholder="그룹명" />
          </Form.Item>
        </Col>
        <Col xxl={8} xl={8} md={12} sm={24} xs={24}>
          <Form.Item name="deviceRegexr" label="모델 정규식">
            <Input placeholder="^UHD+[0-9]$" />
          </Form.Item>
        </Col>
      </Row>
    )
  }, [manufacturerList])

  const onFinish = useCallback(async () => {
    const variables = form.getFieldsValue() as MutationAddGroupArgs
    if (isEqualTitle(groupList, variables.title)) {
      const files: FieldData[] = [
        { name: 'title', errors: ['동일한 그룹명이 존재합니다.'] },
      ]
      form.setFields(files)
      return
    }

    try {
      await addGroup({ variables })
      notiSuccess(`그룹 생성 완료(${variables.title})`)
      form.resetFields()
    } catch (e) {
      if (isErrorByNetwork(e)) {
        notiErrorNetwork(ERROR_TITLE)
      } else {
        notiError(ERROR_TITLE, String(e))
      }
    }
  }, [groupList, addGroup, form])

  return (
    <ContainerFormLayout
      modalIndex={GeneratorModalIndex.Shop}
      loading={loading}
      title="그룹 생성"
      form={form}
      formChildren={fields}
      onFinish={onFinish}
    />
  )
}

const FormGroup = () => {
  const adminInfo = useReduxSelector(({ auth }) => auth.admin)

  return isSystemUser(adminInfo) ? (
    <FormGroupBody />
  ) : (
    <EmptyContainer
      index={GeneratorModalIndex.Group}
      title="그룹 생성(사용 권한 없음)"
    />
  )
}

export default ReactMemoEx(FormGroup)
