import { Spin } from 'antd'
import { useDidMount } from 'beautiful-react-hooks'
import { Config } from 'common'
import { TitleLoading } from 'components'
import {
  createGraphQLClient,
  GqlApolloClient,
  QueryHelper,
} from 'modules/graphql'
import { $Actions } from 'modules/store'
import { ApplicationStateDatabase } from 'modules/store/features'
import { getAuthToken } from 'modules/useLibs'
import { ReactMemoEx } from 'modules/view'
import React from 'react'
import { useDispatch } from 'react-redux'

export type LoadingProps = {
  onCreateApolloClient?: (gqlCli: GqlApolloClient) => void
}

const Loading = (props: LoadingProps) => {
  const { onCreateApolloClient } = props
  const dispatch = useDispatch()

  useDidMount(async () => {
    if (onCreateApolloClient) {
    }
    const gqlCli = createGraphQLClient(
      {
        http: Config.httpGraphql,
      },
      true,
      getAuthToken
    )
    if (onCreateApolloClient) {
      onCreateApolloClient(gqlCli)
    }
    const { client } = gqlCli
    try {
      const { data } = await client.query<ApplicationStateDatabase>({
        query: QueryHelper.queryLoadInitData,
        fetchPolicy: 'no-cache',
      })
      dispatch($Actions.initDatabase(data))
    } catch (e) {}
  })

  return (
    <>
      <Spin size="large" />
      <TitleLoading />
    </>
  )
}

export default ReactMemoEx(Loading)
