export enum GeneratorModalIndex {
  MediaCenter = 'MediaCenter',
  Shop = 'Shop',
  SaleChannel = 'SaleChannel',
  CountryCode = 'CountryCode',
  AMCode = 'AMCode',
  Group = 'Group',
  Manufacturer = 'Manufacturer',
  Admin = 'Admin',
}

export type GenratorModalItemLevel = 'system' | 'all'
export type GenratorModalItem =
  | {
      type: 'divider'
      level: GenratorModalItemLevel
    }
  | {
      type: 'item'
      key: GeneratorModalIndex
      hotkey: string
      level: GenratorModalItemLevel
    }

export const GenratorModalItemList: GenratorModalItem[] = [
  {
    level: 'all',
    type: 'item',
    key: GeneratorModalIndex.MediaCenter,
    hotkey: 'alt+1',
  },
  {
    level: 'all',
    type: 'item',
    key: GeneratorModalIndex.Shop,
    hotkey: 'alt+2',
  },
  { level: 'system', type: 'divider' },
  {
    level: 'system',
    type: 'item',
    key: GeneratorModalIndex.SaleChannel,
    hotkey: 'alt+3',
  },
  {
    level: 'system',
    type: 'item',
    key: GeneratorModalIndex.Group,
    hotkey: 'alt+4',
  },
  {
    level: 'system',
    type: 'item',
    key: GeneratorModalIndex.AMCode,
    hotkey: 'alt+5',
  },
  {
    level: 'system',
    type: 'item',
    key: GeneratorModalIndex.CountryCode,
    hotkey: 'alt+6',
  },
  {
    level: 'system',
    type: 'item',
    key: GeneratorModalIndex.Manufacturer,
    hotkey: 'alt+7',
  },
  {
    level: 'system',
    type: 'item',
    key: GeneratorModalIndex.Admin,
    hotkey: 'alt+8',
  },
]
