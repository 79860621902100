import { CalendarOutlined } from '@ant-design/icons'
import { Button, DatePicker, Form, Popconfirm, Select } from 'antd'
import { ReactMemoEx } from 'modules/view'
import moment, { Moment } from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { EventType } from 'common'
import { useContextSelector } from 'use-context-selector'
import { ContextByPLM } from 'components/src/PlayListManager/features/StorePLM'
/**
 * 예약 등록 button
 */
const ButtonReservation = styled(Button)`
  border-left: 0;
  border-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
`
export type DatePickPopconfirm = {
  mediaSize: number
  onClick: (dateTime: number, type: EventType) => void
}

function disabledDate(current: Moment) {
  return current && current < moment().endOf('day')
}

const DatePickPopconfirm: React.FC<DatePickPopconfirm> = props => {
  const [form] = Form.useForm()
  const { mediaSize, onClick } = props
  const [eventType, setEventType] = useState(EventType.Add)
  const [reserveDate, setReserveDate] = useState<Moment | undefined>(undefined)
  const selectMedia = useContextSelector(
    ContextByPLM,
    state => state[0].selectMediaList
  )

  const onChangeDate = useCallback((date: any) => {
    if (date === null || date === undefined) {
      setReserveDate(undefined)
    } else if (moment.isMoment(date)) {
      const time = moment(date)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
      setReserveDate(time)
    }
  }, [])

  const mDisabled = useMemo(() => mediaSize <= 0, [mediaSize])

  const onConfirm = useCallback(() => {
    if (reserveDate === undefined) {
      return
    }
    onClick(reserveDate.toDate().getTime(), eventType)
  }, [reserveDate, onClick, eventType])

  const onCancel = useCallback(() => {
    form.resetFields(['date'])
    form.setFields([{ name: 'eventType', value: EventType.Add }])
    setReserveDate(undefined)
  }, [form])

  const onChange = useCallback(value => {
    setEventType(value as EventType)
  }, [])

  const title = useMemo(
    () => (
      <>
        <Form.Item
          label="예약 등록"
          style={{ marginBottom: '0.5rem' }}
          name="date"
        >
          <DatePicker
            placeholder="일자를 선택해주세요."
            size="small"
            onChange={onChangeDate}
            disabledDate={disabledDate}
          />
        </Form.Item>
        <Form.Item
          label="예약 타입"
          style={{ marginBottom: '0.5rem' }}
          name="eventType"
        >
          <Select size="small" onChange={onChange}>
            <Select.Option value={EventType.Add}>미디어 추가</Select.Option>
            <Select.Option value={EventType.Delete}>미디어 제외</Select.Option>
          </Select>
        </Form.Item>
      </>
    ),
    [onChangeDate, onChange]
  )
  const disabled = useMemo(() => {
    if (selectMedia.length <= 0) {
      return true
    }
    return reserveDate === undefined
  }, [selectMedia, reserveDate])

  return (
    <>
      <Form form={form} component={false}>
        <Popconfirm
          title={title}
          onCancel={onCancel}
          placement="bottom"
          okText="등록"
          onVisibleChange={onCancel}
          cancelText="취소"
          onConfirm={onConfirm}
          okButtonProps={{ disabled }}
        >
          <div className="kbl-btn-container">
            <ButtonReservation size="small" type="primary" disabled={mDisabled}>
              <CalendarOutlined />
            </ButtonReservation>
          </div>
        </Popconfirm>
      </Form>
    </>
  )
}

export default ReactMemoEx(DatePickPopconfirm)
