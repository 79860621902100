import { Badge, Divider, Typography } from 'antd'
import { ReactMemoEx } from 'modules/view'
import numeral from 'numeral'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import './TVInfoTooltip.less'

type TVInfoTooltipProps = {
  active: number
  error: number
  offline: number
  title?: string
}

const { Paragraph } = Typography
const SParagraph = styled(Paragraph)`
  color: white;
`

export const TVInfoTooltip: React.FC<TVInfoTooltipProps> = ReactMemoEx(
  props => {
    const { active, error, offline, children, title } = props

    return useMemo(
      () => (
        <div className="tvinfo-tooltip">
          <SParagraph>
            {title && <p className="tooltip-title">{title}</p>}

            <ul>
              <li>
                <Badge status="success" />
                <span className="label">TV 총 갯수:</span>
                <span className="value">
                  {`${numeral(active + error + offline)
                    .format(',')
                    .toString()}대`}
                </span>
              </li>
              <li>
                <Badge status="processing" />
                <span className="label">활성:</span>
                <span className="value">
                  {`${numeral(active)
                    .format(',')
                    .toString()}대`}
                </span>
              </li>
              <li>
                <Badge status="default" />
                <span className="label">오프라인:</span>
                <span className="value">
                  {`${numeral(offline)
                    .format(',')
                    .toString()}대`}
                </span>
              </li>
              <li>
                <Badge status="error" />
                <span className="label">장애:</span>
                <span className="value">
                  {`${numeral(error)
                    .format(',')
                    .toString()}대`}
                </span>
              </li>
              {children && (
                <>
                  <Divider style={{ margin: '0.25rem 0 0.25rem 0' }} />
                  {children}
                </>
              )}
            </ul>
          </SParagraph>
        </div>
      ),
      [active, error, offline, children, title]
    )
  }
)
