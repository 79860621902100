import { findCacheById, findGroupById, safeNum } from 'common'
import { useMutationUpdateCountryCode } from 'modules/graphql'
import { useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo, useState } from 'react'
import { EditTable } from '../../../EditTable'
import { SearchState } from '../../../util'
import createColumns from './countryCode.column'
import { CountryCode$ as DB } from './countryCode.types'
type TableCountryCodeProps = {}

export const TableCountryCode = ReactMemoEx((props: TableCountryCodeProps) => {
  const [updateCountryCode, { loading }] = useMutationUpdateCountryCode()

  const [searchState, setSearchedColumn] = useState<SearchState<DB>>([])

  const amCodeList = useReduxSelector(state => state.amCodeList)
  const countryCodeList = useReduxSelector(state => state.countryCodeList)
  const manufacturerList = useReduxSelector(state => state.manufacturerList)
  const shopList = useReduxSelector(state => state.shopList$)

  const countryCodeAlphaList = useMemo(() => {
    const findManufacturer = findCacheById(manufacturerList)
    const findShopList = findGroupById(shopList, 'countryCodeId')
    const findAMCodeList = findCacheById(amCodeList)
    return countryCodeList.map(
      (countryCode): DB => {
        const manufacturer = findManufacturer(countryCode.manufacturerId)
        const shopList = findShopList(countryCode.id)
        const amCode = findAMCodeList(countryCode.amCodeId)

        const [activeCount, errorCount, offlineCount] = shopList.reduce(
          ([inActive, inError, inOffline], currentShop) => {
            return [
              inActive + currentShop.tvActiveCount,
              inError + currentShop.tvErrorCount,
              inOffline + currentShop.tvOfflineCount,
            ]
          },
          [0, 0, 0]
        )

        const totalCount = activeCount + errorCount + offlineCount
        return {
          ...countryCode,
          manufacturerTitle: manufacturer ? manufacturer.title : undefined,
          shopCount: shopList.length,
          totalCount,
          activeCount,
          errorCount,
          offlineCount,
          activePercent: safeNum(activeCount / totalCount),
          amCodeTitle: amCode ? amCode.code : '',
        }
      }
    )
  }, [countryCodeList, manufacturerList, amCodeList, shopList])

  const columns = useMemo(
    () =>
      createColumns(searchState, setSearchedColumn, {
        filterAmCode: amCodeList.map(({ code }) => code),
        filterManufacturer: manufacturerList.map(({ title }) => title),
      }),
    [searchState, setSearchedColumn, manufacturerList, amCodeList]
  )

  const onEdit = useCallback(
    (newData: DB) => {
      updateCountryCode({ variables: newData })
    },
    [updateCountryCode]
  )

  return (
    <EditTable<DB>
      loading={loading}
      bordered
      columns={columns}
      dataSource={countryCodeAlphaList}
      rowKey="id"
      onEdit={onEdit}
      scroll={{
        x: true,
      }}
    />
  )
})
