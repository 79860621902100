import * as CryptoJS from 'crypto-js'
import { useState, useRef } from 'react'
import axios, { AxiosRequestConfig } from 'axios'
import { Config } from 'common'

export type SignIn =
  | {
      id: string
      pw: string
    }
  | string

export enum ResponseError {
  Unknow = 1,
  NotFoundAdmin = 2,
  PasswordNotMatch = 3,
  DataError = 4,
}
const ERROR_MSG_UNKNOW = '알수 없는 문제가 발생되었습니다.'
const ERROR_MSG_NOTFOUND_ADMIN = '아이디를 찾을 수 없습니다.'
const ERROR_MSG_PW_NOTMATCH = '패스워드가 일치하지 않습니다.'
const ERROR_MSG_DATA_ERROR = '데이터로드 실패'

export type SignResult = {
  userName: string
  token: string
  manufacturerId?: string
}

export type ResponseLogin = {
  loading: boolean
  signResult?: SignResult
  error?: ResponseError
  errorMessage?: string
}

const loginApi = (
  setState: React.Dispatch<React.SetStateAction<ResponseLogin>>
) => {
  return async (signIn: SignIn) => {
    setState({ loading: true })

    const config: AxiosRequestConfig = {
      baseURL: Config.http,
    }

    if (typeof signIn === 'string') {
      config.headers = {
        authorization: `Bearer ${signIn}`,
      }
      config.url = '/token/check-auth/user'
      config.method = 'GET'
    } else {
      const { id, pw: inputPassword } = signIn
      const pw = CryptoJS.AES.encrypt(inputPassword, id).toString()
      const urlencoded = new URLSearchParams()
      urlencoded.append('id', id)
      urlencoded.append('pw', pw)
      config.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
      config.url = '/login'
      config.method = 'POST'
      config.data = urlencoded
    }

    let outputState: ResponseLogin = { loading: false }
    try {
      const { data: signResult } = await axios.request<SignResult>(config)
      if (signResult) {
        saveAuthToken(signResult.token)
        outputState = { loading: false, signResult }
      } else {
        outputState = {
          loading: false,
          error: ResponseError.Unknow,
        }
      }
    } catch (e) {
      let error = ResponseError.Unknow
      if (e && e.response && e.response.data) {
        const { message } = e.response.data
        if (typeof message === 'string') {
          if (message.indexOf('admin') >= 0) {
            error = ResponseError.NotFoundAdmin
          } else if (message.indexOf('password') >= 0) {
            error = ResponseError.PasswordNotMatch
          } else if (message.indexOf('data') >= 0) {
            error = ResponseError.DataError
          }
        }
      }
      outputState = { loading: false, error }
    }
    if (outputState.error !== undefined) {
      clearAuthToken()
      if (outputState.error === ResponseError.NotFoundAdmin) {
        outputState.errorMessage = ERROR_MSG_NOTFOUND_ADMIN
      } else if (outputState.error === ResponseError.PasswordNotMatch) {
        outputState.errorMessage = ERROR_MSG_PW_NOTMATCH
      } else if (outputState.error === ResponseError.DataError) {
        outputState.errorMessage = ERROR_MSG_DATA_ERROR
      } else {
        outputState.errorMessage = ERROR_MSG_UNKNOW
      }
    }
    setState(outputState)
    return outputState
  }
}

type RequestSingin = (siginIn: SignIn) => Promise<ResponseLogin>

export function useSignIn(): [RequestSingin, ResponseLogin] {
  const [state, setState] = useState<ResponseLogin>({ loading: false })
  const useAPI = useRef(loginApi(setState))
  return [useAPI.current, state]
}

const AuthKey = '_MED_InitData'
export const clearAuthToken = () => {
  localStorage.removeItem(AuthKey)
}
export const saveAuthToken = (token: string) => {
  axios.defaults.headers = { authorization: `Bearer ${token}` }
  localStorage.setItem(AuthKey, token)
}

export const getAuthToken = () => localStorage.getItem(AuthKey)

export function useTokenCheck() {}
