import { Button, Result } from 'antd'
import { PanelIndex } from 'common'
import { ReactMemoEx } from 'modules/view'
import React from 'react'
import PanelByConnected from './PanelByConncected'
import PanelByMediaCenter from './PanelByMediaCenter'
import PanelByMedia from './PanelByMedia'
import PanelByPlayList from './PanelByPlayList'
import PanelByShop from './PanelByShop'
import PanelByUpdateContents from './PanelByUpdateContents'
import PanelDashboardBrowser from './PanelDashboard'
import PanelByGroup from './PanelByGroup'
import PanelByMediaCenterByUser from './PanelByMediaCenterByUser'
import PanelByUser from './PanelByUser'
import PanelBySaleChannel from './PanelBySaleChannel'
import PanelByCountryCode from './PanelByCountryCode'
import PanelByPlayListByUser from './PanelByPlayListByUser'

export type PanelMapConfigProps = {
  panelIndex?: PanelIndex
}

const PanelMapConfig = (props: PanelMapConfigProps) => {
  const { panelIndex } = props
  if (panelIndex === PanelIndex.CountryCode) {
    return <PanelByCountryCode />
  }
  if (panelIndex === PanelIndex.SaleChannel) {
    return <PanelBySaleChannel />
  }
  if (panelIndex === PanelIndex.User) {
    return <PanelByUser />
  }
  if (panelIndex === PanelIndex.UserMediacenter) {
    return <PanelByMediaCenterByUser />
  }
  if (panelIndex === PanelIndex.MediaCenter) {
    return <PanelByMediaCenter />
  }
  // UserMediacenter
  if (panelIndex === PanelIndex.Group) {
    return <PanelByGroup />
  }
  if (panelIndex === PanelIndex.Contents) {
    return <PanelByMedia />
  }

  if (panelIndex === PanelIndex.Shop) {
    return <PanelByShop />
  }
  if (panelIndex === PanelIndex.Playlist) {
    //  관리 -> 편성표 관리
    return <PanelByPlayList />
  }
  if (panelIndex === PanelIndex.UpdateContents) {
    return <PanelByUpdateContents />
  }
  if (panelIndex === PanelIndex.Dashboard) {
    return <PanelDashboardBrowser />
  } else if (panelIndex === PanelIndex.Monitoring) {
    return <PanelByConnected />
  } else if (panelIndex === PanelIndex.PlayListUser) {
    return <PanelByPlayListByUser />
  }

  return (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, the server is wrong."
      extra={<Button type="primary">Back Home</Button>}
    />
  )
}

export default ReactMemoEx(PanelMapConfig)
