import { SaleChannel } from 'common'
import { reducerDatabase, actionsDatabase } from '../util'

type Base = SaleChannel
export type State = Base[]

export enum ActionTypes {
  ADD = '@@saleChannelList/ADD',
  ADDS = '@@saleChannelList/ADDS',
  REMOVE = '@@saleChannelList/REMOVE',
  REMOVES = '@@saleChannelList/REMOVES',
  SET_LIST = '@@saleChannelList/SET_LIST',
  UPDATE = '@@saleChannelList/UPDATE',
  UPDATES = '@@saleChannelList/UPDATES',
}

const actionDB = actionsDatabase<Base>(ActionTypes)

export const actions = {
  /**
   * SaleChannel 추가
   */
  addSaleChannel: actionDB.add,
  addSaleChannels: actionDB.adds,
  /**
   * SaleChannel 삭제
   */
  removeSaleChannel: actionDB.remove,
  removeSaleChannels: actionDB.removes,
  /**
   * SaleChannel List 셋팅
   */
  setSaleChannelList: actionDB.setList,
  /**
   * SaleChannel 업데이트
   */
  updateSaleChannel: actionDB.update,
  updateSaleChannels: actionDB.updates,
}

export const initialState: State = []

export const reducer = reducerDatabase(initialState, ActionTypes)
