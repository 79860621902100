import { blue, red } from '@ant-design/colors'
import {
  InfoCircleOutlined,
  MinusOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import { Button, Dropdown, Menu } from 'antd'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback } from 'react'
import styled from 'styled-components'

const IconInfoCircleOutlined = styled(InfoCircleOutlined)`
  margin-right: 0.5rem;
  color: ${blue.primary};
`
const IconMinusOutlined = styled(MinusOutlined)`
  margin-right: 0.5rem;
  color: ${red.primary};
`

export type ButtonMoreProps = {
  mediaId: string
  onClickInfoMedia?: (mediaId: string) => void
  onClickExceptionMedia?: (mediaId: string) => void
}

const ButtonMore = (props: ButtonMoreProps) => {
  const { mediaId, onClickExceptionMedia, onClickInfoMedia } = props

  const triggerEvent = useCallback(
    (type?: 'info' | 'exception') => () => {
      const trigger =
        type === 'exception' ? onClickExceptionMedia : onClickInfoMedia
      if (trigger) {
        trigger(mediaId)
      }
    },
    [mediaId, onClickExceptionMedia, onClickInfoMedia]
  )

  const menu = (
    <Menu>
      <Menu.Item onClick={triggerEvent('info')}>
        <IconInfoCircleOutlined />
        미디어 상세정보 보기
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={triggerEvent('exception')}>
        <IconMinusOutlined />
        재생목록에서 제외
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button
        className="more-button"
        type="ghost"
        shape="circle"
        size="small"
        icon={<MoreOutlined />}
      />
    </Dropdown>
  )
}

export default ReactMemoEx(ButtonMore)
