import { Table } from 'antd'
import { TableProps } from 'antd/lib/table'
import { groupBy } from 'lodash'
import { useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useMemo } from 'react'
import { useContextSelector } from 'use-context-selector'
import { parseMediaCenterAlphaList } from './Adapters'
import { createColumns } from './mediaCenter.columns'
import { ContextByMCTable } from './mediaCenter.context'
import TimeLineMediaCenter from './TimeLineMediaCenter'
import {
  GroupMap,
  MediaCenterAlpha,
  MediaCenterTablePickColumnOpt,
} from './types'

export type MediaCenterTableProps = TableProps<MediaCenterAlpha> &
  MediaCenterTablePickColumnOpt

const MediaCenterTable = (props: MediaCenterTableProps) => {
  const { disabledDelete, disabledEdit, pickColumn, ...rest } = props
  const shopFinder = useContextSelector(
    ContextByMCTable,
    state => state.shopFinder
  )
  const mediaCenterList = useReduxSelector(state => state.mediaCenterList)

  const [
    groupList,
    manufacturerList,
    countryCodeList,
    saleChannelList,
  ] = useReduxSelector(state => {
    const {
      groupList,
      manufacturerList,
      countryCodeList,
      saleChannelList,
    } = state
    return [groupList, manufacturerList, countryCodeList, saleChannelList]
  })

  const groupMap = useMemo((): GroupMap => {
    const output: GroupMap = {}
    const groups = groupBy(groupList, 'manufacturerId')
    manufacturerList.forEach(({ id: mId }) => {
      const groupList = groups[mId] || []
      output[mId] = {
        groupList,
        gorupIdIndex: groupList.map(({ id }) => id),
      }
    })
    return output
  }, [groupList, manufacturerList])

  const dataSource = useMemo(
    () => parseMediaCenterAlphaList(mediaCenterList, groupMap, shopFinder),
    [mediaCenterList, shopFinder, groupMap]
  )

  const [column, colWidth] = useMemo(
    () =>
      createColumns(
        groupList,
        manufacturerList,
        countryCodeList,
        saleChannelList,
        {
          disabledDelete,
          disabledEdit,
          pickColumn,
        }
      ),
    [
      countryCodeList,
      disabledDelete,
      disabledEdit,
      groupList,
      manufacturerList,
      pickColumn,
      saleChannelList,
    ]
  )

  const expandable = useMemo((): TableProps<MediaCenterAlpha>['expandable'] => {
    return {
      rowExpandable: () => true,
      expandedRowRender: (record, _, _2, expandabled) => {
        return expandabled ? (
          <TimeLineMediaCenter mediaCenterId={record.id} />
        ) : (
          <></>
        )
      },
    }
  }, [])

  return useMemo(
    () => (
      <Table
        {...rest}
        columns={column}
        size="small"
        className="table-mediacenter"
        bordered
        rowKey="id"
        dataSource={dataSource}
        expandable={expandable}
        scroll={{ x: colWidth + 48 }}
      />
    ),
    [dataSource, column, colWidth, expandable, rest]
  )
}

export default ReactMemoEx(MediaCenterTable)
