import { FileExcelFilled } from '@ant-design/icons'
import { Dropdown, Menu, Tag } from 'antd'
import Axios from 'axios'
import { Config } from 'common'
import dateFormat from 'dateformat'
import { useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { CSSProperties, useCallback, useMemo } from 'react'
import { getAuthToken } from 'modules/useLibs'

const style: CSSProperties = { cursor: 'pointer' }

const AdminExcelDownload = ReactMemoEx((props: { id: string }) => {
  const manufacturer = useReduxSelector(({ manufacturerList }) =>
    manufacturerList.find(({ id }) => props.id === id)
  )
  const onClickExcelDown = useCallback(() => {
    if (manufacturer === undefined) {
      return
    }
    Axios.get('/excel', {
      baseURL: Config.http,
      responseType: 'blob',
      params: {
        manufacturerId: manufacturer.id,
      },
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `${manufacturer.title} CMS Report_${dateFormat(
          Date.now(),
          'yyyymmdd'
        )}.xlsx`
      )
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }, [manufacturer])

  return (
    <>
      {manufacturer && (
        <Tag
          icon={<FileExcelFilled />}
          color="#38714A"
          style={style}
          onClick={onClickExcelDown}
        >
          다운로드
        </Tag>
      )}
    </>
  )
})

const SystemExcelDownload = ReactMemoEx(() => {
  const manufacturerList = useReduxSelector(
    ({ manufacturerList }) => manufacturerList
  )
  const onClickExcelDown = useCallback(
    (manufacturerId: string, title: string) => () => {
      const link = document.createElement('a')
      link.href = `${
        Config.http
      }/excel?manufacturerId=${manufacturerId}&token=${getAuthToken()}`
      link.setAttribute(
        'download',
        `${title} CMS Report 양식_${dateFormat(
          Date.now(),
          'yyyymmdd'
        )}.xlsx`
      )
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    []
  )
  const menu = useMemo(
    () => (
      <Menu>
        {manufacturerList.map(({ id, title }) => {
          return (
            <Menu.Item key={id} onClick={onClickExcelDown(id, title)}>
              {title}
            </Menu.Item>
          )
        })}
      </Menu>
    ),
    [manufacturerList, onClickExcelDown]
  )

  return (
    <Dropdown trigger={['click']} overlay={menu}>
      <Tag icon={<FileExcelFilled />} color="#38714A" style={style}>
        다운로드
      </Tag>
    </Dropdown>
  )
})

export const ExcelDownloadButton = ReactMemoEx(() => {
  const admin = useReduxSelector(state => state.auth.admin)

  if (admin.isLogin === false) {
    return null
  }
  return admin.manufacturerId ? (
    <AdminExcelDownload id={admin.manufacturerId} />
  ) : (
    <SystemExcelDownload />
  )
})
