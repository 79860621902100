import { getOf, Manufacturer } from 'common'
import { groupBy } from 'lodash'
import { ApplicationState } from '../state'
import { Manufacturer$, SaleChannel$ } from '../types'
import { getTVStatusByList, Finders } from './inLib'

const adapterManufacturer = (
  state: ApplicationState,
  saleChannelList$: SaleChannel$[],
  { groupByGroupId }: Finders
): Manufacturer$[] => {
  const { manufacturerList, mediaList } = state
  const groupBySaleCh$ = groupBy(saleChannelList$, 'manufacturerId')
  const groupByMedia$ = groupBy(mediaList, 'manufacturerId')

  // groupBy(state.mediaCenterList,'manu')
  // console.log("state.mediaCenterList", state.mediaCenterList)

  return manufacturerList.map(
    (manufacturer: Manufacturer): Manufacturer$ => {
      const { id } = manufacturer
      const saleChannelList = getOf(groupBySaleCh$, id, [])
      const mediaList = getOf(groupByMedia$, id, [])

      const shopCount = saleChannelList.reduce(
        (total, { shopCount }) => total + shopCount,
        0
      )
      const tvStatus = getTVStatusByList(
        saleChannelList,
        groupByGroupId[id] || []
      )

      const manufacturer$: Manufacturer$ = {
        ...manufacturer,
        mediaCount: mediaList.length,
        mediaSize: mediaList.reduce((total, item) => {
          return total + item.size
        }, 0),
        ...tvStatus,
        shopCount,
      }

      return manufacturer$
    }
  )
}
export default adapterManufacturer
