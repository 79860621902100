import { Breadcrumb } from 'antd'
import React, { useMemo } from 'react'
import { ReactMemoEx } from 'modules/view'

type Path =
  | {
      title: string
    }
  | string
export type TableBreadcrumbProps = {
  path?: Path | Path[]
}

const TableBreadcrumb = (props: TableBreadcrumbProps) => {
  const { path } = props
  const render = useMemo(() => {
    if (path === undefined) {
      return undefined
    }
    const mPath: string[] = []

    if (Array.isArray(path)) {
      path.forEach((item) => {
        if (typeof item === 'string') {
          mPath.push(item)
        } else {
          mPath.push(item.title)
        }
      })
    } else {
      if (typeof path === 'string') {
        mPath.push(path)
      } else {
        mPath.push(path.title)
      }
    }

    if (mPath.length === 0) {
      return null
    }
    return (
      <Breadcrumb>
        {mPath.map((title, i) => (
          <Breadcrumb.Item key={i} children={title} />
        ))}
      </Breadcrumb>
    )
  }, [path])
  return <>{render}</>
}

export default ReactMemoEx(TableBreadcrumb)
