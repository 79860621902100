import produce from 'immer'
import { $GetActionType, withReducer } from '../../util'
import { actions } from './actions'
import { ActionTypes, State } from './types'

export const initialState: State = {
  admin: { isLogin: false },
}

export const reducer = withReducer<State, $GetActionType<typeof actions>>(
  initialState,
  (state = initialState, action) => {
    if (action.type === ActionTypes.LOGIN) {
      const { payload } = action
      return produce(state, draft => {
        draft.admin = payload
      })
    }
    if (action.type === ActionTypes.LOGOUT) {
      return produce(state, draft => {
        draft.admin = { isLogin: false }
      })
    }
    return state
  }
)
