import { SubscriptionEntity, toComma, toEA, toStringPercent, ui } from 'common'
import { QueryHelper } from 'modules/graphql'
import { SaleChannel$ as DB } from 'modules/store'
import React from 'react'
import { EditCellType } from '../../../EditTable'
import {
  generateFilterColumn,
  generateNumberColumn,
  generateSearchColumn,
  genrateDeleteColumn,
} from '../../../GenerateColumn'
import { SearchState } from '../../../util'
import ActiveRender from '../../../ActiveRender'

type Options = {
  manufacturerList?: string[]
  disabledManufacturer?: boolean
}
/**
 * 제조사 정보
 * @param searchState
 * @param setSearchedColumn
 */
export default function createColumns(
  searchState: SearchState<DB>,
  setSearchedColumn: React.Dispatch<React.SetStateAction<SearchState<DB>>>,
  options: Options
): EditCellType<DB>[] {
  const { manufacturerList = [], disabledManufacturer = false } = options

  const columns: EditCellType<DB>[] = [
    // 판매채널명
    {
      editable: true,
      editType: 'string',
      ...generateSearchColumn<DB>(
        ui.createCol<DB>('판매채널명', ui.ColSize.xxl, {
          dataIndex: 'title',
          align: 'left',
        }),
        searchState,
        {
          onSearch: option => {
            setSearchedColumn(option)
          },
          onClear: option => {
            setSearchedColumn(option)
          },
        }
      ),
    },
  ]
  if (disabledManufacturer === false) {
    columns.push(
      generateFilterColumn<DB>(
        ui.createCol<DB>('제조사명', ui.ColSize.md, {
          dataIndex: 'manufacturerTitle',
          align: 'center',
        }),
        manufacturerList
      )
    )
  }
  columns.push(
    generateNumberColumn<DB>(
      ui.createCol<DB>('지점 수', ui.ColSize.md, {
        dataIndex: 'shopCount',
        render: toComma,
      })
    ),

    generateNumberColumn<DB>(
      ui.createCol<DB>('전체 TV', ui.ColSize.md, {
        dataIndex: 'tvTotalCount',
        render: toEA,
      })
    ),

    generateNumberColumn<DB>(
      ui.createCol<DB>('활성 TV', ui.ColSize.md, {
        dataIndex: 'tvActiveCount',
        render: toEA,
      })
    ),

    generateNumberColumn<DB>(
      ui.createCol<DB>('TV접속률', ui.ColSize.sm, {
        dataIndex: 'tvActivePercent',
        render: (percent: number) => (
          <ActiveRender percent={percent}>
            {toStringPercent(percent)}
          </ActiveRender>
        ),
      })
    ),

    genrateDeleteColumn<DB>(
      QueryHelper.removeOneById(SubscriptionEntity.SaleChannel)
    )
  )

  return columns
}
