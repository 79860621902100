import { useMutation } from '@apollo/react-hooks'
import { AdminEntity, MutationUpdateAdminArgs } from 'common'
import gql from 'graphql-tag'
import { Admin$ as DB, useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo, useState } from 'react'
import { EditTable } from '../../../EditTable'
import { SearchState } from '../../../util'
import createColumns from './admin.columns'
import { ContextByAdmin } from './admin.context'
import EditPwdPopup from './EditPwdPopup'

const MUTATION_ADMIN = gql`
  mutation UpdateAdmin(
    $adminLevel: Float
    $description: String
    $email: String!
    $id: String!
    $manufacturerId: String
    $phoneNumber: String
    $userName: String
  ) {
    Admin: updateAdmin(
      adminLevel: $adminLevel
      description: $description
      email: $email
      id: $id
      manufacturerId: $manufacturerId
      phoneNumber: $phoneNumber
      userName: $userName
    ) {
      id
      email
      userName
      phoneNumber
      adminLevel
      manufacturerId
      description
    }
  }
`
export const TableAdmin = ReactMemoEx(() => {
  const [pwdEditAdmin, setPwdEditAdmin] = useState<AdminEntity | undefined>(
    undefined
  )

  const [updateAdmin, { loading }] = useMutation<
    { Admin: AdminEntity },
    MutationUpdateAdminArgs
  >(MUTATION_ADMIN)
  const adminList = useReduxSelector(state => state.adminList)
  const [searchState, setSearchedColumn] = useState<SearchState<DB>>([])

  const columns = useMemo(() => createColumns(searchState, setSearchedColumn), [
    searchState,
  ])

  const onEdit = useCallback(
    (newData: DB) => {
      const { id, email, description, userName, phoneNumber } = newData
      updateAdmin({
        variables: { id, email, description, userName, phoneNumber },
      })
    },
    [updateAdmin]
  )

  return (
    <ContextByAdmin.Provider value={{ setPwdEditAdmin, pwdEditAdmin }}>
      <EditTable<DB>
        loading={loading}
        columns={columns}
        dataSource={adminList}
        onEdit={onEdit}
        rowKey="email"
      />
      <EditPwdPopup />
    </ContextByAdmin.Provider>
  )
})
