import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { LogList, QueryFindStatsArgs } from 'common'

export const QUERY_FINDSTATS = gql`
  query FindStats($unit: StatsUnit!, $standard: StatsStandard!) {
    statsList: findStats(unit: $unit, standard: $standard) {
      id
      list {
        date
        activeRate
        offlineRate
        updateRate
        errorRate
      }
    }
  }
`
export type ResultStats = {
  statsList: Array<LogList>
}

export const useFindStatsQuery = (variables: QueryFindStatsArgs) => {
  const values = useQuery<ResultStats, QueryFindStatsArgs>(QUERY_FINDSTATS, {
    variables,
  })
  return values
}
