import {
  StateByPLMType,
  ActionByPLM,
  initialStateByPLM,
  ActionByPLMTypes,
} from './PLM.types'
import produce from 'immer'

export const reducerByPLM: React.Reducer<StateByPLMType, ActionByPLM> = (
  state = initialStateByPLM,
  action
) => {
  if (action.type === ActionByPLMTypes.SET_TREE) {
    const { payload } = action
    return produce(state, draft => {
      draft.fkGSId = payload.fkGSId
      draft.fkGSSId = payload.fkGSSId
      draft.groupId = payload.groupId
      if (state.manufacturerId !== payload.manufacturerId) {
        draft.selectMediaList = []
      }
      draft.manufacturerId = payload.manufacturerId
    })
  }

  if (action.type === ActionByPLMTypes.SET_SELECT_MEDIALIST) {
    const { payload } = action
    return produce(state, draft => {
      draft.selectMediaList = payload
    })
  }

  if (action.type === ActionByPLMTypes.SHOW_MEDIAINFO) {
    const { payload } = action
    return produce(state, draft => {
      if (Array.isArray(payload)) {
        draft.infoMediaListId = payload
      } else {
        draft.infoMediaListId = [payload]
      }
    })
  }

  if (action.type === ActionByPLMTypes.HIDE_MEDIAINFO) {
    return produce(state, draft => {
      if (
        state.infoMediaListId !== undefined &&
        state.infoMediaListId.length >= 1
      ) {
        draft.infoMediaListId = []
      }
    })
  }

  if (action.type === ActionByPLMTypes.REDUX_UPDATE_MEDIA) {
    // 미디어 업데이트
    const { payload } = action
    return produce(state, draft => {
      draft.selectMediaList = state.selectMediaList.filter(
        mId => payload.findIndex(({ id }) => mId === id) >= 0
      )
    })
  }

  if (action.type === ActionByPLMTypes.REDUX_UPDATE_MANUFACTURER) {
    // 제조사 업데이트
    const { payload } = action
    const { manufacturerId } = state
    if (manufacturerId === undefined) {
      return state
    }
    const index = payload.findIndex(({ id }) => id === manufacturerId)
    if (index >= 0) {
      return state
    }
    return produce(state, draft => {
      draft.fkGSId = undefined
      draft.fkGSSId = undefined
      draft.groupId = undefined
      draft.manufacturerId = undefined
      draft.selectMediaList = []
    })
  }

  if (action.type === ActionByPLMTypes.REDUX_UPDATE_GROUP) {
    // 제조사 업데이트
    const { payload } = action
    const { groupId } = state
    if (groupId === undefined) {
      return state
    }
    const index = payload.findIndex(({ id }) => id === groupId)
    if (index >= 0) {
      return state
    }
    return produce(state, draft => {
      draft.fkGSId = undefined
      draft.fkGSSId = undefined
      draft.groupId = undefined
    })
  }

  if (action.type === ActionByPLMTypes.REDUX_UPDATE_FKGS) {
    // 제조사 업데이트
    const { payload } = action
    const { fkGSId } = state
    if (fkGSId === undefined) {
      return state
    }
    const index = payload.findIndex(({ id }) => id === fkGSId)
    if (index >= 0) {
      return state
    }
    return produce(state, draft => {
      draft.fkGSId = undefined
      draft.fkGSSId = undefined
    })
  }

  if (action.type === ActionByPLMTypes.REDUX_UPDATE_FKGSS) {
    // 제조사 업데이트
    const { payload } = action
    const { fkGSSId } = state
    if (fkGSSId === undefined) {
      return state
    }
    const index = payload.findIndex(({ id }) => id === fkGSSId)
    if (index >= 0) {
      return state
    }
    return produce(state, draft => {
      draft.fkGSSId = undefined
    })
  }
  return state
}
