import React from 'react'
import { MediaCenterAlpha } from './types'
import { Tooltip } from 'antd'
import { TVInfoTooltip } from '../../../../../TVInfoTooltip'
import ActiveRender from '../../../ActiveRender'
import ActiveOfflineError from './ActiveOfflineError'
import { toStringPercent } from 'common'
import { ReactMemoEx } from 'modules/view'

type ConnectionRenderProps = {
  data: MediaCenterAlpha
}

const ConnectionRender = (props: ConnectionRenderProps) => {
  const {
    totalTVActivePercent,
    totalTVErrorCount,
    totalTVActiveCount,
    totalTVOfflineCount,
  } = props.data
  return (
    <Tooltip
      title={
        <TVInfoTooltip
          active={totalTVActiveCount}
          error={totalTVErrorCount}
          offline={totalTVOfflineCount}
        />
      }
    >
      <div>
        <ActiveRender
          children={toStringPercent(totalTVActivePercent)}
          percent={totalTVActivePercent}
        />
        <ActiveOfflineError
          active={totalTVActiveCount}
          error={totalTVErrorCount}
          offline={totalTVOfflineCount}
        />
      </div>
    </Tooltip>
  )
}

export default ReactMemoEx(ConnectionRender)
