import React, { useMemo } from 'react'
import styled from 'styled-components'
import { ReactMemoEx } from './reactSupport'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

type ContainerProps = {
  isSizeFull?: boolean
  marginRem?: number | [number, number, number, number]
  flex?: number
}

const getSizeAndMargin = (
  props: Pick<ContainerProps, 'isSizeFull' | 'marginRem'>
) => {
  const { marginRem, isSizeFull } = props
  if (isSizeFull === undefined || isSizeFull === false) {
    if (marginRem === undefined) {
      return ''
    }
    if (Array.isArray(marginRem)) {
      return `margin: ${marginRem.map(value => `${value}rem`).join(' ')};`
    }
    return `margin: ${marginRem}rem;`
  }

  if (Array.isArray(marginRem)) {
    const [top, right, bottom, left] = marginRem
    return `
    width: calc(100% - ${right + left}rem);
    height: calc(100% - ${top + bottom}rem);
    margin: ${marginRem.map(value => `${value}rem`).join(' ')};
    `
  }
  if (marginRem === undefined) {
    return `
    width:100%;
    height:100%;
    `
  }

  const calc = `calc(100% - ${marginRem * 2}rem)`
  return `
    width: ${calc};
    height: ${calc};
    margin: ${marginRem}rem;
  `
}

const Container = styled.div<ContainerProps>`
  position: relative;
  overflow: hidden;
  ${props => getSizeAndMargin(props)}
  ${({ flex }) => (flex === undefined ? '' : `flex:${flex};`)}
`

const InnerContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
`

export type ViewContainerProps = React.HTMLAttributes<HTMLDivElement> &
  ContainerProps

export const ViewContainer = ReactMemoEx((props: ViewContainerProps) => {
  const { children, ...rest } = props
  return (
    <Container {...rest}>
      <InnerContainer>{children}</InnerContainer>
    </Container>
  )
})

export type BreakPoint = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs'

export const useBreakpointEx = () => {
  const breakpoint = useBreakpoint()
  return useMemo((): BreakPoint => {
    if (breakpoint.xxl) {
      return 'xxl'
    }
    if (breakpoint.xl) {
      return 'xl'
    }
    if (breakpoint.lg) {
      return 'lg'
    }
    if (breakpoint.md) {
      return 'md'
    }
    if (breakpoint.sm) {
      return 'sm'
    }
    if (breakpoint.xs) {
      return 'xs'
    }
    return 'xs'
  }, [breakpoint])
}
