import { useApolloClient } from '@apollo/react-hooks'
import { Badge, Button, Divider, Typography } from 'antd'
import { Event, PlayList } from 'common'
import { ContextByPLM } from 'components/src/PlayListManager/features/StorePLM'
import dateFormat from 'dateformat'
import { $AsyncActions, useReduxSelector } from 'modules/store'
import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useContextSelector } from 'use-context-selector'
import DatePickPopconfirm from './DatePickPopconfirm'
import ModalCalendar from './ModalCalendar'
const { Text } = Typography

const Title = (props: { eventList: Event[] }) => {
  const { eventList } = props
  const size = eventList.length

  return (
    <div className="event-status">
      <Badge
        className="site-badge-count-109"
        count={eventList.length}
        style={{ backgroundColor: '#52c41a' }}
        offset={[8, 0]}
      >
        <Text>
          <strong>{size <= 0 ? '예약 없음' : '예약:'}</strong>
        </Text>
      </Badge>

      {eventList.length >= 1 && (
        <Text type="secondary" style={{ marginLeft: 20 }}>
          {`${dateFormat(eventList[0].startTime, 'yyyy-mm-dd')} ${
            size <= 2 ? '' : ''
          }`}
        </Text>
      )}
    </div>
  )
}
/**
 * 예약 등록 button
 */
const ButtonRegister = styled(Button)`
  border-radius: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-right-color: rgba(255, 255, 255, 0.3);
`
type EventReservationProps = {
  playList: PlayList
}

const EventReservation = (props: EventReservationProps) => {
  const { playList } = props
  const { id } = playList
  const [isVisibleModalCalendar, setVisibleModalCalendar] = useState(false)
  const gqlClient = useApolloClient()
  const dispatch = useDispatch()

  const mediaList = useContextSelector(
    ContextByPLM,
    state => state[0].selectMediaList
  )

  const eventList = useReduxSelector(state => {
    const now = Date.now()
    return state.eventList
      .filter(({ playListId, startTime }) => {
        const isPlayList = id === playListId
        if (isPlayList && typeof startTime === 'number') {
          return startTime >= now
        }
        return false
      })
      .sort((a, b) => {
        if (
          typeof a.startTime === 'number' &&
          typeof b.startTime === 'number'
        ) {
          return a.startTime - b.startTime
        }
        return 0
      })
  })

  useEffect(() => {
    if (eventList.length === 0) {
      setVisibleModalCalendar(false)
    }
  }, [eventList])

  const onClickDateReservation = useCallback(
    (time: number, type) => {
      dispatch(
        $AsyncActions.addPlayListEvent(gqlClient, {
          playListId: id,
          mediaList,
          start: time,
          type,
        })
      )
    },
    [dispatch, gqlClient, id, mediaList]
  )

  const toggleVisibleModalCalendar = useCallback(() => {
    setVisibleModalCalendar(!isVisibleModalCalendar)
  }, [isVisibleModalCalendar])

  return (
    <div className="event-reservation">
      <Title eventList={eventList} />
      <Divider type="vertical" style={{ height: '100%' }} />
      <Badge count={mediaList.length}>
        <div>
          <div className="kbl-btn-container">
            <ButtonRegister
              size="small"
              type="primary"
              disabled={eventList.length <= 0}
              onClick={toggleVisibleModalCalendar}
            >
              보기
            </ButtonRegister>
          </div>
          <DatePickPopconfirm
            mediaSize={mediaList.length}
            onClick={onClickDateReservation}
          />
        </div>
      </Badge>
      <ModalCalendar
        visible={isVisibleModalCalendar}
        onCancel={toggleVisibleModalCalendar}
        eventList={eventList}
        playList={playList}
      />
    </div>
  )
}

export default EventReservation
