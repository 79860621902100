import { useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useMemo } from 'react'
import { COLOR_ERROR, COLOR_PRIMARY, COLOR_SUB, toPercent } from './inLibs'
import TabChartContainer, { TabChartItemType } from './TabChartContainer'
import { toEA } from 'common'

const backgroundColor = [COLOR_PRIMARY, COLOR_SUB, COLOR_ERROR]

export const MonitoringSaleChannel = ReactMemoEx(() => {
  const saleChannelList = useReduxSelector(
    ({ saleChannelList$ }) => saleChannelList$
  )

  const data = useMemo(() => {
    return saleChannelList.map(
      (saleChannel): TabChartItemType => {
        const {
          tvActiveCount,
          tvOfflineCount,
          tvErrorCount,
          tvTotalCount,
          tvActivePercent,
          title,
        } = saleChannel
        const data = [tvActiveCount, tvOfflineCount, tvErrorCount]
        const errorValue = tvErrorCount / tvTotalCount
        return {
          title,
          subTitle: toEA(saleChannel.tvTotalCount),
          value: toPercent(tvActivePercent),
          errorValue: `${toPercent(errorValue)}%`,
          datasets: [{ data, backgroundColor }],
          key: saleChannel.id,
        }
      }
    )
  }, [saleChannelList])

  return <TabChartContainer data={data} />
})
