import { useLazyQuery } from '@apollo/react-hooks'
import { Spin } from 'antd'
import { useDidMount } from 'beautiful-react-hooks'
import { ChartDataSets, ChartOptions } from 'chart.js'
import color from 'color'
import { findCacheById, MediaLogInfo, toStringFileSize } from 'common'
import gql from 'graphql-tag'
import { useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useEffect, useMemo, useRef } from 'react'
import { Bar } from 'react-chartjs-2'
import { getChartBackgroundByIndex } from '../../libs'

const QUERY_MEDIA_LOG = gql`
  query FindMediaLog {
    mediaLogList: findMediaLog {
      date
      list {
        manufacturerId
        count
        size
      }
    }
  }
`

type ResultMediaLog = {
  mediaLogList: MediaLogInfo[]
}

type AdapteData = {
  title: string
  id: string
  countList: number[]
  sizeList: number[]
}

export const ChartContentSize = ReactMemoEx(() => {
  const manufacturerList = useReduxSelector(state => state.manufacturerList)
  const mediaList = useReduxSelector(state => state.mediaList)
  const refIsMount = useRef(false)
  const [
    requestMediaLog,
    { data: { mediaLogList } = { mediaLogList: [] }, loading },
  ] = useLazyQuery<ResultMediaLog>(QUERY_MEDIA_LOG, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (refIsMount.current) {
      requestMediaLog()
    }
    return () => {}
  }, [mediaList, requestMediaLog])

  useDidMount(() => {
    refIsMount.current = true
    requestMediaLog()
  })

  const [labels, datasets, dataPaseList] = useMemo((): [
    string[],
    ChartDataSets[],
    AdapteData[]
  ] => {
    const labelList: string[] = []
    const dataMap = new Map<
      string,
      {
        title: string
        id: string
        countList: number[]
        sizeList: number[]
      }
    >()

    const findManufacturer = findCacheById(manufacturerList)

    mediaLogList.forEach(logByDate => {
      labelList.push(logByDate.date)
      logByDate.list.forEach(manufacturerByLog => {
        const { count, size, manufacturerId } = manufacturerByLog
        // const size = randomScalingFactor() * 2222222
        // const count = randomScalingFactor()
        const item = dataMap.get(manufacturerId)

        if (item === undefined) {
          const manufacturer = findManufacturer(manufacturerId)
          dataMap.set(manufacturerId, {
            id: manufacturerId,
            sizeList: [size],
            countList: [count],
            title: manufacturer ? manufacturer.title : '',
          })
        } else {
          item.sizeList.push(size)
          item.countList.push(count)
        }
      })
    })

    const datasetList: ChartDataSets[] = []

    const adapteData: AdapteData[] = []
    dataMap.forEach(c => {
      adapteData.push(c)
      const { countList, sizeList, title: label } = c
      const i = datasetList.length
      const datasetColor = getChartBackgroundByIndex(i)

      datasetList.push({
        label,
        // order:i,
        ...datasetColor,
        stack: '0',
        data: sizeList,
        datalabels: {
          color: '#ffffff',
          formatter: (value: number, context) => {
            const { dataIndex } = context
            const count = countList[dataIndex]
            return `${toStringFileSize(value)}(${count})`
          },
          display: context => {
            const { dataIndex } = context
            const count = countList[dataIndex]
            return count >= 1
          },
          backgroundColor: datasetColor.borderColor
            ? color(datasetColor.borderColor.toString())
                .darken(0.5)
                .toString()
            : undefined,
          font: { weight: 'bold' },
          offset: 0,
          padding: 2,
        },
      })
    })

    return [labelList, datasetList, adapteData]
  }, [mediaLogList, manufacturerList])

  const options = useMemo(
    (): ChartOptions => ({
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        position: 'bottom',
        display: dataPaseList.length >= 2,
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: tooltipItem => {
            const { datasetIndex = -1, index = -1 } = tooltipItem
            const dataAdapte = dataPaseList[datasetIndex]
            if (dataAdapte === undefined) {
              return ''
            }
            const size = dataAdapte.sizeList[index]
            const count = dataAdapte.countList[index]
            return `${dataAdapte.title}: ${toStringFileSize(size)}(${count})`
          },
        },
      },
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: value => {
                if (value < 1) {
                  return toStringFileSize(0)
                }
                return toStringFileSize(value)
              },
            },
          },
          // {
          //   id: 'B',
          //   type: 'linear',
          //   position: 'right',
          // },
        ],
      },
    }),
    [dataPaseList]
  )
  return useMemo(
    () => (
      <>
        <Bar
          options={options}
          data={{
            labels,
            datasets,
          }}
        />
        {loading && (
          <div className="c-spin">
            <Spin />
          </div>
        )}
      </>
    ),
    [options, labels, datasets, loading]
  )
})
