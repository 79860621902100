import { Card, Col, Row } from 'antd'
import { TabCharts, TableMediaCenterUpdate } from 'components'
import { ReactMemoEx } from 'modules/view'
import React from 'react'
// const mId = '2584b822-8a10-4379-b63c-de7a822c3def'
const PanelByUpdateContents = () => {
  // const state = useReduxSelector(state => state.auth)
  // const manufacturerList = useReduxSelector(state => state.manufacturerList)

  // const pickColumns = useMemo((): TableMediaProps['pickColumn'] => {
  //   if (state.admin.isLogin && state.admin.manufacturerId) {
  //     return ['fileName', 'size', 'createAt']
  //   }
  //   return ['fileName', 'manufacturerTitle', 'size', 'createAt']
  // }, [state])

  // const selectManufacturer = useMemo(() => {
  //   if (state.admin.isLogin && state.admin.manufacturerId) {
  //     const { manufacturerId } = state.admin
  //     return manufacturerList.find(({ id }) => id === manufacturerId)
  //   }
  //   return undefined
  // }, [manufacturerList, state.admin])

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
          title="현재 업데이트 상태"
        >
          <TabCharts.UpdateSaleChannel />
        </Card>
      </Col>
      {/* <Col xs={24} sm={24} md={24} lg={12}>
        <ChartPanelByUpdateContents />
      </Col> */}
      {/* <Col xs={24} sm={24} md={24} lg={12}>
        <Card style={{ height: '100%' }} title="콘텐츠 리스트 (현재)">
          <TableMedia
            cardOptions={{ title: '미디어 리스트', style: { height: '100%' } }}
            pickColumn={pickColumns}
            manufacturer={selectManufacturer ? selectManufacturer : undefined}
            disabledDelete={true}
            disabledEdit={true}
          />
        </Card>
      </Col> */}
      <Col span={24}>
        <Card>
          <TableMediaCenterUpdate />
          {/* <TableMediaCenter
            pickColumn={[
              'mac',
              'manufacturerTitle',
              'saleChannelTitle',
              'shopTitle',
              'countryTitle',
              'tvInfoGroup',
              'totalMediaUpdate',
              'updateAt',
            ]}
            disabledDelete={true}
            disabledEdit={true}
          /> */}
        </Card>
      </Col>
    </Row>
  )
}

export default ReactMemoEx(PanelByUpdateContents)
