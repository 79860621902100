import { Badge, Button, Popconfirm, Tooltip } from 'antd'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo } from 'react'
import { useContextSelector } from 'use-context-selector'
import { ContextByPLM } from '../../StorePLM'
import { useDispatch } from 'react-redux'
import { $AsyncActions } from 'modules/store'
import { useApolloClient } from '@apollo/react-hooks'
import { DeleteOutlined } from '@ant-design/icons'

const ButtonRemove = () => {
  const gqlClient = useApolloClient()
  const dispatch = useDispatch()
  const selectMediaList = useContextSelector(
    ContextByPLM,
    state => state['0'].selectMediaList
  )

  const count = useMemo(() => selectMediaList.length, [selectMediaList.length])

  const onConfirm = useCallback(() => {
    dispatch($AsyncActions.removeMediaList(gqlClient, selectMediaList))
  }, [dispatch, selectMediaList, gqlClient])

  return (
    <Popconfirm
      title={`선택한 미디어 ${count}개를 삭제하시겠습니까?`}
      okText="확인"
      cancelText="취소"
      placement="bottom"
      onConfirm={onConfirm}
    >
      <Badge count={count}>
        <Tooltip title="선택 삭제">
          <Button
            type="primary"
            shape="circle"
            danger
            disabled={count <= 0}
            icon={<DeleteOutlined />}
          />
        </Tooltip>
      </Badge>
    </Popconfirm>
  )
}

export default ReactMemoEx(ButtonRemove)
