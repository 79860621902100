import { useMutation, MutationTuple } from '@apollo/react-hooks'
import {
  Group,
  Manufacturer,
  MediaCenter,
  MutationAddGroupArgs,
  MutationAddManufacturerArgs,
  MutationAddMediaCenterArgs,
  MutationAddSaleChannelArgs,
  MutationAddShopArgs,
  SaleChannel,
  Shop,
  SubscriptionEntity,
  MutationAddAdminArgs,
  AdminEntity,
  MutationAddAmCodeArgs,
  AmCode,
  MutationAddCountryCodeArgs,
  CountryCode,
} from 'common'
import gql from 'graphql-tag'
import { MTArgs } from './ArgTypes'
import {
  FRAG_GROUP,
  FRAG_MANUFACTURER,
  FRAG_SALECHANNEL,
  FRAG_SHOP,
  FRAG_ADMIN,
  FRAG_AMCODE,
  FRAG_COUNTRY_CODE,
} from './queryFindOneMap'
import { DocumentNode } from 'graphql'

function createAddMutation<T>(
  entity: SubscriptionEntity,
  args: MTArgs<T>[],
  frag: string
) {
  let argParams = ''
  let argArgsPrams = ''
  args.forEach(arg => {
    const [key, valueType] = arg
    const mKey = String(key)
    argParams += `$${mKey}: ${valueType}\n`
    argArgsPrams += `${mKey}: $${mKey}\n`
  })
  return gql`
  mutation Add${entity}(
   ${argParams}
  ) {
    ${entity}: add${entity}(
      ${argArgsPrams}
    ){
      ${frag}
    }
  }
  `
}

function createAddMutationAndMutation<T, R>(
  entity: SubscriptionEntity,
  args: MTArgs<T>[],
  frag: string
): [DocumentNode, () => MutationTuple<R, T>] {
  let argParams = ''
  let argArgsPrams = ''
  args.forEach(arg => {
    const [key, valueType] = arg
    const mKey = String(key)
    argParams += `$${mKey}: ${valueType}\n`
    argArgsPrams += `${mKey}: $${mKey}\n`
  })
  const mDocument = gql`
  mutation Add${entity}(
   ${argParams}
  ) {
    ${entity}: add${entity}(
      ${argArgsPrams}
    ){
      ${frag}
    }
  }
  `
  return [
    mDocument,
    () => {
      return useMutation<R, T>(mDocument)
    },
  ]
}

/**
 * Group 생성
 */
export const [
  MUTATION_ADD_GROUP,
  useMutationAddGroup,
] = createAddMutationAndMutation<
  MutationAddGroupArgs,
  {
    Group: Group
  }
>(
  SubscriptionEntity.Group,
  [
    ['deviceRegexr', 'String'],
    ['description', 'String'],
    ['manufacturerId', 'String!'],
    ['title', 'String!'],
  ],
  FRAG_GROUP
)

/**
 * 제조사 생성
 */
export const MUTATION_ADD_MANUFACTURER = createAddMutation<
  MutationAddManufacturerArgs
>(SubscriptionEntity.Manufacturer, [['title', 'String!']], FRAG_MANUFACTURER)

/**
 * 제조사 생성 Mutation
 */
export const useMutationAddManufacturer = () => {
  return useMutation<
    {
      Manufacturer: Manufacturer
    },
    MutationAddManufacturerArgs
  >(MUTATION_ADD_MANUFACTURER)
}

/**
 * MediaCenter 생성
 */
export const MUTATION_MEDIACENTER = createAddMutation<
  MutationAddMediaCenterArgs
>(
  SubscriptionEntity.MediaCenter,
  [
    ['mac', 'String!'],
    ['shopId', 'String!'],
  ],
  `
 id
 mac
 `
)

/**
 * MediaCenter 생성 Mutation
 */
export const useMutationAddMediaCenter = () => {
  return useMutation<
    {
      MediaCenter: MediaCenter
    },
    MutationAddMediaCenterArgs
  >(MUTATION_MEDIACENTER)
}

/**
 * 판매채널 생성
 */
export const MUTATION_ADD_SALECHNNAEL = createAddMutation<
  MutationAddSaleChannelArgs
>(
  SubscriptionEntity.SaleChannel,
  [
    ['manufacturerId', 'String!'],
    ['title', 'String!'],
  ],
  FRAG_SALECHANNEL
)

/**
 * salechannel 생성 mutation
 */
export const useMutationAddSaleChannel = () => {
  return useMutation<
    {
      SaleChannel: SaleChannel
    },
    MutationAddSaleChannelArgs
  >(MUTATION_ADD_SALECHNNAEL)
}

/**
 * shop 생성
 */
export const MUTATION_ADD_SHOP = createAddMutation<MutationAddShopArgs>(
  SubscriptionEntity.Shop,
  [
    ['address', 'String!'],
    ['countryCodeId', 'String'],
    ['lat', 'Float'],
    ['lng', 'Float'],
    ['shopCode', 'String'],
    ['saleChannelId', 'String!'],
    ['title', 'String!'],
  ],
  FRAG_SHOP
)
/**
 * shop 생성 mutation
 */
export const useMutationAddShop = () => {
  return useMutation<
    {
      Shop: Shop
    },
    MutationAddShopArgs
  >(MUTATION_ADD_SHOP)
}

export const MUTATION_ADD_ADMIN = createAddMutation<MutationAddAdminArgs>(
  SubscriptionEntity.Admin,
  [
    ['adminLevel', 'Float!'],
    ['description', 'String'],
    ['email', 'String!'],
    ['manufacturerId', 'String'],
    ['password', 'String!'],
    ['phoneNumber', 'String!'],
    ['userName', 'String!'],
  ],
  FRAG_ADMIN
)

export const useMutationAddAdmin = () => {
  return useMutation<
    {
      Admin: AdminEntity
    },
    MutationAddAdminArgs
  >(MUTATION_ADD_ADMIN)
}

export const MUTATION_ADD_EVENT = gql`
  mutation AddEvent(
    $mediaList: [String!]!
    $playListId: String!
    $start: Timestamp!
    $type: EventType!
  ) {
    event: addEvent(
      mediaList: $mediaList
      playListId: $playListId
      start: $start
      type: $type
    ) {
      id
      startTime
      type
      isComplete
      createAt
      updateAt
      playListId
      mediaIds
    }
  }
`

export const [
  MUTATION_ADD_AMCODE,
  useMutationAddAmCode,
] = createAddMutationAndMutation<
  MutationAddAmCodeArgs,
  {
    AMCode: AmCode
  }
>(
  SubscriptionEntity.AmCode,
  [
    ['code', 'String!'],
    ['manufacturerId', 'String!'],
  ],
  FRAG_AMCODE
)

export const [
  MUTATION_ADD_COUNTRY_CODE,
  useMutationAddCountryCode,
] = createAddMutationAndMutation<
  MutationAddCountryCodeArgs,
  {
    CountryCode: CountryCode
  }
>(
  SubscriptionEntity.CountryCode,
  [
    ['amCodeId', 'String!'],
    ['manufacturerId', 'String!'],
    ['title', 'String!'],
  ],
  FRAG_COUNTRY_CODE
)
