import { ColProps } from 'antd/lib/col'

export const defaultMinColProps: ColProps = {
  xxl: 6,
  xl: 6,
  md: 12,
  sm: 24,
  xs: 24,
}

export const defaultColProps: ColProps = {
  xxl: 8,
  xl: 8,
  md: 12,
  sm: 24,
  xs: 24,
}

export const defaultColLargeProps: ColProps = {
  xxl: 12,
  xl: 12,
  md: 12,
  sm: 24,
  xs: 24,
}
