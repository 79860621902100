import { LogStatItem } from 'modules/store'
import { SaleChannel, findDataById, toStringPercent } from 'common'
import { ChartComponentProps } from 'react-chartjs-2'
import {
  sampleColors,
  getChartBackground,
  defaultChartOptions,
} from '../../libs'

export const parseDatasetUpdate = (
  stateItem: LogStatItem,
  saleChannelList: SaleChannel[]
): ChartComponentProps => {
  const lastData = stateItem.logList[stateItem.logList.length - 1]
  const data: number[] = []
  const labels: string[] = []
  if (lastData) {
    lastData.list.forEach(logStat => {
      const { updateRate, id } = logStat
      const saleChannel = findDataById(saleChannelList, id)
      if (saleChannel === undefined) {
        return
      }
      labels.push(saleChannel.title)
      data.push(updateRate)
    })
  }

  return {
    options: {
      legend: { display: false },
      ...defaultChartOptions,
      plugins: {
        datalabels: {
          color: 'white',
          formatter: value => toStringPercent(value),
          font: { weight: 'bold' },
          offset: 0,
          padding: 0,
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0,
              max: 1,
              callback: value => toStringPercent(value),
            },
          },
        ],
      },
      tooltips: {
        mode: 'index',
        intersect: true,
        callbacks: {
          label: (tooltipItem, data) => {
            if (
              data.datasets !== undefined &&
              tooltipItem.datasetIndex !== undefined
            ) {
              return `${
                data.datasets[tooltipItem.datasetIndex].label
              }: ${toStringPercent(tooltipItem.value)}`
            }
            return toStringPercent(tooltipItem.value)
          },
        },
      },
    },
    data: {
      labels,
      datasets: [
        {
          ...getChartBackground(sampleColors.blue),
          label: '업데이트',
          data,
        },
      ],
    },
  }
}
