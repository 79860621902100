import { useMutation } from '@apollo/react-hooks'
import {
  Group,
  Manufacturer,
  MutationUpdateGroupArgs,
  toArrayKeyValue,
  isDuplicateTitle,
  Language,
} from 'common'
import { TableCard } from 'components/src/CustomCard'
import gql from 'graphql-tag'
import { Group$ as DB, Group$, useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo, useState } from 'react'
import { EditTable, EditTableFormValidation } from '../../../EditTable'
import { SearchState } from '../../../util'
import generateColumn from './group.columns'

type TableGroupProps = {
  manufacturer?: Manufacturer
}

const MUTATION_UPDATE_GROUP = gql`
  mutation UpdateGroup(
    $id: String!
    $deviceRegexr: String
    $description: String
    $title: String
  ) {
    Group: updateGroup(
      id: $id
      title: $title
      deviceRegexr: $deviceRegexr
      description: $description
    ) {
      id
      title
      description
      deviceRegexr
    }
  }
`

/**
 * 제조사 테이블
 */
export const TableGroup = ReactMemoEx((props: TableGroupProps) => {
  const { manufacturer } = props
  // const [form] = Form.useForm()

  const [updateGroup, { loading }] = useMutation<
    { Group: Group },
    MutationUpdateGroupArgs
  >(MUTATION_UPDATE_GROUP)
  const [searchState, setSearchedColumn] = useState<SearchState<DB>>([])

  const [dataSource, fullGroupList] = useReduxSelector(state => {
    const { groupList$ } = state
    let filterGroupList$: Group$[] = groupList$

    if (manufacturer) {
      const { id } = manufacturer
      filterGroupList$ = state.groupList$.filter(
        ({ manufacturerId }) => manufacturerId === id
      )
    }
    return [filterGroupList$, groupList$]
  })

  const manufacturerList = useReduxSelector(state => {
    if (manufacturer === undefined) {
      return toArrayKeyValue(state.manufacturerList$, 'title') as string[]
    }
    return []
  })

  const columns = useMemo(
    () =>
      generateColumn(searchState, setSearchedColumn, {
        disabledManufacturer: manufacturer !== undefined,
        manufacturerList: manufacturerList,
      }),
    [searchState, setSearchedColumn, manufacturerList, manufacturer]
  )

  const onEdit = useCallback(
    (newData: DB) => {
      const { id, description, deviceRegexr, title } = newData
      updateGroup({
        variables: { id, description, deviceRegexr, title },
      })
    },
    [updateGroup]
  )

  const formValidation: EditTableFormValidation<DB> = useCallback(
    (newData: DB) => {
      const { id, title } = newData
      if (isDuplicateTitle(fullGroupList, title, id)) {
        return [
          {
            name: 'title',
            errors: [Language.ErrorMessages.duplicateGroupTitle],
          },
        ]
      }
      return undefined
    },
    [fullGroupList]
  )

  return (
    <TableCard>
      <EditTable<DB>
        loading={loading}
        bordered
        columns={columns}
        dataSource={dataSource}
        rowKey="id"
        formValidation={formValidation}
        onEdit={onEdit}
      />
    </TableCard>
  )
})
