import { Manufacturer } from 'common'
import { reducerDatabase, actionsDatabase } from '../util'

type Base = Manufacturer
export type State = Base[]

export enum ActionTypes {
  ADD = '@@manufacturerList/ADD',
  ADDS = '@@manufacturerList/ADDS',
  REMOVE = '@@manufacturerList/REMOVE',
  REMOVES = '@@manufacturerList/REMOVES',
  SET_LIST = '@@manufacturerList/SET_LIST',
  UPDATE = '@@manufacturerList/UPDATE',
  UPDATES = '@@manufacturerList/UPDATES',
}

const actionDB = actionsDatabase<Base>(ActionTypes)

export const actions = {
  /**
   * Manufacturer 추가
   */
  addManufacturer: actionDB.add,
  addManufacturers: actionDB.adds,
  /**
   * Manufacturer 삭제
   */
  removeManufacturer: actionDB.remove,
  removeManufacturers: actionDB.removes,
  /**
   * Manufacturer List 셋팅
   */
  setManufacturerList: actionDB.setList,
  /**
   * Manufacturer 업데이트
   */
  updateManufacturer: actionDB.update,
  updateManufacturers: actionDB.updates,
}

export const initialState: State = []

export const reducer = reducerDatabase(initialState, ActionTypes)
