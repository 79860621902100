import { Rule } from 'antd/lib/form'
import { Language } from './language'
import { REQUIRED_SELECT_0 } from './language/src/App.lang'

type InputRuleDefault = {
  name: string
  label: string
  rules?: Rule[]
}

interface InputRuleTypeOneLength extends InputRuleDefault {
  type: 'one-length'
  length: number
  rules: [Rule, Rule]
}

interface InputRuleTypeRequired extends InputRuleDefault {
  type: 'required'
  rules: [Rule]
}

interface InputRuleTypeAny extends InputRuleDefault {
  type: 'any'
}

export const generateInputRuleOneLength = (
  name: string,
  label: string,
  length: number,
  fnMessage: (num: number) => string
): InputRuleTypeOneLength => {
  const message = fnMessage(length)
  return {
    name,
    label,
    type: 'one-length',
    length,
    rules: [
      { min: length, message },
      { max: length, message },
    ],
  }
}

export const generateInputRequired = (
  name: string,
  label: string,
  message: string
): InputRuleTypeRequired => ({
  type: 'required',
  label,
  name,
  rules: [{ required: true, message }],
})
export const generateInputAny = (
  name: string,
  label: string
): InputRuleTypeAny => ({
  type: 'any',
  label,
  name,
})

export const shopCode = generateInputRuleOneLength(
  'shopCode',
  Language.ShopCode,
  7,
  Language.InputRules.fnMessageLengthByShopCode
)

export const shopTitle = generateInputRequired(
  'title',
  Language.ShopTitle,
  `${Language.ShopTitle}${Language.REQUIRED_INPUT_0}`
)

export const saleChanel = generateInputRequired(
  'saleChannelId',
  Language.SaleChannel,
  `${Language.SaleChannel}${REQUIRED_SELECT_0}`
)

export const countryCodeId = generateInputAny(
  'countryCodeId',
  Language.CountryCode
)
