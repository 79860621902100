import { blue } from '@ant-design/colors'
import * as chartjs from 'chart.js'
import { findDataById, LogList, SaleChannel, StatisticsInfo } from 'common'
import hexToRgba from 'hex-to-rgba'
import { getChartBackgroundByIndex } from '../../libs'

export type StatisticsInfoPickKey = keyof Pick<
  StatisticsInfo,
  'activeRate' | 'errorRate' | 'offlineRate' | 'updateRate'
>
const defaultBarData: chartjs.ChartDataSets = {
  type: 'bar',
  fill: false,
  lineTension: 0.1,
  backgroundColor: 'rgba(75,192,192,0.4)',
  borderColor: 'rgba(75,192,192,1)',
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
}

const defaultLineData: chartjs.ChartDataSets = {
  type: 'line',
  label: '평균',
  fill: false,
  lineTension: 0.1,
  backgroundColor: hexToRgba(blue.primary || '', 0.4),
  borderColor: blue.primary,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderColor: 'rgba(75,192,192,1)',
  pointBackgroundColor: '#fff',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: '#fff',
  pointHoverBorderColor: blue.primary,
  pointHoverBorderWidth: 2,
  pointRadius: 3,
  pointHitRadius: 10,
  order: -1,
}

type SaleChannelDataSet = {
  id: string
  label: string
  dataNum: number[]
}

export const parseDatasets = (
  statsList: LogList[],
  saleChannelList: SaleChannel[],
  type: StatisticsInfoPickKey = 'activeRate'
): chartjs.ChartData => {
  const labels: string[] = []
  const saleChannelDataSetList: SaleChannelDataSet[] = []

  let dataLength = 0

  statsList.forEach(statsItem => {
    const { date, list } = statsItem
    if (list.length <= 0) {
      return
    }
    labels.push(date)
    dataLength++
    list.forEach(stat => {
      const { id } = stat
      const data = stat[type]

      const scDS = findDataById(saleChannelDataSetList, id)
      if (scDS) {
        scDS.dataNum.push(data)
      } else {
        const label = findDataById(saleChannelList, id)
        saleChannelDataSetList.push({
          id: stat.id,
          label: label ? label.title : '',
          dataNum: [data],
        })
      }
    })
  })

  let avgData: number[] = Array(dataLength).fill(0)

  saleChannelDataSetList.forEach(({ dataNum }) => {
    dataNum.forEach((value, index) => {
      avgData[index] += value
    })
  })

  const size = saleChannelDataSetList.length
  avgData = avgData.map(avg => avg / size)
  return {
    labels,
    datasets: [
      ...saleChannelDataSetList.map((item, i) => ({
        ...defaultBarData,
        label: item.label,
        data: item.dataNum,
        ...getChartBackgroundByIndex(i),
      })),
      {
        ...defaultLineData,
        data: avgData,
      },
    ],
  }
}
