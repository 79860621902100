import { StatsStandard, StatsUnit } from 'common/src/types'

type StatPrams0 = {
  standard: StatsStandard
  unit: StatsUnit
}

export const toStatId = (options: StatPrams0) => {
  const { standard, unit } = options
  return `${standard}-${unit}`
}
