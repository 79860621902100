export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Timestamp: any;
};

export type AdminEntity = {
   __typename?: 'AdminEntity';
  id: Scalars['ID'];
  email: Scalars['String'];
  adminLevel: Scalars['Float'];
  userName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  manufacturerId?: Maybe<Scalars['String']>;
  createAt: Scalars['Timestamp'];
  updateAt: Scalars['Timestamp'];
};

export type AmCode = {
   __typename?: 'AMCode';
  id: Scalars['String'];
  code: Scalars['String'];
  createAt: Scalars['Timestamp'];
  updateAt: Scalars['Timestamp'];
  manufacturerId: Scalars['String'];
  countryCodeList: Array<CountryCode>;
};

export type CountryCode = {
   __typename?: 'CountryCode';
  id: Scalars['ID'];
  title: Scalars['String'];
  createAt: Scalars['Timestamp'];
  updateAt: Scalars['Timestamp'];
  manufacturerId: Scalars['String'];
  amCodeId: Scalars['String'];
  manufacturer: Manufacturer;
  shopList: Array<Shop>;
};

export enum EntityTopic {
  Create = 'create',
  Update = 'update',
  Remove = 'remove'
}

export type Event = {
   __typename?: 'Event';
  id: Scalars['String'];
  startTime: Scalars['Timestamp'];
  type: Scalars['String'];
  isComplete: Scalars['Boolean'];
  createAt: Scalars['Timestamp'];
  updateAt: Scalars['Timestamp'];
  mediaIds: Array<Scalars['String']>;
  playListId: Scalars['String'];
  mediaList: Array<Media>;
};

export enum EventType {
  Add = 'Add',
  Delete = 'Delete'
}

export type FkGasAndShop = {
   __typename?: 'FKGasAndShop';
  id: Scalars['ID'];
  createAt: Scalars['Timestamp'];
  updateAt: Scalars['Timestamp'];
  shopId: Scalars['String'];
  fkGSId: Scalars['String'];
  fkGS: FkGroupAndSalechannel;
  shop: Shop;
};

export type FkGroupAndSalechannel = {
   __typename?: 'FKGroupAndSalechannel';
  id: Scalars['ID'];
  createAt: Scalars['Timestamp'];
  updateAt: Scalars['Timestamp'];
  groupId: Scalars['String'];
  saleChannelId: Scalars['String'];
  saleChannel: SaleChannel;
  group: Group;
  playList: PlayList;
};

export type Group = {
   __typename?: 'Group';
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  deviceRegexr?: Maybe<Scalars['String']>;
  manufacturerId: Scalars['String'];
  createAt: Scalars['Timestamp'];
  updateAt: Scalars['Timestamp'];
  playList: PlayList;
  manufacturer: Manufacturer;
  saleChannelList: Array<SaleChannel>;
  gsList: Array<FkGroupAndSalechannel>;
};

export type LogInfoDetail = {
   __typename?: 'LogInfoDetail';
  manufacturerId: Scalars['String'];
  count: Scalars['Float'];
  size: Scalars['Float'];
};

export type LogList = {
   __typename?: 'LogList';
  date: Scalars['String'];
  list: Array<StatisticsInfo>;
};

export type LogTvInfo = {
   __typename?: 'LogTvInfo';
  groupId: Scalars['String'];
  active: Scalars['Float'];
  offline: Scalars['Float'];
  error: Scalars['Float'];
};

export type Manufacturer = {
   __typename?: 'Manufacturer';
  id: Scalars['ID'];
  title: Scalars['String'];
  createAt: Scalars['Timestamp'];
  updateAt: Scalars['Timestamp'];
  groupList: Array<Group>;
  countryCode: Array<CountryCode>;
  saleChannelList: Array<SaleChannel>;
  playList: PlayList;
};

export type McDayLog = {
   __typename?: 'MCDayLog';
  id: Scalars['ID'];
  mediaCenterId?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
  saleChannelId?: Maybe<Scalars['String']>;
  manufacturerId?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  activeRate: Scalars['Float'];
  offlineRate: Scalars['Float'];
  errorRate: Scalars['Float'];
  updateRate: Scalars['Float'];
  createAt: Scalars['Timestamp'];
};

export type McGroup = {
   __typename?: 'MCGroup';
  id: Scalars['String'];
  title: Scalars['String'];
  deviceRegexr?: Maybe<Scalars['String']>;
  updateAt: Scalars['Timestamp'];
  createAt: Scalars['Timestamp'];
  media: Array<Media>;
};

export type McLog = {
   __typename?: 'MCLog';
  id: Scalars['ID'];
  isOnline: Scalars['Boolean'];
  mediaList?: Maybe<Scalars['String']>;
  mediaCenterId: Scalars['String'];
  createAt: Scalars['Timestamp'];
  updateAt: Scalars['Timestamp'];
  tvInfo: Array<LogTvInfo>;
  tvInfoToString: Scalars['String'];
  mediaRate: Scalars['Float'];
};

export type McMedia = {
   __typename?: 'MCMedia';
  id: Scalars['ID'];
  mediaInfo?: Maybe<Array<Scalars['String']>>;
  mediaIds: Array<Scalars['String']>;
  mediaCenterId: Scalars['String'];
  createAt: Scalars['Timestamp'];
  updateAt: Scalars['Timestamp'];
};

export type McMonthLog = {
   __typename?: 'MCMonthLog';
  id: Scalars['ID'];
  mediaCenterId?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
  saleChannelId?: Maybe<Scalars['String']>;
  manufacturerId?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  activeRate: Scalars['Float'];
  offlineRate: Scalars['Float'];
  errorRate: Scalars['Float'];
  updateRate: Scalars['Float'];
  createAt: Scalars['Timestamp'];
};

export type McYearLog = {
   __typename?: 'MCYearLog';
  id: Scalars['ID'];
  mediaCenterId?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
  saleChannelId?: Maybe<Scalars['String']>;
  manufacturerId?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  activeRate: Scalars['Float'];
  offlineRate: Scalars['Float'];
  errorRate: Scalars['Float'];
  updateRate: Scalars['Float'];
  createAt: Scalars['Timestamp'];
};

export type Media = {
   __typename?: 'Media';
  id: Scalars['ID'];
  fileName: Scalars['String'];
  createAt: Scalars['Timestamp'];
  updateAt: Scalars['Timestamp'];
  licenseEnd?: Maybe<Scalars['Timestamp']>;
  manufacturerId: Scalars['String'];
  size: Scalars['Float'];
  directory: Scalars['String'];
  filePath: Scalars['String'];
  thumbnailPath?: Maybe<Scalars['String']>;
  parseResult?: Maybe<VideoParseResult>;
  audioCodec?: Maybe<Scalars['String']>;
  videoCodec?: Maybe<Scalars['String']>;
  bitRate?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type MediaCenter = {
   __typename?: 'MediaCenter';
  id: Scalars['ID'];
  mac: Scalars['String'];
  InitialTime?: Maybe<Scalars['Timestamp']>;
  createAt: Scalars['Timestamp'];
  updateAt: Scalars['Timestamp'];
  shopId?: Maybe<Scalars['String']>;
};

export type MediaCenterCurrentInfo = {
   __typename?: 'MediaCenterCurrentInfo';
  groupList: Array<McGroup>;
  mediaList: Array<Media>;
};

export type MediaLog = {
   __typename?: 'MediaLog';
  id: Scalars['ID'];
  date: Scalars['String'];
  manufacturerId: Scalars['String'];
  count: Scalars['Float'];
  size: Scalars['Float'];
  createAt: Scalars['Timestamp'];
};

export type MediaLogInfo = {
   __typename?: 'MediaLogInfo';
  date: Scalars['String'];
  list: Array<LogInfoDetail>;
};

export type MediCenterAndLog = {
   __typename?: 'MediCenterAndLog';
  id: Scalars['String'];
  mac: Scalars['String'];
  createAt: Scalars['Timestamp'];
  updateAt: Scalars['Timestamp'];
  shopId?: Maybe<Scalars['String']>;
  isOnline: Scalars['Boolean'];
  InitialTime?: Maybe<Scalars['Timestamp']>;
  tvInfo: Array<TvInfo>;
  mediaDone: Scalars['Float'];
  mediaTotal: Scalars['Float'];
  tvInfoToNum?: Maybe<Array<TvInfoNum>>;
  updateRate?: Maybe<Scalars['Float']>;
  shop?: Maybe<Shop>;
};

export type Mutation = {
   __typename?: 'Mutation';
  removeManufacturer: Scalars['Boolean'];
  addManufacturer: Manufacturer;
  updateManufacturer: Manufacturer;
  addSaleChannel: SaleChannel;
  removeSaleChannel: Scalars['Boolean'];
  updateSaleChannel: SaleChannel;
  addGroup: Group;
  removeGroup: Scalars['Boolean'];
  updateGroup: Group;
  addCountryCode: CountryCode;
  removeCountryCode: Scalars['Boolean'];
  updateCountryCode: CountryCode;
  addShop: Shop;
  removeShop: Scalars['Boolean'];
  updateShop: Shop;
  addFKGS: FkGroupAndSalechannel;
  removeFKGS: Scalars['Boolean'];
  addFKGSS: FkGasAndShop;
  removeFKGSS: Scalars['Boolean'];
  updateMedia: Media;
  removeMedia: Scalars['Boolean'];
  addPlayList: PlayList;
  updatePlayList: PlayList;
  addAdmin: AdminEntity;
  updateAdmin: AdminEntity;
  removeAdmin: Scalars['Boolean'];
  addMediaCenter: MediaCenter;
  removeMediaCenter: Scalars['Boolean'];
  updateMediaCenter: MediaCenter;
  saveLog: Scalars['Boolean'];
  updateMDCMedia: Array<Scalars['String']>;
  saveMediaLog: Scalars['Boolean'];
  addEvent: Event;
  updateEvent: Event;
  removeEvent: Scalars['Boolean'];
  addAMCode: AmCode;
  updateAMCode: AmCode;
  removeAMCode: Scalars['Boolean'];
};


export type MutationRemoveManufacturerArgs = {
  id: Scalars['String'];
};


export type MutationAddManufacturerArgs = {
  title: Scalars['String'];
};


export type MutationUpdateManufacturerArgs = {
  title: Scalars['String'];
  id: Scalars['String'];
};


export type MutationAddSaleChannelArgs = {
  manufacturerId: Scalars['String'];
  title: Scalars['String'];
};


export type MutationRemoveSaleChannelArgs = {
  id: Scalars['String'];
};


export type MutationUpdateSaleChannelArgs = {
  description?: Maybe<Scalars['String']>;
  manufacturerId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationAddGroupArgs = {
  deviceRegexr?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  manufacturerId: Scalars['String'];
  title: Scalars['String'];
};


export type MutationRemoveGroupArgs = {
  id: Scalars['String'];
};


export type MutationUpdateGroupArgs = {
  deviceRegexr?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  manufacturerId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationAddCountryCodeArgs = {
  amCodeId: Scalars['String'];
  manufacturerId: Scalars['String'];
  title: Scalars['String'];
};


export type MutationRemoveCountryCodeArgs = {
  id: Scalars['String'];
};


export type MutationUpdateCountryCodeArgs = {
  amCodeId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  manufacturerId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationAddShopArgs = {
  lng?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  shopCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  countryCodeId?: Maybe<Scalars['String']>;
  saleChannelId: Scalars['String'];
  title: Scalars['String'];
};


export type MutationRemoveShopArgs = {
  id: Scalars['String'];
};


export type MutationUpdateShopArgs = {
  lng?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  address?: Maybe<Scalars['String']>;
  shopCode?: Maybe<Scalars['String']>;
  salechannelId?: Maybe<Scalars['String']>;
  countryCodeId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationAddFkgsArgs = {
  groupId: Scalars['String'];
  saleChannelId: Scalars['String'];
};


export type MutationRemoveFkgsArgs = {
  id: Scalars['String'];
};


export type MutationAddFkgssArgs = {
  shopId: Scalars['String'];
  fkGSId: Scalars['String'];
};


export type MutationRemoveFkgssArgs = {
  id: Scalars['String'];
};


export type MutationUpdateMediaArgs = {
  licenseEnd?: Maybe<Scalars['Timestamp']>;
  videoCodec?: Maybe<Scalars['String']>;
  audioCodec?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
};


export type MutationRemoveMediaArgs = {
  id: Array<Scalars['String']>;
};


export type MutationAddPlayListArgs = {
  id: Scalars['String'];
  parentRole: PlayListParentRoleType;
};


export type MutationUpdatePlayListArgs = {
  isParentBlock: Scalars['Boolean'];
  mediaIds: Array<Scalars['String']>;
  playListId: Scalars['String'];
};


export type MutationAddAdminArgs = {
  description?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  manufacturerId?: Maybe<Scalars['String']>;
  userName: Scalars['String'];
  adminLevel: Scalars['Float'];
  password: Scalars['String'];
  email: Scalars['String'];
};


export type MutationUpdateAdminArgs = {
  description?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  manufacturerId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  adminLevel?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationRemoveAdminArgs = {
  id: Scalars['String'];
};


export type MutationAddMediaCenterArgs = {
  shopId: Scalars['String'];
  mac: Scalars['String'];
};


export type MutationRemoveMediaCenterArgs = {
  id: Scalars['String'];
};


export type MutationUpdateMediaCenterArgs = {
  shopId?: Maybe<Scalars['String']>;
  mac?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationSaveLogArgs = {
  date?: Maybe<Scalars['String']>;
};


export type MutationUpdateMdcMediaArgs = {
  createAt?: Maybe<Scalars['Timestamp']>;
  media?: Maybe<Array<Scalars['String']>>;
};


export type MutationAddEventArgs = {
  type: EventType;
  mediaList: Array<Scalars['String']>;
  playListId: Scalars['String'];
  start: Scalars['Timestamp'];
};


export type MutationUpdateEventArgs = {
  mediaList?: Maybe<Array<Scalars['String']>>;
  playListId?: Maybe<Scalars['String']>;
  type?: Maybe<EventType>;
  start?: Maybe<Scalars['Timestamp']>;
  id: Scalars['String'];
};


export type MutationRemoveEventArgs = {
  id: Scalars['String'];
};


export type MutationAddAmCodeArgs = {
  manufacturerId: Scalars['String'];
  code: Scalars['String'];
};


export type MutationUpdateAmCodeArgs = {
  manufacturerId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationRemoveAmCodeArgs = {
  id: Scalars['String'];
};

export type Notification = {
   __typename?: 'Notification';
  id: Scalars['ID'];
  topic: EntityTopic;
  date: Scalars['Timestamp'];
};

export type PlayList = {
   __typename?: 'PlayList';
  id: Scalars['ID'];
  isParentBlock: Scalars['Boolean'];
  createAt: Scalars['Timestamp'];
  updateAt: Scalars['Timestamp'];
  parentRole: Scalars['String'];
  manufacturerId?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  fkGSId?: Maybe<Scalars['String']>;
  fkGSSId?: Maybe<Scalars['String']>;
  mediaIds: Array<Scalars['String']>;
  mediaList: Array<Media>;
  manufacturer?: Maybe<Manufacturer>;
  group?: Maybe<Group>;
  fkGS?: Maybe<FkGroupAndSalechannel>;
  fkGSS?: Maybe<FkGroupAndSalechannel>;
};

export enum PlayListParentRoleType {
  Manufacturer = 'Manufacturer',
  Group = 'Group',
  Fkgs = 'FKGS',
  Fkgss = 'FKGSS'
}

export type Query = {
   __typename?: 'Query';
  findManufacturer: Array<Manufacturer>;
  findOneManufacturer: Manufacturer;
  findSaleChannel: Array<SaleChannel>;
  findOneSaleChannel: SaleChannel;
  findGroup: Array<Group>;
  findOneGroup: Group;
  findCountryCode: Array<CountryCode>;
  findOneCountryCode: CountryCode;
  findShop: Array<Shop>;
  findOneShop: Shop;
  findFKGS: Array<FkGroupAndSalechannel>;
  findOneFKGS: FkGroupAndSalechannel;
  findFKGSS: Array<FkGasAndShop>;
  findOneFKGSS: FkGasAndShop;
  findMedia: Array<Media>;
  findOneMedia: Media;
  findMediaInPlayList: Array<Media>;
  findPlayList: Array<PlayList>;
  findOnePlayList?: Maybe<PlayList>;
  findAdmin: Array<AdminEntity>;
  findOneAdmin: AdminEntity;
  getCurrentInfo: MediaCenterCurrentInfo;
  findMediaCenter: Array<MediCenterAndLog>;
  findOneMediaCenter: MediCenterAndLog;
  findStats: Array<LogList>;
  findMCLog: Array<McLog>;
  findMediaLog: Array<MediaLogInfo>;
  findEvent: Array<Event>;
  findOneEvent: Event;
  findAMCode: Array<AmCode>;
  findOneAMCode: AmCode;
};


export type QueryFindOneManufacturerArgs = {
  id: Scalars['String'];
};


export type QueryFindOneSaleChannelArgs = {
  id: Scalars['String'];
};


export type QueryFindOneGroupArgs = {
  id: Scalars['String'];
};


export type QueryFindOneCountryCodeArgs = {
  id: Scalars['String'];
};


export type QueryFindOneShopArgs = {
  id: Scalars['String'];
};


export type QueryFindOneFkgsArgs = {
  id: Scalars['String'];
};


export type QueryFindOneFkgssArgs = {
  id: Scalars['String'];
};


export type QueryFindOneMediaArgs = {
  id: Scalars['String'];
};


export type QueryFindOnePlayListArgs = {
  id: Scalars['String'];
};


export type QueryFindOneAdminArgs = {
  id: Scalars['String'];
};


export type QueryFindOneMediaCenterArgs = {
  id: Scalars['String'];
};


export type QueryFindStatsArgs = {
  unit: StatsUnit;
  standard: StatsStandard;
};


export type QueryFindMcLogArgs = {
  mediaCenterId: Scalars['String'];
};


export type QueryFindOneEventArgs = {
  id: Scalars['String'];
};


export type QueryFindOneAmCodeArgs = {
  id: Scalars['String'];
};

export type SaleChannel = {
   __typename?: 'SaleChannel';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  createAt: Scalars['Timestamp'];
  updateAt: Scalars['Timestamp'];
  manufacturerId: Scalars['String'];
  manufacturer: Manufacturer;
  fkGSList: Array<FkGroupAndSalechannel>;
  shopList: Array<Shop>;
};

export type Shop = {
   __typename?: 'Shop';
  id: Scalars['ID'];
  title: Scalars['String'];
  saleChannelId: Scalars['String'];
  countryCodeId?: Maybe<Scalars['String']>;
  shopCode?: Maybe<Scalars['String']>;
  createAt: Scalars['Timestamp'];
  updateAt: Scalars['Timestamp'];
  address?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  mediaCenterList: Array<MediaCenter>;
  country: CountryCode;
  fkGSSList: Array<FkGasAndShop>;
  saleChannel: CountryCode;
};

export type StatisticsInfo = {
   __typename?: 'StatisticsInfo';
  id: Scalars['String'];
  activeRate: Scalars['Float'];
  offlineRate: Scalars['Float'];
  errorRate: Scalars['Float'];
  updateRate: Scalars['Float'];
};

export enum StatsStandard {
  SaleChannel = 'SaleChannel',
  Manufacturer = 'Manufacturer',
  Shop = 'Shop'
}

export enum StatsUnit {
  Day = 'Day',
  Month = 'Month',
  Year = 'Year'
}

export type Subscription = {
   __typename?: 'Subscription';
  onSubsEntity: Notification;
  onSubsMCLog: Notification;
  onTest: Notification;
};


export type SubscriptionOnSubsEntityArgs = {
  entity: SubscriptionEntity;
};

export enum SubscriptionEntity {
  Manufacturer = 'Manufacturer',
  Group = 'Group',
  Fkgs = 'FKGS',
  Fkgss = 'FKGSS',
  SaleChannel = 'SaleChannel',
  Media = 'Media',
  Shop = 'Shop',
  CountryCode = 'CountryCode',
  PlayList = 'PlayList',
  Admin = 'Admin',
  MediaCenter = 'MediaCenter',
  McLog = 'MCLog',
  McDayLog = 'MCDayLog',
  McMonthLog = 'MCMonthLog',
  McYearLog = 'MCYearLog',
  McMedia = 'MCMedia',
  MediaLog = 'MediaLog',
  Event = 'Event',
  AmCode = 'AMCode'
}


export type TvInfo = {
   __typename?: 'TvInfo';
  groupId: Scalars['String'];
  active: Array<Scalars['String']>;
  offline: Array<Scalars['String']>;
  error: Array<Scalars['String']>;
  media: Scalars['Float'];
  mediaDone: Scalars['Float'];
  mediaTotal: Scalars['Float'];
};

export type TvInfoNum = {
   __typename?: 'TvInfoNum';
  groupId: Scalars['String'];
  active: Scalars['Float'];
  offline: Scalars['Float'];
  error: Scalars['Float'];
  media: Scalars['Float'];
  mediaDone: Scalars['Float'];
  mediaTotal: Scalars['Float'];
};

export enum VideoParseResult {
  Success = 'success',
  VideoInfoError = 'videoInfoError',
  ThumbnailErorr = 'thumbnailErorr',
  Error = 'error'
}
