import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Form, PageHeader, Button, Empty } from 'antd'
import { ReactMemoEx } from 'modules/view'
import { GeneratorModalIndex } from 'common'
import { useDispatch } from 'react-redux'
import { $Actions } from 'modules/store'

const SForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
const FormContainerBody = styled.div`
  flex: 1;
  padding: 1.5rem;
`

const FormBody = styled.div`
  background-color: #fff;
  padding: 1.5rem;

  .ant-form-item {
    display: flex;
  }

  .ant-form-item-control {
    flex: 1;
  }
`

const SPageHeader = styled(PageHeader)`
  background-color: #fff;
`

type EmptyContainerProps = {
  index: GeneratorModalIndex
  title: string
}

const EmptyContainer = (props: EmptyContainerProps) => {
  const { index, title } = props
  const dispatch = useDispatch()

  const onClose = useCallback(() => {
    dispatch($Actions.setHideGeneratorModal(index))
  }, [dispatch, index])

  return (
    <SForm className="ant-advanced-search-form">
      <SPageHeader
        title={title}
        extra={
          <>
            <Button onClick={onClose}>취소</Button>
          </>
        }
      />
      <FormContainerBody>
        <FormBody>
          <Empty />
        </FormBody>
      </FormContainerBody>
    </SForm>
  )
}

export default ReactMemoEx(EmptyContainer)
