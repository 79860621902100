import { TableMedia } from 'components'
import { ReactMemoEx } from 'modules/view'
import React from 'react'
import { Row, Col, Card } from 'antd'

const PanelByMedia = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card>
          <TableMedia />
        </Card>
      </Col>
    </Row>
  )
}

export default ReactMemoEx(PanelByMedia)
