import ApolloClient from 'apollo-client'
import autobind from 'autobind-decorator'
import {
  EntityTopic,
  MediCenterAndLog,
  Subscription,
  SubscriptionEntity,
  SubscriptionOnSubsEntityArgs,
} from 'common'
import { EventEmitter } from 'events'
import { get } from 'lodash'
import { $Actions, ApplicationActionTypes } from 'modules/store'
import { Dispatch } from 'react'
import { QueryHelper } from './querys'

const SUBSLIST: Array<SubscriptionEntity> = [
  SubscriptionEntity.Admin,
  SubscriptionEntity.CountryCode,
  SubscriptionEntity.Fkgs,
  SubscriptionEntity.Fkgss,
  SubscriptionEntity.Group,
  SubscriptionEntity.Manufacturer,
  SubscriptionEntity.Media,
  SubscriptionEntity.PlayList,
  SubscriptionEntity.SaleChannel,
  SubscriptionEntity.Shop,
  SubscriptionEntity.MediaCenter,
  SubscriptionEntity.Event,
  SubscriptionEntity.AmCode,
]

type FKAnyAction = (data: any) => ApplicationActionTypes

type FKRemoveyAction = (id: string) => ApplicationActionTypes

export class AllSubscriptions<T> extends EventEmitter {
  private dispatch?: Dispatch<any>

  public constructor(private readonly gql: ApolloClient<T>) {
    super()
    this.init()
  }

  @autobind
  private init() {
    SUBSLIST.map(item => {
      return this.subscribe(item)
    })
    this.gql
      .subscribe({ query: QueryHelper.subscription.mediaCenterUpdate })
      .subscribe(this.findMediaCenter)
  }

  @autobind
  private findMediaCenter() {
    if (this.dispatch === undefined || this.dispatch === null) {
      return
    }
    const dispatch = this.dispatch
    this.gql
      .query<{ mediaCenterList: MediCenterAndLog[] }>({
        query: QueryHelper.findMediaCenter,
        fetchPolicy: 'no-cache',
      })
      .then(({ data: { mediaCenterList } }) => {
        dispatch($Actions.setMediaCenterList(mediaCenterList))
      })
      .catch(() => {})
  }

  @autobind
  public setDispatch(dispatch: Dispatch<any>) {
    this.dispatch = dispatch
  }

  @autobind
  private dispatchAddUpdateEntity(entity: SubscriptionEntity, data: any) {
    if (this.dispatch === undefined || this.dispatch === null) {
      return
    }
    // let action: ApplicationActionTypes | undefined = undefined
    let fkAction: FKAnyAction | undefined = undefined

    if (entity === SubscriptionEntity.CountryCode) {
      fkAction = $Actions.addCountryCode
    } else if (entity === SubscriptionEntity.Fkgs) {
      fkAction = $Actions.addFKGS
    } else if (entity === SubscriptionEntity.Fkgss) {
      fkAction = $Actions.addFKGSS
    } else if (entity === SubscriptionEntity.Group) {
      fkAction = $Actions.addGroup
    } else if (entity === SubscriptionEntity.Manufacturer) {
      fkAction = $Actions.addManufacturer
    } else if (entity === SubscriptionEntity.MediaCenter) {
      fkAction = $Actions.addMediaCenter
    } else if (entity === SubscriptionEntity.Media) {
      fkAction = $Actions.addMedia
    } else if (entity === SubscriptionEntity.PlayList) {
      fkAction = $Actions.addPls
    } else if (entity === SubscriptionEntity.SaleChannel) {
      fkAction = $Actions.addSaleChannel
    } else if (entity === SubscriptionEntity.Shop) {
      fkAction = $Actions.addShop
    } else if (entity === SubscriptionEntity.Admin) {
      fkAction = $Actions.addAdmin
    } else if (entity === SubscriptionEntity.Event) {
      fkAction = $Actions.addEvent
    } else if (entity === SubscriptionEntity.AmCode) {
      fkAction = $Actions.addAmCode
    }
    if (fkAction === undefined) {
      return
    }
    this.dispatch(fkAction(data))
  }

  @autobind
  private findOne(entity: SubscriptionEntity, id: string) {
    this.gql
      .query<any>({
        query: QueryHelper.findOne(entity),
        fetchPolicy: 'no-cache',
        variables: { id },
      })
      .then(result => {
        const data = get(result.data, entity)
        if (data === undefined || data === null) {
          return
        }
        this.dispatchAddUpdateEntity(entity, data)
      })
      .catch(e => {
      })
  }

  @autobind
  private removeOne(entity: SubscriptionEntity, id: string) {
    if (this.dispatch === undefined) {
      return
    }
    let fkAction: FKRemoveyAction | undefined = undefined
    if (entity === SubscriptionEntity.CountryCode) {
      fkAction = $Actions.removeCountryCode
    } else if (entity === SubscriptionEntity.Fkgs) {
      fkAction = $Actions.removeFKGS
    } else if (entity === SubscriptionEntity.Fkgss) {
      fkAction = $Actions.removeFKGSS
    } else if (entity === SubscriptionEntity.Group) {
      fkAction = $Actions.removeGroup
    } else if (entity === SubscriptionEntity.Manufacturer) {
      fkAction = $Actions.removeManufacturer
    } else if (entity === SubscriptionEntity.MediaCenter) {
      fkAction = $Actions.removeMediaCenter
    } else if (entity === SubscriptionEntity.Media) {
      fkAction = $Actions.removeMedia
    } else if (entity === SubscriptionEntity.PlayList) {
      fkAction = $Actions.removePls
    } else if (entity === SubscriptionEntity.SaleChannel) {
      fkAction = $Actions.removeSaleChannel
    } else if (entity === SubscriptionEntity.Shop) {
      fkAction = $Actions.removeShop
    } else if (entity === SubscriptionEntity.Admin) {
      fkAction = $Actions.removeAdmin
    } else if (entity === SubscriptionEntity.Event) {
      fkAction = $Actions.removeEvent
    } else if (entity === SubscriptionEntity.AmCode) {
      fkAction = $Actions.removeAmCode
    }
    if (fkAction === undefined) {
      return
    }
    this.dispatch(fkAction(id))
  }

  @autobind
  private subscribe<T>(entity: SubscriptionEntity) {
    this.gql
      .subscribe<Subscription, SubscriptionOnSubsEntityArgs>({
        query: QueryHelper.subscription.dynamicEntity,
        variables: { entity },
        fetchPolicy: 'no-cache',
      })
      .subscribe(({ data }) => {
        if (data) {
          const { id, topic } = data.onSubsEntity
          if (topic === EntityTopic.Create) {
            this.emit(EntityTopic.Create, id)
            this.findOne(entity, id)
          } else if (topic === EntityTopic.Update) {
            this.emit(EntityTopic.Update, id)
            this.findOne(entity, id)
          } else if (topic === EntityTopic.Remove) {
            this.removeOne(entity, id)
          }
        }
      })
    return
  }
}
