import { AmCode } from 'common'
import { reducerDatabase, actionsDatabase } from '../util'

type Base = AmCode
export type State = Base[]

export enum ActionTypes {
  ADD = '@@amCode/ADD',
  ADDS = '@@amCode/ADDS',
  REMOVE = '@@amCode/REMOVE',
  REMOVES = '@@amCode/REMOVES',
  SET_LIST = '@@amCode/SET_LIST',
  UPDATE = '@@amCode/UPDATE',
  UPDATES = '@@amCode/UPDATES',
}

const actionDB = actionsDatabase<Base>(ActionTypes)

export const actions = {
  /**
   * AmCode 추가
   */
  addAmCode: actionDB.add,
  addAmCodes: actionDB.adds,
  /**
   * AmCode 삭제
   */
  removeAmCode: actionDB.remove,
  removeAmCodes: actionDB.removes,
  /**
   * AmCode List 셋팅
   */
  setAmCodeList: actionDB.setList,
  /**
   * AmCode 업데이트
   */
  updateAmCode: actionDB.update,
  updateAmCodes: actionDB.updates,
}

export const initialState: State = []

export const reducer = reducerDatabase(initialState, ActionTypes)
