import { createContext } from 'use-context-selector'
import { AdminEntity } from 'common'

export type ContextByAdminState = {
  pwdEditAdmin?: AdminEntity
  setPwdEditAdmin: React.Dispatch<React.SetStateAction<AdminEntity | undefined>>
}
export const ContextByAdmin = createContext<ContextByAdminState>({
  setPwdEditAdmin: () => {},
})
