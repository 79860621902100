import React, { useMemo, useCallback } from 'react'
import { useContextSelector } from 'use-context-selector'
import { ContextByPLM, ActionByPLMTypes } from '../../StorePLM'
import { Badge, Button, Tooltip } from 'antd'
import { ReactMemoEx } from 'modules/view'
import { InfoCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const SBadge = styled(Badge)`
  margin-right: 1rem;
`
const ButtonInfo = () => {
  const selectMediaList = useContextSelector(
    ContextByPLM,
    state => state['0'].selectMediaList
  )
  const dispatch = useContextSelector(ContextByPLM, state => state[1])
  const count = useMemo(() => selectMediaList.length, [selectMediaList.length])

  const onClick = useCallback(
    () =>
      dispatch({
        type: ActionByPLMTypes.SHOW_MEDIAINFO,
        payload: selectMediaList,
      }),
    [dispatch, selectMediaList]
  )
  return (
    <SBadge count={count}>
      <Tooltip title="선택 미디어 정보보기">
        <Button
          type="primary"
          shape="circle"
          disabled={count <= 0}
          onClick={onClick}
          icon={<InfoCircleOutlined />}
        />
      </Tooltip>
    </SBadge>
  )
}

export default ReactMemoEx(ButtonInfo)
