import { Col, Row } from 'antd'
import { TableShop } from 'components'
import { ReactMemoEx } from 'modules/view'
import React from 'react'

const PanelByShop = () => {
  // const manufacturerList = useReduxSelector(
  //   ({ manufacturerList$ }) => manufacturerList$
  // )
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <TableShop />
      </Col>
    </Row>
  )
}

export default ReactMemoEx(PanelByShop)
