import { Card, Col, Row } from 'antd'
import { TabCharts, TableMediaCenter } from 'components'
import { ReactMemoEx } from 'modules/view'
import React from 'react'
import ChartPanel from './ChartPanel'

const PanelByConnected = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
          title="현재 접속 상태"
        >
          <TabCharts.MonitoringSaleChannel />
        </Card>
      </Col>
      <Col span={24}>
        <ChartPanel />
      </Col>
      <Col span={24}>
        <Card>
          <TableMediaCenter
            disabledDelete
            disabledEdit
            pickColumn={[
              'manufacturerTitle',
              'saleChannelTitle',
              'countryTitle',
              'shopTitle',
              'tvInfoGroup',
              'totalTVActivePercent',
              'isOnline',
            ]}
          />
        </Card>
      </Col>
    </Row>
  )
}

export default ReactMemoEx(PanelByConnected)
