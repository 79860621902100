import { SwapRightOutlined } from '@ant-design/icons'
import { Table, Tag, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { Language, toStringFileSize, ui } from 'common'
import { useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useMemo, useState } from 'react'
import {
  generateFilterColumn,
  generateNumberColumn,
  generateSearchColumn,
} from '../../../GenerateColumn'
import { SearchState } from '../../../util'
import adpaterPLS from './adapterPLS'
import { InPLSBySaleChannel, PLSParentBlock } from './PlayListTable.type'

const { Paragraph } = Typography
export const TablePlayList = ReactMemoEx((props: { height?: number }) => {
  const { height } = props
  const [searchState, setSearchedColumn] = useState<
    SearchState<InPLSBySaleChannel>
  >([])

  const fkGSList = useReduxSelector(state => state.fkGSList)
  const groupList = useReduxSelector(state => state.groupList)
  const mediaList = useReduxSelector(state => state.mediaList)
  const plsList = useReduxSelector(state =>
    state.plsList.filter(({ fkGSSId }) => fkGSSId !== undefined)
  )
  const saleChannelList = useReduxSelector(state => state.saleChannelList)

  const dataSource = useMemo(
    () =>
      adpaterPLS(plsList, { fkGSList, groupList, saleChannelList, mediaList }),
    [plsList, fkGSList, groupList, saleChannelList, mediaList]
  )

  const [colums, x] = useMemo(() => {
    const outputColumns: ColumnsType<InPLSBySaleChannel> = [
      generateSearchColumn<InPLSBySaleChannel>(
        ui.createCol<InPLSBySaleChannel>(Language.SaleChannel, ui.ColSize.md, {
          dataIndex: 'saleChannelTitle',
        }),
        searchState,
        {
          onSearch: option => {
            setSearchedColumn(option)
          },
          onClear: option => {
            setSearchedColumn(option)
          },
        }
      ),
      generateFilterColumn(
        ui.createCol<InPLSBySaleChannel>(Language.Group, ui.ColSize.sm, {
          dataIndex: 'groupTitle',
        }),
        groupList.map(({ title }) => title)
      ),
      generateFilterColumn(
        ui.createCol<InPLSBySaleChannel>('예외', ui.ColSize.md, {
          dataIndex: 'parentsBlock',
          render(parentsBlock: PLSParentBlock) {
            if (parentsBlock === 'none') {
              return null
            }
            return (
              <Tag
                icon={<SwapRightOutlined />}
                color={parentsBlock === 'group' ? undefined : 'purple'}
              >
                {parentsBlock === 'group' ? '그룹 이하' : '판매채널 이하'}
              </Tag>
            )
          },
        }),
        [
          { text: '예외없음', value: 'none' },
          { text: '그룹 이하 적용', value: 'group' },
          { text: '판매채널 이하 적용', value: 'saleChannel' },
        ]
      ),
      generateNumberColumn(
        ui.createCol<InPLSBySaleChannel>(Language.MediaCount, ui.ColSize.md, {
          dataIndex: 'fileCount',
        })
      ),
      generateNumberColumn(
        ui.createCol<InPLSBySaleChannel>(Language.FileSize, ui.ColSize.md, {
          dataIndex: 'fileSize',
          render(fileSize = 0) {
            return toStringFileSize(fileSize)
          },
        })
      ),
      {
        align: 'center' as 'center',
        dataIndex: 'mediaNamesManufacturer',
        ellipsis: true,
        title: '제조사 편성',
        width: ui.ColSize.xxl,
        render(mediaNamesManufacturer: string[]) {
          return (
            <Paragraph style={{ textAlign: 'left' }}>
              <ul>
                {mediaNamesManufacturer.map((fileName, i) => (
                  <li key={i}>{fileName}</li>
                ))}
              </ul>
            </Paragraph>
          )
        },
      },
      {
        align: 'center' as 'center',
        dataIndex: 'mediaNamesGroup',
        ellipsis: true,
        title: '그룹 편성',
        width: ui.ColSize.xxl,
        render(mediaNamesGroup: string[]) {
          return (
            <Paragraph style={{ textAlign: 'left' }}>
              <ul>
                {mediaNamesGroup.map((fileName, i) => (
                  <li key={i}>{fileName}</li>
                ))}
              </ul>
            </Paragraph>
          )
        },
      },
      {
        align: 'center' as 'center',
        dataIndex: 'mediaNamesGroup',
        ellipsis: true,
        title: '판매채널 편성',
        width: ui.ColSize.xxl,
        render(mediaNamesSaleChannel: string[]) {
          return (
            <Paragraph style={{ textAlign: 'left' }}>
              <ul>
                {mediaNamesSaleChannel.map((fileName, i) => (
                  <li key={i}>{fileName}</li>
                ))}
              </ul>{' '}
            </Paragraph>
          )
        },
      },
    ]
    return [
      outputColumns,
      outputColumns.reduce((totla, column) => {
        if (typeof column.width === 'number') {
          return totla + column.width
        }
        return totla
      }, 0),
    ]
  }, [searchState, groupList])

  return (
    <Table
      bordered
      dataSource={dataSource}
      rowKey="id"
      scroll={{ x: x, y: height }}
      size="small"
      columns={colums}
    />
  )
})
