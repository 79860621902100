import { findDataById, PlayList } from 'common'
import { ApplicationState } from 'modules/store'

function getTitle<T extends { id: string; title: string }>(
  list: T[],
  id: string
): string {
  const value = findDataById(list, id)
  if (value === undefined) {
    return ''
  }
  return value.title
}

export const getPLSTitle = (
  state: ApplicationState,
  playList: PlayList
): string => {
  const {
    manufacturerList,
    groupList,
    shopList,
    saleChannelList,
    fkGSList,
    fkGSShopList,
  } = state

  if (playList.manufacturerId) {
    return getTitle(manufacturerList, playList.manufacturerId)
  }
  if (playList.groupId) {
    return getTitle(groupList, playList.groupId)
  }

  if (playList.fkGSId) {
    const { fkGSId } = playList
    const fkGS = findDataById(fkGSList, fkGSId)
    if (fkGS) {
      return getTitle(saleChannelList, fkGS.saleChannelId)
    }
  }
  if (playList.fkGSSId) {
    const { fkGSSId } = playList
    const fkGSS = findDataById(fkGSShopList, fkGSSId)
    if (fkGSS) {
      return getTitle(shopList, fkGSS.shopId)
    }
  }
  return ''
}
