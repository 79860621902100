import { Form, Input } from 'antd'
import { ReactMemoEx } from 'modules/view'
import React, { useMemo } from 'react'
import { useContextSelector } from 'use-context-selector'
import { ContextByMCTable } from './mediaCenter.context'
import { EditCellDefaultProps } from './types'
import { Rule } from 'antd/lib/form'
import { Language } from 'common'

const rules: Rule[] = [
  {
    pattern: /^([0-9A-F]{2}){5}([0-9A-F]{2})$/,
    message: Language.InputRules.notMacAddress,
  },
  {
    required: true,
    message: Language.InputRules.requiredMacAddress,
  },
]

const EditCellMacAddress: React.FC<EditCellDefaultProps> = props => {
  const { children, originData, dataIndex } = props
  const editRow = useContextSelector(ContextByMCTable, state => state.editRow)

  const isEditCurrentRow = useMemo(() => {
    if (editRow === undefined) {
      return false
    }
    if (originData.id !== editRow.id) {
      return false
    }
    return true
  }, [editRow, originData])

  return useMemo(() => {
    if (!isEditCurrentRow) {
      return <>{children}</>
    }
    return (
      <Form.Item name={dataIndex} rules={rules}>
        <Input />
      </Form.Item>
    )
  }, [children, dataIndex, isEditCurrentRow])
}

export default ReactMemoEx(EditCellMacAddress)
