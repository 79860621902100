import ApolloClient from 'apollo-client'
import { AppThunkAction } from './asyncAction.type'
import { $Actions } from 'modules/store/crossSliceReducer'
import gql from 'graphql-tag'
import { MutationRemoveMediaArgs } from 'common'

const MUTATION = gql`
  mutation RemoveFKGSS($id: [String!]!) {
    isRemove: removeMedia(id: $id)
  }
`

export const asyncActions = {
  removeMediaList: (
    gqlClient: ApolloClient<object>,
    mediaId: string[]
  ): AppThunkAction => async dispatch => {
    dispatch($Actions.removeMedias(mediaId))
    try {
      const { data } = await gqlClient.mutate<
        { isRemove: boolean },
        MutationRemoveMediaArgs
      >({
        mutation: MUTATION,
        variables: {
          id: mediaId,
        },
      })
      return data
    } catch (e) {
      return false
    }
  },
}
