import { useMutation } from '@apollo/react-hooks'
import { Button, Popconfirm } from 'antd'
import { MutationRemoveMediaArgs } from 'common'
import gql from 'graphql-tag'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback } from 'react'

export type RemoveButtonProps = {
  id: string
}

const MUTATION = gql`
  mutation Remove($id: [String!]!) {
    isRemove: removeMedia(id: $id)
  }
`
const RemoveButtonOnlyMedia = (props: RemoveButtonProps) => {
  const { id } = props
  const [removeById, { loading }] = useMutation<
    { isRemove: boolean },
    MutationRemoveMediaArgs
  >(MUTATION)

  const onConfirm = useCallback(() => {
    removeById({ variables: { id: [id] } })
  }, [id, removeById])

  return (
    <Popconfirm title="데이터를 삭제하시겠습니까?" onConfirm={onConfirm}>
      <Button type="link" loading={loading} danger={true}>
        {loading ? '' : '삭제'}
      </Button>
    </Popconfirm>
  )
}

export default ReactMemoEx(RemoveButtonOnlyMedia)
