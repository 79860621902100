import { Col, Form, Input, Row } from 'antd'
import {
  GeneratorModalIndex,
  isErrorByNetwork,
  MutationAddManufacturerArgs,
} from 'common'
import { useMutationAddManufacturer } from 'modules/graphql'
import { useReduxSelector } from 'modules/store'
import React, { useCallback, useMemo } from 'react'
import ContainerFormLayout from './ContainerFormLayout'
import { isEqualTitle, notiError, notiErrorNetwork, notiSuccess } from './util'
import { defaultColProps } from './Config'

const ERROR_TITLE = '제조사 생성 실패'

const FormManufacturer = () => {
  const [form] = Form.useForm()
  const manufacturerList = useReduxSelector(state => state.manufacturerList)
  const [addManufacturer, { loading }] = useMutationAddManufacturer()

  const fields = useMemo(() => {
    return (
      <Row>
        <Col {...defaultColProps}>
          <Form.Item
            name="title"
            label="제조사명"
            rules={[
              {
                max: 255,
                message: '제조사명은 최대 255자입니다.',
              },
              {
                min: 2,
                message: '제조사명은 최소2 이상 입력해주세요.',
              },
              {
                required: true,
                message: '제조사명을 입력해주세요.',
              },
            ]}
          >
            <Input placeholder="제조사명을 입력하세요." />
          </Form.Item>
        </Col>
      </Row>
    )
  }, [])

  const onFinish = useCallback(
    async data => {
      const variables = data as MutationAddManufacturerArgs
      if (isEqualTitle(manufacturerList, variables.title)) {
        form.setFields([
          { name: 'title', errors: ['동일한 제조사명이 존재합니다.'] },
        ])
        return
      }
      try {
        await addManufacturer({ variables, fetchPolicy: 'no-cache' })
        notiSuccess(`제조사 생성완료(${variables.title})`)
        form.resetFields()
      } catch (e) {
        if (isErrorByNetwork(e)) {
          notiErrorNetwork(ERROR_TITLE)
        } else {
          notiError(ERROR_TITLE, String(e.message))
        }
      }
    },
    [addManufacturer, form, manufacturerList]
  )

  return (
    <ContainerFormLayout
      modalIndex={GeneratorModalIndex.Manufacturer}
      title="제조사 생성"
      form={form}
      formChildren={fields}
      loading={loading}
      onFinish={onFinish}
    />
  )
}

export default FormManufacturer
