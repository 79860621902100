import { Shop$ } from 'modules/store'
import autobind from 'autobind-decorator'
import { MediCenterAndLog, safeNum } from 'common'
import { GroupMap, MediaCenterAlpha } from './types'

export class ShopFinder {
  private idList: string[] = []
  constructor(public readonly shopList: Shop$[]) {
    this.parser()
  }

  @autobind
  private parser() {
    this.idList = this.shopList.map(({ id }) => id)
  }

  @autobind
  public findById(id: string) {
    const index = this.idList.indexOf(id)
    if (index <= -1) {
      return undefined
    }
    return this.shopList[index]
  }

  @autobind
  public findByIndex(id: string) {
    return this.idList.indexOf(id)
  }
}

export const parseMediaCenterAlphaList = (
  mediaCenterList: MediCenterAndLog[],
  groupMap: GroupMap,
  shopFinder: ShopFinder
): MediaCenterAlpha[] => {
  const output = mediaCenterList.map(
    (mediaCenter): MediaCenterAlpha => {
      const shopData =
        typeof mediaCenter.shopId === 'string'
          ? shopFinder.findById(mediaCenter.shopId)
          : undefined

      if (shopData === undefined) {
        return {
          ...mediaCenter,
          countryCodeId: '',
          countryTitle: '',
          manufacturerId: '',
          manufacturerTitle: '',
          saleChannelId: '',
          saleChannelTitle: '',
          shopTitle: '',
          totalMediaUpdate: 0,
          totalTVActiveCount: 0,
          totalTVActivePercent: 0,
          totalTVCount: 0,
          totalTVErrorCount: 0,
          totalTVOfflineCount: 0,
          tvInfoGroup: {},
        }
      }

      const { manufacturerId } = shopData
      let totalTVCount = 0
      let totalTVActiveCount = 0
      let totalTVOfflineCount = 0
      let totalTVErrorCount = 0
      let totalMediaUpdate = 0
      let groupCount = 0

      const tvInfoGroup: MediaCenterAlpha['tvInfoGroup'] = {}
      groupMap[manufacturerId].gorupIdIndex.forEach(gId => {
        if (mediaCenter.tvInfoToNum) {
          const tvGroupLog = mediaCenter.tvInfoToNum.find(
            ({ groupId }) => groupId === gId
          )
          if (tvGroupLog === undefined) {
            return
          }
          const { active, error, offline, media } = tvGroupLog
          const total = active + error + offline
          totalTVActiveCount += tvGroupLog.active
          totalTVCount += total
          totalTVOfflineCount += offline
          totalTVErrorCount += error
          groupCount += 1
          totalMediaUpdate += media
          tvInfoGroup[gId] = { ...tvGroupLog, total }
        }
      })
      const totalTVActivePercent = totalTVActiveCount / totalTVCount
      return {
        ...mediaCenter,
        tvInfoGroup,
        totalTVActiveCount,
        totalTVActivePercent: isNaN(totalTVActivePercent)
          ? 0
          : totalTVActivePercent,
        shopTitle: shopData.title,
        manufacturerTitle: shopData.manufacturerTitle,
        manufacturerId: shopData.manufacturerId,
        countryCodeId: shopData.countryCodeId,
        countryTitle: shopData.countryTitle,
        saleChannelId: shopData.saleChannelId,
        saleChannelTitle: shopData.saleChannelTitle,
        totalTVErrorCount,
        totalTVOfflineCount,
        totalTVCount,
        totalMediaUpdate: safeNum(totalMediaUpdate / groupCount),
      }
    }
  )
  return output
}
