import { Col, Row } from 'antd'
import { Language, StatsUnit } from 'common'
import { Charts, CustomCard, ExcelDownloadButton } from 'components'
import { BreakPoint, ReactMemoEx, useBreakpointEx } from 'modules/view'
import React, { useCallback, useMemo, useState, CSSProperties } from 'react'
import { isMobile } from 'react-device-detect'
// type ManufacturerInSaleChannel = {
//   id: string
//   title: string
//   saleChannelList: SaleChannel$[]
// }

const getCharHeight = (breakpoint: BreakPoint): [number, number] => {
  if (breakpoint === 'xxl') {
    return [520, 260]
  } else if (breakpoint === 'xl') {
    return [520, 260]
  } else if (breakpoint === 'lg') {
    return [440, 440]
  } else if (breakpoint === 'md') {
    return [420, 420]
  } else if (breakpoint === 'sm') {
    return [360, 360]
  }
  return [320, 320]
}

const styleTitle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
}
// TODO 선택된 판매채널만 보이도록 변경(전체도 필요.. ㅠ.ㅠ)
const ChartPanel = () => {
  const [unit, setUnit] = useState<StatsUnit>(StatsUnit.Day)
  // const saleChannel = useReduxSelector(state => state.saleChannelList$)
  const breakpoint = useBreakpointEx()

  // const tabBarExtraContent = useMemo(() => {
  //   const output: ManufacturerInSaleChannel[] = []
  //   saleChannel.forEach(s => {
  //     const index = output.findIndex(({ id }) => s.manufacturerId === id)
  //     if (index <= -1) {
  //       output.push({
  //         id: s.manufacturerId,
  //         title: s.manufacturerTitle,
  //         saleChannelList: [s],
  //       })
  //     } else {
  //       output[index].saleChannelList.push(s)
  //     }
  //   })
  //   return (
  //     <Select
  //       style={{ width: '100%' }}
  //       size="small"
  //       defaultValue={saleChannel.length <= 0 ? undefined : saleChannel[0].id}
  //     >
  //       {output.length === 1
  //         ? output[0].saleChannelList.map(saleChannel => {
  //             return (
  //               <Select.Option value={saleChannel.id} key={saleChannel.id}>
  //                 {saleChannel.title}
  //               </Select.Option>
  //             )
  //           })
  //         : output.map(manufacturer => {
  //             return (
  //               <Select.OptGroup
  //                 label={manufacturer.title}
  //                 key={manufacturer.id}
  //               >
  //                 {manufacturer.saleChannelList.map(saleChannel => {
  //                   return (
  //                     <Select.Option
  //                       value={saleChannel.id}
  //                       key={saleChannel.id}
  //                     >
  //                       {saleChannel.title}
  //                     </Select.Option>
  //                   )
  //                 })}
  //               </Select.OptGroup>
  //             )
  //           })}
  //     </Select>
  //   )
  // }, [saleChannel])

  const onChangeTab = useCallback((activeKey: string) => {
    if (
      activeKey === StatsUnit.Day ||
      activeKey === StatsUnit.Month ||
      activeKey === StatsUnit.Year
    ) {
      setUnit(activeKey)
    }
  }, [])
  const sizes = useMemo(() => getCharHeight(breakpoint), [breakpoint])

  return (
    <CustomCard
      childrenType="element"
      title={
        isMobile ? (
          Language.ConnectStatistics
        ) : (
          <div style={styleTitle}>
            <span style={{ marginRight: '0.5rem' }}>
              {Language.ConnectStatistics}
            </span>{' '}
            <div
              style={{
                display: 'flex',
                justifyItems: 'center',
                alignItems: 'center',
              }}
            >
              <ExcelDownloadButton />
            </div>
          </div>
        )
      }
      tabs={[
        { key: StatsUnit.Day, title: Language.Daily },
        { key: StatsUnit.Month, title: Language.Monthly },
        { key: StatsUnit.Year, title: Language.Yearly },
      ]}
      tabOptions={{
        defaultActiveKey: StatsUnit.Day,
        onChange: onChangeTab,
      }}
    >
      {isMobile && (
        <Row style={{ justifyContent: 'flex-end', marginTop: '-0.5rem' }}>
          <ExcelDownloadButton />
        </Row>
      )}

      <Row gutter={[16, 16]}>
        <Col
          xxl={14}
          xl={14}
          lg={24}
          md={24}
          sm={24}
          xs={24}
          style={{ height: sizes[0] }}
        >
          <Charts.ChartTotalSCConnect type="activeRate" unit={unit} />
        </Col>
        <Col xxl={10} xl={10} lg={24} md={24} sm={24} xs={24}>
          <Row>
            <Col
              xxl={24}
              xl={24}
              lg={12}
              md={12}
              sm={12}
              xs={24}
              style={{ height: sizes[1] }}
            >
              <Charts.ChartTotalSCConnect type="errorRate" unit={unit} />
            </Col>
            <Col
              xxl={24}
              xl={24}
              lg={12}
              md={12}
              sm={12}
              xs={24}
              style={{ height: sizes[1] }}
            >
              <Charts.ChartTotalSCConnect type="offlineRate" unit={unit} />
            </Col>
          </Row>
        </Col>
      </Row>
    </CustomCard>
  )
}

export default ReactMemoEx(ChartPanel)
