import { red } from '@ant-design/colors'
import { DeleteOutlined } from '@ant-design/icons'
import { useApolloClient } from '@apollo/react-hooks'
import {
  Badge,
  Button,
  Form,
  Popconfirm,
  Select,
  Timeline,
  Tooltip,
} from 'antd'
import {
  Config,
  Event,
  EventType,
  findCacheById,
  FindOutput,
  Media,
  reorder,
} from 'common'
import { ContextByPLM } from 'components/src/PlayListManager/features/StorePLM'
import dateFormat from 'dateformat'
import produce from 'immer'
import { $AsyncActions, useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useContextSelector } from 'use-context-selector'

type TimeLineItemProps = {
  event: Event
}

const ListItem = styled.div`
  display: block;
`
const Img = styled.img`
  width: 13rem;
  height: 7.3125rem;
`

const TimeLineItem = (props: TimeLineItemProps) => {
  const [form] = Form.useForm()
  const { event } = props
  const { mediaIds, startTime, id: eventId } = event
  const gqlClient = useApolloClient()
  const dispatch = useDispatch()
  const manufacturerId = useContextSelector(
    ContextByPLM,
    state => state[0].manufacturerId
  )

  const storeByMediaList = useReduxSelector(state => state.mediaList)

  const findMedia = useMemo(
    (): FindOutput<Media> => findCacheById(storeByMediaList),
    [storeByMediaList]
  )

  const nonSelectMediaList = useMemo(
    () =>
      storeByMediaList.filter(({ id, manufacturerId: mfId }) => {
        if (manufacturerId !== mfId) {
          return false
        }
        return mediaIds.indexOf(id) <= -1
      }),
    [storeByMediaList, manufacturerId, mediaIds]
  )

  // Event Remove
  const onClickRemove = useCallback(() => {
    dispatch($AsyncActions.removePlayListEvent(gqlClient, eventId))
  }, [dispatch, gqlClient, eventId])

  // DragDrop
  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination } = result
      if (!destination) {
        return
      }
      const nextEvent = produce(event, draft => {
        const mediaIds = reorder(
          event.mediaIds,
          source.index,
          destination.index
        )
        draft.mediaIds = mediaIds
      })
      dispatch($AsyncActions.updatePlayListEvent(gqlClient, nextEvent))
    },
    [dispatch, gqlClient, event]
  )

  const onRemoveInItem = useCallback(
    (mediaId: string) => () => {
      const index = event.mediaIds.indexOf(mediaId)
      if (index <= -1) {
        return
      }
      const next = produce(event, draft => {
        draft.mediaIds.splice(index, 1)
      })
      if (next.mediaIds.length <= 0) {
        dispatch($AsyncActions.removePlayListEvent(gqlClient, event.id))
      } else {
        dispatch($AsyncActions.updatePlayListEvent(gqlClient, next))
      }
    },
    [dispatch, gqlClient, event]
  )

  const onChangeAddMedia = useCallback(
    (value: string) => {
      form.resetFields()
      const next = produce(event, draft => {
        draft.mediaIds.push(value)
      })
      dispatch($AsyncActions.updatePlayListEvent(gqlClient, next))
    },
    [event, form, dispatch, gqlClient]
  )

  const onChangeType = useCallback(
    (value: string) => {
      const next = produce(event, draft => {
        draft.type = value as EventType
      })
      dispatch($AsyncActions.updatePlayListEvent(gqlClient, next))
    },
    [event, dispatch, gqlClient]
  )

  return (
    <Timeline.Item
      key={eventId}
      className="timeline-item"
      color={event.type === EventType.Add ? undefined : red.primary}
    >
      <div>
        {dateFormat(startTime, 'yyyy-mm-dd HH:MM:ss')}

        <Select
          className="type-select "
          size="small"
          value={event.type}
          onChange={onChangeType}
        >
          <Select.Option value={EventType.Add}>미디어 추가</Select.Option>
          <Select.Option value={EventType.Delete}>미디어 제외</Select.Option>
        </Select>
        <Popconfirm
          title="정말 예약된 작업을 삭제 하시겠습니까?"
          onConfirm={onClickRemove}
          okText="삭제"
          cancelText="취소"
        >
          <Button size="small" danger type="link">
            예약 삭제
          </Button>
        </Popconfirm>
      </div>
      <div className="item-body">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={eventId}>
            {provided => (
              <div
                ref={provided.innerRef}
                style={{ height: (mediaIds.length + 0.5) * 32 }}
              >
                {mediaIds.map((mediaId, i) => {
                  const media = findMedia(mediaId)
                  return (
                    <Draggable draggableId={mediaId} index={i} key={mediaId}>
                      {provided => (
                        <ListItem
                          key={mediaId}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Tooltip
                            title={
                              <div>
                                {media ? (
                                  <Img
                                    alt={media.fileName}
                                    src={`${Config.httpMedia}${media.thumbnailPath}`}
                                  />
                                ) : (
                                  undefined
                                )}
                              </div>
                            }
                            placement="right"
                          >
                            <div style={{ display: 'inline' }}>
                              <Badge
                                status="success"
                                text={media ? media.fileName : mediaId}
                              />
                            </div>
                          </Tooltip>
                          <Button
                            danger
                            ghost
                            type="link"
                            onClick={onRemoveInItem(mediaId)}
                          >
                            <DeleteOutlined color={red.primary} />
                          </Button>
                        </ListItem>
                      )}
                    </Draggable>
                  )
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div style={{ display: 'block' }}>
          {nonSelectMediaList.length >= 1 && (
            <Form form={form}>
              <Form.Item label="미디어 추가" name="media">
                <Select
                  style={{ minWidth: 200 }}
                  size="small"
                  onChange={onChangeAddMedia}
                  placeholder="미디어 선택"
                >
                  {nonSelectMediaList.map(item => {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item.fileName}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </Timeline.Item>
  )
}

export default ReactMemoEx(TimeLineItem)
