import { Col, Form, Input, Row, Select } from 'antd'
import {
  FieldData,
  GeneratorModalIndex,
  isErrorByNetwork,
  MutationAddSaleChannelArgs,
} from 'common'
import { useMutationAddSaleChannel } from 'modules/graphql'
import { useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo } from 'react'
import ContainerFormLayout from './ContainerFormLayout'
import EmptyContainer from './EmptyContainer'
import { isEqualTitle, notiError, notiErrorNetwork, notiSuccess } from './util'

const ERROR_TITLE = '판매채널 생성 실패'

const FormSaleChannelBody = () => {
  const [form] = Form.useForm()
  const [addSaleChannel, { loading }] = useMutationAddSaleChannel()
  const saleChannelList = useReduxSelector(
    ({ saleChannelList }) => saleChannelList
  )
  const manufacturerList = useReduxSelector(
    ({ manufacturerList }) => manufacturerList
  )

  const fields = useMemo(
    () => (
      <Row gutter={[32, 16]}>
        <Col xxl={8} xl={8} md={12} sm={24} xs={24}>
          <Form.Item
            name="manufacturerId"
            label="제조사"
            rules={[
              {
                required: true,
                message: '제조사를 선택해주세요.',
              },
            ]}
          >
            <Select>
              {manufacturerList.map(({ id, title }) => (
                <Select.Option key={id} value={id} children={title} />
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xxl={8} xl={8} md={12} sm={24} xs={24}>
          <Form.Item
            name="title"
            label="판매채널명"
            rules={[
              {
                required: true,
                message: '판매채널명을 입력하세요!',
              },
            ]}
          >
            <Input placeholder="판매채널명" />
          </Form.Item>
        </Col>
      </Row>
    ),
    [manufacturerList]
  )

  const onFinish = useCallback(async () => {
    const variables = form.getFieldsValue() as MutationAddSaleChannelArgs

    if (isEqualTitle(saleChannelList, variables.title)) {
      const files: FieldData[] = [
        { name: 'title', errors: ['동일한 판매채널명이 존재합니다.'] },
      ]
      form.setFields(files)
      return
    }

    try {
      await addSaleChannel({ variables })
      form.resetFields()
      notiSuccess(`판매채널 생성 성공(${variables.title})`)
    } catch (e) {
      if (isErrorByNetwork(e)) {
        notiErrorNetwork(ERROR_TITLE)
      } else {
        notiError(ERROR_TITLE, String(e))
      }
    }
  }, [form, addSaleChannel, saleChannelList])

  return (
    <ContainerFormLayout
      loading={loading}
      modalIndex={GeneratorModalIndex.Shop}
      title="판매채널 생성"
      form={form}
      formChildren={fields}
      onFinish={onFinish}
    />
  )
}

const FormSaleChannel = () => {
  const adminInfo = useReduxSelector(({ auth }) => auth.admin)
  return adminInfo.isLogin === false ? (
    <EmptyContainer
      index={GeneratorModalIndex.Group}
      title="판매채널 생성(사용 권한 없음)"
    />
  ) : (
    <FormSaleChannelBody />
  )
}

export default ReactMemoEx(FormSaleChannel)
