import { PlayList } from 'common'
import { useReduxSelector } from 'modules/store'
import React, { useMemo } from 'react'
import { useContextSelector } from 'use-context-selector'
import { ContextByPLM } from '../../StorePLM'
import InnerPLEContainer from './InnerPLEContainer'
import { ReactMemoEx } from 'modules/view'
/**
 * PlayList Editor Container
 */
export const PlayListEditor = ReactMemoEx(() => {
  const plsList = useReduxSelector((state) => state.plsList)

  const { fkGSId, fkGSSId, groupId, manufacturerId } = useContextSelector(
    ContextByPLM,
    (state) => {
      const { fkGSId, fkGSSId, groupId, manufacturerId } = state[0]
      return { fkGSId, fkGSSId, groupId, manufacturerId }
    }
  )

  const data = useMemo(() => {
    const outPlsList: PlayList[] = []
    if (manufacturerId) {
      const v = plsList.find(
        ({ manufacturerId: cId }) => cId === manufacturerId
      )
      if (v) {
        outPlsList.push(v)
      }
    }

    if (groupId) {
      const v = plsList.find(({ groupId: cId }) => cId === groupId)
      if (v) {
        outPlsList.push(v)
      }
    }

    if (fkGSId) {
      const v = plsList.find(({ fkGSId: cId }) => cId === fkGSId)
      if (v) {
        outPlsList.push(v)
      }
    }

    if (fkGSSId) {
      const v = plsList.find(({ fkGSSId: cId }) => cId === fkGSSId)
      if (v) {
        outPlsList.push(v)
      }
    }

    return outPlsList
  }, [plsList, fkGSId, fkGSSId, groupId, manufacturerId])

  return <InnerPLEContainer plsList={data} />
})
