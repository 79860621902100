import { Form, Select, Spin } from 'antd'
import { MapData, useNaverGeocode } from 'modules/useLibs'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback } from 'react'

const { Option } = Select

export type SelectAddressProps = {
  name: string
  label: string
  onChange: (geoCode: MapData) => void
}

const SelectAddress = (props: SelectAddressProps) => {
  const { name, label, onChange } = props
  const [loadGeoCode, { loading, result }, reset] = useNaverGeocode()
  const onChangeSelect = useCallback(
    (propsValue: any) => {
      try {
        const { value } = propsValue as { value: string }
        const geoData = result.find(geoCode => geoCode.address === value)
        if (geoData) {
          onChange(geoData)
        }
      } catch (e) {}
    },
    [result, onChange]
  )

  return (
    <Form.Item
      name={name}
      label={label}
      onReset={reset}
      rules={[
        {
          required: true,
          message: '주소를 입력해주세요.',
        },
      ]}
    >
      <Select
        labelInValue
        onChange={onChangeSelect}
        showSearch
        placeholder="주소 입력"
        notFoundContent={loading ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={loadGeoCode}
      >
        {result.map((d, i) => (
          <Option key={i} value={d.address}>
            {d.address}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default ReactMemoEx(SelectAddress)
