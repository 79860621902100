import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import assets from 'assets'
import { Drawer } from 'antd'
import StyleAttr from '../StyleAttr'
import { ReactMemoEx } from 'modules/view'
import { BrowserView, MobileView } from 'react-device-detect'
import { SideNavigation } from '../SideNavigation'

const Logo = styled.img`
  height: auto;
  max-width: 12rem;
  ${StyleAttr.blockDrag} /* filter: invert(0.8); */
  /* opacity: ; */
`
const MobileLogo = styled.img`
  height: auto;
  max-width: 3rem;
  cursor: pointer;
  ${StyleAttr.blockDrag}
`

const MobileMenu = () => {
  const [isOpen, setOpen] = useState(false)

  const setDrawerOpenToggle = useCallback(() => {
    setOpen(!isOpen)
  }, [isOpen])

  return (
    <>
      <MobileLogo
        src={assets.images.logoDGTZColor}
        onClick={setDrawerOpenToggle}
      />
      <Drawer
        placement="left"
        closable={false}
        onClose={setDrawerOpenToggle}
        visible={isOpen}
        bodyStyle={{ padding: 0, overflowX: 'hidden' }}
      >
        <SideNavigation mobile={true} />
      </Drawer>
    </>
  )
}

export default ReactMemoEx(() => {
  return (
    <div>
      <MobileView>
        <MobileMenu />
      </MobileView>
      <BrowserView>
        <Logo src={assets.images.logoHeaderBlack} alt="digitalzone" />
      </BrowserView>
    </div>
  )
})
