import { UploadFile } from 'common'
import produce from 'immer'
import { action } from 'typesafe-actions'
import { $GetActionType, withReducer } from '../util'

export type State = UploadFile[]

export const initialState: State = []

export enum ActionTypes {
  UPLOAD = '@@mediaupload/upload',
  RESULT = '@@mediaupload/result',
}

export const actions = {
  uploadFiles: (manufacturerId: string, files: File[]) =>
    action(ActionTypes.UPLOAD, {
      manufacturerId,
      files,
    }),
  resultFiles: (manufacturerId: string, files: File[]) =>
    action(ActionTypes.RESULT, {
      manufacturerId,
      files,
    }),
}

export const reducer = withReducer<State, $GetActionType<typeof actions>>(
  initialState,
  (state = initialState, action) => {
    if (action.type === ActionTypes.UPLOAD) {
      const { payload } = action
      const { files, manufacturerId } = payload
      if (files.length <= 0) {
        return state
      }

      return produce(state, draft => {
        draft.push(
          ...files.map(
            ({ name, size, type }): UploadFile => ({
              name,
              size,
              type,
              manufacturerId,
            })
          )
        )
      })
    } else if (action.type === ActionTypes.RESULT) {
      const { payload } = action
      const { files, manufacturerId } = payload
      if (files.length <= 0) {
        return state
      }

      const newState:State = []
      state.forEach(file => {
        const stateFile = files.find(
          ({ name }) =>
            name === file.name && file.manufacturerId === manufacturerId
        )
        if (stateFile === undefined) {
          newState.push(file)
        }
      })

      return newState
    }
    return state
  }
)
