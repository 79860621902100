import {
  SubscriptionEntity,
  toComma,
  toEA,
  toStringFileSize,
  toStringPercent,
  ui,
} from 'common'
import { QueryHelper } from 'modules/graphql'
import { Manufacturer$ as DB } from 'modules/store'
import React from 'react'
import { EditCellType } from '../../../EditTable'
import {
  generateNumberColumn,
  generateSearchColumn,
  genrateDeleteColumn,
} from '../../../GenerateColumn'
import { SearchState } from '../../../util'
import ActiveRender from '../../../ActiveRender'

/**
 * 제조사 정보
 * @param searchState
 * @param setSearchedColumn
 */
export default function createColumns(
  searchState: SearchState<DB>,
  setSearchedColumn: React.Dispatch<React.SetStateAction<SearchState<DB>>>
): EditCellType<DB>[] {
  return [
    // 제조사명 수정이 가능하다.
    {
      editable: true,
      editType: 'string',
      ...generateSearchColumn<DB>(
        ui.createCol<DB>('제조사명', '1', {
          dataIndex: 'title',
          align: 'left',
        }),
        searchState,
        {
          onSearch: option => {
            setSearchedColumn(option)
          },
          onClear: option => {
            setSearchedColumn(option)
          },
        }
      ),
    },
    // 지점수
    generateNumberColumn<DB>(
      ui.createCol<DB>('지점 수', ui.ColSize.md, {
        dataIndex: 'shopCount',
        render: toComma,
      })
    ),
    // tv 수
    generateNumberColumn<DB>(
      ui.createCol<DB>('전체 TV', ui.ColSize.md, {
        dataIndex: 'tvTotalCount',
        render: toEA,
      })
    ),
    // tv 접속 수
    generateNumberColumn<DB>(
      ui.createCol<DB>('활성 TV', ui.ColSize.md, {
        dataIndex: 'tvActiveCount',
        render: toEA,
      })
    ),
    // tv 접속률
    generateNumberColumn<DB>(
      ui.createCol<DB>('TV접속률', ui.ColSize.sm, {
        dataIndex: 'tvActivePercent',
        render: (percent: number) => (
          <ActiveRender percent={percent}>{toStringPercent(percent)}</ActiveRender>
        ),
      })
    ),
    generateNumberColumn<DB>(
      ui.createCol<DB>('미디어 수', ui.ColSize.md, {
        dataIndex: 'mediaCount',
        render: toComma,
      })
    ),
    generateNumberColumn<DB>(
      ui.createCol<DB>('미디어 용량', ui.ColSize.md, {
        dataIndex: 'mediaCount',
        render: toStringFileSize,
      })
    ),
    genrateDeleteColumn<DB>(
      QueryHelper.removeOneById(SubscriptionEntity.Manufacturer)
    ),
  ]
}
