import { SearchOutlined } from '@ant-design/icons'
import { Button, Input } from 'antd'
import { InputProps } from 'antd/lib/input'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import React, { useCallback, useEffect, useRef } from 'react'

export type FilterSearchProps<T, K = keyof T> = {
  filterSearch: FilterDropdownProps
  dataIndex: K
  handleSearch?: (
    selectedKeys: FilterDropdownProps['selectedKeys'],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: K
  ) => void
  onClear?: (clearValue: string | number) => void
}

function FilterSearch<T>(props: FilterSearchProps<T>) {
  const refInput = useRef<Input>(null)
  const { filterSearch, dataIndex, handleSearch, onClear: propsOnClear } = props
  const { setSelectedKeys, selectedKeys, confirm, clearFilters } = filterSearch

  const onSearch = useCallback(() => {
    confirm()
    if (handleSearch) {
      handleSearch(selectedKeys, confirm, dataIndex)
    }
  }, [handleSearch, selectedKeys, confirm, dataIndex])

  const onChange: Required<InputProps>['onChange'] = useCallback(
    e => setSelectedKeys(e.target.value ? [e.target.value] : []),
    [setSelectedKeys]
  )

  const onClear = useCallback(() => {
    if (clearFilters) {
      if (propsOnClear) {
        propsOnClear(selectedKeys[0])
      }
      clearFilters()
    }
  }, [clearFilters, selectedKeys, propsOnClear])

  useEffect(() => {
    if (filterSearch.visible && refInput.current) {
      refInput.current.select()
    }
  }, [filterSearch.visible])

  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={refInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={onChange}
        onPressEnter={onSearch}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={onSearch}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        검색
      </Button>
      <Button onClick={onClear} size="small" style={{ width: 90 }}>
        초기화
      </Button>
    </div>
  )
}

export default FilterSearch
