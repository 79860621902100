import { ApiOutlined, NodeExpandOutlined } from '@ant-design/icons'
import { useApolloClient } from '@apollo/react-hooks'
import { Badge, Button, Switch, Tooltip } from 'antd'
import { PlayList, toStringFileSize } from 'common'
import produce from 'immer'
import { difference } from 'lodash'
import { $AsyncActions, useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useContextSelector } from 'use-context-selector'
import { ContextByPLM } from '../../StorePLM'
import { getPLSTitle } from './util'

export const Title = styled.div`
  text-transform: uppercase;
  height: 3rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 1rem;
  padding-right: 1rem;
  background: var(--color-table-header);
  border-bottom: var(--color-border) 1px solid;

  .switch-parents-block {
    margin-right: 0.5rem;
  }
  & > .title-body {
    flex: 1;
    margin: 0 0.5rem 0 0;
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 0;
      &.title {
        font-weight: bold;
      }
      &.sub {
        line-height: 0.75rem;
        font-size: 0.75rem;
        padding-left: 0.25rem;
        .ant-badge,
        .ant-badge-status-text {
          line-height: 0.75rem;
          font-size: 0.75rem;
          vertical-align: middle;
        }
        .ant-badge-status-text {
          margin-left: 0.25rem;
          opacity: 0.9;
        }
        .counter {
          line-height: 0.7rem;
          font-size: 0.7rem;
          margin-left: 0.25rme;
          opacity: 0.7;
        }
      }
    }
  }
`

type Props = {
  playList: PlayList
}
const PlayListInMediaSize = (props: Props) => {
  const {
    playList: { mediaIds },
  } = props
  const fileSize = useReduxSelector(state => {
    if (mediaIds.length <= 0) {
      return 0
    }
    const { mediaList } = state
    return mediaIds.reduce((size, mId) => {
      const media = mediaList.find(({ id }) => id === mId)
      if (media) {
        size += media.size
      }
      return size
    }, 0)
  })

  return useMemo(() => {
    return (
      <>
        {fileSize >= 1 && (
          <Badge status="processing" text={`${toStringFileSize(fileSize)}`} />
        )}
        {mediaIds.length >= 1 && (
          <span className="counter">{`${mediaIds.length}개`}</span>
        )}
      </>
    )
  }, [fileSize, mediaIds])
}

export type KanbanTitleProps = Props & React.HtmlHTMLAttributes<HTMLElement>

const KanbanTitle: React.FC<KanbanTitleProps> = props => {
  const { playList, ...rest } = props
  const gqlClient = useApolloClient()
  const dispatch = useDispatch()

  const mediaList = useContextSelector(
    ContextByPLM,
    state => state[0].selectMediaList
  )
  const title = useReduxSelector(state => getPLSTitle(state, playList))

  const [differenceMediaList, count] = useMemo(() => {
    const differenceMediaList = difference(mediaList, playList.mediaIds)
    return [differenceMediaList, differenceMediaList.length]
  }, [mediaList, playList.mediaIds])

  const onClick = useCallback(() => {
    const nextPlayList = produce(playList, draft => {
      differenceMediaList.forEach(media => {
        draft.mediaIds.push(media)
      })
    })
    dispatch($AsyncActions.updatePLS(gqlClient, nextPlayList))
  }, [differenceMediaList, dispatch, gqlClient, playList])

  const disabled = useMemo(() => count <= 0, [count])

  const onChangeParentsBlock = useCallback(
    (checked: boolean) => {
      dispatch(
        $AsyncActions.updatePLS(
          gqlClient,
          produce(playList, draft => {
            draft.isParentBlock = checked
          })
        )
      )
    },
    [dispatch, gqlClient, playList]
  )

  return (
    <Title {...rest}>
      <div className="title-body noselect">
        <p className="title">{title}</p>
        <p className="sub">
          <PlayListInMediaSize playList={playList} />
        </p>
      </div>
      {playList.parentRole !== 'manufacturer' && (
        <Tooltip
          title={
            playList.isParentBlock ? '상위 편성표 미적용' : '상위 편성표 적용'
          }
        >
          <Switch
            size="small"
            className="switch-parents-block"
            checkedChildren={<ApiOutlined />}
            unCheckedChildren={<NodeExpandOutlined />}
            checked={playList.isParentBlock}
            onChange={onChangeParentsBlock}
          />
        </Tooltip>
      )}

      <Badge count={count} offset={[-2, 4]}>
        <Button
          size="small"
          type="primary"
          onClick={onClick}
          disabled={disabled}
        >
          등록
        </Button>
      </Badge>
    </Title>
  )
}

export default ReactMemoEx(KanbanTitle)
