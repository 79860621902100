import * as chartjs from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {
  findDataById,
  LogList,
  SaleChannel,
  toStringPercent,
  Language,
} from 'common'
import { LogStatItem } from 'modules/store'
import { ChartComponentProps } from 'react-chartjs-2'
import {
  barPercentageHorizontal,
  getChartBackground,
  sampleColors,
  defaultChartOptions,
} from '../../libs'
import { MC_ERROR } from 'common/src/language/src/App.lang'

const getOptions = (text: string): ChartComponentProps['options'] => ({
  ...defaultChartOptions,
  title: {
    display: true,
    position: 'top',
    text,
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        const { datasetIndex = -1, index = -1 } = tooltipItem
        if (data && data.datasets && data.datasets[datasetIndex]) {
          const value = data.datasets[datasetIndex]
          if (
            value.data &&
            value.data[index] !== undefined &&
            data.labels &&
            data.labels[index]
          ) {
            return `${data.labels[index]}: ${toStringPercent(
              value.data[index]
            )}`
          }
        }
        return String(tooltipItem)
      },
    },
  },
  responsive: true,
  legend: {
    position: 'bottom',
    reverse: false,
  },
  plugins: {
    datalabels: {
      color: 'white',
      formatter: value => toStringPercent(value),
      font: { weight: 'bold' },
      offset: 0,
      padding: 0,
    },
  },
})

const safeNum = (num: number) => {
  if (isNaN(num)) {
    return 0
  }
  return num
}

export const parseDatasetConnection = (
  stateItem: LogStatItem
): [[ChartComponentProps, ChartComponentProps], undefined | LogList] => {
  const lastData = stateItem.logList[stateItem.logList.length - 1]
  let activeTotal = 0
  let errorTotal = 0
  let offlineTotal = 0

  if (lastData) {
    lastData.list.forEach(({ activeRate, errorRate, offlineRate }) => {
      activeTotal += activeRate
      errorTotal += errorRate
      offlineTotal += offlineRate
    })
  }

  const allTotal = activeTotal + errorTotal + offlineTotal
  const disabledTotal = errorTotal + offlineTotal

  return [
    [
      {
        data: {
          datasets: [
            {
              data: [
                safeNum(activeTotal / allTotal),
                safeNum((allTotal - activeTotal) / allTotal),
              ],
              backgroundColor: [sampleColors.blue, sampleColors.grey],
            },
          ],
          labels: [Language.ConnectRate, Language.DisConnectRate],
        },
        options: getOptions(Language.ConnectRate),
        plugins: [ChartDataLabels],
      },
      {
        data: {
          datasets: [
            {
              data: [
                safeNum(offlineTotal / disabledTotal),
                safeNum(errorTotal / disabledTotal),
              ],
              backgroundColor: [sampleColors.grey, sampleColors.red],
            },
          ],
          labels: [Language.Offline, MC_ERROR],
        },
        options: getOptions(Language.TITLE_DISCONNECT),
      },
    ],
    lastData,
  ]
}

type RequireDataSets = Omit<chartjs.ChartDataSets, 'data'> &
  Pick<Required<chartjs.ChartDataSets>, 'data'>

/**
 *
 * @param saleChannelList
 */
export const parseDatasetSaleChannelHorizontal = (
  saleChannelList: SaleChannel[],
  logList: LogList
): ChartComponentProps => {
  const labels: string[] = []

  const datasets = saleChannelList.reduce<RequireDataSets[]>(
    (output, saleChannel) => {
      const currentItem = findDataById(logList.list, saleChannel.id)
      if (currentItem) {
        labels.push(saleChannel.title)
        output[0].data.push(currentItem.activeRate)
        output[1].data.push(currentItem.offlineRate)
        output[2].data.push(currentItem.errorRate)
      }
      return output
    },
    [
      {
        barPercentage: barPercentageHorizontal,
        ...getChartBackground(sampleColors.blue),
        label: Language.Connected,
        data: [],
      },
      {
        barPercentage: barPercentageHorizontal,
        ...getChartBackground(sampleColors.grey),
        label: Language.DisConnectRate,
        data: [],
      },
      {
        barPercentage: barPercentageHorizontal,
        ...getChartBackground(sampleColors.red),
        label: Language.MC_ERROR,
        data: [],
      },
    ]
  )

  return {
    data: {
      labels,
      datasets,
    },
    options: {
      ...defaultChartOptions,
      plugins: {
        datalabels: {
          color: 'white',
          formatter: value => toStringPercent(value),
          font: { weight: 'bold' },
          offset: 0,
          padding: 0,
        },
      },
      legend: {
        position: 'bottom',
        reverse: false,
      },
      tooltips: {
        mode: 'index',
        intersect: true,
        callbacks: {
          label: (tooltipItem, data) => {
            if (
              data.datasets !== undefined &&
              tooltipItem.datasetIndex !== undefined
            ) {
              return `${
                data.datasets[tooltipItem.datasetIndex].label
              }: ${toStringPercent(tooltipItem.value)}`
            }
            return toStringPercent(tooltipItem.value)
          },
        },
      },

      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            type: 'category',
            stacked: true,
          },
        ],
      },
    },
  }
}
