import { findDataById, Media, PlayList } from 'common'
import { useReduxSelector } from 'modules/store'
import React, { useMemo } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import ButtonMore, { ButtonMoreProps } from './ButtonMore'
import Thumbnail from './Thumbnail'
import './KanbanListItem.style.less'
import { ReactMemoEx } from 'modules/view'

export type KabanListItemProps = {
  mediaId: Media['id']
  playListId: PlayList['id']
  index: number
} & Omit<ButtonMoreProps, 'mediaId'> &
  React.HTMLAttributes<HTMLElement>

const KanbanListItem = (props: KabanListItemProps) => {
  const {
    mediaId,
    playListId,
    onClickExceptionMedia,
    onClickInfoMedia,
    index,
    ...rest
  } = props
  const mediaList = useReduxSelector((state) => state.mediaList)
  const draggableId = useMemo(() => `${playListId},${mediaId}`, [
    mediaId,
    playListId,
  ])

  const media = useMemo(() => findDataById(mediaList, mediaId), [
    mediaList,
    mediaId,
  ])

  if (media === undefined) {
    return null
  }

  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <div
          className="kbl-item-container"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          {...rest}
        >
          <div className="inner-container">
            <Thumbnail thumbnail={media.thumbnailPath} />
            <ButtonMore
              mediaId={media.id}
              onClickExceptionMedia={onClickExceptionMedia}
              onClickInfoMedia={onClickInfoMedia}
            />
            <div className="media-info noselect">{media.fileName}</div>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default ReactMemoEx(KanbanListItem)
