const ZERO_BYTES = '0 Bytes'

export const FileSizeSufixed: string[] = [
  'Bytes',
  'KB',
  'MB',
  'GB',
  'TB',
  'PB',
  'EB',
  'ZB',
  'YB',
]


/**
 * 숫자, 글자를 용량 표기로 변경해준다.
 * @param kb
 * @returns (string) 파일 사이즈
 */
export const toStringFileSize = (kb: number | string): string => {
  if (typeof kb === 'string') {
    if (/^\d+$/.test(kb)) {
      return toStringFileSize(parseInt(kb, 10))
    }
    const isFileSize = /^\d+(bytes|kb|mb|gb|tb|pb|eb|zb|yb)$/.test(
      kb.toLowerCase()
    )
    if (isFileSize) {
      return kb
    }
    return ZERO_BYTES
  }
  if (isNaN(kb)) {
    return ZERO_BYTES
  }
  const i = Math.floor(Math.log(kb) / Math.log(1024))
  const output =
    (!kb && ZERO_BYTES) ||
    (kb / Math.pow(1024, i)).toFixed(0) + ' ' + FileSizeSufixed[i]
  return output
}
