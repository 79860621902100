import { useMutation } from '@apollo/react-hooks'
import { Button, Popconfirm } from 'antd'
import { DocumentNode } from 'graphql'
import React, { useCallback } from 'react'
import { ReactMemoEx } from 'modules/view'

export type RemoveButtonProps = {
  id: string
  mutation: DocumentNode
}

const RemoveButton = (props: RemoveButtonProps) => {
  const { id, mutation } = props
  const [removeById, { loading }] = useMutation(mutation)

  const onConfirm = useCallback(() => {
    removeById({ variables: { id } })
  }, [id, removeById])

  return (
    <Popconfirm title="데이터를 삭제하시겠습니까?" onConfirm={onConfirm}>
      <Button type="link" loading={loading} danger={true}>
        {loading ? '' : '삭제'}
      </Button>
    </Popconfirm>
  )
}

export default ReactMemoEx(RemoveButton)
