import { Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { ReactMemoEx } from 'modules/view'

const { Title } = Typography

export const TitleLoading = ReactMemoEx(() => {
  const [dotdotdot, setDotDotDot] = useState('.')
  useEffect(() => {
    let i = 0
    const intervalDotdotdot = setInterval(() => {
      i += 1
      if (i % 3 === 0) {
        i = 0
      }
      setDotDotDot(
        Array(i + 1)
          .fill('.')
          .join('')
      )
    }, 1000)
    return () => {
      clearInterval(intervalDotdotdot)
    }
  }, [])
  return <Title>Loading{dotdotdot}</Title>
})
