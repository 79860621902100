import { Menu } from 'antd'
import {
  PanelIndex,
  NaivigationItem,
  WIDTH_COLLAPSED,
  NAVI_SYSTEM,
  Config,
  NAVI_DEFAULT,
} from 'common'
import { ApplicationState, useReduxSelector, $Actions } from 'modules/store'
import React, { useCallback, useEffect, useRef, useMemo } from 'react'
import styled from 'styled-components'
import IconNavigation from './IconNavigation'
import { useDispatch } from 'react-redux'
import StyleAttr from '../StyleAttr'
import { ReactMemoEx } from 'modules/view'
const { SubMenu } = Menu

const Contaienr = styled.nav<Pick<ApplicationState['layout'], 'theme'>>`
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 0.5rem;
  flex-direction: column;
  background: ${props => (props.theme === 'dark' ? '#001529' : '#fff')};
  border-right: ${props =>
    props.theme === 'dark' ? '0' : '1px solid #f0f0f0;'};
  transition: background 0.3s, width 0.2s;
  color: ${props =>
    props.theme === 'dark' ? 'rgba(255, 255, 255, 0.65)' : ''};
  .ant-menu-inline {
    border-right: 0;
  }
  li {
    ${StyleAttr.blockDrag}
  }
`

const MenuContainer = styled.div`
  width: 100%;
  position: relative;
  overflow-y: auto;
  flex: 1;
`

type SideNavigationProps = {
  mobile?: boolean
}

export const SideNavigation = ReactMemoEx((props: SideNavigationProps) => {
  const { mobile = false } = props
  console.log('SideNavigation -> mobile', mobile)
  const admin = useReduxSelector(state => state.auth.admin)
  const refKeyDown = useRef<boolean>(false)

  const dispatch = useDispatch()
  const { inlineCollapsed } = useReduxSelector(state => {
    const { naviSize } = state.layout
    if (mobile) {
      return {
        inlineCollapsed: false,
      }
    }
    return {
      inlineCollapsed: naviSize <= WIDTH_COLLAPSED,
    }
  })

  const naviList = useMemo(() => {
    if (admin.isLogin) {
      if (admin.manufacturerId) {
        return NAVI_DEFAULT
      }
      return NAVI_SYSTEM
    }
    return []
  }, [admin])
  const onClickMenu = useCallback(
    (panelIndex: PanelIndex) => () => {
      dispatch($Actions.pushPanel(panelIndex, refKeyDown.current))
    },
    [dispatch]
  )

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.altKey) {
        refKeyDown.current = true
      } else {
        refKeyDown.current = false
      }
    }
    const onKeyUp = (e: KeyboardEvent) => {
      refKeyDown.current = false
    }
    window.addEventListener('keydown', onKeyDown)
    window.addEventListener('keyup', onKeyUp)
    return () => {
      refKeyDown.current = false
      window.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('keyup', onKeyUp)
    }
  }, [])

  const generatedMenuItems = useCallback(
    (menuItemList: NaivigationItem[]) => {
      return menuItemList.map(navItem => {
        if (navItem.type === 'sub') {
          const { key, icon, title, children } = navItem
          return (
            <SubMenu
              key={key}
              title={
                <span>
                  {icon && <IconNavigation icon={icon} />}
                  <span>{title}</span>
                </span>
              }
            >
              {children.map(item => (
                <Menu.Item
                  key={item.panelIndex}
                  onClick={onClickMenu(item.panelIndex)}
                >
                  {item.icon && <IconNavigation icon={item.icon} />}
                  <span>{item.title}</span>
                </Menu.Item>
              ))}
            </SubMenu>
          )
        }

        const { icon, title, panelIndex } = navItem
        return (
          <Menu.Item key={panelIndex} onClick={onClickMenu(panelIndex)}>
            {icon && <IconNavigation icon={icon} />}
            <span>{title}</span>
          </Menu.Item>
        )
      })
    },
    [onClickMenu]
  )
  return (
    <Contaienr>
      <MenuContainer>
        <Menu
          inlineCollapsed={inlineCollapsed}
          mode="inline"
          defaultSelectedKeys={[Config.defaultPanelIndex]}
        >
          {generatedMenuItems(naviList)}
        </Menu>
      </MenuContainer>
    </Contaienr>
  )
})
