import * as antColors from '@ant-design/colors'
import { Typography } from 'antd'
import color from 'color'
import { Config } from 'common'
import GoogleMapReact from 'google-map-react'
import { Shop$, useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useMemo } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { CustomSimpleCard } from '../../libs'
import './ChartMap.less'
const { Paragraph } = Typography
export const COLOR_PRIMARY = antColors.blue.primary || '#00f'

export const COLOR_SUB = color(antColors.grey[0])
  .alpha(1)
  .toString()

const ShopGraphComponent = (props: Shop$) => {
  const {
    // tvTotalCount,
    // tvActivePercent,
    // tvErrorCount,
    // tvOfflineCount,
    tvActiveCount,
    title,
  } = props

  return (
    <div className="chart-map-marker">
      <Paragraph>{title}</Paragraph>
      <div className="chart-container">
        <Doughnut
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: { datalabels: { display: false } },
            tooltips: { enabled: false },
          }}
          data={{
            datasets: [
              {
                data: [tvActiveCount, 1 - tvActiveCount],
                backgroundColor: [COLOR_PRIMARY, COLOR_SUB],
              },
            ],
          }}
        />
      </div>
    </div>
  )
}

export const ChartMap = ReactMemoEx(() => {
  const shopList$ = useReduxSelector(({ shopList$ }) => shopList$)
  const shopList = useMemo(() => {
    return shopList$.filter(
      ({ lat, lng }) => typeof lat === 'number' && typeof lng === 'number'
    )
  }, [shopList$])
  return (
    <CustomSimpleCard title="지역별 현황">
      <GoogleMapReact
        bootstrapURLKeys={{ key: Config.googleMapKey }}
        defaultCenter={Config.defaultCenter}
        defaultZoom={9}
        style={{ width: '100%', height: '100%' }}
      >
        {shopList.map(shop => {
          return <ShopGraphComponent {...shop} key={shop.id} />
        })}
      </GoogleMapReact>
    </CustomSimpleCard>
  )
})
