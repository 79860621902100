import { notification, Spin, Typography } from 'antd'
import { Media, toStringFileSize } from 'common'
import { ReactMemoEx } from 'modules/view'
import React from 'react'
import styled from 'styled-components'
import { v1 as uuid } from 'uuid'

const { Paragraph } = Typography

const ParagraphLi = styled(Paragraph)`
  margin-bottom: 0.25rem !important;
`
export type UploadResponseFile = Media | File | string

const NotiUL = ReactMemoEx((props: { list: UploadResponseFile[] }) => {
  const { list } = props
  return (
    <>
      <Paragraph>
        <ul>
          {list.map((item, i) => {
            let title = ''
            if (typeof item === 'string') {
              title = item
            } else if (item instanceof File) {
              title = item.name
            } else if (item) {
              title = item.fileName
            }

            return (
              <li key={i}>
                <ParagraphLi ellipsis copyable>
                  {title}
                </ParagraphLi>
              </li>
            )
          })}
        </ul>
      </Paragraph>
    </>
  )
})

/**
 * show error files
 * @param files
 */
export const showNotiUpload = (files: File[]) => {
  const key = uuid()

  const size = toStringFileSize(
    files.reduce((totalSize, item) => totalSize + item.size, 0)
  )
  notification.open({
    key,
    icon: <Spin size="small" />,
    duration: 0,
    message: `미디어 파일 업로드(${size})`,
    description: <NotiUL list={files} />,
  })
  return key
}

export const showNotiDuplicateUploadMedia = (files: File[]) => {
  notification.open({
    type: 'error',
    duration: 3,
    message: '동일한 파일명이 존재합니다.',
    description: <NotiUL list={files} />,
  })
}

export type ResponseUploadFile = {
  mediaList: Media[]
  notMediaList: string[]
}

export const showNotiUploadResult = (result: ResponseUploadFile) => {
  if (result.mediaList && result.mediaList.length >= 1) {
    notification.open({
      type: 'success',
      duration: 3,
      message: `미디어 파일 업로드 성공(${result.mediaList.length})`,
      description: <NotiUL list={result.mediaList} />,
    })
  }

  if (result.notMediaList && result.notMediaList.length >= 1) {
    notification.open({
      type: 'error',
      duration: 3,
      message: `미디어 파일 업로드 실패(${result.notMediaList.length})`,
      description: <NotiUL list={result.notMediaList} />,
    })
  }
}
