import { DatePicker, Form, Input, InputNumber, TimePicker } from 'antd'
import { Rule } from 'antd/lib/form'
import { get } from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'
import SelectorCountry from '../../SelectorCountry'
import AmCodeEditCell from './AMCodeEditCell'
import { EditCellProps } from './EditCell.type'
import ShopCodeEditCell from './ShopCodeEditCell'
import ShopTitleEditCell from './ShopTitleEditCell'

function disabledDate(current: moment.Moment) {
  // Can not select days before today and today
  return current && current < moment().endOf('day')
}

function EditCell<T>(props: EditCellProps<T>) {
  const {
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    isNullable,
    ...rest
  } = props
  const Render = useMemo(() => {
    if (editing) {
      const name = String(dataIndex)
      if (inputType === 'shopTitle') {
        return <ShopTitleEditCell {...props} />
      }
      if (inputType === 'shopCode') {
        return <ShopCodeEditCell {...props} />
      }
      if (inputType === 'amCode') {
        return <AmCodeEditCell {...props} />
      }
      if (inputType === 'country') {
        return (
          <SelectorCountry<T>
            dataIndex={dataIndex}
            manufacturerId={get(record, 'manufacturerId')}
          />
        )
      }
      if (inputType === 'time') {
        return (
          <Form.Item style={{ margin: 0 }} name={name}>
            <TimePicker placeholder="시간선택" />
          </Form.Item>
        )
      }

      if (inputType === 'date') {
        return (
          <Form.Item style={{ margin: 0 }} name={name}>
            <DatePicker disabledDate={disabledDate} showToday={false} />
          </Form.Item>
        )
      }
      const inputNode: JSX.Element =
        inputType === 'number' ? <InputNumber /> : <Input />

      const rules: Rule[] = []

      if (name === 'deviceRegexr') {
        rules.push({
          validator(_, value) {
            return new Promise((resolve, reject) => {
              try {
                new RegExp(value)
                resolve()
              } catch (e) {
                reject('정규식 에러')
              }
            })
          },
        })
      }

      rules.push({
        required: Boolean(isNullable) ? false : true,
        message: `${title} 을 입력하세요`,
      })
      return (
        <Form.Item name={name} style={{ margin: 0 }} rules={rules}>
          {inputNode}
        </Form.Item>
      )
    }
    return children
  }, [
    dataIndex,
    children,
    record,
    title,
    editing,
    inputType,
    isNullable,
    props,
  ])

  return <td {...rest}>{Render}</td>
}

export default EditCell
