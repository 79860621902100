import ApolloClient from 'apollo-client'
import { AppThunkAction } from './asyncAction.type'
import { StatsUnit, StatsStandard, LogList, QueryFindStatsArgs } from 'common'
import { $Actions } from 'modules/store/crossSliceReducer'
import gql from 'graphql-tag'

const TIME = 1000 * 30

const QUERY_FINDSTATS = gql`
  query FindStats($unit: StatsUnit!, $standard: StatsStandard!) {
    statsList: findStats(unit: $unit, standard: $standard) {
      date
      list {
        id
        activeRate
        offlineRate
        updateRate
        errorRate
      }
    }
  }
`
type ResultStats = {
  statsList: Array<LogList>
}

export const asyncActions = {
  requestStats: (
    gqlClient: ApolloClient<object>,
    unit: StatsUnit,
    standard: StatsStandard
  ): AppThunkAction => async (dispatch, getState) => {
    const id = `${standard}-${unit}`
    const { stats } = getState()
    const beforeData = stats[id]
    if (beforeData && beforeData.loading) {
      return
    }

    if (beforeData) {
      const beforDay = new Date(beforeData.updateAt)
      if (beforDay.getDay() === new Date().getDay()) {
        // 기간이 최신이라도 일자가 다르다면 다시 로드한다.
        if (beforeData.updateAt > Date.now() - TIME) {
          return
        }
      }
    }

    dispatch($Actions.requestStats(unit, standard))

    try {
      const { data } = await gqlClient.query<ResultStats, QueryFindStatsArgs>({
        query: QUERY_FINDSTATS,
        variables: { standard, unit },
        fetchPolicy: 'no-cache',
      })
      if (data) {
        const { statsList } = data
        dispatch($Actions.responseStats(unit, standard, statsList))
      } else {
        dispatch($Actions.errorStats(unit, standard))
        setTimeout(() => {
          dispatch(asyncActions.requestStats(gqlClient, unit, standard))
        }, 3000)
      }
    } catch (e) {
      dispatch($Actions.errorStats(unit, standard))
      setTimeout(() => {
        dispatch(asyncActions.requestStats(gqlClient, unit, standard))
      }, 3000)
    }
  },
}
