import { LogList, StatsStandard, StatsUnit } from 'common'
import { action } from 'typesafe-actions'
import { ActionTypes } from './types'

const requestStats = (unit: StatsUnit, standard: StatsStandard) =>
  action(ActionTypes.REQUEST_STATS, { unit, standard })

const responseStats = (
  unit: StatsUnit,
  standard: StatsStandard,
  logList: LogList[]
) => action(ActionTypes.RESPONSE_STATS, { unit, standard, logList })

const errorStats = (unit: StatsUnit, standard: StatsStandard) =>
  action(ActionTypes.ERROR_STATS, { unit, standard })

export const actions = {
  errorStats,
  requestStats,
  responseStats,
}
