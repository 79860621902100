import { PanelIndex, getNaviTitle } from 'common'
import { PageHeader } from 'components'
import { useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import PanelMapConfig from './PanelMapConfig'

export type PanelRouterProps = {
  paenlIndex: PanelIndex
  disableCloseBtn?: boolean
  onClose?: Function
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  /* background: red; */
`

const Body = styled.div<{ isModalOpen?: boolean }>`
  padding-left: 1rem;
  padding-top: 1rem;
  padding-right: 1rem;
  flex: 1;
  overflow: auto;
  margin-bottom: ${props => (Boolean(props.isModalOpen) ? '20rem' : '0')};
`

export const PanelRouter = ReactMemoEx((props: PanelRouterProps) => {
  const { paenlIndex, onClose, disableCloseBtn } = props
  const isModalOpen = useReduxSelector(
    state => state.layout.generatorModal !== undefined
  )

  const onClickBack = useMemo(() => {
    if (disableCloseBtn) {
      return undefined
    }
    return () => {
      if (onClose) {
        onClose()
      }
    }
    // NAVI_SYSTEM
  }, [disableCloseBtn, onClose])

  return (
    <Container>
      <PageHeader title={getNaviTitle(paenlIndex)} onBack={onClickBack} />
      <Body isModalOpen={isModalOpen}>
        <PanelMapConfig panelIndex={paenlIndex} />
      </Body>
    </Container>
  )
})
