import { Col, Row, Card } from 'antd'
import { TableAdmin } from 'components'
import { ReactMemoEx } from 'modules/view'
import React from 'react'

const PanelByUser = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card>
          <TableAdmin />
        </Card>
      </Col>
    </Row>
  )
}

export default ReactMemoEx(PanelByUser)
