import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { split, Operation } from 'apollo-link'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { WebSocketLink } from 'apollo-link-ws'
import { OperationDefinitionNode } from 'graphql'

export type GetAuthorization = () => string | null | undefined

export type GraphQLCliURLOption = {
  http: string
  ws?: string
}

function parseURLOption(
  option: GraphQLCliURLOption | string
): Required<GraphQLCliURLOption> {
  if (typeof option === 'string') {
    return {
      http: option,
      ws: option.replace(/http/, 'ws'),
    }
  }
  const { http, ws } = option
  return {
    http,
    ws: ws === undefined ? http.replace(/http/, 'ws') : ws,
  }
}

const cache = new InMemoryCache()

export type GqlClientManager = {
  client: ApolloClient<NormalizedCacheObject>
  close: () => void
}

export function createGraphQLClient(
  option: GraphQLCliURLOption | string,
  isAuth: boolean,
  authorization: GetAuthorization
) {
  const { http: httpURL, ws: wsURL } = parseURLOption(option)

  const subsClient = isAuth
    ? new SubscriptionClient(wsURL, {
        reconnect: true,
        lazy: true,
	timeout: 300000,
        connectionCallback: () => {
        },
        connectionParams: () => {
          const token = authorization()
          if (token) {
            return {
              authorization: `Bearer ${token}`,
            }
          }
          return {}
        },
      })
    : undefined
  const wsLink =
    subsClient !== undefined ? new WebSocketLink(subsClient) : undefined

  const httpLink = createHttpLink({
    uri: httpURL,
  })

  const authLink = setContext((_, { headers }) => {
    const token = authorization()
    if (token) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      }
    }
    return {
      headers,
    }
  })

  const client = new ApolloClient({
    link: wsLink
      ? authLink.concat(
          split(
            operation => {
              return isSubscription(operation)
            },
            wsLink,
            httpLink
          )
        )
      : httpLink,
    cache: cache,
  })

  return {
    client,
    close: () => {
      if (subsClient) {
        subsClient.close(true, true)
      }

      if (client) {
        client.stop()
      }
    },
  }
}

export type GqlApolloClient = ReturnType<typeof createGraphQLClient>

function isSubscription(operation: Operation): boolean {
  const selectedOperation = getSelectedOperation(operation)
  if (selectedOperation) {
    return selectedOperation.operation === 'subscription'
  }
  return false
}

function getSelectedOperation(
  operation: Operation
): OperationDefinitionNode | undefined {
  if (operation.query.definitions.length === 1) {
    return operation.query.definitions[0] as OperationDefinitionNode
  }

  return operation.query.definitions.find(
    d =>
      d.kind === 'OperationDefinition' &&
      !!d.name &&
      d.name.value === operation.operationName
  ) as OperationDefinitionNode
}
