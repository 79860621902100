import { blue, red } from '@ant-design/colors'
import {
  DisconnectOutlined,
  FrownOutlined,
  SmileOutlined,
} from '@ant-design/icons'
import { notification } from 'antd'
import React from 'react'

/**
 * 동일 제목 체크
 * @param list
 * @param title
 */
export function isEqualTitle<T extends { title: string }>(
  list: T[],
  title: string
) {
  const mTitle = title.toLowerCase()
  return list.findIndex(({ title: t }) => t.toLowerCase() === mTitle) >= 0
}

/**
 * 성공 noti
 * @param message
 * @param description
 */
export const notiSuccess = (message: string, description?: string) =>
  notification.open({
    message,
    description,
    icon: <SmileOutlined style={{ color: blue.primary }} />,
  })

/**
 * 성공 noti
 * @param message
 * @param description
 */
export const notiError = (message: string, description?: string) =>
  notification.open({
    message: `에러: ${message}`,
    description,
    icon: <FrownOutlined style={{ color: red.primary }} />,
  })

/**
 * 성공 noti
 * @param title
 */
export const notiErrorNetwork = (title?: string) =>
  notification.open({
    message: `네트워크 에러:${title}`,
    icon: <DisconnectOutlined style={{ color: red.primary }} />,
  })
