import { Form, Input } from 'antd'
import { get } from 'lodash'
import { useReduxSelector } from 'modules/store'
import React, { useMemo } from 'react'
import { EditCellProps } from './EditCell.type'

export default function ShopTitleEditCell<T = unknown>(
  props: EditCellProps<T>
) {
  const { record } = props
  const shopList = useReduxSelector(({ shopList }) => shopList)

  const validator = useMemo(
    () => (_: any, value: any): Promise<void> => {
      return new Promise((resolve, reject) => {
        const currentId: string = get(record, 'id', '-1')
        const saleChannelId: string = get(record, 'saleChannelId', '-1')
        const index = shopList.findIndex(
          ({ id, title, saleChannelId: scId }) => {
            return id !== currentId && scId === saleChannelId && title === value
          }
        )
        if (index >= 0) {
          reject('동일한 지점명이 존재합니다.')
        } else {
          resolve()
        }
      })
    },
    [record, shopList]
  )

  return (
    <Form.Item
      style={{ margin: 0 }}
      name={String(props.dataIndex)}
      rules={[
        {
          validator,
        },
      ]}
    >
      <Input type="string" />
    </Form.Item>
  )
}
