import React, { useMemo } from 'react'
import { Statistic } from 'antd'
import styled from 'styled-components'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { StatisticProps } from 'antd/lib/statistic/Statistic'
import { ReactMemoEx } from 'modules/view'
import { usePreviousValue } from 'beautiful-react-hooks'

const MiniStatistic = styled(Statistic)`
  min-width: 3.8rem;
  .ant-statistic-title {
    font-size: 0.5rem;
    margin-bottom: 0;
  }
  .ant-statistic-content {
    font-size: 1rem;
    .ant-statistic-content-value {
      .ant-statistic-content-value-decimal {
        font-size: 0.75rem;
      }
    }
    .ant-statistic-content-suffix {
      font-size: 0.75rem;
    }
  }
`
type TinyStatisticProps = {} & StatisticProps

const styleByDown: React.CSSProperties = { color: '#cf1322' }
const styleByUp: React.CSSProperties = { color: '#3f8600' }
const TinyStatistic = (props: TinyStatisticProps) => {
  const { value } = props
  const prevValue = usePreviousValue(value)
  const valueStat = useMemo((): TinyStatisticProps => {
    if (typeof value === 'number' && typeof prevValue === 'number') {
      if (value < prevValue) {
        return {
          prefix: <ArrowDownOutlined />,
          valueStyle: styleByDown,
        }
      }
      if (value > prevValue) {
        return {
          prefix: <ArrowUpOutlined />,
          valueStyle: styleByUp,
        }
      }
    }
    return {}
  }, [prevValue, value])
  return <MiniStatistic {...valueStat} {...props} />
}

export default ReactMemoEx(TinyStatistic)
