import { Form, Table } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { ColumnType, TableProps } from 'antd/lib/table'
import moment from 'moment'
import { CustomizeComponent } from 'rc-table/lib/interface'
import React, { useCallback, useMemo, useState } from 'react'
import isEqual from 'react-fast-compare'
import { COLUMN_KEY_REMOVE } from '../../Config'
import EditButton, { getColumnEditDefault } from './EditButton'
import EditCell from './EditCell'
import { EditCellType, omitCellEdit } from './types'
import { FieldData } from 'common'

export type EditTableFormValidation<T> = (newData: T) => FieldData[] | undefined

export type EditTableProps<T> = {
  rowKey: keyof T
  columns: Array<EditCellType<T>>
  onEdit?: (newData: T, form: FormInstance) => void
  disabledEdit?: boolean
  formValidation?: EditTableFormValidation<T>
} & Omit<TableProps<T>, 'rowKey' | 'columns'>

const TIME_SEC = 1000
const TIME_MIN = TIME_SEC * 60
const TIME_HOUR = TIME_MIN * 60

export function EditTable<T extends object>(props: EditTableProps<T>) {
  const {
    bordered = true,
    size = 'small',
    dataSource,
    columns,
    rowKey,
    disabledEdit = false,
    onEdit: onPropsEdit,
    formValidation,
    ...rest
  } = props
  const [form] = Form.useForm()

  const [editingKey, setEditingKey] = useState<string>('')
  const isEditing = useCallback(
    (record: T) => String(record[rowKey]) === editingKey,
    [editingKey, rowKey]
  )

  const setEditColumn = useCallback(
    (record: T) => {
      const value: { [key: string]: any } = { ...record }
      columns.forEach(col => {
        const { editType, dataIndex } = col
        if (editType && dataIndex) {
          const key = String(dataIndex)
          const v = value[key]
          if (editType === 'date' && v) {
            value[key] = moment(v)
          } else if (editType === 'time' && typeof v === 'number') {
            const d = new Date()
            d.setHours(0, 0, 0, 0)
            d.setTime(d.getTime() + v * 1000)
            value[key] = moment(d)
          }
        }
      })
      form.setFieldsValue(value)
      setEditingKey(String(record[rowKey]))
    },
    [form, rowKey, columns]
  )

  const setEditCancel = useCallback(() => {
    setEditingKey('')
    form.resetFields()
  }, [form])

  const save = useCallback(
    async (data: T) => {
      try {
        const row = await form.validateFields()
        columns.forEach(col => {
          const { editType, dataIndex, isNullable } = col
          if (editType && dataIndex) {
            const key = String(dataIndex)
            const v = row[key]
            if (editType === 'date') {
              if (v instanceof moment) {
                row[key] = moment(v)
                  .toDate()
                  .getTime()
              } else {
                row[key] = isNullable ? undefined : 0
              }
            } else if (editType === 'time') {
              if (v instanceof moment) {
                const mValue = moment(v)
                const output =
                  (mValue.hours() * TIME_HOUR +
                    mValue.minute() * TIME_MIN +
                    mValue.second() * TIME_SEC) /
                  TIME_SEC

                row[key] = isNaN(output) ? (isNullable ? undefined : 0) : output
              } else {
                row[key] = isNullable ? undefined : 0
              }
            }
          }
        })

        const newData = { ...data, ...row } as Required<T>
        if (formValidation) {
          const values = formValidation(newData)
          if (values) {
            form.setFields(values)
            return
          }
        }

        setEditCancel()
        if (!isEqual(data, newData) && onPropsEdit) {
          onPropsEdit(newData, form)
        }
      } catch (errInfo) {}
    },
    [columns, form, onPropsEdit, setEditCancel, formValidation]
  )

  const onChagePagination = useCallback(() => {
    setEditCancel()
  }, [setEditCancel])

  const mergeColumns = useMemo((): Array<ColumnType<T>> => {
    if (disabledEdit) {
      return columns
    }
    const mColumns: Array<EditCellType<T>> = []
    mColumns.push(...columns)

    const removeIndex = columns.findIndex(
      ({ key }) => key === COLUMN_KEY_REMOVE
    )
    const fixed = columns[removeIndex].fixed

    const editColumn: EditCellType<T> = {
      ...getColumnEditDefault<T>(),
      fixed,
      render: (_: any, record: T) => {
        const editable = isEditing(record)
        const disableEditButton = editingKey !== ''

        return (
          <EditButton<T>
            record={record}
            editable={editable}
            onEdit={setEditColumn}
            onEditSave={save}
            onEidtCancel={setEditCancel}
            disableEditButton={disableEditButton}
          />
        )
      },
    }

    if (removeIndex >= 0) {
      mColumns.splice(removeIndex, 0, editColumn)
    } else {
      mColumns.push(editColumn)
    }

    return mColumns.map(col => {
      const { editType } = col
      if (!editType) {
        return omitCellEdit(col)
      }
      return {
        ...col,
        onCell(record: T) {
          const props = {
            inputType: editType,
            isNullable: col.isNullable,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
            record: record,
          } as React.HTMLAttributes<HTMLElement>
          return props
        },
      }
    })
  }, [
    columns,
    disabledEdit,
    isEditing,
    setEditColumn,
    save,
    setEditCancel,
    editingKey,
  ])

  const scrollX = useMemo(() => {
    return mergeColumns.reduce((x, current) => {
      if (typeof current.width === 'number') {
        return x + current.width
      }
      return x
    }, 0)
  }, [mergeColumns])
  return (
    <Form form={form} component={false}>
      <Table<T>
        {...rest}
        bordered={bordered}
        size={size}
        components={{
          body: {
            cell: EditCell as CustomizeComponent,
          },
        }}
        scroll={{ x: scrollX }}
        columns={mergeColumns}
        dataSource={dataSource}
        rowKey={String(rowKey)}
        pagination={{
          showSizeChanger: true,
          onChange: onChagePagination,
        }}
      />
    </Form>
  )
}
