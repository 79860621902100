import { useApolloClient } from '@apollo/react-hooks'
import { useDidMount } from 'beautiful-react-hooks'
import { StatsStandard, StatsUnit, toStatId } from 'common'
import { $AsyncActions, useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { useDispatch } from 'react-redux'
import { CustomSimpleCard, getSafeStateItem } from '../../libs'
import { parseDatasetUpdate } from './datasets'

const standard = StatsStandard.SaleChannel
const unit = StatsUnit.Day

export const ChartUpdate = ReactMemoEx(() => {
  const dispatch = useDispatch()
  const gqlClient = useApolloClient()
  const [
    statItem,
    saleChannelList,
  ] = useReduxSelector(({ stats, saleChannelList }) => [
    getSafeStateItem(stats[toStatId({ standard, unit })], { standard, unit }),
    saleChannelList,
  ])
  useDidMount(() => {
    dispatch($AsyncActions.requestStats(gqlClient, unit, standard))
  })

  const barProps = useMemo(
    () => parseDatasetUpdate(statItem, saleChannelList),
    [statItem, saleChannelList]
  )

  return (
    <CustomSimpleCard title="업데이트 현황">
      <Bar {...barProps} />
    </CustomSimpleCard>
  )
})
