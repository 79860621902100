import * as Features from '../../features'
import {
  Admin$,
  Group$,
  Manufacturer$,
  Media$,
  SaleChannel$,
  Shop$,
} from './types'

type CrossState = {
  shopList$: Shop$[]
  manufacturerList$: Manufacturer$[]
  // groupList$: Group$[]
  saleChannelList$: SaleChannel$[]
  mediaList$: Media$[]
  groupList$: Group$[]
  adminList$: Admin$[]
}

const CROSS_INITIAL_STATE: CrossState = {
  shopList$: [],
  manufacturerList$: [],
  // groupList$: [],
  saleChannelList$: [],
  mediaList$: [],
  groupList$: [],
  adminList$: [],
}

export const INITIAL_APPLICATION_STATE = {
  ...Features.INITIAL_APPLICATION_STATE,
  ...CROSS_INITIAL_STATE,
}

export type ApplicationState = typeof INITIAL_APPLICATION_STATE
