import { PlayList } from 'common'
import { reducerDatabase, actionsDatabase } from '../util'

type Base = PlayList
export type State = Base[]

export enum ActionTypes {
  ADD = '@@plsList/ADD',
  ADDS = '@@plsList/ADDS',
  REMOVE = '@@plsList/REMOVE',
  REMOVES = '@@plsList/REMOVES',
  SET_LIST = '@@plsList/SET_LIST',
  UPDATE = '@@plsList/UPDATE',
  UPDATES = '@@plsList/UPDATES',
}

const actionDB = actionsDatabase<Base>(ActionTypes)

export const actions = {
  /**
   * Pls 추가
   */
  addPls: actionDB.add,
  addPlss: actionDB.adds,
  /**
   * Pls 삭제
   */
  removePls: actionDB.remove,
  removePlss: actionDB.removes,
  /**
   * Pls List 셋팅
   */
  setPlsList: actionDB.setList,
  /**
   * Pls 업데이트
   */
  updatePls: actionDB.update,
  updatePlss: actionDB.updates,
}

export const initialState: State = []

export const reducer = reducerDatabase(initialState, ActionTypes)
