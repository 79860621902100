import { ColumnsType } from 'antd/lib/table'
import { EditCellType } from './EditTable'

export type PickColumnList<T> = Array<keyof T>
export type PickColumnProps<T> = {
  pickColumn?: PickColumnList<T>
  disabledDelete?: boolean
  disabledEdit?: boolean
}

export function generatorPickColumns<T>(props: PickColumnProps<T>) {
  const { pickColumn, disabledDelete = false, disabledEdit = false } = props
  let columns: ColumnsType<T>[] = []
  return {
    push(data: keyof T, column: ColumnsType<T>) {
      if (pickColumn === undefined || pickColumn.indexOf(data) >= 0) {
        columns.push(column)
      }
    },
    pushEdit(column: ColumnsType<T>) {
      if (!disabledEdit) {
        columns.push(column)
      }
    },
    pushDelete(column: ColumnsType<T>) {
      if (!disabledDelete) {
        columns.push(column)
      }
    },
    pushForced(column: ColumnsType<T>) {
      columns.push(column)
    },
    toColumns() {
      return columns
    },
  }
}

export function generatorPickEditColumns<T>(props: PickColumnProps<T>) {
  const { pickColumn, disabledDelete = false, disabledEdit = false } = props
  let columns: EditCellType<T>[] = []
  return {
    push(data: keyof T, column: EditCellType<T>) {
      if (pickColumn === undefined || pickColumn.indexOf(data) >= 0) {
        columns.push(column)
      }
    },
    pushEdit(column: EditCellType<T>) {
      if (!disabledEdit) {
        columns.push(column)
      }
    },
    pushDelete(column: EditCellType<T>) {
      if (!disabledDelete) {
        columns.push(column)
      }
    },
    pushForced(column: EditCellType<T>) {
      columns.push(column)
    },
    toColumns() {
      return columns
    },
  }
}
