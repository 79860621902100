import { useMutation } from '@apollo/react-hooks'
import { MutationUpdateShopArgs, SaleChannel } from 'common'
import gql from 'graphql-tag'
import { Shop$ as DB, useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo, useState } from 'react'
import { TableCard, TableCardProps } from '../../../../../CustomCard'
import { EditTable } from '../../../EditTable'
import { SearchState } from '../../../util'
import createColumns from './shop.columns'
/*
// filterMode: 제조사 
|판매채널(제조사에 등록된 판매채널만)|지역(제조사에 등록된 지역만)|

// filterMode: 판매채널 
|지역(제조사에 등록된 지역만)|

// filterMode: 전체 
|제조사|판매채널|지역
*/

const MUTATION = gql`
  mutation UpdateShop(
    $title: String!
    $id: String!
    $shopCode: String
    $countryCodeId: String!
  ) {
    Shop: updateShop(
      title: $title
      id: $id
      countryCodeId: $countryCodeId
      shopCode: $shopCode
    ) {
      id
      title
      saleChannelId
      countryCodeId
      shopCode
    }
  }
`

export type TableShopProps = {
  cardProps?: TableCardProps
}

/**
 * 제조사 테이블
 */
export const TableShop = ReactMemoEx((props: TableShopProps) => {
  const { cardProps } = props

  // 검색
  const [searchState, setSearchedColumn] = useState<SearchState<DB>>([])

  const [updateMutation, { loading }] = useMutation<
    { result: SaleChannel },
    MutationUpdateShopArgs
  >(MUTATION)

  const shopList = useReduxSelector(state => state.shopList$)
  const columns = useMemo(() => createColumns(searchState, setSearchedColumn), [
    searchState,
    setSearchedColumn,
  ])

  const onEdit = useCallback(
    (newData: DB) => {
      let shopCode = undefined
      if (newData.shopCode) {
        if (newData.shopCode.trim().length <= 0) {
          shopCode = undefined
        } else {
          shopCode = newData.shopCode
        }
      }

      updateMutation({
        variables: {
          id: newData.id,
          title: newData.title,
          shopCode,
          countryCodeId: newData.countryCodeId,
        },
      })
    },
    [updateMutation]
  )

  return (
    <TableCard {...cardProps}>
      <EditTable
        columns={columns}
        dataSource={shopList}
        rowKey="id"
        onEdit={onEdit}
        loading={loading}
      />
    </TableCard>
  )
})
