import { getOf } from 'common'
import { groupBy } from 'lodash'
import { ApplicationState } from '../state'
import { SaleChannel$, Shop$ } from '../types'
import { Finders, getTVStatusByList } from './inLib'

const adapterSaleChannel = (
  state: ApplicationState,
  shopList$: Shop$[],
  { findManufacturer, groupByGroupId }: Finders
): SaleChannel$[] => {
  const { saleChannelList } = state
  const shopList = groupBy(shopList$, 'saleChannelId')

  const output = saleChannelList.map(
    (saleChannel): SaleChannel$ => {
      const { id, manufacturerId } = saleChannel
      const childrenShops = getOf(shopList, id, [])
      const manufacturer = findManufacturer(manufacturerId)
      const manufacturerTitle = manufacturer ? manufacturer.title : ''

      const tvStatus = getTVStatusByList(
        childrenShops,
        groupByGroupId[manufacturerId] || []
      )
      return {
        ...saleChannel,
        ...tvStatus,
        shopCount: childrenShops.length,
        manufacturerTitle,
      }
    }
  )
  return output
}

export default adapterSaleChannel
