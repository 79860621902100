import { LogStatItem } from 'modules/store/types'

export enum ActionTypes {
  REQUEST_STATS = '@@stats/REQUEST',
  RESPONSE_STATS = '@@stats/RESPONSE',
  ERROR_STATS = '@@stats/ERROR',
}

export type State = {
  [key: string]: LogStatItem
}
