import { Spin, Typography } from 'antd'
import { toStringFileSize } from 'common'
import { groupBy } from 'lodash'
import { useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './FileUploadNotification.less'

type MFile = { size: number; type: string; name: string }
type UploadTypes = {
  manufacturerId: string
  manufacturerTitle?: string
  fileSize: number
  files: MFile[]
}
const { Paragraph, Text } = Typography

const FileUploadNotification = () => {
  const [visible, setVisible] = useState(true)
  const manufacturerList = useReduxSelector(state => state.manufacturerList)
  const admin = useReduxSelector(state => state.auth.admin)
  const uploadFiles = useReduxSelector(state => state.mediaUpload)

  const uploadList = useMemo((): UploadTypes[] | undefined => {
    if (!admin.isLogin) {
      return undefined
    }
    if (admin.manufacturerId) {
      const { manufacturerId } = admin
      const manufacturer = manufacturerList.find(
        ({ id }) => id === manufacturerId
      )
      const output = uploadFiles.filter(
        ({ manufacturerId: mId }) => mId === manufacturerId
      )

      if (output.length <= 0) {
        return undefined
      }
      const files = output.map(({ name, size, type }) => ({ name, size, type }))
      return [
        {
          manufacturerId,
          manufacturerTitle: manufacturer ? manufacturer.title : undefined,
          files,
          fileSize: files.reduce((total, current) => total + current.size, 0),
        },
      ]
    }

    const output: UploadTypes[] = []

    const mapUploadFiles = groupBy(uploadFiles, 'manufacturerId')
    Object.keys(mapUploadFiles).forEach(manufacturerId => {
      const manufacturer = manufacturerList.find(
        ({ id }) => id === manufacturerId
      )
      const mUploadList = mapUploadFiles[manufacturerId]
      if (mUploadList.length >= 1) {
        const files = mUploadList.map(({ name, size, type }) => ({
          name,
          size,
          type,
        }))
        output.push({
          manufacturerId,
          manufacturerTitle: manufacturer ? manufacturer.title : undefined,
          files,
          fileSize: files.reduce((total, current) => total + current.size, 0),
        })
      }
    })
    return output.length <= 0 ? undefined : output
  }, [manufacturerList, uploadFiles, admin])

  useEffect(() => {
    if (uploadList !== undefined) {
      setVisible(true)
    }
  }, [uploadList])

  const onClickClose = useCallback(() => {
    setVisible(false)
  }, [])

  return (
    <>
      {uploadList && visible && (
        <div className="file-noti-container">
          <div className="ant-notification">
            <span>
              <div className="ant-notification-notice ant-notification-notice-closable">
                <div className="ant-notification-notice-content">
                  <div className="">
                    <div className="ant-notification-notice-message">
                      <Spin size="small" style={{ marginRight: '0.5rem' }} />
                      파일 업로드 중입니다.
                    </div>
                    <div className="ant-notification-notice-description">
                      {uploadList.map(upload => {
                        return (
                          <React.Fragment key={upload.manufacturerId}>
                            <Paragraph strong className="m-title">
                              {`${upload.manufacturerTitle}(${toStringFileSize(
                                upload.fileSize
                              )})`}
                            </Paragraph>
                            <Paragraph>
                              <ul>
                                {upload.files.map(({ name, size }) => {
                                  return (
                                    <li key={name}>
                                      <Text ellipsis copyable>
                                        {name}
                                      </Text>
                                    </li>
                                  )
                                })}
                              </ul>
                            </Paragraph>
                          </React.Fragment>
                        )
                      })}
                    </div>
                    {React.createElement(
                      'a',
                      {
                        className: 'ant-notification-notice-close',
                        onClick: onClickClose,
                      },
                      <span className="ant-notification-close-x">
                        <span
                          role="img"
                          aria-label="close"
                          className="anticon anticon-close ant-notification-close-icon"
                        >
                          <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            className=""
                            data-icon="close"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                          </svg>
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
      )}
    </>
  )
}

export default ReactMemoEx(FileUploadNotification)
