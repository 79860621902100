import React from 'react'
import { TableGroup } from 'components'
import { ReactMemoEx } from 'modules/view'
import { Row, Col } from 'antd'

const PanelByGroup = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <TableGroup />
      </Col>
    </Row>
  )
}

export default ReactMemoEx(PanelByGroup)
