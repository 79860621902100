import axios from 'axios'
import Chart from 'chart.js'
import 'chartjs-plugin-colorschemes'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { configureStore } from 'modules/store'
import { getAuthToken } from 'modules/useLibs'
import React from 'react'
import ReactDOM from 'react-dom'
import 'react-grid-layout/css/styles.css'
import { Provider } from 'react-redux'
import 'react-resizable/css/styles.css'
import App from './App'
import './index.less'
import * as serviceWorker from './serviceWorker'
import { ConfigProvider } from 'antd'
import koKR from 'antd/es/locale/ko_KR'

// Geocode.setApiKey(Config.googleMapKey)
// Geocode.setLanguage('ko')
// Geocode.fromAddress('충북 청주시 용암동 2060').then(data => {
//   console.log('data', data)
// })
Chart.plugins.register(ChartDataLabels)

axios.interceptors.request.use(config => {
  if (config.headers === undefined) {
    config.headers = {}
  }
  if (typeof config.headers['authorization'] === 'undefined') {
    const token = getAuthToken()
    if (token) {
      config.headers['authorization'] = `Bearer ${token}`
    }
  }
  return config
})

const store = configureStore()

ReactDOM.render(
  <ConfigProvider locale={koKR}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
