import React from 'react'
import { ReactMemoEx } from 'modules/view'
import { PlayListManager, TablePlayList } from 'components'
import { isMobile } from 'react-device-detect'
import { Row, Col, Card } from 'antd'

const PanelByPlayListByUser = () =>
  isMobile ? (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card>
          <TablePlayList />
        </Card>
      </Col>
    </Row>
  ) : (
    <PlayListManager />
  )

export default ReactMemoEx(PanelByPlayListByUser)
