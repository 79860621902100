import { Button, Divider } from 'antd'
import React, { useCallback, useMemo } from 'react'
import styeld from 'styled-components'
import { useContextSelector } from 'use-context-selector'
import { ContextByMCTable } from './mediaCenter.context'
import { MediaCenterAlpha } from './types'
import isEqual from 'react-fast-compare'
import { Language } from 'common'

const SDivider = styeld(Divider)`
  margin:0;
`
export const WIDTH_EDITBUTTN = 65

export type EditButtonProps = {
  record: MediaCenterAlpha
}

function EditButton(props: EditButtonProps) {
  const { record } = props
  const { id } = record
  const editRow = useContextSelector(ContextByMCTable, state => state.editRow)
  const setEditRow = useContextSelector(
    ContextByMCTable,
    state => state.setEditRow
  )
  const onEdit = useContextSelector(ContextByMCTable, state => state.onEdit)
  const form = useContextSelector(ContextByMCTable, state => state.form)

  const isEditing = useMemo(() => editRow !== undefined, [editRow])
  const isEditingByCurrent = useMemo(() => {
    if (editRow === undefined) {
      return false
    }
    return id === editRow.id
  }, [id, editRow])

  const onClickStartEditing = useCallback(() => {
    if (form) {
      form.setFieldsValue(record)
    }
    setEditRow(record)
  }, [record, setEditRow, form])

  const onClickCancel = useCallback(() => {
    if (form) {
      form.resetFields()
    }
    setEditRow(undefined)
  }, [form, setEditRow])

  const onClickSave = useCallback(async () => {
    if (form === undefined) {
      return
    }
    try {
      await form.validateFields()
      const data = form.getFieldsValue() as Partial<MediaCenterAlpha>
      const next = Object.assign({ ...record }, data)
      if (!isEqual(next, record)) {
        onEdit(next, record)
      }
      form.resetFields()
      setEditRow(undefined)
    } catch (e) {}
  }, [form, onEdit, record, setEditRow])

  return useMemo(() => {
    if (isEditingByCurrent) {
      return (
        <>
          <Button type="link" onClick={onClickSave}>
            {Language.BTN_SAVE}
          </Button>
          <SDivider type="vertical" />
          <Button type="link" danger onClick={onClickCancel}>
            {Language.BTN_CANCEL}
          </Button>
        </>
      )
    }
    return (
      <Button type="link" disabled={isEditing} onClick={onClickStartEditing}>
        {Language.BTN_EDIT}
      </Button>
    )
  }, [
    isEditing,
    isEditingByCurrent,
    onClickSave,
    onClickCancel,
    onClickStartEditing,
  ])
}

export default EditButton
