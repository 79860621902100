import { SubscriptionEntity, toEA, toStringPercent, ui } from 'common'
import { QueryHelper } from 'modules/graphql'
import { Group$ as DB } from 'modules/store'
import React from 'react'
import ActiveRender from '../../../ActiveRender'
import { EditCellType } from '../../../EditTable'
import {
  generateFilterColumn,
  generateNumberColumn,
  generateSearchColumn,
  genrateDeleteColumn,
} from '../../../GenerateColumn'
import { SearchState } from '../../../util'

type Options = {
  manufacturerList?: string[]
  disabledManufacturer?: boolean
}

export default function createColumns(
  searchState: SearchState<DB>,
  setSearchedColumn: React.Dispatch<React.SetStateAction<SearchState<DB>>>,
  options: Options
): EditCellType<DB>[] {
  const { manufacturerList = [], disabledManufacturer = false } = options

  const columns: EditCellType<DB>[] = [
    {
      editType: 'string',
      ...generateSearchColumn<DB>(
        ui.createCol<DB>('그룹명', ui.ColSize.xxl, {
          dataIndex: 'title',
          align: 'left',
        }),
        searchState,
        {
          onSearch: option => {
            setSearchedColumn(option)
          },
          onClear: option => {
            setSearchedColumn(option)
          },
        }
      ),
    },
    {
      editType: 'string',
      // isRequred
      isNullable: true,
      ...generateSearchColumn<DB>(
        ui.createCol<DB>('디바이스정규식', ui.ColSize.xxl, {
          dataIndex: 'deviceRegexr',
          align: 'left',
        }),
        searchState,
        {
          onSearch: option => {
            setSearchedColumn(option)
          },
          onClear: option => {
            setSearchedColumn(option)
          },
        }
      ),
    },
  ]

  if (disabledManufacturer === false) {
    columns.push(
      generateFilterColumn<DB>(
        ui.createCol<DB>('제조사명', ui.ColSize.md, {
          dataIndex: 'manufacturerTitle',
          align: 'center',
        }),
        manufacturerList
      )
    )
  }

  const resultColumns = columns.concat([
    generateNumberColumn<DB>(
      ui.createCol<DB>('TV 수', ui.ColSize.md, {
        dataIndex: 'tvTotalCount',
        render: toEA,
      })
    ),

    generateNumberColumn<DB>(
      ui.createCol<DB>('활성 TV', ui.ColSize.md, {
        dataIndex: 'tvActiveCount',
        render: toEA,
      })
    ),

    generateNumberColumn<DB>(
      ui.createCol<DB>('TV접속률', ui.ColSize.md, {
        dataIndex: 'tvActivePercent',
        render: (percent: number) => (
          <ActiveRender percent={percent}>
            {toStringPercent(percent)}
          </ActiveRender>
        ),
      })
    ),
    genrateDeleteColumn<DB>(
      QueryHelper.removeOneById(SubscriptionEntity.Group)
    ),
  ])

  return resultColumns
}
