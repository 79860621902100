import { action } from 'typesafe-actions'
import { ActionTypes } from './types'
import { PanelIndex } from 'common'

const pushPanel = (panel: PanelIndex, isSub = false) =>
  action(ActionTypes.PUSH_PANEL, { panel, isSub })

const removePanel = (index: number) => action(ActionTypes.REMOVE_PANEL, index)

export const actions = { pushPanel, removePanel }
