import React, { useMemo } from 'react'
import Highlighter, { HighlighterProps } from 'react-highlight-words'
import { ReactMemoEx } from 'modules/view'

type HighlighterTextProps = Omit<HighlighterProps, 'searchWords'> & {
  children?: string
  searchWords?: string | string[]
}

const HIGHLIGHT_STYLE = { backgroundColor: '#ffc069', padding: 0 }

export const HighlighterText = ReactMemoEx((props: HighlighterTextProps) => {
  const { children, searchWords = [], highlightStyle = HIGHLIGHT_STYLE } = props
  const text = useMemo(() => {
    if (children === undefined) {
      return ''
    }
    return String(children)
  }, [children])

  const mSearchWords = useMemo(() => {
    if (typeof searchWords === 'string') {
      return [searchWords]
    }
    return searchWords
  }, [searchWords])

  return (
    <Highlighter
      highlightStyle={highlightStyle}
      searchWords={mSearchWords}
      autoEscape
      textToHighlight={text}
    />
  )
})
