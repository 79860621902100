import { Card } from 'antd'
import { Config, Manufacturer, Media } from 'common'
import { $AsyncActions } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo, useRef } from 'react'
import Dropzone from 'react-dropzone'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useContextSelector } from 'use-context-selector'
import { ActionByPLMTypes, ContextByPLM } from '../../StorePLM'
import ButtonInfo from './ButtonInfo'
import ButtonRemove from './ButtonRemove'
import ButtonUpload from './ButtonUpload'
import showErroFiles from './ErrorUL'
import MediaFinderBody from './MediaFinderBody'
type DropZoneContainerProps = { isDropEnter?: boolean }

const DropZoneContainer = styled.div<DropZoneContainerProps>`
  outline: none;
  height: 100%;
  ${({ isDropEnter = false }) =>
    Boolean(isDropEnter) ? 'background: rgba(83,146,240,0.125)' : ''}
`

const SCard = styled(Card)`
  display: flex;
  flex-direction: column;
  min-width: 20rem;
  width: 100%;
  height: 100%;
  &.ant-card-hoverable {
    cursor: auto;
  }
  .ant-card-body {
    cursor: auto;
    padding: 0.25rem;
    flex: 1;
    overflow: auto;
  }
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export type MediaFinderContainerProps = {
  manufacturerId?: Manufacturer['id']
}

type InnerMediaFinderProps = {
  manufacturerId: string
}

const InnerMediaFinder = ReactMemoEx((props: InnerMediaFinderProps) => {
  const { manufacturerId } = props
  const dispatch = useDispatch()
  const refInput = useRef<HTMLInputElement | null>(null)
  const dispatchContext = useContextSelector(ContextByPLM, state => state[1])
  // const [rejectFileList, setRejectFileList] = useState<Array<File>>()
  const onDropAccepted = useCallback(
    (files: File[]) => {
      if (manufacturerId) {
        dispatch($AsyncActions.uploadMedia(manufacturerId, files))
      }
    },
    [dispatch, manufacturerId]
  )

  const onDropRejected = useCallback(files => showErroFiles(files), [])
  const onSelect = useCallback(
    (payload: Media[]) =>
      dispatchContext({
        type: ActionByPLMTypes.SET_SELECT_MEDIALIST,
        payload: payload.map(({ id }) => id),
      }),
    [dispatchContext]
  )

  const onClickUpload = useCallback(() => {
    if (refInput.current) {
      refInput.current.click()
    }
  }, [])
  return (
    <Dropzone
      accept={Config.mediaUploadAccept}
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
    >
      {({ getRootProps, getInputProps }) => (
        <SCard
          title={
            <TitleContainer>
              <span>미디어리스트</span>
            </TitleContainer>
          }
          extra={
            <>
              <ButtonUpload onClick={onClickUpload} />
              <ButtonInfo />
              <ButtonRemove />
            </>
          }
        >
          <DropZoneContainer {...getRootProps()}>
            <input {...getInputProps()} ref={refInput} />
            <MediaFinderBody
              manufacturerId={manufacturerId}
              onSelect={onSelect}
            />
          </DropZoneContainer>
        </SCard>
      )}
    </Dropzone>
  )
})

export const MediaFinder = ReactMemoEx(() => {
  const manufacturerId = useContextSelector(
    ContextByPLM,
    state => state[0].manufacturerId
  )

  return useMemo(() => {
    if (manufacturerId === undefined) {
      return null
    }
    return <InnerMediaFinder manufacturerId={manufacturerId} />
  }, [manufacturerId])
})
