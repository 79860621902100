import { findGroupById, getOf, Group, MediCenterAndLog } from 'common'
import { groupBy } from 'lodash'
import { ApplicationState } from '../state'
import { Shop$, TVTotalStatus } from '../types'
import { DEFAULT_TV_STATUS, Finders, getTVStatusByList } from './inLib'

const safeNaN = (percent: number) => Math.min(isNaN(percent) ? 0 : percent, 1)

const getTVTotalStatus = (
  mc: MediCenterAndLog | undefined,
  group: Group[] = []
): TVTotalStatus => {
  if (mc === undefined) {
    return { ...DEFAULT_TV_STATUS }
  }
  let tvActiveCount = 0
  let tvErrorCount = 0
  let tvOfflineCount = 0
  // let totalMediaPercent = 0
  const tvGroupCount: { [key: string]: number } = {}

  group.forEach(({ id }) => {
    if (mc.tvInfoToNum) {
      const log = mc.tvInfoToNum.find(({ groupId }) => groupId === id)
      if (log) {
        const { active, error, offline } = log
        const count = active + error + offline
        tvActiveCount += active
        tvErrorCount += error
        tvOfflineCount += offline
        // totalMediaPercent += media
        if (tvGroupCount[id] === undefined) {
          tvGroupCount[id] = count
        } else {
          tvGroupCount[id] = tvGroupCount[id] + count
        }
      }
    }
  })

  const tvTotalCount = tvActiveCount + tvErrorCount + tvOfflineCount
  const percent = tvActiveCount / tvTotalCount
  return {
    tvActiveCount,
    tvErrorCount,
    tvOfflineCount,
    tvTotalCount,
    tvActivePercent: safeNaN(percent),
    tvGroupCount,
    totalMediaPercent: mc.updateRate || 0,
    isNoneData: false,
  }
}

/**
 * Shop에 정보를 MediaCenter 데이터를 추가한다.
 * @param state
 */
const adapterShop = (
  state: ApplicationState,
  {
    findManufacturer,
    findSaleChannel,
    findCountryCode,
    groupByGroupId,
  }: Finders
): Shop$[] => {
  const { shopList, mediaCenterList, groupList } = state

  const groups = groupBy(groupList, 'manufacturerId')
  const findMediaCenter = findGroupById(mediaCenterList, 'shopId')

  const output = shopList.reduce<Shop$[]>((outputList, shop) => {
    const { countryCodeId, saleChannelId, id } = shop
    const countryTitle = getOf(findCountryCode(countryCodeId), 'title', '')
    const saleChannel = findSaleChannel(saleChannelId)
    if (saleChannel === undefined) {
      return outputList
    }
    const { manufacturerId } = saleChannel
    const inGroupList = groups[manufacturerId] || []
    const manufacturer = findManufacturer(manufacturerId)
    if (manufacturer === undefined) {
      return outputList
    }
    const inMediaCenterList = findMediaCenter(id)
    // console.log('inMediaCenterList', inMediaCenterList)
    const mediaCenterCount = inMediaCenterList.length

    const tvStatus =
      inMediaCenterList.length <= 0
        ? { ...DEFAULT_TV_STATUS }
        : mediaCenterCount <= 1
        ? getTVTotalStatus(inMediaCenterList[0], inGroupList)
        : getTVStatusByList(
            inMediaCenterList.map(mediaCenter =>
              getTVTotalStatus(mediaCenter, inGroupList)
            ),
            groupByGroupId[manufacturerId] || []
          )

    outputList.push({
      ...shop,
      ...tvStatus,
      countryTitle,
      manufacturerId,
      manufacturerTitle: manufacturer.title,
      saleChannelTitle: saleChannel.title,
    })
    return outputList
  }, [])
  return output
}

export default adapterShop
