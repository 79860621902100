import * as antColors from '@ant-design/colors'
import { Badge, Statistic, Typography } from 'antd'
import { ChartDataSets } from 'chart.js'
import color from 'color'
import { ReactMemoEx } from 'modules/view'
import React from 'react'
import { Doughnut } from 'react-chartjs-2'

const { Paragraph } = Typography

export const toPercent = (num: number | undefined | null) => {
  if (num === undefined || num === null) {
    return 0
  }
  if (isNaN(num)) {
    return 0
  }
  return (num * 100)
    .toFixed(2)
    .replace(/0+$/, '')
    .replace(/\.$/, '')
}

export type TabChartItemProps = {
  title: string
  subTitle?: string
  value: string | number
  errorValue?: string | number
  datasets: ChartDataSets[]
}

export const COLOR_PRIMARY = antColors.blue.primary || '#00f'

export const COLOR_SUB = color(antColors.grey[0])
  .alpha(0.2)
  .toString()

export const COLOR_ERROR = antColors.red.primary || '#f00'

const TabChartItem = (props: TabChartItemProps) => {
  const { title, subTitle, value, errorValue, datasets } = props

  return (
    <div className="tabchart-item">
      <div className="statistic-container">
        <Statistic
          title={
            <>
              <Paragraph className="title">{title}</Paragraph>
              {subTitle && (
                <Paragraph className="subtitle">{subTitle}</Paragraph>
              )}
            </>
          }
          value={typeof value === 'number' ? toPercent(value) : value}
          prefix={<Badge dot status="success" />}
          suffix="%"
        />
        {errorValue !== undefined && (
          <Paragraph style={{ margin: 0 }}>
            <Badge dot status="error" />
            {typeof errorValue === 'number'
              ? toPercent(errorValue)
              : errorValue}
          </Paragraph>
        )}
      </div>
      <div className="graph-container">
        <Doughnut
          options={{
            plugins: { datalabels: { display: false } },
            tooltips: { enabled: false },
            responsive: true,
            maintainAspectRatio: false,
          }}
          data={{ datasets }}
        />
      </div>
    </div>
  )
}

export default ReactMemoEx(TabChartItem)
