import { useApolloClient } from '@apollo/react-hooks'
import { Spin } from 'antd'
import {
  Language,
  StatsStandard,
  StatsUnit,
  toStatId,
  toStringPercent,
} from 'common'
import { $AsyncActions, useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { useDispatch } from 'react-redux'
import { CustomCard, defaultChartOptions, getSafeStateItem } from '../../libs'
import { parseDatasets, StatisticsInfoPickKey } from './datasets'

export const CardChartTotalSCConnect = ReactMemoEx(
  (props: { disabledTitle?: boolean }) => {
    const { disabledTitle = false } = props
    const [unit, setUnit] = useState<StatsUnit>(StatsUnit.Day)
    const onChangeTab = useCallback((activeKey: string) => {
      if (
        activeKey === StatsUnit.Day ||
        activeKey === StatsUnit.Month ||
        activeKey === StatsUnit.Year
      ) {
        setUnit(activeKey)
      }
    }, [])

    return (
      <CustomCard
        childrenType="element"
        title={Language.ConnectStatistics}
        tabs={[
          { key: StatsUnit.Day, title: Language.Daily },
          { key: StatsUnit.Month, title: Language.Monthly },
          { key: StatsUnit.Year, title: Language.Yearly },
        ]}
        tabOptions={{
          defaultActiveKey: StatsUnit.Day,
          onChange: onChangeTab,
        }}
      >
        <ChartTotalSCConnect
          unit={unit}
          type="activeRate"
          disabledTitle={disabledTitle}
        />
      </CustomCard>
    )
  }
)

export type ChartTotalSCConnectProps = {
  unit: StatsUnit
  type: StatisticsInfoPickKey
  disabledTitle?: boolean
}

const toTitle = (type: StatisticsInfoPickKey) => {
  if (type === 'activeRate') {
    return Language.ConnectRate
  }
  if (type === 'errorRate') {
    return Language.ErrorRate
  }
  if (type === 'offlineRate') {
    return Language.DisConnectRate
  }
  if (type === 'updateRate') {
    return 'd'
  }
}

export const ChartTotalSCConnect = ReactMemoEx(
  (props: ChartTotalSCConnectProps) => {
    const { unit, type, disabledTitle = false } = props
    const gqlClient = useApolloClient()
    const dispatch = useDispatch()

    const [saleChannelList, logStatItem] = useReduxSelector(state => {
      const { saleChannelList, stats } = state
      return [
        saleChannelList,
        getSafeStateItem(
          stats[toStatId({ unit, standard: StatsStandard.SaleChannel })],
          {
            standard: StatsStandard.SaleChannel,
            unit,
          }
        ),
      ]
    })

    // console.log('logStatItem.loading', logStatItem.loading)
    const mData = useMemo(
      () => parseDatasets(logStatItem.logList, saleChannelList, type),
      [saleChannelList, logStatItem, type]
    )

    useEffect(() => {
      dispatch(
        $AsyncActions.requestStats(gqlClient, unit, StatsStandard.SaleChannel)
      )
    }, [unit, dispatch, gqlClient])
    return (
      <>
        <Bar
          data={mData}
          options={{
            title: {
              display: !disabledTitle,
              text: toTitle(type),
              fullWidth: true,
              position: 'top',
            },
            plugins: {
              datalabels: {
                display: false,
              },
            },
            ...defaultChartOptions,
            scales: {
              yAxes: [
                {
                  ticks: {
                    min: 0,
                    max: 1,
                    callback: value => toStringPercent(value),
                  },
                },
              ],
            },
            legend: {
              position: 'bottom',
            },
            tooltips: {
              mode: 'index',
              intersect: true,
              callbacks: {
                label: (tooltipItem, data) => {
                  if (
                    data.datasets !== undefined &&
                    tooltipItem.datasetIndex !== undefined
                  ) {
                    return `${
                      data.datasets[tooltipItem.datasetIndex].label
                    }: ${toStringPercent(tooltipItem.value)}`
                  }
                  return toStringPercent(tooltipItem.value)
                },
              },
            },
          }}
        />
        {logStatItem.loading && (
          <div className="c-spin">
            <Spin />
          </div>
        )}
      </>
    )
  }
)
