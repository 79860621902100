import * as mediaUpload from './src/mediaUpload.action'
import * as playList from './src/playList.action'
import * as plsEvent from './src/plsEvent.action'
import * as mediaRemove from './src/mediaRemove.action'
import * as stats from './src/stats.action'

export const $AsyncActions = {
  ...mediaUpload.asyncActions,
  ...plsEvent.asnycActions,
  ...playList.asyncActions,
  ...mediaRemove.asyncActions,
  ...stats.asyncActions,
}
