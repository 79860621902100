import { LockOutlined } from '@ant-design/icons'
import { Col, Form, Input, Row, Select } from 'antd'
import { useDidMount } from 'beautiful-react-hooks'
import {
  FieldData,
  GeneratorModalIndex,
  isErrorByNetwork,
  MutationAddAdminArgs,
} from 'common'
import { useMutationAddAdmin } from 'modules/graphql'
import { isSystemUser, useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { defaultColProps, defaultMinColProps } from './Config'
import ContainerFormLayout from './ContainerFormLayout'
import EmptyContainer from './EmptyContainer'
import { notiError, notiErrorNetwork, notiSuccess } from './util'

// const MUTATION_ADD_ADMIN
const SelectOption = Select.Option

const FormCol = styled(Col)`
  label.ant-form-item-required {
    width: 5.5rem;
  }
`
const ERROR_TITLE = '관리자 생성 실패'

const FormAdminBody = () => {
  const [form] = Form.useForm()
  const [addAdmin, { loading }] = useMutationAddAdmin()
  const adminList = useReduxSelector(state => state.adminList)
  const [selectAdminLevel, setSelectAdminLevel] = useState(0)
  const manufacturerList = useReduxSelector(
    ({ manufacturerList }) => manufacturerList
  )

  useDidMount(() => {
    form.setFieldsValue({ adminLevel: 0 })
  })

  const onSelectAdminLevel = useCallback((value: number) => {
    setSelectAdminLevel(value)
  }, [])

  const fields = useMemo(() => {
    return (
      <>
        <Row gutter={[32, 16]}>
          <FormCol {...defaultColProps}>
            <Form.Item
              name="adminLevel"
              label="관리자 레벨"
              rules={[
                {
                  required: true,
                  message: '관리자 레벨을 선택하세요',
                },
              ]}
            >
              <Select onChange={onSelectAdminLevel}>
                <SelectOption value={0} children="시스템 관리자" />
                <SelectOption value={1} children="제조사" />
              </Select>
            </Form.Item>
          </FormCol>
          {selectAdminLevel === 1 && (
            <FormCol {...defaultColProps}>
              <Form.Item
                name="manufacturerId"
                label="제조사"
                rules={[
                  {
                    required: true,
                    message: '제조사를 선택해주세요!',
                  },
                ]}
              >
                <Select placeholder="제조사를 선택해주세요">
                  {manufacturerList.map(manufacturer => {
                    return (
                      <SelectOption
                        key={manufacturer.id}
                        value={manufacturer.id}
                        children={manufacturer.title}
                      />
                    )
                  })}
                </Select>
              </Form.Item>
            </FormCol>
          )}
        </Row>
        <Row gutter={[32, 16]}>
          <FormCol {...defaultMinColProps}>
            <Form.Item
              name="email"
              label="e-mail"
              rules={[
                {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Email이 입력되지 않았습니다.',
                },
              ]}
            >
              <Input placeholder="그룹명" />
            </Form.Item>
          </FormCol>
          <FormCol {...defaultMinColProps}>
            <Form.Item
              name="password"
              label="패스워드"
              rules={[
                { min: 4, message: '최소 4글자 이상을 입력해주세요.' },
                { required: true, message: '비밀번호를 입력해주세요.' },
              ]}
            >
              <Input placeholder="password" prefix={<LockOutlined />} />
            </Form.Item>
          </FormCol>
          <FormCol {...defaultMinColProps}>
            <Form.Item
              name="userName"
              label="관리자명"
              rules={[{ required: true, message: '연락처를 입력해주세요.' }]}
            >
              <Input placeholder="홍길동" />
            </Form.Item>
          </FormCol>
          <FormCol {...defaultMinColProps}>
            <Form.Item
              name="phoneNumber"
              label="연락처"
              rules={[{ required: true, message: '연락처를 입력해주세요.' }]}
            >
              <Input placeholder="연락처" />
            </Form.Item>
          </FormCol>
        </Row>
      </>
    )
  }, [manufacturerList, selectAdminLevel, onSelectAdminLevel])

  const onFinish = useCallback(async () => {
    const variables = form.getFieldsValue() as MutationAddAdminArgs
    const { email } = variables
    const isEqaulEmail =
      adminList.findIndex(({ email: em }) => em === email) >= 0
    if (isEqaulEmail) {
      const files: FieldData[] = [
        { name: 'email', errors: ['동일한 이메일이 존재합니다.'] },
      ]
      form.setFields(files)
      return
    }

    try {
      await addAdmin({ variables })
      notiSuccess(`관리자 생성완료(${variables.email})`)
      form.resetFields()
    } catch (e) {
      if (isErrorByNetwork(e)) {
        notiErrorNetwork(ERROR_TITLE)
      } else {
        notiError(ERROR_TITLE, String(e))
      }
    }
  }, [form, addAdmin, adminList])

  return (
    <ContainerFormLayout
      modalIndex={GeneratorModalIndex.Shop}
      loading={loading}
      title="관리자 생성"
      form={form}
      formChildren={fields}
      onFinish={onFinish}
    />
  )
}

const FormAdmin = () => {
  const adminInfo = useReduxSelector(({ auth }) => auth.admin)

  return isSystemUser(adminInfo) ? (
    <FormAdminBody />
  ) : (
    <EmptyContainer
      index={GeneratorModalIndex.Admin}
      title="관리자 생성(사용 권한 없음)"
    />
  )
}

export default ReactMemoEx(FormAdmin)
