import {
  ClusterOutlined,
  CompassOutlined,
  DownOutlined,
  GlobalOutlined,
  HddOutlined,
  ShopOutlined,
  UngroupOutlined,
  UserAddOutlined,
  BorderlessTableOutlined,
} from '@ant-design/icons'
import { Button, Dropdown, Menu, Tag, Drawer } from 'antd'
import { GeneratorModalIndex, GenratorModalItemList } from 'common'
import { $Actions, useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

const toStringShowHide = (
  modalIndex: GeneratorModalIndex,
  reduxModalIndex: GeneratorModalIndex | undefined
) => {
  const addOne = modalIndex === reduxModalIndex ? '닫기' : '생성'
  if (modalIndex === GeneratorModalIndex.MediaCenter) {
    return `미디어 센터 ${addOne}`
  }
  if (modalIndex === GeneratorModalIndex.Shop) {
    return `지점 ${addOne}`
  }
  if (modalIndex === GeneratorModalIndex.Group) {
    return `그룹 ${addOne}`
  }
  if (modalIndex === GeneratorModalIndex.AMCode) {
    return `AM 코드 ${addOne}`
  }
  if (modalIndex === GeneratorModalIndex.Manufacturer) {
    return `제조사 ${addOne}`
  }
  if (modalIndex === GeneratorModalIndex.SaleChannel) {
    return `판매 채널 ${addOne}`
  }
  if (modalIndex === GeneratorModalIndex.CountryCode) {
    return `지역 코드 ${addOne}`
  }
  if (modalIndex === GeneratorModalIndex.Admin) {
    return `관리자 ${addOne}`
  }

  return ''
}

// const SMenu = styled(Men)
const MenuItem = styled(Menu.Item)``
const STag = styled(Tag)`
  margin: 0;
  opacity: 0.9;
`
const MenuDivider = Menu.Divider

const getIcon = (key: GeneratorModalIndex) => {
  if (key === GeneratorModalIndex.CountryCode) {
    return <CompassOutlined />
  }
  if (key === GeneratorModalIndex.Group) {
    return <UngroupOutlined />
  }
  if (key === GeneratorModalIndex.AMCode) {
    return <BorderlessTableOutlined />
  }
  if (key === GeneratorModalIndex.Manufacturer) {
    return <GlobalOutlined />
  }
  if (key === GeneratorModalIndex.MediaCenter) {
    return <HddOutlined />
  }
  if (key === GeneratorModalIndex.SaleChannel) {
    return <ClusterOutlined />
  }
  if (key === GeneratorModalIndex.Shop) {
    return <ShopOutlined />
  }
  if (key === GeneratorModalIndex.Admin) {
    return <UserAddOutlined />
  }
  return null
}

/* <UsergroupAddOutlined /> */
const NewButton = () => {
  const dispatch = useDispatch()
  const auth = useReduxSelector(state => state.auth.admin)
  const generatorModal = useReduxSelector(({ layout }) => layout.generatorModal)
  const onToggleOpen = useCallback(
    (modalIndex: GeneratorModalIndex) => () => {
      dispatch($Actions.setToggleGeneratorModal(modalIndex))
    },
    [dispatch]
  )

  const menu = useMemo(() => {
    return (
      <Menu style={{ borderRight: isMobile ? 'none' : undefined }}>
        {GenratorModalItemList.filter(item => {
          if (auth.isLogin) {
            if (auth.manufacturerId) {
              return item.level !== 'system'
            }
            return true
          }
          return false
        }).map(item => {
          if (item.type === 'divider') {
            return <MenuDivider key="divider" />
          }
          const { key, hotkey } = item
          return (
            <MenuItem key={key} onClick={onToggleOpen(key)}>
              {getIcon(key)}
              {toStringShowHide(key, generatorModal)}
              {!isMobile && <STag style={{ float: 'right' }}>{hotkey}</STag>}
            </MenuItem>
          )
        })}
      </Menu>
    )
  }, [generatorModal, onToggleOpen, auth])

  const [visibleDrawer, setVisbileDrawer] = useState(false)

  const toggleVisibleDrawer = useCallback(() => {
    setVisbileDrawer(!visibleDrawer)
  }, [visibleDrawer])

  return isMobile ? (
    <>
      <Button
        type="default"
        style={{ marginRight: '1rem' }}
        onClick={toggleVisibleDrawer}
      >
        새로만들기 <DownOutlined />
      </Button>
      <Drawer
        closable={false}
        placement="bottom"
        visible={visibleDrawer}
        onClose={toggleVisibleDrawer}
        title="새로만들기"
      >
        {menu}
      </Drawer>
    </>
  ) : (
    <Dropdown overlay={menu} overlayStyle={{ width: '14rem' }}>
      <Button type="default" style={{ marginRight: '1rem' }}>
        새로만들기 <DownOutlined />
      </Button>
    </Dropdown>
  )
}

export default ReactMemoEx(NewButton)
