import { Config } from 'common'
import produce from 'immer'
import { $GetActionType, withReducer } from 'modules/store/util'
import { actions } from './actions'
import { ActionTypes, State } from './types'

export const initialState: State = [Config.defaultPanelIndex]

export const reducer = withReducer<State, $GetActionType<typeof actions>>(
  initialState,
  (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.PUSH_PANEL: {
        const { isSub, panel } = action.payload
        return produce(state, draft => {
          if (isSub) {
            const [, subPanel] = draft
            if (subPanel === undefined || subPanel !== panel) {
              if (draft.length >= 2) {
                draft[1] = panel
              } else {
                draft.push(panel)
              }
            }
            return
          }
          const [mainPanel] = draft
          if (mainPanel !== panel) {
            draft[0] = panel
          }
        })
      }
      case ActionTypes.REMOVE_PANEL: {
        const index = action.payload
        return produce(state, draft => {
          draft.splice(index, 1)
        })
      }
      default: {
        return state
      }
    }
  }
)
