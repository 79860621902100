import * as antColors from '@ant-design/colors'
import * as chartjs from 'chart.js'
import { ReactMemoEx } from 'modules/view'
import React, { useMemo } from 'react'
import { Doughnut } from 'react-chartjs-2'
import styled from 'styled-components'
import Color from 'color'

type ContainerProps = {
  size?: number | string
}

const getSizeUnit = (size: number | string) => {
  if (typeof size === 'number') {
    return `${size}px`
  }
  return size
}

const Container = styled.div<ContainerProps>`
  ${({ size }) =>
    size !== undefined
      ? `width: ${getSizeUnit(size)}; height: ${getSizeUnit(size)};`
      : ''}
  position: relative;
`

export type SimplePieGraphProps = {
  data: number[]
  color?: string[]
} & ContainerProps &
  Pick<React.HTMLAttributes<HTMLElement>, 'style' | 'className'>

const defaultOptions: chartjs.ChartOptions = {
  title: {
    display: false,
  },
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: { datalabels: { display: false } },
  tooltips: { enabled: false },
}

export const COLOR_PRIMARY = antColors.blue.primary || 'rgb(71,158,240)'
export const COLOR_SUB =
  Color(antColors.grey[0])
    .alpha(0.5)
    .toString() || 'rgb(240,240,240)'
export const COLOR_ERROR = antColors.red.primary || '#f5222d'
const defaultColor = [COLOR_PRIMARY, COLOR_SUB]

export const SimplePieGraph = ReactMemoEx((props: SimplePieGraphProps) => {
  const { data, color = defaultColor, size, ...rest } = props
  const datasets = useMemo(() => {
    const output: chartjs.ChartDataSets = {
      data,
      backgroundColor: color,
      borderWidth: 1,
    }
    return [output]
  }, [data, color])
  return (
    <Container {...rest} size={size}>
      <Doughnut options={defaultOptions} data={{ datasets }} />
    </Container>
  )
})
