import { Button, Form, PageHeader, Spin } from 'antd'
import { FormInstance, FormProps } from 'antd/lib/form'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { $Actions } from 'modules/store'
import { GeneratorModalIndex } from 'common'
import { ReactMemoEx } from 'modules/view'
import './ContainerFormLayout.less'

const SForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const FormBody = styled.div`
  overflow: auto;
  background-color: #fff;
  padding: 1.5rem;
  margin: 1.5rem;
  overflow: auto;

  .ant-form-item {
    display: flex;
  }

  .ant-form-item-control {
    flex: 1;
  }
`

const SPageHeader = styled(PageHeader)`
  background-color: #fff;
`
export type FormLayout = {
  modalIndex: GeneratorModalIndex
  title: string
  form: FormInstance
  formChildren?: JSX.Element | JSX.Element[]
  loading?: boolean
} & Omit<FormProps, 'form'>

const FormLayout = (props: FormLayout) => {
  const {
    className,
    title,
    formChildren,
    modalIndex,
    loading = false,
    ...rest
  } = props
  const dispatch = useDispatch()

  const onClickCancel = useCallback(() => {
    dispatch($Actions.setHideGeneratorModal(modalIndex))
  }, [dispatch, modalIndex])

  return (
    <SForm {...rest} className={classNames('generator-modal', className)}>
      <Spin spinning={loading}>
        <SPageHeader
          title={title}
          extra={
            <>
              <Button onClick={onClickCancel}>취소</Button>
              <Button type="primary" htmlType="submit">
                생성
              </Button>
            </>
          }
        />
        <div className="from-container">
          <FormBody>{formChildren}</FormBody>
        </div>
      </Spin>
    </SForm>
  )
}

export default ReactMemoEx(FormLayout)
