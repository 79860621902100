import { LogStatItem } from 'modules/store'
import { StatsUnit, StatsStandard } from 'common'

export const getSafeStateItem = (
  item: LogStatItem,
  defaultLogItem: Partial<LogStatItem>
) => {
  if (item !== undefined && item !== null) {
    return item
  }
  return {
    loading: true,
    logList: [],
    standard: StatsStandard.SaleChannel,
    unit: StatsUnit.Day,
    updateAt: -1,
    ...defaultLogItem,
  }
}

export const barPercentageHorizontal = 0.6

export const defaultChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
}
