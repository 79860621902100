import ApolloClient from 'apollo-client'
import {
  Event,
  EventType,
  MutationAddEventArgs,
  MutationRemoveEventArgs,
  MutationUpdateEventArgs,
} from 'common'
import { MUTATION_ADD_EVENT } from 'modules/graphql'
import { Overwrite } from 'utility-types'
import { AppThunkAction } from './asyncAction.type'
import { $Actions } from 'modules/store/crossSliceReducer'
import gql from 'graphql-tag'
import { FRAG_EVENT } from 'modules/graphql/src/queryFindOneMap'

type Result = {
  event: Event
}

type AddPlayListMitationArg = Overwrite<
  MutationAddEventArgs,
  {
    start: number
  }
>

const addPlayListEvent = (
  gqlClient: ApolloClient<object>,
  evnetArgs: AddPlayListMitationArg
): AppThunkAction => async dispatch => {
  console.log('evnetArgs', evnetArgs)
  const result = await gqlClient.mutate<Result, MutationAddEventArgs>({
    mutation: MUTATION_ADD_EVENT,
    variables: evnetArgs,
  })
  if (result.data && result.data.event) {
    dispatch($Actions.addEvent(result.data.event))
  }
}

const MUTATION_REMOVE_EVENT = gql`
  mutation RemoveEvent($id: String!) {
    isRemove: removeEvent(id: $id)
  }
`

/**
 * Event 삭제
 * @param gqlClient
 * @param id
 */
const removePlayListEvent = (
  gqlClient: ApolloClient<object>,
  id: string
): AppThunkAction => async dispatch => {
  try {
    const result = await gqlClient.mutate<
      { isRemove: boolean },
      MutationRemoveEventArgs
    >({
      mutation: MUTATION_REMOVE_EVENT,
      variables: { id },
    })
    if (result.data && result.data.isRemove) {
      dispatch($Actions.removeEvent(id))
    }
  } catch (e) {}
}

const MUTATION_UPDATE_EVENT = gql`
  mutation UpdateEvent(
    $id: String!
    $type: EventType!
    $mediaList: [String!]!
  ) {
    event: updateEvent(id: $id, type: $type, mediaList: $mediaList) {
      ${FRAG_EVENT}
    }
  }
`

const updatePlayListEvent = (
  gqlClient: ApolloClient<object>,
  nextEvent: Event
): AppThunkAction => async dispatch => {
  try {
    dispatch($Actions.updateEvent(nextEvent))

    const variables: MutationUpdateEventArgs = {
      id: nextEvent.id,
      mediaList: nextEvent.mediaIds || [],
      start: nextEvent.startTime,
      playListId: nextEvent.playListId,
      type: nextEvent.type as EventType,
    }
    const result = await gqlClient.mutate<Result, MutationUpdateEventArgs>({
      mutation: MUTATION_UPDATE_EVENT,
      variables,
    })
    if (result.data && result.data.event) {
      // console.log("result.data.event", result.data.event)
      dispatch($Actions.updateEvent(result.data.event))
    }
  } catch (e) {}
}

export const asnycActions = {
  addPlayListEvent,
  removePlayListEvent,
  updatePlayListEvent,
}
