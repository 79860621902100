import React from 'react'
import Card from './Card'
import CardUpload from './CardUpload'
import { CardType } from './types'
type ListProps = {
  items: CardType[]
}
const List = (props: ListProps) => {
  const { items } = props
  return (
    <div className="albums">
      {items.map((item, i) => {
        if (item.type === 'upload') {
          return <CardUpload media={item} key={i} />
        }
        return <Card media={item} key={item.id} selectableKey={item.id} />
      })}
    </div>
  )
}

export default List
