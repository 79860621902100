import { SearchOutlined } from '@ant-design/icons'
import { Badge, Typography } from 'antd'
import { ColumnsType, ColumnType } from 'antd/lib/table'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import {
  CountryCode,
  generateSort,
  Group,
  Language,
  Manufacturer,
  SaleChannel,
  SubscriptionEntity,
  toStringPercent,
  ui,
} from 'common'
import dateFormat from 'dateformat'
import { groupBy } from 'lodash'
import { QueryHelper } from 'modules/graphql'
import React, { CSSProperties } from 'react'
import { COLUMN_KEY_EDIT } from '../../../Config'
import { genrateDeleteColumn } from '../../../GenerateColumn'
import { generatorPickEditColumns, PickColumnList } from '../../../PickColumn'
import ConnectionRender from './ConnectionRender'
import EditButton from './EditButton'
import EditCellMacAddress from './EditCellMacAddress'
import EditCellShop from './EditCellShop'
import SearchFilterDropDown, { onFilterBySearch } from './SearchFilterDropDown'
import SearchText from './SearchText'
import { MediaCenterAlpha, MediaCenterTablePickColumnOpt } from './types'
import { isMobile } from 'react-device-detect'

const { ColSize } = ui
type GenereatorSearchProps = Pick<
  ColumnType<MediaCenterAlpha>,
  'filterDropdown' | 'filterIcon' | 'onFilter' | 'onFilterDropdownVisibleChange'
>

const { Paragraph } = Typography
const genereatorSearch = (
  dataIndex: keyof MediaCenterAlpha
): GenereatorSearchProps => ({
  filterIcon: filtered => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilter: onFilterBySearch(dataIndex),
  filterDropdown: (filterDropdown: FilterDropdownProps) => (
    <SearchFilterDropDown {...filterDropdown} dataIndex={dataIndex} />
  ),
})

export type CreateColumnOptions = {
  groupList: Group[]
  manufacturerList: Manufacturer[]
  saleChannelList: SaleChannel
}

const defaultPickColumn: PickColumnList<MediaCenterAlpha> = [
  'mac',
  'manufacturerTitle',
  'tvInfoGroup',
  'saleChannelTitle',
  'shopTitle',
  'countryTitle',
  'totalTVActivePercent',
  'totalMediaUpdate',
  'updateAt',
  'isOnline',
]

const PStyle: CSSProperties = { margin: 0 }
const PSubStyle: CSSProperties = { margin: 0, fontSize: '0.5rem' }
/**
 * Create Columns
 * @param options
 */
export const createColumns = (
  groupList: Group[],
  manufacturerList: Manufacturer[],
  countyList: CountryCode[],
  saleChannelList: SaleChannel[],
  pickCoulmnOpt: MediaCenterTablePickColumnOpt
): [ColumnsType<MediaCenterAlpha>, number] => {
  // console.log('pickCoulmnOpt', pickCoulmnOpt)
  const {
    disabledDelete,
    disabledEdit,
    // pickColumn
    pickColumn = defaultPickColumn,
  } = pickCoulmnOpt
  const columns = generatorPickEditColumns({
    disabledDelete,
    disabledEdit,
    pickColumn,
  })
  // group 을 grouping 한다.
  const groups = groupBy(groupList, 'manufacturerId')
  // 그룹 정보는 실제 그룹이 보여지는 경우에만 동작한다.
  const filterManufacturerList = manufacturerList.filter(({ id }) => {
    const gList = groups[id]
    if (gList === undefined) {
      return false
    }
    return gList.length >= 1
  })
  // 제조사 필터했을 경우 하나인가
  const isOneGroupManufacturer = filterManufacturerList.length <= 1
  const isInputManagerOne = manufacturerList.length <= 1

  // mac
  columns.push('mac', {
    title: Language.MacAddress,
    dataIndex: 'mac',
    width: ColSize.xxl,
    ellipsis: true,
    align: 'left',
    fixed: isMobile ? undefined : 'left',
    ...genereatorSearch('mac'),
    sorter: generateSort<MediaCenterAlpha>('mac'),
    render(value, record) {
      return (
        <EditCellMacAddress originData={record} dataIndex="mac">
          <SearchText children={value} searchKey="mac" />
        </EditCellMacAddress>
      )
    },
  })

  pickColumn.forEach(pickColumnKey => {
    if (pickColumnKey === 'manufacturerTitle' && !isInputManagerOne) {
      // 제조사명 그리고 제조사가 하나가 아닐 경우에만 표기한다.
      columns.pushForced({
        align: 'center',
        dataIndex: 'manufacturerTitle',
        ellipsis: true,
        sorter: generateSort<MediaCenterAlpha>('manufacturerTitle'),
        title: Language.Manufacturer,
        ...(!isInputManagerOne
          ? {
              filters: manufacturerList.map(({ title }) => ({
                text: title,
                value: title,
              })),
              onFilter: (value, record) =>
                record.manufacturerTitle.indexOf(value.toString()) === 0,
            }
          : {}),
        width: ColSize.md,
      })
    } else if (pickColumnKey === 'saleChannelTitle') {
      // 판매 채널명
      columns.push('saleChannelTitle', {
        align: 'center',
        dataIndex: 'saleChannelTitle',
        ellipsis: true,
        sorter: generateSort<MediaCenterAlpha>('saleChannelTitle'),
        title: Language.SaleChannel,
        width: ColSize.md,
        ...(manufacturerList.length === 1
          ? {
              filters: saleChannelList
                .filter(
                  ({ manufacturerId }) =>
                    manufacturerId === manufacturerList[0].id
                )
                .map(({ title }) => ({
                  text: title,
                  value: title,
                })),
            }
          : {
              filters: saleChannelList.map(({ title }) => ({
                text: title,
                value: title,
              })),
            }),
        onFilter: (value, record) =>
          record.saleChannelTitle.indexOf(value.toString()) === 0,
      })
    } else if (pickColumnKey === 'countryTitle') {
      // 지역 선택
      columns.push('countryTitle', {
        align: 'center',
        dataIndex: 'countryTitle',
        ellipsis: true,
        sorter: generateSort<MediaCenterAlpha>('countryTitle'),
        title: Language.Country,
        ...(manufacturerList.length === 1
          ? {
              filters: countyList
                .filter(
                  ({ manufacturerId }) =>
                    manufacturerId === manufacturerList[0].id
                )
                .map(({ title }) => ({
                  text: title,
                  value: title,
                })),
            }
          : {
              filters: countyList.map(({ title }) => ({
                text: title,
                value: title,
              })),
            }),
        onFilter: (value, record) =>
          record.countryTitle.indexOf(value.toString()) === 0,
        width: ColSize.md,
      })
    } else if (pickColumnKey === 'shopTitle') {
      // 매장명
      columns.push('shopTitle', {
        align: 'center',
        dataIndex: 'shopId',
        ellipsis: true,
        sorter: generateSort<MediaCenterAlpha>('shopTitle'),
        title: Language.Shop,
        width: ColSize.xl,
        ...genereatorSearch('shopTitle'),
        render(_, record) {
          return <EditCellShop originData={record} dataIndex="shopId" />
        },
      })
    } else if (pickColumnKey === 'tvInfoGroup') {
      // 그룹 정보
      filterManufacturerList.forEach(({ id: mId, title: mTitle }) => {
        const inGroupList = groups[mId] || []
        if (inGroupList.length <= 0) {
          return
        }
        columns.pushForced({
          title: isOneGroupManufacturer
            ? Language.Group
            : `${Language.Group}(${mTitle})`,
          ellipsis: true,
          width: inGroupList.length * ColSize.sm,
          children: inGroupList.map(group => {
            const { id: gId } = group
            return {
              title: group.title,
              ellipsis: true,
              key: `tvInfoToNum.${gId}`,
              width: ColSize.sm,
              align: 'center',
              sorter(a, b) {
                const { tvInfoGroup: aTvInfoToNum } = a
                const { tvInfoGroup: bTvInfoToNum } = b
                const aValue = aTvInfoToNum[gId] ? aTvInfoToNum[gId].active : 0
                const bValue = bTvInfoToNum[gId] ? bTvInfoToNum[gId].active : 0
                return aValue - bValue
              },
              render(_, data) {
                const { tvInfoGroup } = data
                const tvInfo = tvInfoGroup[gId]

                if (tvInfo === undefined) {
                  return 0
                }

                return `${tvInfo.active}/${tvInfo.total}`
              },
            }
          }),
        })
      })
    } else if (pickColumnKey === 'totalTVActivePercent') {
      columns.push('totalTVActivePercent', {
        align: 'center',
        ellipsis: true,
        key: 'totalTVActivePercent',
        sorter: generateSort<MediaCenterAlpha>('totalTVActivePercent'),
        title: Language.ConnectRate,
        width: ColSize.sm,
        render(_, record) {
          return <ConnectionRender data={record} />
        },
      })
    } else if (pickColumnKey === 'totalMediaUpdate') {
      columns.push('totalMediaUpdate', {
        align: 'center',
        ellipsis: true,
        dataIndex: 'updateRate',
        sorter: generateSort<MediaCenterAlpha>('updateRate'),
        title: Language.Update,
        width: ColSize.sm,
        render(value: number = 0, recod: MediaCenterAlpha) {
          return (
            <>
              <Paragraph style={PStyle}>{toStringPercent(value)}</Paragraph>
              {recod.mediaTotal >= 1 && (
                <Paragraph
                  style={PSubStyle}
                >{`(${recod.mediaDone}/${recod.mediaTotal})`}</Paragraph>
              )}
            </>
          )
        },
      })
    } else if (pickColumnKey === 'updateAt') {
      columns.push('updateAt', {
        align: 'center',
        ellipsis: true,
        dataIndex: 'updateAt',
        sorter: generateSort<MediaCenterAlpha>('updateAt'),
        title: Language.LastUpdate,
        width: ColSize.md,
        render(value: number = 0) {
          if (value <= 0) {
            return ''
          }
          return dateFormat(value, 'yyyy-mm-dd')
        },
      })
    } else if (pickColumnKey === 'isOnline') {
      columns.push('isOnline', {
        align: 'center',
        ellipsis: true,
        dataIndex: 'isOnline',
        sorter: generateSort<MediaCenterAlpha>('isOnline'),
        title: Language.Status,
        width: ColSize.sm,
        render(value) {
          if (typeof value === 'boolean') {
            if (value) {
              return <Badge status="processing" text="Online" />
            }
            return <Badge status="error" text="Offline" />
          }
          return ''
        },
      })
    }
  })

  // columns.pushDelete()

  columns.pushEdit({
    title: Language.BTN_EDIT,
    ellipsis: true,
    key: COLUMN_KEY_EDIT,
    align: 'center',
    width: ColSize.lg,
    fixed: isMobile ? undefined : 'right',
    render(_, record) {
      return <EditButton record={record} />
    },
  })
  columns.pushDelete({
    ...genrateDeleteColumn(
      QueryHelper.removeOneById(SubscriptionEntity.MediaCenter)
    ),
    fixed: isMobile ? undefined : 'right',
  })

  const outputColumns = columns.toColumns()
  const width = outputColumns.reduce((output, col) => {
    if (col.width) {
      if (typeof col.width === 'string') {
        output += parseInt(col.width, 10)
      } else {
        output += col.width
      }
    }
    return output
  }, 0)
  return [outputColumns, width]
}
