import { Avatar, Dropdown, Menu } from 'antd'
import { useReduxSelector } from 'modules/store'
import { clearAuthToken } from 'modules/useLibs'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo } from 'react'
import stc from 'string-to-color'
import styeld from 'styled-components'
import StyleAttr from '../StyleAttr'
const SMenu = styeld(Menu)`
  min-width: 8rem;
`
const SAvatar = styeld(Avatar)`
  cursor: pointer;
  ${StyleAttr.blockDrag}
`

// type IconBulbProps = {
//   isDark?: boolean
// }
// const IconBulb = ReactMemoEx((props: IconBulbProps) => {
//   // , BugFilled
//   const { isDark = false } = props
//   if (isDark) {
//     return (
//       <>
//         밝은 테마 변경
//         <BulbOutlined />
//       </>
//     )
//   }
//   return (
//     <>
//       어두운 테마 변경
//       <BulbFilled />
//     </>
//   )
// })

const UserAvartar = () => {
  const admin = useReduxSelector(({ auth }) => auth.admin)

  // const dispatch = useDispatch()

  const onClickLogout = useCallback(() => {
    // dispatch($Actions.setlog)
    clearAuthToken()
    requestAnimationFrame(() => {
      window.location.reload()
    })
  }, [])

  const menu = useMemo(
    () => (
      <SMenu>
        {/* <Menu.Item onClick={onChangeTheme(!isDark)}>
          <IconBulb isDark={isDark} />
        </Menu.Item> */}
        <Menu.Item onClick={onClickLogout}>로그아웃</Menu.Item>
      </SMenu>
    ),
    [onClickLogout]
  )

  const mAdmin = useMemo(() => {
    if (admin && admin.isLogin) {
      const displayName = admin.displayName.length >= 1 && admin.displayName[0]
      return {
        backgroundColor: stc(displayName),
        displayName,
      }
    }
    return {
      displayName: '',
      backgroundColor: undefined,
    }
  }, [admin])

  return admin.isLogin ? (
    <Dropdown overlay={menu} trigger={['click']}>
      <SAvatar
        size="default"
        children={mAdmin.displayName}
        style={{ backgroundColor: mAdmin.backgroundColor }}
      />
    </Dropdown>
  ) : null
}

export default ReactMemoEx(UserAvartar)
