import { useMutation } from '@apollo/react-hooks'
import { Dropdown, Menu, Spin } from 'antd'
import {
  FkGroupAndSalechannel,
  Group,
  Manufacturer,
  MutationAddFkgsArgs,
  SaleChannel,
} from 'common'
import gql from 'graphql-tag'
import { useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { FC, useCallback, useMemo } from 'react'

type ContextTitleGroupProps = {
  group: Group
  manufacturerId: Manufacturer['id']
  children?: React.ReactNode
}

const MUTATION_ADD = gql`
  mutation AddFKGS($groupId: String!, $saleChannelId: String!) {
    fkgs: addFKGS(groupId: $groupId, saleChannelId: $saleChannelId) {
      id
      groupId
      saleChannelId
    }
  }
`

const ContextMenu = (props: ContextTitleGroupProps) => {
  const {
    manufacturerId,
    group: { id: groupId },
  } = props

  const [addFKGS, { loading }] = useMutation<
    { fkgs: FkGroupAndSalechannel },
    MutationAddFkgsArgs
  >(MUTATION_ADD)
  const dataList = useReduxSelector(state => {
    const { saleChannelList$, fkGSList } = state
    // 포함된 판매채널만 가져온다.
    const saleChannels = saleChannelList$.filter(
      ({ manufacturerId: mId }) => manufacturerId === mId
    )

    return saleChannels.filter(({ id }) => {
      const index = fkGSList.findIndex(item => {
        return item.saleChannelId === id && item.groupId === groupId
      })
      return index <= -1
    })
  })

  const title = useMemo(() => {
    if (dataList.length >= 1) {
      return '예외 채널 추가'
    }
    return '예외 추가 불가'
  }, [dataList.length])

  const onClick = useCallback(
    (saleChannelId: SaleChannel['id']) => () => {
      addFKGS({
        variables: {
          groupId,
          saleChannelId,
        },
      })
    },
    [groupId, addFKGS]
  )

  return (
    <Spin spinning={loading}>
      <Menu>
        <Menu.Item disabled>{title}</Menu.Item>
        {dataList.map(({ title, id }) => (
          <Menu.Item key={id} onClick={onClick(id)}>
            {title}
          </Menu.Item>
        ))}
      </Menu>
    </Spin>
  )
}

const GroupTitle: FC<ContextTitleGroupProps & {
  children?: React.ReactChild
}> = props => {
  const { children } = props
  return (
    <>
      <Dropdown
        overlay={<ContextMenu {...props} />}
        trigger={['contextMenu']}
        overlayClassName="dropdown-comp"
      >
        <div>{children}</div>
      </Dropdown>
    </>
  )
}

export default ReactMemoEx(GroupTitle)
