import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Alert, Button, Col, Form, Input, Row, Spin, Typography } from 'antd'
import { FormProps } from 'antd/lib/form'
import assets from 'assets'
import { useDidMount, useWillUnmount } from 'beautiful-react-hooks'
import { $Actions } from 'modules/store'
import { useSignIn, getAuthToken } from 'modules/useLibs'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
const { Text } = Typography

const FormContainer = styled.div`
  max-width: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 10rem;
`

const Logo = styled.img`
  width: 100%;
  height: auto;
`

const SForm = styled(Form)`
  margin-top: 1rem;
`

type Login = {
  email: string
  password: string
}

const LoginForm = () => {
  const [requestLogin, { loading, signResult, errorMessage }] = useSignIn()
  const dispatch = useDispatch()
  const refTimeout = useRef<number>()
  const [form] = Form.useForm()

  const onFinish: Required<FormProps>['onFinish'] = useCallback(
    submitData => {
      const formData = submitData as Login
      requestLogin({ id: formData.email, pw: formData.password })
    },
    [requestLogin]
  )

  useEffect(() => {
    if (signResult) {
      dispatch(
        $Actions.login({
          displayName: signResult.userName,
          isLogin: true,
          token: signResult.token,
          manufacturerId: signResult.manufacturerId,
        })
      )
    }
  }, [dispatch, signResult])

  useDidMount(() => {
    const token = getAuthToken()
    if (token) {
      requestLogin(token)
    }
    // form.setFieldsValue({
    //   email: 'admin@redlab.co.kr',
    //   password: 'red14557',
    // })
  })

  useWillUnmount(() => {
    clearTimeout(refTimeout.current)
  })

  return (
    <Spin spinning={loading}>
      <FormContainer>
        <Logo src={assets.images.logoHeaderBlack} />
        <Text disabled>Content Management Service</Text>

        <SForm form={form} onFinish={onFinish}>
          <Row gutter={[4, 4]}>
            {errorMessage !== undefined && (
              <Col span={24}>
                <Alert message={errorMessage} type="error" showIcon />
              </Col>
            )}

            <Col span={24}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Email이 입력되지 않았습니다.',
                  },
                ]}
              >
                <Input
                  autoFocus
                  placeholder="Email (admin@digitalzone.co.kr)"
                  autoComplete="email"
                  prefix={<UserOutlined />}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="password"
                rules={[
                  { min: 4, message: '최소 4글자 이상을 입력해주세요.' },
                  { required: true, message: '비밀번호를 입력해주세요.' },
                ]}
              >
                <Input
                  placeholder="password"
                  prefix={<LockOutlined />}
                  autoComplete="current-password"
                  type="password"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item shouldUpdate={true}>
                {() => (
                  <Button
                    type="primary"
                    block
                    htmlType="submit"
                    disabled={
                      !form.isFieldsTouched(true) ||
                      form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length >= 1
                    }
                  >
                    로그인
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </SForm>
      </FormContainer>
    </Spin>
  )
}

export default ReactMemoEx(LoginForm)
