import { Event } from 'common'
import { reducerDatabase, actionsDatabase } from '../util'

type Base = Event
export type State = Base[]

export enum ActionTypes {
  ADD = '@@event/ADD',
  ADDS = '@@event/ADDS',
  REMOVE = '@@event/REMOVE',
  REMOVES = '@@event/REMOVES',
  SET_LIST = '@@event/SET_LIST',
  UPDATE = '@@event/UPDATE',
  UPDATES = '@@event/UPDATES',
}

const actionDB = actionsDatabase<Base>(ActionTypes)

export const actions = {
  /**
   * Event 추가
   */
  addEvent: actionDB.add,
  addEvents: actionDB.adds,
  /**
   * Event 삭제
   */
  removeEvent: actionDB.remove,
  removeEvents: actionDB.removes,
  /**
   * Event List 셋팅
   */
  setEventList: actionDB.setList,
  /**
   * Event 업데이트
   */
  updateEvent: actionDB.update,
  updateEvents: actionDB.updates,
}

export const initialState: State = []

export const reducer = reducerDatabase(initialState, ActionTypes)
