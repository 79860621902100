import { FkGroupAndSalechannel } from 'common'
import { reducerDatabase, actionsDatabase } from '../util'

type Base = FkGroupAndSalechannel
export type State = Base[]

export enum ActionTypes {
  ADD = '@@fkGSList/ADD',
  ADDS = '@@fkGSList/ADDS',
  REMOVE = '@@fkGSList/REMOVE',
  REMOVES = '@@fkGSList/REMOVES',
  SET_LIST = '@@fkGSList/SET_LIST',
  UPDATE = '@@fkGSList/UPDATE',
  UPDATES = '@@fkGSList/UPDATES',
}

const actionDB = actionsDatabase<Base>(ActionTypes)

export const actions = {
  /**
   * FKGS 추가
   */
  addFKGS: actionDB.add,
  addFKGSs: actionDB.adds,
  /**
   * FKGS 삭제
   */
  removeFKGS: actionDB.remove,
  removeFKGSs: actionDB.removes,
  /**
   * FKGS List 셋팅
   */
  setFKGSList: actionDB.setList,
  /**
   * FKGS 업데이트
   */
  updateFKGS: actionDB.update,
  updateFKGSs: actionDB.updates,
}

export const initialState: State = []

export const reducer = reducerDatabase(initialState, ActionTypes)
