import logoDGTZColor from './img/dgtz_logo.png'
import logoDTZFooter from './img/logo_dzone_footer.png'
import logoHeaderWhite from './img/logo-ci-wh.png'
import logoHeaderBlack from './img/logo-ci.png'
import logoSamsungWhite from './img/samsung-logo-light.png'
import logoSamsungBlack from './img/samsung-logo.png'
import svgLogo from './svg/logo.svg'

const images = {
  logoDGTZColor,
  logoDTZFooter,
  logoHeaderWhite,
  logoHeaderBlack,
  logoSamsungWhite,
  logoSamsungBlack,
}

const svg = {
  logo: svgLogo,
}

export default { images, svg }
