import { Button, Divider } from 'antd'
import { ui } from 'common'
import React, { useCallback, useMemo } from 'react'
import styeld from 'styled-components'
import { EditCellType } from './types'
import { COLUMN_KEY_EDIT } from '../../Config'

type EditEvent<T> = (record: T) => void

const SDivider = styeld(Divider)`
  margin:0;
`
export const WIDTH_EDITBUTTN = 65

export type EditButtonProps<T> = {
  editable?: boolean
  disableEditButton?: boolean
  onEdit?: EditEvent<T>
  onEditSave?: EditEvent<T>
  onEidtCancel?: EditEvent<T>
  record: T
}

function EditButton<T>(props: EditButtonProps<T>) {
  const {
    editable,
    disableEditButton,
    onEdit: onPropsEdit,
    onEditSave: onPropsEditSave,
    onEidtCancel: onPropsEidtCancel,
    record,
  } = props

  const onEdit = useCallback(
    (type: 'edit' | 'save' | 'canel') => () => {
      let call =
        type === 'edit'
          ? onPropsEdit
          : type === 'save'
          ? onPropsEditSave
          : onPropsEidtCancel

      if (call) {
        call(record)
      }
    },
    [onPropsEdit, onPropsEditSave, onPropsEidtCancel, record]
  )
  const render = useMemo(() => {
    if (editable) {
      return (
        <>
          <Button type="link" onClick={onEdit('save')}>
            저장
          </Button>
          <SDivider type="vertical" />
          <Button type="link" danger onClick={onEdit('canel')}>
            취소
          </Button>
        </>
      )
    }
    return (
      <Button type="link" disabled={disableEditButton} onClick={onEdit('edit')}>
        수정
      </Button>
    )
  }, [disableEditButton, editable, onEdit])

  return render
}

export default EditButton

export function getColumnEditDefault<T>() {
  return ui.createCol<T>('수정', ui.ColSize.lg, {
    key: COLUMN_KEY_EDIT,
  }) as EditCellType<T>
}
