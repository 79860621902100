import { Col, Form, Input, Row, Select } from 'antd'
import {
  GeneratorModalIndex,
  isErrorByNetwork,
  MutationAddMediaCenterArgs,
} from 'common'
import { useMutationAddMediaCenter } from 'modules/graphql'
import { useReduxSelector } from 'modules/store'
import React, { useCallback, useMemo } from 'react'
import { defaultColProps } from './Config'
import ContainerFormLayout from './ContainerFormLayout'
import { notiError, notiErrorNetwork, notiSuccess } from './util'

const ERROR_TITLE = '미디어센터 생성 실패'

const FormMediaCenter = () => {
  const admin = useReduxSelector(({ auth }) => auth.admin)
  const [addMediaCenter, { loading }] = useMutationAddMediaCenter()
  const reduxShopList = useReduxSelector(({ shopList$ }) => shopList$)
  const mediaCenterList = useReduxSelector(
    ({ mediaCenterList }) => mediaCenterList
  )

  const shopList = useMemo(() => {
    if (admin.isLogin && admin.manufacturerId) {
      const authManufacturerId = admin.manufacturerId
      return reduxShopList.filter(({ manufacturerId: mId }) => {
        return mId === authManufacturerId
      })
    }
    return reduxShopList
  }, [admin, reduxShopList])

  const [form] = Form.useForm()
  const { Option } = Select

  const filter = useCallback((inputValue: string, option?: any) => {
    const mOption = option as { value: string; children: string }
    return mOption.children.toLowerCase().indexOf(inputValue) >= 0
  }, [])

  const fields = useMemo(
    () => (
      <Row gutter={[16, 16]}>
        <Col {...defaultColProps}>
          <Form.Item
            name="mac"
            label="맥주소"
            rules={[
              {
                required: true,
                message: 'mac 주소를 입력하세요.',
              },
            ]}
          >
            <Input placeholder="맥주소 선택" />
          </Form.Item>
        </Col>
        <Col {...defaultColProps}>
          <Form.Item
            name="shopId"
            label="지점"
            rules={[
              {
                message: '지점을 선택해주세요.',
                required: true,
              },
            ]}
          >
            <Select
              showSearch
              filterOption={filter}
              placeholder="지점을 선택해주세요."
            >
              {shopList.map(shop => (
                <Option key={shop.id} value={shop.id}>
                  {shop.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    ),
    [filter, shopList]
  )

  const onFinish = useCallback(async () => {
    const variables = form.getFieldsValue() as MutationAddMediaCenterArgs
    if (variables.mac === undefined || variables.shopId === undefined) {
      return
    }

    const { mac } = variables
    if (mediaCenterList.findIndex(({ mac: mMac }) => mMac === mac) >= 0) {
      form.setFields([{ name: 'mac', errors: ['동일한 MAC이 있습니다.'] }])
      return
    }

    try {
      await addMediaCenter({ variables })
      notiSuccess(`미디어센터 생성(${variables.mac})`)
      form.resetFields()
    } catch (e) {
      if (isErrorByNetwork(e)) {
        notiErrorNetwork(ERROR_TITLE)
      } else {
        notiError(ERROR_TITLE, String(e))
      }
    }
  }, [form, addMediaCenter, mediaCenterList])

  return (
    <ContainerFormLayout
      loading={loading}
      modalIndex={GeneratorModalIndex.Shop}
      title="미디어센터 생성"
      form={form}
      formChildren={fields}
      onFinish={onFinish}
    />
  )
}

export default FormMediaCenter
