import { useReduxSelector } from 'modules/store'
import React from 'react'
import styled from 'styled-components'
import Loading, { LoadingProps } from './Loading'
import LoginForm from './LoginForm'
import { ReactMemoEx } from 'modules/view'
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: auto;
`

export type SplashProps = LoadingProps

const Splash = (props: SplashProps) => {
  const { onCreateApolloClient } = props
  const isLogin = useReduxSelector(state => state.auth.admin.isLogin)
  return (
    <Container>
      {!isLogin ? (
        <LoginForm />
      ) : (
        <Loading onCreateApolloClient={onCreateApolloClient} />
      )}
    </Container>
  )
}

export default ReactMemoEx(Splash)
