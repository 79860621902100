import { LogList, StatsStandard, StatsUnit } from 'common'
import { useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useMemo } from 'react'
import { HorizontalBar } from 'react-chartjs-2'
import { parseDatasetSaleChannelHorizontal } from './datasets'

type ChartTotalSCConnectRTHorizontalProps = {
  standard: StatsStandard
  unit: StatsUnit
  logList?: LogList
}

const ChartTotalSCConnectRTHorizontal = (
  props: ChartTotalSCConnectRTHorizontalProps
) => {
  const { logList = { list: [], date: '' } } = props
  const saleChannelList = useReduxSelector(
    ({ saleChannelList }) => saleChannelList
  )

  const mProps = useMemo(
    () => parseDatasetSaleChannelHorizontal(saleChannelList, logList),
    [saleChannelList, logList]
  )

  return <HorizontalBar {...mProps} />
}

export default ReactMemoEx(ChartTotalSCConnectRTHorizontal)
