import { CloseOutlined } from '@ant-design/icons'
import { Button, PageHeader as AntDPageHeader, Tooltip } from 'antd'
import { PageHeaderProps as AntDPageHeaderProps } from 'antd/lib/page-header'
import classNames from 'classnames'
import { ReactMemoEx } from 'modules/view'
import React from 'react'
import './PageHeader.less'

export type PageHeaderProps = AntDPageHeaderProps & {}

export const PageHeader = ReactMemoEx((props: PageHeaderProps) => {
  const { backIcon = <CloseOutlined />, onBack, className, ...rest } = props
  return (
    <AntDPageHeader
      {...rest}
      extra={
        onBack && (
          <Tooltip title={`${rest.title} 닫기`}>
            <Button
              type="link"
              size="small"
              ghost
              onClick={onBack}
              className="close-btn"
            >
              {backIcon}
            </Button>
          </Tooltip>
        )
      }
      className={classNames('app-page-header', className)}
    />
  )
})
