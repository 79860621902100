export enum PanelIndex {
  Dashboard = 'Dashboard',
  Monitoring = 'Monitoring',
  PlayListUser = 'PlayListUser',
  UpdateContents = 'UpdateContents',
  // UpdatePlaylist = 'UpdatePlaylist',
  UserMediacenter = 'UserMediacenter',
  // MainSetting = 'MainSetting',
  MediaCenter = 'MediaCenter',
  Contents = 'Contents',
  Group = 'Group',
  Playlist = 'Playlist',
  User = 'User',
  Shop = 'Shop',
  SaleChannel = 'SaleChannel',
  CountryCode = 'CountryCode',
}

export const WIDTH_COLLAPSED = 90
export type NavigationMenuItem = {
  type: 'item'
  title: string
  panelIndex: PanelIndex
  icon?: IconNames
}

export type NavigationSubMenuItem = Pick<
  NavigationMenuItem,
  'title' | 'icon'
> & {
  type: 'sub'
  key: string
  children: Array<NavigationMenuItem>
}

export type NaivigationItem = NavigationMenuItem | NavigationSubMenuItem

export type IconNames =
  | 'appstore'
  | 'bar-chart'
  | 'bulb'
  | 'control'
  | 'dashboard'
  | 'deployment-unit'
  | 'exclamation-circle'
  | 'hdd'
  | 'schedule'
  | 'shop' // ShopOutlined
  | 'play' //<PlaySquareOutlined /> - 콘텐츠
  | 'group' //UngroupOutlined - 그룹
  | 'cluster' //ClusterOutlined - saleCHannel
  | 'global' // GlobalOutlined - manufecturer
  | 'user' //<IdcardOutlined />
  | 'tree' //<PartitionOutlined />
  | 'compass' //CompassOutlined countryCode

const NaviTitle = new Map<PanelIndex, string>()
NaviTitle.set(PanelIndex.Dashboard, '대시보드')
NaviTitle.set(PanelIndex.Monitoring, '접속현황')
NaviTitle.set(PanelIndex.PlayListUser, '편성표')
NaviTitle.set(PanelIndex.UpdateContents, '업데이트 현황')
// NaviTitle.set(PanelIndex.UpdatePlaylist, '편성 및 업데이트 현황')
NaviTitle.set(PanelIndex.UserMediacenter, 'Media Center 관리')
// NaviTitle.set(PanelIndex.MainSetting, '메인 / 설정')
NaviTitle.set(PanelIndex.MediaCenter, '미디어 센터 관리')
NaviTitle.set(PanelIndex.Contents, '콘텐츠 관리')
NaviTitle.set(PanelIndex.Group, '그룹 관리')
NaviTitle.set(PanelIndex.SaleChannel, '판매채널')
NaviTitle.set(PanelIndex.Playlist, '편성표 관리')
NaviTitle.set(PanelIndex.User, '사용자 관리')
NaviTitle.set(PanelIndex.Shop, '지점 관리')
NaviTitle.set(PanelIndex.CountryCode, '지역코드 관리')

export const getNaviTitle = (panelIndex: PanelIndex) => {
  const value = NaviTitle.get(panelIndex)
  if (value === undefined) {
    return ''
  }
  return value
}

const createItem = (
  panelIndex: PanelIndex,
  icon?: IconNames
): NavigationMenuItem => ({
  type: 'item',
  title: getNaviTitle(panelIndex),
  panelIndex,
  icon,
})

export const NAVI_DEFAULT: Array<NaivigationItem> = [
  createItem(PanelIndex.Dashboard, 'dashboard'),
  createItem(PanelIndex.Monitoring, 'deployment-unit'),
  {
    type: 'sub',
    title: '업데이트',
    icon: 'exclamation-circle',
    key: 'updateGroup',
    children: [
      // 편성표
      createItem(PanelIndex.PlayListUser, 'appstore'),
      // 콘텐츠 통계
      createItem(PanelIndex.UpdateContents, 'bar-chart'),
      // 편성 및 업데이트 현황
      // createItem(PanelIndex.UpdatePlaylist, 'schedule'),
    ],
  },
  // Media Center 관리
  createItem(PanelIndex.UserMediacenter, 'hdd'),
]

/**
 * 시스템 사용자만 사용가능한 메뉴
 */
export const NAVI_SYSTEM: Array<NaivigationItem> = [
  // 메인 / 설정
  // createItem(PanelIndex.MainSetting, 'bulb'),
  {
    type: 'sub',
    title: '관리',
    key: 'managment',
    icon: 'control',
    children: [
      // 미디어 센터 관리
      createItem(PanelIndex.MediaCenter, 'hdd'),
      // 콘텐츠 관리
      createItem(PanelIndex.Contents, 'play'),
      // 판매채널 관리
      createItem(PanelIndex.SaleChannel, 'cluster'),
      // 그룹 관리
      createItem(PanelIndex.Group, 'group'),
      // 지역코드 관리
      createItem(PanelIndex.CountryCode, 'compass'),
      // 지점괁리
      createItem(PanelIndex.Shop, 'shop'),
      // 편성표 관리
      createItem(PanelIndex.Playlist, 'tree'),
      // 사용자 관리
      createItem(PanelIndex.User, 'user'),
    ],
  },
  ...NAVI_DEFAULT,
]
