import { Col, Row, Card } from 'antd'
import { TableMediaCenter } from 'components'
import { ReactMemoEx } from 'modules/view'
import React from 'react'

const PanelByMediaCenter = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card>
          <TableMediaCenter disabledDelete disabledEdit />
        </Card>
      </Col>
    </Row>
  )
}

export default ReactMemoEx(PanelByMediaCenter)
