import { SubscriptionEntity, ui } from 'common'
import { QueryHelper } from 'modules/graphql'
import { Admin$ as DB } from 'modules/store'
import React from 'react'
import { EditCellType } from '../../../EditTable'
import {
  generateSearchColumn,
  genrateDeleteColumn,
} from '../../../GenerateColumn'
import { SearchState } from '../../../util'
import EditPwdButton from './EditPwdButton'

export default function createColumns(
  searchState: SearchState<DB>,
  setSearchedColumn: React.Dispatch<React.SetStateAction<SearchState<DB>>>
) {
  const columns: EditCellType<DB>[] = [
    {
      editType: 'string',
      ...generateSearchColumn<DB>(
        ui.createCol<DB>('메일주소', ui.ColSize.xxl, {
          dataIndex: 'email',
          align: 'left',
        }),
        searchState,
        {
          onSearch: option => {
            setSearchedColumn(option)
          },
          onClear: option => {
            setSearchedColumn(option)
          },
        }
      ),
    },
    {
      editType: 'string',
      ...generateSearchColumn<DB>(
        ui.createCol<DB>('사용자명', ui.ColSize.md, {
          dataIndex: 'userName',
          align: 'left',
        }),
        searchState,
        {
          onSearch: option => {
            setSearchedColumn(option)
          },
          onClear: option => {
            setSearchedColumn(option)
          },
        }
      ),
    },
    {
      editType: 'string',
      ...generateSearchColumn<DB>(
        ui.createCol<DB>('연락처', ui.ColSize.md, {
          dataIndex: 'phoneNumber',
          align: 'center',
        }),
        searchState,
        {
          onSearch: option => {
            setSearchedColumn(option)
          },
          onClear: option => {
            setSearchedColumn(option)
          },
        }
      ),
    },
    {
      editType: 'string',
      isNullable: true,
      ...generateSearchColumn<DB>(
        ui.createCol<DB>('기타설명', ui.ColSize.xxl, {
          dataIndex: 'description',
          align: 'center',
        }),
        searchState,
        {
          onSearch: option => {
            setSearchedColumn(option)
          },
          onClear: option => {
            setSearchedColumn(option)
          },
        }
      ),
    },
    {
      key: 'editPassword',
      title: '비밀번호 수정',
      width: ui.ColSize.sm,
      align: 'center',
      render(_, data) {
        return <EditPwdButton data={data} />
      },
    },
    genrateDeleteColumn<DB>(
      QueryHelper.removeOneById(SubscriptionEntity.Admin)
    ),
  ]

  return columns
}
