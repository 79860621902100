import { Form, Input } from 'antd'
import { get } from 'lodash'
import { useReduxSelector } from 'modules/store'
import React, { useMemo } from 'react'
import { EditCellProps } from './EditCell.type'

export default function ShopCodeEditCell<T = unknown>(props: EditCellProps<T>) {
  const { record } = props
  const shopList = useReduxSelector(({ shopList }) => shopList)

  // const filterAMCodeList = useMemo(() => {
  //   const manufacturerId: string | undefined = get(
  //     record,
  //     'manufacturerId',
  //     undefined
  //   )
  //   if (manufacturerId) {
  //     return amCodeList.filter(
  //       ({ manufacturerId: mId }) => mId === manufacturerId
  //     )
  //   }
  //   return amCodeList
  // }, [amCodeList, record])

  const shopCodeList = useMemo(() => {
    const shopCodeList: string[] = []
    const recordId = String(get(record, 'id'))
    shopList.forEach(({ id, shopCode }) => {
      if (recordId !== id && shopCode) {
        shopCodeList.push(shopCode)
      }
    })
    return shopCodeList
  }, [shopList, record])

  return (
    <Form.Item
      style={{ margin: 0 }}
      name="shopCode"
      rules={[
        {
          validator(_, value) {
            return new Promise((resolve, reject) => {
              if (shopCodeList.indexOf(value) <= -1) {
                resolve()
              } else {
                reject('동일한 매장코드가 존재합니다.')
              }
            })
          },
        },
        {
          min: 7,
          message: '매장코드는 숫자 7자리로 입력하세요',
        },
        {
          max: 7,
          message: '매장코드는 숫자 7자리로 입력하세요',
        },
      ]}
    >
      <Input type="number" />
    </Form.Item>
  )
}
