import { GeneratorModalIndex } from 'common'
import { $Actions, useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useDispatch } from 'react-redux'
import GeneratorModalContaner from './GeneratorModalContaner'

// 16
export const BackGeneratorModals = ReactMemoEx(() => {
  const admin = useReduxSelector(state => state.auth.admin)

  const dispatch = useDispatch()
  const isModalOepn = useReduxSelector(
    state => state.layout.generatorModal !== undefined
  )
  const onToggleOpen = useCallback(
    (modalIndex: GeneratorModalIndex) => () => {
      if (!admin.isLogin) {
        return
      }
      if (admin.manufacturerId) {
        if (
          modalIndex === GeneratorModalIndex.MediaCenter ||
          modalIndex === GeneratorModalIndex.Shop
        ) {
          dispatch($Actions.setToggleGeneratorModal(modalIndex))
        }
        return
      }
      dispatch($Actions.setToggleGeneratorModal(modalIndex))
    },
    [dispatch, admin]
  )
  useHotkeys('alt+1', onToggleOpen(GeneratorModalIndex.MediaCenter))
  useHotkeys('alt+2', onToggleOpen(GeneratorModalIndex.Shop))
  useHotkeys('alt+3', onToggleOpen(GeneratorModalIndex.SaleChannel))
  useHotkeys('alt+4', onToggleOpen(GeneratorModalIndex.Group))
  useHotkeys('alt+5', onToggleOpen(GeneratorModalIndex.AMCode))
  useHotkeys('alt+6', onToggleOpen(GeneratorModalIndex.CountryCode))
  useHotkeys('alt+7', onToggleOpen(GeneratorModalIndex.Manufacturer))
  useHotkeys('alt+8', onToggleOpen(GeneratorModalIndex.Admin))

  // useDidMount(() => {
  //   dispatch($Actions.setToggleGeneratorModal(GeneratorModalIndex.CountryCode))
  // })
  return <>{isModalOepn && <GeneratorModalContaner />}</>
})
