import { useApolloClient } from '@apollo/react-hooks'
import { Col, Row } from 'antd'
import { useDidMount } from 'beautiful-react-hooks'
import { StatsStandard, StatsUnit, toStatId, Language } from 'common'
import { $AsyncActions, useReduxSelector } from 'modules/store'
import React, { useCallback, useMemo, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { useDispatch } from 'react-redux'
import { CustomCard, getSafeStateItem } from '../../libs'
import ChartTotalSCConnectRTHorizontal from './ChartTotalSCConnectRTHorizontal'
import { parseDatasetConnection } from './datasets'
import ReactResizeDetector from 'react-resize-detector'
import { ReactMemoEx } from 'modules/view'
import styled from 'styled-components'
import { TabItem } from '../../libs'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
const RowContainer = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
`

const TAB_ITEMS: Array<TabItem> = [
  { key: StatsUnit.Day, title: Language.Daily },
  { key: StatsUnit.Month, title: Language.Monthly },
]

export const ChartTotalSCConnectRT = ReactMemoEx(() => {
  const dispatch = useDispatch()
  const gqlClient = useApolloClient()
  const [unit, setUnit] = useState<StatsUnit>(StatsUnit.Day)
  const statItem = useReduxSelector(({ stats }) =>
    getSafeStateItem(
      stats[toStatId({ standard: StatsStandard.SaleChannel, unit })],
      { standard: StatsStandard.SaleChannel, unit }
    )
  )

  useDidMount(() => {
    dispatch(
      $AsyncActions.requestStats(gqlClient, unit, StatsStandard.SaleChannel)
    )
  })

  const onChangeTab = useCallback(
    (actvieKey: string) => {
      if (
        actvieKey === StatsUnit.Day ||
        actvieKey === StatsUnit.Month ||
        actvieKey === StatsUnit.Year
      ) {
        setUnit(actvieKey)
        dispatch(
          $AsyncActions.requestStats(
            gqlClient,
            actvieKey,
            StatsStandard.SaleChannel
          )
        )
      }
    },
    [dispatch, gqlClient]
  )

  const [graphData, lastLogList] = useMemo(
    () => parseDatasetConnection(statItem),
    [statItem]
  )

  return (
    <CustomCard
      childrenType="element"
      tabs={TAB_ITEMS}
      title={Language.TOTAL_CONNECT_RATE}
      tabOptions={{
        defaultActiveKey: StatsUnit.Day,
        onChange: onChangeTab,
      }}
    >
      <Container>
        <RowContainer>
          <Row gutter={[16, 16]}>
            <ReactResizeDetector handleWidth>
              {({ width }: { width: number; height: number }) => (
                <>
                  {graphData.map((pieProps, index) => {
                    const isDivision = width >= 400
                    const cssWdith = isDivision ? width / 2 : width
                    const calcHeight = (cssWdith / 4) * 3
                    const height = isNaN(calcHeight)
                      ? undefined
                      : Math.min(calcHeight, 320)

                    return (
                      <Col
                        span={isDivision ? 12 : 24}
                        style={{ minHeight: 160, height, maxHeight: '20rem' }}
                        key={index}
                      >
                        <Doughnut {...pieProps} />
                      </Col>
                    )
                  })}
                </>
              )}
            </ReactResizeDetector>
          </Row>
        </RowContainer>
        <RowContainer style={{ maxHeight: '20rem' }}>
          <ChartTotalSCConnectRTHorizontal
            standard={StatsStandard.SaleChannel}
            unit={unit}
            logList={lastLogList}
          />
        </RowContainer>
      </Container>
    </CustomCard>
  )
})
