import { ApolloProvider } from '@apollo/react-hooks'
import { Layout } from 'antd'
import ApolloClient from 'apollo-client'
import { useDidMount } from 'beautiful-react-hooks'
import { BackGeneratorModals, Header } from 'components'
import { AllSubscriptions } from 'modules/graphql'
import { ReactMemoEx } from 'modules/view'
import { LayoutContent } from 'panels'
import React, { useEffect, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import FileUploadNotification from './FileUploadNotification'
const SLayout = styled(Layout)`
  height: 100%;
`
type PrivateAppProps<TCache> = {
  client: ApolloClient<TCache>
}

function PrivateApp<TCache>(props: PrivateAppProps<TCache>) {
  const { client } = props
  const refAllSubs = useRef<AllSubscriptions<any>>()
  const dispatch = useDispatch()

  useDidMount(() => {
    refAllSubs.current = new AllSubscriptions(client)
  })

  useEffect(() => {
    if (refAllSubs.current) {
      refAllSubs.current.setDispatch(dispatch)
    }
  }, [dispatch])

  const Draw = useMemo(() => {
    return () => {
      return (
        <>
          <FileUploadNotification />
          <SLayout>
            <Header />
            <LayoutContent />
          </SLayout>
          <BackGeneratorModals />
        </>
      )
    }
  }, [])

  return (
    <ApolloProvider client={client}>
      <Draw />
    </ApolloProvider>
  )
}

export default ReactMemoEx(PrivateApp)
