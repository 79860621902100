import { findCacheById, Group } from 'common'
import produce from 'immer'
import { Dictionary } from 'lodash'
import { ApplicationState } from '../state'
import adapterGroup from './adapterGroup'
import adapterManufacturer from './adapterManufacturer'
import adapterMedia from './adapterMedia'
import adapterSaleChannel from './adapterSaleChannel'
import adapterShop from './adapterShop'
import { Finders } from './inLib'

const groupMap = (groupList: Group[]) => {
  const idMap: Dictionary<Group['id'][]> = {}
  groupList.forEach(group => {
    const { manufacturerId, id } = group
    if (idMap[manufacturerId]) {
      idMap[manufacturerId].push(id)
    } else {
      idMap[manufacturerId] = [id]
    }
  })
  return idMap
}

export const parseState$ = (nextState: ApplicationState) => {
  const finders: Finders = {
    findCountryCode: findCacheById(nextState.countryCodeList),
    findManufacturer: findCacheById(nextState.manufacturerList),
    findSaleChannel: findCacheById(nextState.saleChannelList),
    groupByGroupId: groupMap(nextState.groupList),
  }

  const output = produce(nextState, draft => {
    draft.mediaList$ = adapterMedia(nextState, finders)
    const shopList$ = adapterShop(nextState, finders)
    // console.log("shopList$", shopList$)
    draft.shopList$ = shopList$
    const saleChannelList$ = adapterSaleChannel(nextState, shopList$, finders)
    draft.saleChannelList$ = saleChannelList$
    const manufacturerList$ = adapterManufacturer(
      nextState,
      saleChannelList$,
      finders
    )
    draft.manufacturerList$ = manufacturerList$
    console.log("manufacturerList$", manufacturerList$)
    draft.groupList$ = adapterGroup(nextState, finders)
  })
  return output
}
