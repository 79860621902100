import {
  FkGroupAndSalechannel,
  Group,
  PlayList,
  SaleChannel,
  Media,
  findCacheById,
  FindOutput,
} from 'common'
import { uniq } from 'lodash'
import { InPLSBySaleChannel, PLSParentBlock } from './PlayListTable.type'
import produce from 'immer'

/**
 * playList 빠르게 찾기 쉽게 하는 hellper
 * @param plsList
 */
const finderHelperPLS = (plsList: PlayList[]) => {
  const manufacturerPLS: PlayList[] = []
  const groupPLS: PlayList[] = []
  const fkGSPLS: PlayList[] = []

  const idsByManufacturer: string[] = []
  const idsByGroup: string[] = []
  const idsByFKGS: string[] = []

  plsList.forEach(pls => {
    if (pls.manufacturerId) {
      manufacturerPLS.push(pls)
      idsByManufacturer.push(pls.manufacturerId)
    } else if (pls.groupId) {
      groupPLS.push(pls)
      idsByGroup.push(pls.groupId)
    } else if (pls.fkGSId) {
      fkGSPLS.push(pls)
      idsByFKGS.push(pls.fkGSId)
    }
  })

  return {
    findByManufacturer(id: string) {
      const index = idsByManufacturer.indexOf(id)
      return index <= -1 ? undefined : manufacturerPLS[index]
    },
    findByGroupId(id: string) {
      const index = idsByGroup.indexOf(id)
      return index <= -1 ? undefined : groupPLS[index]
    },
    findByFKGS(id: string) {
      const index = idsByFKGS.indexOf(id)
      return index <= -1 ? undefined : fkGSPLS[index]
    },
  }
}
/**
 * instance FKGS ID
 */
const toIstFKGSId = (groupId: string, saleChannelId: string) =>
  `${groupId}+${saleChannelId}`

const finderHelperFKGS = (fkGSList: FkGroupAndSalechannel[]) => {
  const idList = fkGSList.map(({ groupId, saleChannelId }) =>
    toIstFKGSId(groupId, saleChannelId)
  )
  return (groupId: string, saleChannelId: string) => {
    const id = toIstFKGSId(groupId, saleChannelId)
    const index = idList.indexOf(id)
    return index <= -1 ? undefined : fkGSList[index]
  }
}

const getGroupMediaList = (
  group: Group,
  helperPLS: ReturnType<typeof finderHelperPLS>
): [PlayList | undefined, PlayList | undefined] => {
  const plsByMfr = helperPLS.findByManufacturer(group.manufacturerId)
  const plsByGroup = helperPLS.findByGroupId(group.id)

  return [plsByMfr, plsByGroup]
}

type Options = {
  fkGSList: FkGroupAndSalechannel[]
  groupList: Group[]
  saleChannelList: SaleChannel[]
  mediaList: Media[]
}

const toSizeAndFileName = (
  ids: string[],
  finderMediaCenter: FindOutput<Media>
) => {
  return ids.reduce<[number, string[]]>(
    (output, id) => {
      const media = finderMediaCenter(id)
      if (media) {
        output[1].push(media.fileName)
        output[0] = output[0] + media.size
      }
      return output
    },
    [0, []]
  )
}

const toFinalInPLSBySaleChannel = (
  inPLSBySaleChannel: InPLSBySaleChannel,
  finderMediaCenter: FindOutput<Media>
) => {
  const {
    mediaIdsGroup,
    mediaIdsManufacturer,
    mediaIdsSaleChannel,
    parentsBlock,
  } = inPLSBySaleChannel

  const ids: string[] = []
  if (parentsBlock === 'saleChannel') {
    ids.push(...mediaIdsSaleChannel)
  } else if (parentsBlock === 'group') {
    ids.push(...uniq([...mediaIdsSaleChannel, ...mediaIdsGroup]))
  } else {
    ids.push(
      ...uniq([
        ...mediaIdsSaleChannel,
        ...mediaIdsGroup,
        ...mediaIdsManufacturer,
      ])
    )
  }

  const fileSize = ids.reduce((totalSize, id) => {
    const media = finderMediaCenter(id)
    if (media) {
      return totalSize + media.size
    }
    return totalSize
  }, 0)

  return produce(inPLSBySaleChannel, draft => {
    draft.fileCount = ids.length
    draft.fileSize = fileSize
  })
}

export default function adpaterPLS(
  plsList: PlayList[],
  options: Options
): InPLSBySaleChannel[] {
  const { fkGSList, groupList, saleChannelList, mediaList } = options
  const outputList: InPLSBySaleChannel[] = []
  const findFKGS = finderHelperFKGS(fkGSList)
  const findMedia = findCacheById(mediaList)

  const helperPLS = finderHelperPLS(plsList)

  groupList.forEach(group => {
    const { id: groupId, title: groupTitle } = group

    const mediaIdsManufacturer: string[] = []
    const mediaIdsGroup: string[] = []
    const mediaNamesGroup: string[] = []
    const mediaNamesManufacturer: string[] = []

    const [manufacturerPls, groupPls] = getGroupMediaList(group, helperPLS)
    let parentsBlock: PLSParentBlock = 'none'

    if (manufacturerPls) {
      mediaIdsManufacturer.push(...manufacturerPls.mediaIds)
      mediaNamesManufacturer.push(
        ...toSizeAndFileName(manufacturerPls.mediaIds, findMedia)[1]
      )
    }

    if (groupPls) {
      if (groupPls.isParentBlock) {
        parentsBlock = 'group'
      }
      mediaIdsGroup.push(...groupPls.mediaIds)
      mediaNamesGroup.push(
        ...toSizeAndFileName(groupPls.mediaIds, findMedia)[1]
      )
    }

    saleChannelList.forEach(sc => {
      const { id: scId, title: saleChannelTitle } = sc
      const id = toIstFKGSId(groupId, scId)

      const fkGS = findFKGS(groupId, scId)
      if (fkGS) {
        const fkGSPlayList = helperPLS.findByFKGS(fkGS.id)
        if (fkGSPlayList) {
          outputList.push(
            toFinalInPLSBySaleChannel(
              {
                groupTitle,
                id,
                mediaIdsGroup,
                mediaIdsManufacturer,
                mediaIdsSaleChannel: fkGSPlayList.mediaIds,
                parentsBlock: fkGSPlayList.isParentBlock
                  ? 'saleChannel'
                  : parentsBlock,
                saleChannelTitle,
                fileSize: 0,
                fileCount: 0,
                mediaNamesGroup,
                mediaNamesManufacturer,
                mediaNamesSaleChannel: toSizeAndFileName(
                  fkGSPlayList.mediaIds,
                  findMedia
                )[1],
              },
              findMedia
            )
          )
          return
        }
      }
      outputList.push(
        toFinalInPLSBySaleChannel(
          {
            groupTitle,
            id,
            mediaIdsGroup,
            mediaIdsManufacturer,
            mediaIdsSaleChannel: [],
            parentsBlock,
            saleChannelTitle,
            fileCount: 0,
            fileSize: 0,
            mediaNamesGroup,
            mediaNamesManufacturer,
            mediaNamesSaleChannel: [],
          },
          findMedia
        )
      )
    })
  })

  return outputList
}
