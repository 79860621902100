import { useMutation } from '@apollo/react-hooks'
import { Dropdown, Input, Menu, Spin } from 'antd'
import { InputProps } from 'antd/lib/input'
import { MenuItemProps } from 'antd/lib/menu/MenuItem'
import {
  FkGasAndShop,
  FkGroupAndSalechannel,
  MutationAddFkgssArgs,
  SaleChannel,
  Shop,
  MutationRemoveFkgsArgs,
} from 'common'
import gql from 'graphql-tag'
import { useReduxSelector } from 'modules/store'
import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { HighlighterText } from '../../../../../HighlighterText'
import { ReactMemoEx } from 'modules/view'

const DropContainer = styled.div`
  padding: 0.5rem;
  width: 18rem;
  max-height: 18rem;
  display: block;
  background: white;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
`
const SMenu = styled(Menu)`
  width: 100%;
  height: 12rem;
  overflow: scroll;
`

const StyleMenuItem = styled(Menu.Item)`
  &:hover {
    background-color: rgb(245, 245, 245);
  }
`

type TitleSaleChannelProps = {
  saleChannel: SaleChannel
  fkGS: FkGroupAndSalechannel
}
const { Search } = Input

const MUTATION_ADD = gql`
  mutation AddFKGSS($shopId: String!, $fkGSId: String!) {
    addFKGSS(shopId: $shopId, fkGSId: $fkGSId) {
      id
    }
  }
`
const MUTATION_REMOVE = gql`
  mutation RemoveFKGS($id: String!) {
    result: removeFKGS(id: $id)
  }
`

type SMenuItemProps = {
  fkGSId: string
  shop: Shop
  search?: string
} & MenuItemProps

/**
 *
 */
const SMenuItem = ReactMemoEx((props: SMenuItemProps) => {
  const { shop, search, fkGSId, ...rest } = props
  const [addFKGSS, { loading }] = useMutation<
    { fkGSS: FkGasAndShop },
    MutationAddFkgssArgs
  >(MUTATION_ADD)

  const onClick = useCallback(() => {
    addFKGSS({
      variables: { fkGSId, shopId: shop.id },
    })
  }, [addFKGSS, fkGSId, shop.id])

  return (
    <StyleMenuItem disabled={loading} {...rest} onClick={onClick}>
      <Spin spinning={loading}>
        <HighlighterText children={shop.title} searchWords={search} />
      </Spin>
    </StyleMenuItem>
  )
})

/**
 *
 * @param props
 */
const ContextMenu = (props: TitleSaleChannelProps) => {
  // const
  const refTime = useRef<number>()
  const [mutationRemove, { loading }] = useMutation<
    { result: boolean },
    MutationRemoveFkgsArgs
  >(MUTATION_REMOVE)

  const [search, setSearch] = useState<string | undefined>(undefined)
  const { fkGS, saleChannel } = props
  const { id: fkGSId } = fkGS
  const { id: saleChannelId } = saleChannel

  const dataList = useReduxSelector(state => {
    const { shopList$, fkGSShopList } = state
    const shopList = shopList$.filter(
      ({ saleChannelId: sId }) => saleChannelId === sId
    )
    return shopList.filter(({ id }) => {
      const index = fkGSShopList.findIndex(({ fkGSId: mFKGSId, shopId }) => {
        return mFKGSId === fkGSId && shopId === id
      })
      return index <= -1
    })
  })

  const filterDataSource = useMemo(() => {
    if (search === undefined) {
      return dataList
    }
    return dataList.filter(({ title }) => {
      return title.indexOf(search) >= 0
    })
  }, [dataList, search])

  /**
   * 화면 뒤 영역이 선택되지 않도록 한다.
   */
  const onClickContainer = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()
    },
    []
  )

  /**
   * 검색이 바로되면 화면 랜더링 문제가 생겨 200ms 뒤에 검색되도록 한다.
   */
  const onChangeSearch: Required<InputProps>['onChange'] = useCallback(e => {
    if (refTime.current) {
      clearTimeout(refTime.current)
    }
    const { value } = e.target
    refTime.current = setTimeout(() => setSearch(value), 200)
  }, [])

  const onClickRemoveFKGS = useCallback(() => {
    mutationRemove({ variables: { id: fkGSId } })
  }, [fkGSId, mutationRemove])

  return useMemo(
    () => (
      <DropContainer onClick={onClickContainer}>
        <Spin spinning={loading}>
          <Search onChange={onChangeSearch} />
          <SMenu>
            {filterDataSource.map(shop => (
              <SMenuItem
                key={shop.id}
                shop={shop}
                search={search}
                fkGSId={fkGSId}
              />
            ))}
          </SMenu>
          <Menu>
            <Menu.Divider />
            <Menu.Item onClick={onClickRemoveFKGS}>예외 제거</Menu.Item>
          </Menu>
        </Spin>
      </DropContainer>
    ),
    [
      filterDataSource,
      onChangeSearch,
      onClickContainer,
      search,
      fkGSId,
      loading,
      onClickRemoveFKGS,
    ]
  )
}

const SaleChannelTitle: FC<TitleSaleChannelProps & {
  children?: React.ReactChild
}> = props => {
  const { children } = props
  return (
    <>
      <Dropdown
        overlay={<ContextMenu {...props} />}
        trigger={['contextMenu']}
        overlayClassName="dropdown-comp"
      >
        <div>{children}</div>
      </Dropdown>
    </>
  )
}

export default ReactMemoEx(SaleChannelTitle)
