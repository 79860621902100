import { Tabs } from 'antd'
import { ReactMemoEx } from 'modules/view'
import React from 'react'
import TabChartItem, { TabChartItemProps } from './TabChartItem'
const { TabPane } = Tabs

export type TabChartItemType = TabChartItemProps & { key: string }

export type TabChartContainerProps = {
  data: TabChartItemType[]
}

const TabChartContainer = (props: TabChartContainerProps) => {
  const { data } = props
  return (
    <Tabs destroyInactiveTabPane={true} className="tabchart">
      {data.map(item => {
        const { key, ...rest } = item
        return <TabPane tab={<TabChartItem {...rest} />} key={key} />
      })}
    </Tabs>
  )
}

export default ReactMemoEx(TabChartContainer)
