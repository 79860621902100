import { Drawer } from 'antd'
import { GeneratorModalIndex } from 'common'
import { $Actions, useReduxSelector } from 'modules/store'
import React, { useCallback } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import FormAdmin from './form/FormAdmin'
import FormAMCode from './form/FormAMCode'
import FormCountryCode from './form/FormCountryCode'
import FormGroup from './form/FormGroup'
import FormManufacturer from './form/FormManufacturer'
import FormMediaCenter from './form/FormMediaCenter'
import FormSaleChannel from './form/FormSaleChannel'
import FormShop from './form/FormShop'

const BottomModal = styled.div`
  position: fixed;
  height: 20rem;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  z-index: 99;
  background-color: rgb(245, 245, 245);
  box-shadow: 0 -0.25rem 0.5rem -0.25rem rgba(0, 0, 0, 0.15);
`

const GeneratorModalContaner = () => {
  const marginLeft = useReduxSelector(state =>
    Math.max(state.layout.naviSize + 1, 0)
  )
  const modalIndex = useReduxSelector(state => state.layout.generatorModal)

  const Form = useCallback(() => {
    if (modalIndex === GeneratorModalIndex.Admin) {
      return <FormAdmin />
    }
    if (modalIndex === GeneratorModalIndex.Manufacturer) {
      return <FormManufacturer />
    }
    if (modalIndex === GeneratorModalIndex.Group) {
      return <FormGroup />
    }
    if (modalIndex === GeneratorModalIndex.SaleChannel) {
      return <FormSaleChannel />
    }
    if (modalIndex === GeneratorModalIndex.Shop) {
      return <FormShop />
    }
    if (modalIndex === GeneratorModalIndex.MediaCenter) {
      return <FormMediaCenter />
    }
    if (modalIndex === GeneratorModalIndex.AMCode) {
      return <FormAMCode />
    }
    if (modalIndex === GeneratorModalIndex.CountryCode) {
      return <FormCountryCode />
    }
    return <FormShop />
  }, [modalIndex])
  const dispatch = useDispatch()
  const onCancel = useCallback(() => {
    if (modalIndex !== undefined) {
      dispatch($Actions.setHideGeneratorModal(modalIndex))
    }
  }, [dispatch, modalIndex])

  return isMobile ? (
    <Drawer
      placement="bottom"
      visible={modalIndex !== undefined}
      height={'100%'}
      onClose={onCancel}
      closable={false}
    >
      {modalIndex && <Form />}
    </Drawer>
  ) : modalIndex ? (
    <BottomModal style={{ marginLeft }}>
      <Form />
    </BottomModal>
  ) : null
}

export default GeneratorModalContaner
