import { Manufacturer, toArrayKeyValue } from 'common'
import { TableCardProps } from 'components/src/CustomCard'
import { useMutationUpdateMedia } from 'modules/graphql'
import { Media$ as DB, useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo, useState } from 'react'
import { EditTable } from '../../../EditTable'
import { PickColumnProps } from '../../../PickColumn'
import { SearchState } from '../../../util'
import generateColumn from './media.columns'

export type TableMediaProps = {
  manufacturer?: Manufacturer
} & PickColumnProps<DB> & {
    cardOptions?: TableCardProps
  }

export const TableMedia = ReactMemoEx((props: TableMediaProps) => {
  const { manufacturer, cardOptions, ...rest } = props
  const [updateMedia, { loading }] = useMutationUpdateMedia()
  const [searchState, setSearchedColumn] = useState<SearchState<DB>>([])

  const dataSource = useReduxSelector(state => {
    if (manufacturer) {
      const { id } = manufacturer
      return state.mediaList$.filter(
        ({ manufacturerId }) => manufacturerId === id
      )
    }
    return state.mediaList$
  })

  const manufacturerList = useReduxSelector(state => {
    if (manufacturer === undefined) {
      return toArrayKeyValue(state.manufacturerList$, 'title') as string[]
    }
    return []
  })

  const columns = useMemo(
    () =>
      generateColumn(searchState, setSearchedColumn, {
        manufacturerList: manufacturerList,
        ...rest,
      }),
    [searchState, setSearchedColumn, manufacturerList, rest]
  )
  const onEdit = useCallback(
    (newData: DB) => {
      updateMedia({
        variables: {
          id: newData.id,
          audioCodec: newData.audioCodec,
          videoCodec: newData.videoCodec,
          duration: newData.duration,
          licenseEnd: newData.licenseEnd,
        },
      })
    },
    [updateMedia]
  )

  return (
    <EditTable<DB>
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      rowKey="id"
      onEdit={onEdit}
      scroll={{ x: true }}
      disabledEdit={rest.disabledEdit}
    />
  )
})
