import stc from 'string-to-color'
import hexToRgba from 'hex-to-rgba'
import * as chartjs from 'chart.js'
import color from 'color'

type ChartColor = Pick<
  chartjs.ChartDataSets,
  | 'backgroundColor'
  | 'borderColor'
  | 'pointBorderColor'
  | 'pointBackgroundColor'
  | 'pointHoverBackgroundColor'
  | 'pointHoverBorderColor'
>
export const toChartColor = (data: any): ChartColor => {
  const colorHex = stc(data)
  return {
    backgroundColor: hexToRgba(colorHex, 0.6),
    borderColor: colorHex,
    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    pointHoverBorderColor: 'rgba(220,220,220,1)',
  }
}

export const sampleColors = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)',
}

export const sampleColorList = [
  sampleColors.red,
  sampleColors.orange,
  sampleColors.yellow,
  sampleColors.green,
  sampleColors.blue,
  sampleColors.purple,
  sampleColors.grey,
]

const colorSize = sampleColorList.length
export const getChartBackground = (dsColor: string): chartjs.ChartDataSets => ({
  backgroundColor: color(dsColor)
    .alpha(0.5)
    .toString(),
  borderColor: dsColor,
  borderWidth: 2,
})

export const getChartBackgroundByIndex = (index: number) => {
  const i = index % colorSize
  const dsColor = sampleColorList[i]
  return getChartBackground(dsColor)
}
