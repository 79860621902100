import { Manufacturer, Media } from 'common'
import { useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo, useRef } from 'react'
import { SelectableGroup } from 'react-selectable-fast'
import List from './List'
import { CardTypeMedia, CardTypeUpload } from './types'

type MediaFinderBodyProps = {
  manufacturerId?: Manufacturer['id']
  onSelect?: (media: Media[]) => void
}

type SelectItem = {
  props: {
    media: CardTypeMedia
    selectableKey: Media['id']
  }
}

const MediaFinderBody = (props: MediaFinderBodyProps) => {
  const { manufacturerId, onSelect } = props
  const { mediaList, mediaUpload } = useReduxSelector((state) => ({
    mediaList: state.mediaList,
    mediaUpload: state.mediaUpload,
  }))

  const getSelectableGroupRef = useRef<SelectableGroup | null>(null)
  const handleSelecting = useCallback(() => {}, [])
  const handleSelectionClear = useCallback(() => {}, [])
  const handleSelectionFinish = useCallback(
    (selectItemList: Array<SelectItem>) => {
      const mediaList = selectItemList.map(({ props: { media } }) => media)
      if (onSelect) {
        onSelect(mediaList)
      }
    },
    [onSelect]
  )

  const handleSelectedItemUnmount = useCallback(() => {}, [])

  const resultMediaList = useMemo(
    () => [
      ...mediaList
        .filter(({ manufacturerId: mId }) => manufacturerId === mId)
        .map((media): CardTypeMedia => ({ ...media, type: 'media' })),
      ...mediaUpload
        .filter(({ manufacturerId: mId }) => manufacturerId === mId)
        .map((upload): CardTypeUpload => ({ ...upload, type: 'upload' })),
    ],
    [mediaList, mediaUpload, manufacturerId]
  )

  return (
    <SelectableGroup
      ref={getSelectableGroupRef}
      allowClickWithoutSelected={false}
      className="main"
      clickClassName="thumbnail"
      deselectOnEsc={true}
      duringSelection={handleSelecting}
      enableDeselect={true}
      ignoreList={['.not-selectable']}
      onSelectedItemUnmount={handleSelectedItemUnmount}
      onSelectionClear={handleSelectionClear}
      onSelectionFinish={handleSelectionFinish}
      selectboxClassName="selectable-selectbox"
      tolerance={0}
      resetOnStart={false}
      allowAltClick={true}
      allowCtrlClick={true}
      allowMetaClick={true}
      allowShiftClick={true}
    >
      <List items={resultMediaList} />
    </SelectableGroup>
  )
}

export default ReactMemoEx(MediaFinderBody)
