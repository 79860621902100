import { useMutation } from '@apollo/react-hooks'
import {
  Manufacturer,
  MutationUpdateSaleChannelArgs,
  SaleChannel,
  toArrayKeyValue,
  isDuplicateTitle,
  Language,
} from 'common'
import gql from 'graphql-tag'
import { SaleChannel$ as DB, useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo, useState } from 'react'
import { EditTable, EditTableFormValidation } from '../../../EditTable'
import TableBreadcrumb from '../../../TableBreadcrumb'
import { SearchState } from '../../../util'
import generateColumn from './saleChannel.columns'

const MUTATION = gql`
  mutation UpdateSaleChannel($title: String!, $id: String!) {
    SaleChannel: updateSaleChannel(title: $title, id: $id) {
      id
    }
  }
`

type TableSaleChannelProps = {
  manufacturer?: Manufacturer
}

/**
 * 제조사 테이블
 */
export const TableSaleChannel = ReactMemoEx((props: TableSaleChannelProps) => {
  const { manufacturer } = props
  const [updateMutation, { loading }] = useMutation<
    { result: SaleChannel },
    MutationUpdateSaleChannelArgs
  >(MUTATION)

  const [dataSource, fullDataSource] = useReduxSelector(state => {
    const { saleChannelList$ } = state
    let filterList: DB[] = saleChannelList$

    if (manufacturer) {
      const { id } = manufacturer
      filterList = saleChannelList$.filter(
        ({ manufacturerId }) => manufacturerId === id
      )
    }
    return [filterList, saleChannelList$]
  })

  const manufacturerList = useReduxSelector(state => {
    if (manufacturer === undefined) {
      return toArrayKeyValue(state.manufacturerList$, 'title') as string[]
    }
    return []
  })

  const [searchState, setSearchedColumn] = useState<SearchState<DB>>([])

  const columns = useMemo(
    () =>
      generateColumn(searchState, setSearchedColumn, {
        disabledManufacturer: manufacturer !== undefined,
        manufacturerList: manufacturerList,
      }),
    [searchState, setSearchedColumn, manufacturer, manufacturerList]
  )

  const onEdit = useCallback(
    (data: DB) => {
      const { id, title } = data
      updateMutation({ variables: { id, title } })
        .then(() => {})
        .catch(e => {})
    },
    [updateMutation]
  )

  const formValidation: EditTableFormValidation<DB> = useCallback(
    (newData: DB) => {
      const { id, title } = newData
      if (isDuplicateTitle(fullDataSource, title, id)) {
        return [
          {
            name: 'title',
            errors: [Language.ErrorMessages.duplicateSaleChannelTitle],
          },
        ]
      }
      return undefined
    },
    [fullDataSource]
  )

  const title = useMemo(() => {
    if (manufacturer) {
      return () => {
        return <TableBreadcrumb path={[manufacturer, Language.SaleChannel]} />
      }
    }
    return undefined
  }, [manufacturer])

  return (
    <EditTable<DB>
      columns={columns}
      dataSource={dataSource}
      rowKey="id"
      onEdit={onEdit}
      loading={loading}
      title={title}
      formValidation={formValidation}
      scroll={{ x: true }}
      // expandable={{
      //   expandedRowRender: (recode) => (
      //     <TableShop saleChannel={recode} filterMode="saleChannel" />
      //   ),
      // }}
    />
  )
})
