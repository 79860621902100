import produce from 'immer'
import { get } from 'lodash'
import { $GetActionType } from 'modules/store/util'
import { AnyAction } from 'redux'
import { action } from 'typesafe-actions'
import * as Features from '../../features'
import { INITIAL_APPLICATION_STATE } from './state'
import { parseState$ } from './adapters'
import { toAction } from './util'

enum ActionsCrossSlice {
  INIT = '@@root/INIT',
}

export const CrossActions = {
  initDatabase: (state: Features.ApplicationStateDatabase) =>
    action(ActionsCrossSlice.INIT, state),
}

export const Actions = {
  ...Features.Actions,
  ...CrossActions,
}

type ActionType = $GetActionType<typeof Actions>

export type ApplicationState = typeof INITIAL_APPLICATION_STATE

export function crossSliceReducer(
  state: ApplicationState,
  rootAction: AnyAction
): ApplicationState {
  const action = toAction<ActionType>(rootAction)
  if (action.type === ActionsCrossSlice.INIT) {
    const payload = get(action, 'payload') as Features.ApplicationStateDatabase

    const nextState = produce(state, draft => {
      const {
        amCodeList,
        countryCodeList,
        fkGSList,
        fkGSShopList,
        groupList,
        manufacturerList,
        plsList,
        saleChannelList,
        shopList,
        mediaList,
        mediaCenterList,
        adminList,
        eventList,
      } = payload
      draft.amCodeList = amCodeList
      draft.adminList = adminList
      draft.countryCodeList = countryCodeList
      draft.eventList = eventList
      draft.fkGSList = fkGSList
      draft.fkGSShopList = fkGSShopList
      draft.groupList = groupList
      draft.manufacturerList = manufacturerList
      draft.mediaCenterList = mediaCenterList
      draft.mediaList = mediaList
      draft.plsList = plsList
      draft.saleChannelList = saleChannelList
      draft.shopList = shopList
      draft.auth.isLoadInitData = true
    })

    return parseState$(nextState)
  } else if (
    action.type.indexOf('@@auth') <= -1 &&
    action.type.indexOf('@@layout') <= -1 &&
    action.type.indexOf('@@panelList') <= -1
  ) {
    return parseState$(state)
  }
  return state
}
