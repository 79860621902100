import color from 'color'
import * as antColors from '@ant-design/colors'

export const toPercent = (num: number | undefined | null) => {
  if (num === undefined || num === null) {
    return '0'
  }
  if (isNaN(num)) {
    return '0'
  }
  return (num * 100)
    .toFixed(2)
    .replace(/0+$/, '')
    .replace(/\.$/, '')
}

export const COLOR_PRIMARY = antColors.blue.primary || '#00f'

export const COLOR_SUB = color(antColors.grey[0])
  .alpha(0.2)
  .toString()

export const COLOR_ERROR = antColors.red.primary || '#f00'
