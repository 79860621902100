import { Tabs, Typography, Card, Row, Col } from 'antd'
import Spin, { SpinProps } from 'antd/lib/spin'
import { TabPaneProps, TabsProps } from 'antd/lib/tabs'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo } from 'react'
import classnames from 'classnames'
import './comps.less'
import { CardProps } from 'antd/lib/card'
import { ColProps } from 'antd/lib/col'

const { Paragraph } = Typography

const { TabPane } = Tabs

type TabItem = { title: string; key: string }

const mouseParentBlock = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>
) => {
  event.preventDefault()
  event.stopPropagation()
}

export type CustomCardProps = {
  tabOptions?: Omit<TabsProps, 'renderTabBar' | 'tabPosition'>
} & Pick<SpinProps, 'spinning'> &
  (
    | {
        title?: string | React.ReactNode
        childrenType: 'panel'
        children:
          | React.ClassicComponentClass<TabPaneProps>
          | Array<React.ClassicComponentClass<TabPaneProps>>
      }
    | {
        title?: string | React.ReactNode
        childrenType: 'element'
        tabs: Array<TabItem>
        children: React.ReactNode
      }
  )

const COLS_DEFAULT: [ColProps, ColProps, ColProps] = [
  { xs: 12 },
  { xs: 12 },
  {},
]
const COLS_EXTRA: [ColProps, ColProps, ColProps] = [
  {
    xxl: 12,
    xl: 12,
    lg: 12,
    md: 10,
    sm: 12,
    xs: 12,
  },
  {
    xxl: 8,
    xl: 8,
    lg: 8,
    md: 8,
    sm: 12,
    xs: 12,
  },
  {
    xxl: 4,
    xl: 4,
    lg: 4,
    md: 4,
    sm: 24,
    xs: 24,
  },
]
function getCols(isExtra: boolean): [ColProps, ColProps, ColProps] {
  if (isExtra) {
    return COLS_EXTRA
  }
  return COLS_DEFAULT
}

export const CustomCard = (props: CustomCardProps) => {
  const { title, tabOptions, spinning = false } = props
  const { tabBarExtraContent, ...restTabOptions } = tabOptions || {}
  const onMouseDown = useCallback(mouseParentBlock, [])

  const renderTabBar: Required<TabsProps>['renderTabBar'] = useCallback(
    (inProps, DefaultTabBar) => {
      const colProps = getCols(tabBarExtraContent !== undefined)
      return (
        <div className="c-card-header">
          <Row style={{ width: '100%' }}>
            <Col {...colProps[0]}>
              <Paragraph strong ellipsis className="c-card-header-title">
                {title}
              </Paragraph>
            </Col>
            <Col {...colProps[1]}>
              <div className="c-card-header-tabs" onMouseDown={onMouseDown}>
                <div className="c-card-header-tabs-container">
                  <DefaultTabBar {...inProps} />
                </div>
              </div>
            </Col>
            {tabBarExtraContent && (
              <Col {...colProps[2]}>
                <div className="c-card-extra">{tabBarExtraContent}</div>
              </Col>
            )}
          </Row>
        </div>
      )
    },
    [title, onMouseDown, tabBarExtraContent]
  )

  return (
    <div className="c-card">
      {props.childrenType === 'panel' ? (
        <Tabs {...restTabOptions} tabPosition="top" renderTabBar={renderTabBar}>
          {props.children}
        </Tabs>
      ) : (
        <>
          <Tabs
            {...restTabOptions}
            tabPosition="top"
            renderTabBar={renderTabBar}
          >
            {props.tabs.map(tabItem => (
              <TabPane key={tabItem.key} tab={tabItem.title} />
            ))}
          </Tabs>
          <div className="c-card-body" onMouseDown={onMouseDown}>
            <>{props.children}</>
          </div>
        </>
      )}
      {spinning && (
        <div className="c-spin">
          <Spin />
        </div>
      )}
    </div>
  )
}

type CustomSimpleCardProps = {
  title?: string | React.ReactNode
} & Pick<SpinProps, 'spinning'>

export const CustomSimpleCard: React.FC<CustomSimpleCardProps> = ReactMemoEx(
  props => {
    const { title, children, spinning } = props

    const drawHeader = useMemo(() => {
      if (typeof title === 'string') {
        return (
          <div className="c-card-header-title">
            <Paragraph strong ellipsis>
              {title}
            </Paragraph>
          </div>
        )
      } else {
        return title
      }
    }, [title])

    const onMouseDown = useCallback(mouseParentBlock, [])

    return (
      <div className="c-card">
        <div className="c-card-header">{drawHeader}</div>
        <div className="c-card-body" onMouseDown={onMouseDown}>
          {children}
        </div>
        {spinning && (
          <div className="c-spin">
            <Spin />
          </div>
        )}
      </div>
    )
  }
)

export type TableCardProps = CardProps
export const TableCard = ReactMemoEx((props: TableCardProps) => {
  const { children, className, ...rest } = props
  return (
    <Card {...rest} className={classnames('table-card', className)}>
      <div className="table-container">{children}</div>
    </Card>
  )
})
