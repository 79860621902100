import { getOf } from 'common'
import { ApplicationState } from '../state'
import { Media$ } from '../types'
import { Finders } from './inLib'

/**
 * media 에 manufacturer title 을 추가한다.
 * @param state
 */
const adapterMedia = (
  state: ApplicationState,
  { findManufacturer }: Finders
): Media$[] => {
  const { mediaList } = state
  return mediaList.map(media => {
    const manufacturer = findManufacturer(media.manufacturerId)
    return {
      ...media,
      manufacturerTitle: manufacturer ? getOf(manufacturer, 'title', '') : '',
    }
  })
}

export default adapterMedia
