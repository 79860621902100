import React, { useCallback, useMemo } from 'react'
import { ReactMemoEx } from 'modules/view'
import { Drawer } from 'antd'
import { useContextSelector } from 'use-context-selector'
import { ContextByPLM, ActionByPLMTypes } from '../../StorePLM'
import MediaForm from './MediaForm'

export const MediaInfoDrawer = ReactMemoEx(() => {
  const infoMediaList = useContextSelector(
    ContextByPLM,
    state => state[0].infoMediaListId
  )
  const dispatch = useContextSelector(ContextByPLM, state => state[1])

  const onClose = useCallback(
    () => dispatch({ type: ActionByPLMTypes.HIDE_MEDIAINFO }),
    [dispatch]
  )

  const visible = useMemo(
    () => infoMediaList !== undefined && infoMediaList.length >= 1,
    [infoMediaList]
  )

  return (
    <Drawer
      title="미디어 상세보기"
      placement="right"
      closable={true}
      onClose={onClose}
      visible={visible}
      getContainer={false}
      width="19.5rem"
      style={{ position: 'absolute' }}
      bodyStyle={{ padding: '1rem' }}
    >
      {infoMediaList &&
        infoMediaList.map(id => <MediaForm key={id} mediaId={id} />)}
    </Drawer>
  )
})
