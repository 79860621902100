import { MediaCenterAlpha } from './types'
import { ShopFinder } from './Adapters'
import { SearchState } from '../../../util'
import { FormInstance } from 'antd/lib/form'
import { createContext } from 'use-context-selector'

export type StateByMCTable = {
  search: SearchState<MediaCenterAlpha>
  setSearch: (search: SearchState<MediaCenterAlpha>) => void
  editRow?: MediaCenterAlpha
  setEditRow: (row: MediaCenterAlpha | undefined) => void
  shopFinder: ShopFinder
  onEdit: (next: MediaCenterAlpha, prev: MediaCenterAlpha) => void
  form?: FormInstance
}

export const ContextByMCTable = createContext<StateByMCTable>({
  search: [],
  setSearch: () => {},
  shopFinder: new ShopFinder([]),
  setEditRow: () => {},
  onEdit: () => {},
})
