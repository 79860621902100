import { useLazyQuery } from '@apollo/react-hooks'
import { Badge, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import {
  useDidMount,
  useInterval,
  usePreviousValue,
} from 'beautiful-react-hooks'
import {
  findCacheById,
  generateSort,
  LogTvInfo,
  McLog,
  QueryFindMcLogArgs,
  toStringPercent,
  ui,
} from 'common'
import dateformat from 'dateformat'
import gql from 'graphql-tag'
import { get } from 'lodash'
import { useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useMemo } from 'react'
import styled from 'styled-components'

type TimeLineMediaCenterProps = {
  mediaCenterId: string
}

const QUERY_FIND_MC_LOG = gql`
  query FindMediaCenterLog($mediaCenterId: String!) {
    logList: findMCLog(mediaCenterId: $mediaCenterId) {
      id
      isOnline
      createAt
      mediaRate
      tvInfo {
        groupId
        active
        offline
        error
      }
    }
  }
`
type ResultLogList = {
  logList: McLog[]
}
type MCLog$ = McLog & {
  tvInfoMap: { [key: string]: LogTvInfo }
  createAt: number
}

const LogContainer = styled.div`
  padding: 0.5rem;
`

type GroupIdAndTitle = {
  id: string
  title?: string
}

const INTERVAL_TIME = 5 * 60 * 1000
const TimeLineMediaCenter = (props: TimeLineMediaCenterProps) => {
  const { mediaCenterId } = props
  const groupList = useReduxSelector(state => state.groupList)

  const [
    loadMediaCenterLog,
    { loading, data: { logList } = { logList: [] } },
  ] = useLazyQuery<ResultLogList, QueryFindMcLogArgs>(QUERY_FIND_MC_LOG, {
    fetchPolicy: 'no-cache',
  })
  const prevLogList: McLog[] = usePreviousValue(logList)

  useDidMount(() => {
    loadMediaCenterLog({ variables: { mediaCenterId } })
  })

  useInterval(() => {
    loadMediaCenterLog({ variables: { mediaCenterId } })
  }, INTERVAL_TIME)

  const [mediaCenterLogList, groupIDAndTitleList] = useMemo((): [
    Array<MCLog$>,
    Array<GroupIdAndTitle>
  ] => {
    const findGroup = findCacheById(groupList)
    const groupIdList: string[] = []
    const mLogList = logList.length <= 0 ? prevLogList || [] : logList

    const mcLogList = mLogList.map(
      (item): MCLog$ => {
        const { tvInfo } = item
        const tvInfoMap: { [key: string]: LogTvInfo } = {}
        tvInfo.forEach(data => {
          const { groupId } = data
          if (groupIdList.indexOf(groupId) <= -1) {
            groupIdList.push(groupId)
          }
          tvInfoMap[groupId] = data
        })
        return {
          ...item,
          tvInfoMap,
        }
      }
    )
    return [
      mcLogList,
      groupIdList.map(
        (id): GroupIdAndTitle => {
          const foundGroup = findGroup(id)
          return { id, title: foundGroup ? foundGroup.title : id }
        }
      ),
    ]
  }, [groupList, logList, prevLogList])

  const [columns, columnWidth] = useMemo((): [ColumnsType<MCLog$>, number] => {
    const columnList: ColumnsType<MCLog$> = [
      {
        align: 'center',
        dataIndex: 'createAt',
        title: '시간',
        width: ui.ColSize.lg,
        sorter: generateSort<MCLog$>('createAt'),
        render(data: number) {
          return dateformat(data, 'yyyy-mm-dd HH:MM:ss')
        },
      },
      {
        align: 'center',
        dataIndex: 'isOnline',
        title: '상태',
        width: ui.ColSize.sm,
        sorter: generateSort<MCLog$>('isOnline'),
        render(value) {
          if (typeof value === 'boolean') {
            if (value) {
              return <Badge status="processing" text="Online" />
            }
            return <Badge status="error" text="Offline" />
          }
          return ''
        },
      },
      {
        align: 'center',
        dataIndex: 'mediaRate',
        title: '업데이트',
        width: ui.ColSize.xs,
        sorter: generateSort<MCLog$>('mediaRate'),
        render(value: number) {
          return toStringPercent(value)
        },
      },
    ]
    groupIDAndTitleList.forEach(item => {
      columnList.push({
        title: item.title,
        key: item.id,
        width: ui.ColSize.xs * 3,
        children: [
          {
            title: '활성',
            width: ui.ColSize.xs,
            align: 'center',
            dataIndex: `tvInfoMap.${item.id}.active`,
            sorter(a, b) {
              return (
                get(a, `tvInfoMap.${item.id}.active`, 0) -
                get(b, `tvInfoMap.${item.id}.active`, 0)
              )
            },
            render(_, data) {
              return get(data, `tvInfoMap.${item.id}.active`, 0)
            },
          },
          {
            title: '비활성',
            width: ui.ColSize.xs,
            align: 'center',
            sorter(a, b) {
              return (
                get(a, `tvInfoMap.${item.id}.offline`, 0) -
                get(b, `tvInfoMap.${item.id}.offline`, 0)
              )
            },
            dataIndex: `tvInfoMap.${item.id}.offline`,
            render(_, data) {
              return get(data, `tvInfoMap.${item.id}.offline`, 0)
            },
          },
          {
            title: '장애',
            width: ui.ColSize.xs,
            align: 'center',
            dataIndex: `tvInfoMap.${item.id}.error`,
            sorter(a, b) {
              return (
                get(a, `tvInfoMap.${item.id}.error`, 0) -
                get(b, `tvInfoMap.${item.id}.error`, 0)
              )
            },
            render(_, data) {
              return get(data, `tvInfoMap.${item.id}.error`, 0)
            },
          },
        ],
      })
    })

    return [
      columnList,
      columnList.reduce((width, current) => {
        if (current.width) {
          if (typeof current.width === 'number') {
            width += current.width
          }
        }
        return width
      }, 0),
    ]
  }, [groupIDAndTitleList])

  return (
    <LogContainer>
      <Table<MCLog$>
        loading={loading}
        size="small"
        dataSource={mediaCenterLogList}
        columns={columns}
        rowKey="createAt"
        tableLayout="auto"
        scroll={{ x: columnWidth }}
      />
    </LogContainer>
  )
}

export default ReactMemoEx(TimeLineMediaCenter)
