import {
  FkGasAndShop,
  FkGroupAndSalechannel,
  Group,
  Manufacturer,
  Media,
} from 'common'

export type StateByPLMType = {
  manufacturerId?: Manufacturer['id']
  groupId?: Group['id']
  fkGSId?: FkGroupAndSalechannel['id']
  fkGSSId?: FkGasAndShop['id']
  selectMediaList: string[]
  infoMediaListId?: string[]
}

export const initialStateByPLM: StateByPLMType = {
  selectMediaList: [],
}

export type DispatchByPLMType = React.Dispatch<
  React.SetStateAction<StateByPLMType>
>

export enum ActionByPLMTypes {
  SET_TREE = '@@SET/TREE',
  SET_SELECT_MEDIALIST = '@@SET/SELECT_MEDIALIST',
  SHOW_MEDIAINFO = '@@MEDIAINFO/SHOW',
  HIDE_MEDIAINFO = '@@MEDIAINFO/HIDE',
  REDUX_UPDATE_MEDIA = '@@REDUX/UPDATE/MEDIA',
  REDUX_UPDATE_MANUFACTURER = '@@REDUX/UPDATE/MANUFACTURER',
  REDUX_UPDATE_GROUP = '@@REDUX/UPDATE/GROUP',
  REDUX_UPDATE_FKGS = '@@REDUX/UPDATE/FKGS',
  REDUX_UPDATE_FKGSS = '@@REDUX/UPDATE/FKGSS',
}

export type ActionByPLM =
  | {
      type: ActionByPLMTypes.SET_TREE
      payload: Pick<
        StateByPLMType,
        'fkGSId' | 'fkGSSId' | 'groupId' | 'manufacturerId'
      >
    }
  | {
      type: ActionByPLMTypes.SET_SELECT_MEDIALIST
      payload: string[]
    }
  | {
      type: ActionByPLMTypes.SHOW_MEDIAINFO
      payload: string[] | string
    }
  | {
      type: ActionByPLMTypes.HIDE_MEDIAINFO
    }
  | {
      type: ActionByPLMTypes.REDUX_UPDATE_MEDIA
      payload: Media[]
    }
  | {
      type: ActionByPLMTypes.REDUX_UPDATE_MANUFACTURER
      payload: Manufacturer[]
    }
  | {
      type: ActionByPLMTypes.REDUX_UPDATE_GROUP
      payload: Group[]
    }
  | {
      type: ActionByPLMTypes.REDUX_UPDATE_FKGS
      payload: FkGroupAndSalechannel[]
    }
  | {
      type: ActionByPLMTypes.REDUX_UPDATE_FKGSS
      payload: FkGasAndShop[]
    }

export type ContextByPLMType = [StateByPLMType, React.Dispatch<ActionByPLM>]
