import { useMutation } from '@apollo/react-hooks'
import { Form } from 'antd'
import { MediaCenter, MutationUpdateMediaCenterArgs } from 'common'
import gql from 'graphql-tag'
import { useReduxSelector } from 'modules/store'
import React, { useCallback, useMemo, useState } from 'react'
import { ShopFinder } from './Adapters'
import { ContextByMCTable, StateByMCTable } from './mediaCenter.context'
import MediaCenterTable from './mediaCenter.table'
import { MediaCenterAlpha, MediaCenterTablePickColumnOpt } from './types'

const MUTATION_UPDATE_MC = gql`
  mutation UpdateMediaCenter($shopId: String!, $mac: String!, $id: String!) {
    mediaCenter: updateMediaCenter(shopId: $shopId, mac: $mac, id: $id) {
      id
      mac
      shopId
    }
  }
`

export type MediaCenterContainerProps = MediaCenterTablePickColumnOpt

const MediaCenterContainer = (props: MediaCenterContainerProps) => {
  const { ...pcikColumnOptions } = props
  const [updateMediaCenter, { loading }] = useMutation<
    { mediaCenter: MediaCenter },
    MutationUpdateMediaCenterArgs
  >(MUTATION_UPDATE_MC)

  const [form] = Form.useForm()
  const [search, setSearch] = useState<StateByMCTable['search']>([])
  const [editRow, setEditRow] = useState<StateByMCTable['editRow']>(undefined)
  const shopList = useReduxSelector(state => state.shopList$)
  const shopFinder = useMemo(() => new ShopFinder(shopList), [shopList])

  const onEdit = useCallback(
    (next: MediaCenterAlpha) => {
      const { id, mac, shopId } = next
      updateMediaCenter({
        variables: { id, mac, shopId },
      })
    },
    [updateMediaCenter]
  )

  const onChange = useCallback(() => {
    setEditRow(undefined)
    form.resetFields()
  }, [form])

  return (
    <Form component={false} form={form}>
      <ContextByMCTable.Provider
        value={{
          search,
          setSearch,
          editRow,
          shopFinder,
          form,
          setEditRow,
          onEdit,
        }}
      >
        <MediaCenterTable
          onChange={onChange}
          loading={loading}
          {...pcikColumnOptions}
        />
      </ContextByMCTable.Provider>
    </Form>
  )
}

export default MediaCenterContainer
