import React from 'react'
import styled from 'styled-components'
import { Config } from 'common'
import { ReactMemoEx } from 'modules/view'

export type ThumbnailProps = {
  thumbnail?: string
} & React.HTMLAttributes<HTMLElement>

const Thumbnail = styled((props: ThumbnailProps) => {
  const { thumbnail, ...rest } = props
  return <div {...rest}>{thumbnail && <div className="thumbnail-cover" />}</div>
})`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ thumbnail }) =>
    thumbnail
      ? `
  background: radial-gradient(circle,rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%), url('${Config.httpMedia}${thumbnail}');
`
      : `
      background:rgba(0,0,0,0.35);
      &::before { content: 'Not Found Image'; font-weight: bold; color:rgba(255,255,255,0.7);};`}
  background-size: cover;
`

export default ReactMemoEx(Thumbnail)
