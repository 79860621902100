import React, { useMemo } from 'react'
import { ReactMemoEx } from 'modules/view'
import { Typography } from 'antd'
import { useReduxSelector } from 'modules/store'
import { groupBy } from 'lodash'
import { toStringFileSize } from 'common'
const { Paragraph } = Typography

type SizeItem = {
  id: string
  title: string
  count: number
  size: string
}
export const MediaSizeUL = ReactMemoEx(() => {
  const [
    manufacturerList,
    mediaList,
  ] = useReduxSelector(({ manufacturerList, mediaList }) => [
    manufacturerList,
    mediaList,
  ])

  const data = useMemo(() => {
    const mediaMap = groupBy(mediaList, 'manufacturerId')
    return manufacturerList.map(
      ({ title, id }): SizeItem => {
        const medias = mediaMap[id] || []
        const size = medias.reduce((totalSize, { size }) => totalSize + size, 0)
        return {
          id,
          title,
          count: medias.length,
          size: toStringFileSize(size),
        }
      }
    )
  }, [manufacturerList, mediaList])

  return (
    <Paragraph>
      <ul>
        {data.map(item => {
          return (
            <li
              key={item.id}
            >{`${item.title}: ${item.size}(${item.count})`}</li>
          )
        })}
      </ul>
    </Paragraph>
  )
})
