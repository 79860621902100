import { FkGroupAndSalechannel, Group } from 'common'
import { ApplicationState, Manufacturer$ } from 'modules/store'
import { DataNode } from 'rc-tree/lib/interface'
import React from 'react'
import {
  TreeTitleGroup,
  TreeTitleSaleChannel,
  TreeTitleShop,
} from './ContextTitle'
import { toTreeKey } from './util'
import { HighlighterText } from 'components/src/HighlighterText'

export const selectorShop = (
  state: ApplicationState,
  fkGS: FkGroupAndSalechannel,
  parentKey: string,
  search?: string
): DataNode[] => {
  const { id: fkGSId } = fkGS
  const { shopList, fkGSShopList } = state
  return fkGSShopList.reduce((list, fkGSS) => {
    const { id, shopId, fkGSId: fkId } = fkGSS
    if (fkGSId !== fkId) {
      return list
    }
    const shop = shopList.find(({ id: sId }) => sId === shopId)
    if (shop) {
      const key = `${parentKey}-${toTreeKey(id)}`
      if (search === undefined) {
        list.push({
          key,
          title() {
            return (
              <TreeTitleShop fkGSS={fkGSS} shop={shop}>
                {shop.title}
              </TreeTitleShop>
            )
          },
        })
      } else if (shop.title.indexOf(search) >= 0) {
        list.push({
          key,
          title() {
            return (
              <TreeTitleShop fkGSS={fkGSS} shop={shop}>
                <HighlighterText searchWords={search}>
                  {shop.title}
                </HighlighterText>
              </TreeTitleShop>
            )
          },
        })
      }
    }
    return list
  }, [] as DataNode[])
}

/**
 * Tree 판매채널..
 * @param state
 * @param groupId
 * @param parentKey
 */
export const selectorSaleChannel = (
  state: ApplicationState,
  group: Group,
  parentKey: string,
  search?: string
): DataNode[] => {
  const { id: groupId } = group
  const { saleChannelList, fkGSList } = state
  return fkGSList.reduce((list, fkGS) => {
    const { saleChannelId, id: fkGSId, groupId: itemGroupId } = fkGS
    if (itemGroupId !== groupId) {
      return list
    }
    const saleChannel = saleChannelList.find(({ id }) => id === saleChannelId)
    if (saleChannel) {
      const key = `${parentKey}-${toTreeKey(fkGSId)}`
      if (search === undefined) {
        list.push({
          key,
          title() {
            return (
              <TreeTitleSaleChannel fkGS={fkGS} saleChannel={saleChannel}>
                {saleChannel.title}
              </TreeTitleSaleChannel>
            )
          },
          children: selectorShop(state, fkGS, key),
        })
      } else {
        const isEqual = saleChannel.title.indexOf(search) >= 0
        const children = selectorShop(state, fkGS, key, search)
        if (children.length >= 1 || isEqual) {
          const title = isEqual ? (
            <HighlighterText searchWords={search}>
              {saleChannel.title}
            </HighlighterText>
          ) : (
            saleChannel.title
          )

          list.push({
            key,
            title() {
              return (
                <TreeTitleSaleChannel fkGS={fkGS} saleChannel={saleChannel}>
                  {title}
                </TreeTitleSaleChannel>
              )
            },
            children,
          })
        }
      }
    }
    return list
  }, [] as DataNode[])
}

/**
 * Tree 에 Group 데이터를 그려준다.
 * @param state
 * @param manufacturerId
 */
export const selectorGroups = (
  state: ApplicationState,
  manufacturerId: Manufacturer$['id'],
  parentKey: string,
  search?: string
): DataNode[] => {
  const { groupList } = state

  return groupList.reduce((list, group) => {
    const { manufacturerId: mId } = group
    if (mId !== manufacturerId) {
      return list
    }
    const { id } = group
    const key = `${parentKey}-${toTreeKey(id)}`

    if (search === undefined) {
      list.push({
        key,
        children: selectorSaleChannel(state, group, key, search),
        title() {
          return (
            <TreeTitleGroup
              group={group}
              manufacturerId={manufacturerId}
              children={group.title}
            />
          )
        },
      })
    } else {
      const isEqual = group.title.indexOf(search) >= 0
      const children = selectorSaleChannel(state, group, key, search)
      if (children.length >= 1 || isEqual) {
        const title = isEqual ? (
          <HighlighterText searchWords={search}>{group.title}</HighlighterText>
        ) : (
          group.title
        )

        list.push({
          key,
          children: selectorSaleChannel(state, group, key, search),
          title() {
            return (
              <TreeTitleGroup
                group={group}
                manufacturerId={manufacturerId}
                children={title}
              />
            )
          },
        })
      }
    }
    return list
  }, [] as DataNode[])
}

/**
 * Tree에서 필요한 Manufacturer 데이터를 가져온다.
 * @param state
 */
export const selectorManufacturer = (search?: string) => (
  state: ApplicationState
): DataNode[] => {
  const { manufacturerList$ } = state
  const output: DataNode[] = manufacturerList$.map(
    (manufacturer, i): DataNode => {
      const { id } = manufacturer
      const key = toTreeKey(id)
      const nodeManufacturer: DataNode = {
        key: toTreeKey(id),
        title: manufacturer.title,
        children: selectorGroups(state, id, key, search),
      }
      return nodeManufacturer
    }
  )
  return output
}
