// import SplitPane from 'react-split-pane'
import { GqlApolloClient } from 'modules/graphql'
import { useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './App.css'
import PrivateApp from './PrivateApp'
import Splash, { SplashProps } from './Splash'

const App = ReactMemoEx(() => {
  const {
    admin: { isLogin },
    isLoadInitData,
  } = useReduxSelector(state => state.auth)
  const [gqlApolloClient, setGqlApolloClient] = useState<GqlApolloClient>()

  const isSplash = useMemo(() => (isLogin ? isLoadInitData !== true : true), [
    isLogin,
    isLoadInitData,
  ])

  useEffect(() => {})

  useEffect(() => {
    const isLoadStart = isLogin === true && isLoadInitData === false
    if (!isLoadStart) {
      return
    }
  }, [isLogin, isLoadInitData])

  const onCreateApolloClient: Required<
    SplashProps
  >['onCreateApolloClient'] = useCallback(app => {
    setGqlApolloClient(app)
  }, [])

  return useMemo(() => {
    if (isSplash || gqlApolloClient === undefined) {
      return <Splash onCreateApolloClient={onCreateApolloClient} />
    }
    return <PrivateApp client={gqlApolloClient.client} />
  }, [isSplash, onCreateApolloClient, gqlApolloClient])
})

export default App
