import { toEA } from 'common'
import { useReduxSelector } from 'modules/store'
import React, { useMemo } from 'react'
import { COLOR_PRIMARY, COLOR_SUB, toPercent } from './inLibs'
import TabChartContainer, { TabChartItemType } from './TabChartContainer'
import { ReactMemoEx } from 'modules/view'

const backgroundColor = [COLOR_PRIMARY, COLOR_SUB]

export const UpdateSaleChannel = ReactMemoEx(() => {
  const saleChannelList$ = useReduxSelector(
    ({ saleChannelList$ }) => saleChannelList$
  )
  const data = useMemo(() => {
    return saleChannelList$.map(
      (saleChannel): TabChartItemType => {
        const { id: key, title, tvTotalCount, totalMediaPercent } = saleChannel
        return {
          key,
          subTitle: toEA(tvTotalCount),
          value: toPercent(totalMediaPercent),
          title,
          datasets: [
            {
              data: [totalMediaPercent, 1 - totalMediaPercent],
              backgroundColor,
            },
          ],
        }
      }
    )
  }, [saleChannelList$])

  return <TabChartContainer data={data} />
})
