import { Form, Select } from 'antd'
import { ui } from 'common'
import { get } from 'lodash'
import { useReduxSelector } from 'modules/store'
import React, { useMemo } from 'react'
import { EditCellProps } from './EditCell.type'

export default function AmCodeEditCell<T = unknown>(props: EditCellProps<T>) {
  const { dataIndex, record } = props
  const amCodeList = useReduxSelector(({ amCodeList }) => amCodeList)

  const filterAMCodeList = useMemo(() => {
    const manufacturerId: string | undefined = get(
      record,
      'manufacturerId',
      undefined
    )
    if (manufacturerId) {
      return amCodeList.filter(
        ({ manufacturerId: mId }) => mId === manufacturerId
      )
    }
    return amCodeList
  }, [amCodeList, record])

  return (
    <Form.Item style={{ margin: 0 }} name={String(dataIndex)} required>
      <Select style={{ width: ui.ColSize.md }}>
        {filterAMCodeList.map(amCode => {
          return (
            <Select.Option
              key={amCode.id}
              value={amCode.id}
              children={amCode.code}
            />
          )
        })}
      </Select>
    </Form.Item>
  )
}
