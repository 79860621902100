import { useApolloClient } from '@apollo/react-hooks'
import { findDataById, PlayList, reorder } from 'common'
import produce from 'immer'
import { $AsyncActions } from 'modules/store'
import React, { useCallback } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import KanbanList from './KanbanList'
import { ReactMemoEx } from 'modules/view'
import { uniq } from 'lodash'

type ContainerProps = { innerCount?: number }

const Container = styled.div<ContainerProps>`
  display: flex;
  height: 100%;
  width: 100%;
  ${({ innerCount }) =>
    innerCount !== undefined && innerCount >= 1
      ? `min-width: calc(16rem * ${innerCount} + 1rem * ${Math.max(
          innerCount - 1,
          0
        )});`
      : ''}
`

type InnerPLEContainerProps = {
  plsList: PlayList[]
}

const InnerPLEContainer = (props: InnerPLEContainerProps) => {
  const { plsList } = props
  const gqlClient = useApolloClient()
  const dispatch = useDispatch()

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination } = result
      if (!destination) {
        return
      }

      if (source.droppableId === destination.droppableId) {
        // 서로 같은 droppabled
        const playList = findDataById(plsList, source.droppableId)
        if (playList === undefined) {
          return
        }
        const nextPlayList = produce(playList, draft => {
          const mediaIds = reorder(
            playList.mediaIds,
            source.index,
            destination.index
          )
          draft.mediaIds = mediaIds
        })
        dispatch($AsyncActions.updatePLS(gqlClient, nextPlayList))
      } else {
        const sourcePlayList = findDataById(plsList, source.droppableId)
        const destPlayList = findDataById(plsList, destination.droppableId)
        if (sourcePlayList === undefined || destPlayList === undefined) {
          return
        }

        const item = sourcePlayList.mediaIds[source.index]
        const nextSourcePlayList = produce(sourcePlayList, draft => {
          draft.mediaIds.splice(source.index, 1)
        })

        let nextDestPlayList = produce(destPlayList, draft => {
          draft.mediaIds.splice(destination.index, 0, item)
        })

        nextDestPlayList = produce(destPlayList, draft => {
          draft.mediaIds = uniq(nextDestPlayList.mediaIds)
        })

        dispatch(
          $AsyncActions.updatePLSMulti(gqlClient, [
            nextSourcePlayList,
            nextDestPlayList,
          ])
        )
      }
    },
    [plsList, gqlClient, dispatch]
  )

  const onUpdatePlayList = useCallback(
    (playList: PlayList) => {
      dispatch($AsyncActions.updatePLS(gqlClient, playList))
    },
    [dispatch, gqlClient]
  )

  return (
    <Container innerCount={plsList.length}>
      <DragDropContext onDragEnd={onDragEnd}>
        {plsList.map(playList => (
          <KanbanList
            playList={playList}
            key={playList.id}
            onUpdatePlayList={onUpdatePlayList}
          />
        ))}
      </DragDropContext>
    </Container>
  )
}

export default ReactMemoEx(InnerPLEContainer)
