const shadowBlack = '0, 0, 0'
const shadowColor = '64, 145, 247'
export const LiteHover = `
&:hover {
  -webkit-box-shadow: 0 1px 2px -2px rgba(${shadowBlack}, 0.16),
    0 3px 6px 0 rgba(${shadowBlack}, 0.06), 0 5px 12px 4px rgba(${shadowBlack}, 0.045);
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(${shadowBlack}, 0.06), 0 5px 12px 4px rgba(${shadowBlack}, 0.045);
}
&.drop-enter{
  &:hover{
    -webkit-box-shadow: 0 1px 2px -2px rgba(${shadowColor}, 0.16),
    0 3px 6px 0 rgba(${shadowColor}, 0.06), 0 5px 12px 4px rgba(${shadowColor}, 0.045);
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(${shadowColor}, 0.06), 0 5px 12px 4px rgba(${shadowColor}, 0.045);
  }
}
transition: all 0.5s;
`
