import { Layout } from 'antd'
import { ReactMemoEx } from 'modules/view'
import React from 'react'
import styled from 'styled-components'
import { HeaderGraph } from '../HeaderGraph'
import HeaderLogo from './HeaderLogo'
import NewButton from './NewButton'
import UserAvartar from './UserAvartar'
import { BrowserView, MobileView } from 'react-device-detect'

const { Header: OriginHeader } = Layout

const BrowserHeader = styled(OriginHeader)`
  background: rgb(255, 255, 255) !important;
  box-shadow: rgba(230, 230, 230, 0.25) 0px 2px 8px 0px;
  border-bottom: rgb(235, 235, 235) 1px solid;
  padding-left: 2rem;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
`

const MobileHeader = styled(OriginHeader)`
  background: rgb(255, 255, 255) !important;
  box-shadow: rgba(230, 230, 230, 0.25) 0px 2px 8px 0px;
  border-bottom: rgb(235, 235, 235) 1px solid;
  padding-left: 1rem;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
`

const Body = styled.div`
  flex: 1;
`

export const Header = ReactMemoEx(() => {
  return (
    <>
      <BrowserView>
        <BrowserHeader>
          <HeaderLogo />
          <Body />
          <HeaderGraph />
          <NewButton />
          <UserAvartar />
        </BrowserHeader>
      </BrowserView>
      <MobileView>
        <MobileHeader>
          <HeaderLogo />
          <Body />
          <NewButton />
          <UserAvartar />
        </MobileHeader>
      </MobileView>
    </>
  )
})
