import { SearchOutlined } from '@ant-design/icons'
import { ColumnFilterItem, FilterDropdownProps } from 'antd/lib/table/interface'
import { generateSort, ui, Language } from 'common'
import produce, { Draft } from 'immer'
import React from 'react'
import { COLUMN_KEY_REMOVE } from './Config'
import FilterSearch from './FilterSearch'
import HighlighterText from './HighlighterText'
import RemoveButton, { RemoveButtonProps } from './RemoveButton'
import { ColumnTypeProps, SearchState } from './util'

export type OnSearchEvents<T> = {
  onSearch?: (newState: SearchState<T>) => void
  onClear?: (newState: SearchState<T>) => void
}

type CustomOption<T> = {
  dataIndex: keyof T
  displayIndex: keyof T
}

/**
 * 검색이 가능한 Text 컬럼 생성
 * @param column
 * @param searchState
 * @param eventsSearch
 */
export function generateSearchColumn<T>(
  column: ColumnTypeProps<T>,
  searchState: SearchState<T>,
  eventsSearch?: OnSearchEvents<T>,
  customOption?: CustomOption<T>
): ColumnTypeProps<T> {
  const { onClear, onSearch } = eventsSearch || {}

  const { title, dataIndex, ...restColumn } = column
  if (dataIndex === undefined) {
    return column
  }
  let mDataIndex: keyof T = dataIndex
  let mDisplayIndex: keyof T = dataIndex

  if (customOption !== undefined) {
    mDataIndex = customOption.dataIndex
    mDisplayIndex = customOption.displayIndex
  }

  const findData = searchState.find(
    ({ searchedColumn }) => searchedColumn === mDataIndex
  )

  const searchWords = findData ? [String(findData.value)] : []

  return {
    ...restColumn,
    sorter: generateSort<T>(mDisplayIndex),
    sortDirections: ['descend', 'ascend'],
    title,
    dataIndex: mDataIndex,
    key: String(mDataIndex),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    filterDropdown: (filterDropdown: FilterDropdownProps) => (
      <FilterSearch<T>
        filterSearch={filterDropdown}
        dataIndex={dataIndex}
        handleSearch={se => {
          const value = se[0]
          if (onSearch) {
            const searchOption = produce(searchState, draft => {
              const sIndex = draft.findIndex(
                sOption => sOption.searchedColumn === mDataIndex
              )
              if (sIndex >= 0) {
                draft[sIndex].value = value
              } else {
                draft.push({
                  searchedColumn: mDataIndex as Draft<keyof T>,
                  value,
                })
              }
            })
            onSearch(searchOption)
          }
        }}
        onClear={() => {
          if (onClear) {
            const sIndex = searchState.findIndex(
              sOption => sOption.searchedColumn === mDataIndex
            )
            if (sIndex <= -1) {
              return
            }
            const searchOption = produce(searchState, draft => {
              draft.splice(sIndex, 1)
            })
            onClear(searchOption)
          }
        }}
      />
    ),
    onFilter: (value, record) =>
      String(record[mDisplayIndex])
        .toLowerCase()
        .includes(value.toString().toLowerCase()),
    render(_, record, index) {
      if (column.render === undefined) {
        return (
          <HighlighterText
            searchWords={searchWords}
            children={String(record[mDisplayIndex])}
          />
        )
      }
      return (
        <HighlighterText
          searchWords={searchWords}
          children={String(column.render(record[mDisplayIndex], record, index))}
        />
      )
    },
  }
}

/**
 * number sort Column
 * @param column
 */
export function generateNumberColumn<T>(
  column: ColumnTypeProps<T>
): ColumnTypeProps<T> {
  const { dataIndex } = column
  if (dataIndex === undefined) {
    return column
  }

  return {
    ...column,
    sorter: generateSort<T>(dataIndex),
    sortDirections: ['descend', 'ascend'],
  }
}

/**
 * 필터 컬럼 생성
 * @param column
 * @param filters
 */
export function generateFilterColumn<T>(
  column: ColumnTypeProps<T>,
  filters: Array<string | ColumnFilterItem>
): ColumnTypeProps<T> {
  const { dataIndex } = column
  if (dataIndex === undefined) {
    return column
  }

  return {
    ...column,
    filters: filters.map(item => {
      if (typeof item === 'string') {
        return { text: item, value: item }
      }
      return item
    }),
    onFilter: (filterValue: any, record: T) => {
      const data = String(record[dataIndex])
      return data === filterValue
    },
    sorter: generateSort<T>(dataIndex),
    sortDirections: ['descend', 'ascend'],
  }
}

/**
 * 삭제 버튼 생성
 * @param mutation
 */
export function genrateDeleteColumn<T extends { id: string }>(
  mutation: RemoveButtonProps['mutation']
) {
  const column = ui.createCol<T>(Language.BTN_DELETE, ui.ColSize.sm, {
    key: COLUMN_KEY_REMOVE,
    render(data: T) {
      return <RemoveButton id={data.id} mutation={mutation} />
    },
  })
  return column
}
