import { getOf, safeNum, TvInfoNum } from 'common'
import { ApplicationState } from '../state'
import { Group$ } from '../types'
import { Finders } from './inLib'

type LogCount = Omit<TvInfoNum, 'groupId'> & { count: number }

type MCCountMap = {
  [key: string]: LogCount
}

const adapterGroup = (
  state: ApplicationState,
  { findManufacturer }: Finders
): Group$[] => {
  const { groupList, mediaCenterList } = state
  const mMediaCenter: MCCountMap = {}

  mediaCenterList.forEach(mc => {
    // console.log('mc', mc)
    if (mc.tvInfoToNum) {
      mc.tvInfoToNum.forEach(infoToNum => {
        const {
          groupId,
          active,
          error,
          media,
          offline,
          mediaDone,
          mediaTotal,
        } = infoToNum
        const value = mMediaCenter[groupId]
        if (value === undefined) {
          mMediaCenter[groupId] = {
            active,
            error,
            media,
            offline,
            count: 1,
            mediaDone,
            mediaTotal,
          }
        } else {
          const {
            active,
            error,
            media,
            offline,
            mediaDone,
            mediaTotal,
          } = infoToNum
          value.active += active
          value.error += error
          value.media += media
          value.offline += offline
          value.mediaDone += mediaDone
          value.mediaTotal += mediaTotal
          value.count += 1
          mMediaCenter[groupId] = value
        }
      })
    }
  })

  return groupList.map(group => {
    const { id } = group
    const tvInfoToNum = mMediaCenter[id] || {
      active: 0,
      error: 0,
      media: 0,
      offline: 0,
    }

    const tvTotalCount =
      tvInfoToNum.active + tvInfoToNum.error + tvInfoToNum.offline
    const tvActiveCount = tvInfoToNum.active
    const tvErrorCount = tvInfoToNum.error
    const tvOfflineCount = tvInfoToNum.offline

    const manufacturer = findManufacturer(group.manufacturerId)
    // group.
    return {
      ...group,
      mediaSize: 0,
      mediaCount: 0,
      shopCount: 0,
      tvTotalCount,
      tvActiveCount,
      tvErrorCount,
      tvOfflineCount,
      tvActivePercent: safeNum(tvActiveCount / tvTotalCount),
      totalMediaPercent: safeNum(
        tvInfoToNum.mediaDone / tvInfoToNum.mediaTotal
      ),
      manufacturerTitle: manufacturer ? getOf(manufacturer, 'title', '') : '',
      isNoneData: false,
    }
  })
}

export default adapterGroup
