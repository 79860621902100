import { PanelIndex } from './types'

const getLocation = () => {
  const isDev = process.env.NODE_ENV === 'development'
  const location = window.location
  return `${location.protocol}//${location.hostname}:${
    isDev ? '8080' : location.port
  }`
}

const http = getLocation()

export const Config = {
  http,
  httpMedia: `${http}/media`,
  httpGraphql: `${http}/graphql`,
  mediaUploadAccept: ['video/*', '.ts', '.lg'],
  googleMapKey: 'AIzaSyAZbxhiUe6Snw5AQZfKPtqr5RYCjR1JrDc',
  defaultCenter: { lat: 37.34, lng: 126.58 },
  defaultPanelIndex: PanelIndex.Dashboard,
}
