import { CountryCode } from 'common'
import { reducerDatabase, actionsDatabase } from '../util'

type Base = CountryCode
export type State = Base[]

export enum ActionTypes {
  ADD = '@@countryCodeList/ADD',
  ADDS = '@@countryCodeList/ADDS',
  REMOVE = '@@countryCodeList/REMOVE',
  REMOVES = '@@countryCodeList/REMOVES',
  SET_LIST = '@@countryCodeList/SET_LIST',
  UPDATE = '@@countryCodeList/UPDATE',
  UPDATES = '@@countryCodeList/UPDATES',
}

const actionDB = actionsDatabase<Base>(ActionTypes)

export const actions = {
  /**
   * CountryCode 추가
   */
  addCountryCode: actionDB.add,
  addCountryCodes: actionDB.adds,

  /**
   * CountryCode 삭제
   */
  removeCountryCode: actionDB.remove,
  removeCountryCodes: actionDB.removes,
  /**
   * CountryCode List 셋팅
   */
  setCountryCodeList: actionDB.setList,
  /**
   * CountryCode 업데이트
   */
  updateCountryCode: actionDB.update,
  updateCountryCodes: actionDB.updates,
}

export const initialState: State = []

export const reducer = reducerDatabase(initialState, ActionTypes)
