import { Group } from 'common'
import { reducerDatabase, actionsDatabase } from '../util'

type Base = Group
export type State = Base[]

export enum ActionTypes {
  ADD = '@@groupList/ADD',
  ADDS = '@@groupList/ADDS',
  REMOVE = '@@groupList/REMOVE',
  REMOVES = '@@groupList/REMOVES',
  SET_LIST = '@@groupList/SET_LIST',
  UPDATE = '@@groupList/UPDATE',
  UPDATES = '@@groupList/UPDATES',
}

const actionDB = actionsDatabase<Base>(ActionTypes)

export const actions = {
  /**
   * Group 추가
   */
  addGroup: actionDB.add,
  addGroups: actionDB.adds,
  /**
   * Group 삭제
   */
  removeGroup: actionDB.remove,
  removeGroups: actionDB.removes,
  /**
   * Group List 셋팅
   */
  setGroupList: actionDB.setList,
  /**
   * Group 업데이트
   */
  updateGroup: actionDB.update,
  updateGroups: actionDB.updates,
}

export const initialState: State = []

export const reducer = reducerDatabase(initialState, ActionTypes)
