export const toTreeKey = (id: string): string => id.replace(/-/gi, '~')

export type ParsTreeKey =
  | {
      type: 'manufecturer'
      data: {
        manufacturerId: string
      }
    }
  | {
      type: 'group'
      data: {
        manufacturerId: string
        groupId: string
      }
    }
  | {
      type: 'fkGS'
      data: {
        manufacturerId: string
        groupId: string
        fkGSId: string
      }
    }
  | {
      type: 'fkGSS'
      data: {
        manufacturerId: string
        groupId: string
        fkGSId: string
        fkGSSId: string
      }
    }

export const getTreeKeys = (key: string): ParsTreeKey | undefined => {
  const idList = key.split('-').map(str => str.replace(/~/gi, '-'))
  const idLength = idList.length
  if (idLength === 0) {
    return undefined
  }
  if (idLength === 1) {
    return {
      type: 'manufecturer',
      data: {
        manufacturerId: idList[0],
      },
    }
  } else if (idLength === 2) {
    return {
      type: 'group',
      data: {
        manufacturerId: idList[0],
        groupId: idList[1],
      },
    }
  } else if (idLength === 3) {
    return {
      type: 'fkGS',
      data: {
        manufacturerId: idList[0],
        groupId: idList[1],
        fkGSId: idList[2],
      },
    }
  } else if (idLength === 4) {
    return {
      type: 'fkGSS',
      data: {
        manufacturerId: idList[0],
        groupId: idList[1],
        fkGSId: idList[2],
        fkGSSId: idList[3],
      },
    }
  }
  return undefined
}
