import React, { useMemo } from 'react'
import { Badge } from 'antd'
import { ReactMemoEx } from 'modules/view'

type ActiveRenderProps = React.HTMLAttributes<HTMLSpanElement> & {
  percent?: number
}

const ActiveRender: React.FC<ActiveRenderProps> = (props) => {
  const { children, percent = 0, ...rest } = props
  const status = useMemo(() => {
    if (percent >= 0.75) {
      return 'success'
    } else if (percent >= 0.5) {
      return 'processing'
    } else if (percent >= 0.25) {
      return 'warning'
    }
    return 'error'
  }, [percent])
  return (
    <span {...rest}>
      <Badge status={status} />
      {children}
    </span>
  )
}

export default ReactMemoEx(ActiveRender)
