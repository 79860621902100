import {
  AppstoreOutlined,
  BarChartOutlined,
  BulbOutlined,
  ClusterOutlined,
  CompassOutlined,
  ControlOutlined,
  DashboardOutlined,
  DeploymentUnitOutlined,
  ExclamationCircleOutlined,
  GlobalOutlined,
  HddOutlined,
  IdcardOutlined,
  PartitionOutlined,
  PlaySquareOutlined,
  ScheduleOutlined,
  ShopOutlined,
  UngroupOutlined,
} from '@ant-design/icons'
import { IconNames } from 'common'
import React, { useMemo } from 'react'

export type IconNavigationProps = {
  icon: IconNames
}

export default function IconNavigation(props: IconNavigationProps) {
  const { icon } = props

  return useMemo(() => {
    if (icon === 'group') {
      return <UngroupOutlined />
    }
    if (icon === 'tree') {
      return <PartitionOutlined />
    }
    if (icon === 'user') {
      return <IdcardOutlined />
    }
    if (icon === 'global') {
      return <GlobalOutlined />
    }
    if (icon === 'cluster') {
      return <ClusterOutlined />
    }
    if (icon === 'play') {
      return <PlaySquareOutlined />
    }
    if (icon === 'shop') {
      return <ShopOutlined />
    }
    if (icon === 'appstore') {
      return <AppstoreOutlined />
    }
    if (icon === 'bar-chart') {
      return <BarChartOutlined />
    }
    if (icon === 'bulb') {
      return <BulbOutlined />
    }
    if (icon === 'control') {
      return <ControlOutlined />
    }
    if (icon === 'dashboard') {
      return <DashboardOutlined />
    }
    if (icon === 'deployment-unit') {
      return <DeploymentUnitOutlined />
    }
    if (icon === 'exclamation-circle') {
      return <ExclamationCircleOutlined />
    }
    if (icon === 'hdd') {
      return <HddOutlined />
    }
    if (icon === 'schedule') {
      return <ScheduleOutlined />
    }
    if (icon === 'compass') {
      return <CompassOutlined />
    }
    return <></>
  }, [icon])
}
