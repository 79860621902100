import React, { useMemo } from 'react'
import Highlighter, { HighlighterProps } from 'react-highlight-words'

type HighlighterTextProps = Omit<HighlighterProps, 'searchWords'> & {
  children?: string
  searchWords?: string | string[]
}

const highlightStyle = { backgroundColor: '#ffc069', padding: 0 }

function HighlighterText(props: HighlighterTextProps) {
  const { children, searchWords = [] } = props
  const text = useMemo(() => {
    if (children === undefined) {
      return ''
    }
    return String(children)
  }, [children])

  const mSearchWords = useMemo(() => {
    if (typeof searchWords === 'string') {
      return [searchWords]
    }
    return searchWords
  }, [searchWords])

  return (
    <Highlighter
      highlightStyle={highlightStyle}
      searchWords={mSearchWords}
      autoEscape
      textToHighlight={text}
    />
  )
}

export default HighlighterText
