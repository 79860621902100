import { ColumnProps, ColumnType } from 'antd/lib/table'
import { Overwrite } from 'utility-types'
// ColumnsType<RecordType>;
export type ColumnTypeProps<T> = Overwrite<
  ColumnType<T>,
  {
    dataIndex?: keyof T
  }
>
/**
 * Column 의 사이즈
 * - sm: 96
 * - md: 128
 * - lg: 160
 */
export enum ColSize {
  xs = 80,
  sm = 96,
  md = 128,
  lg = 160,
  xl = 192,
  xxl = 224,
}

/**
 * 컬럼을 기본 셋팅을 해서 내보낸다.
 *
 * `align` 이 없을 경우 자동을 `center`로 설정된다.
 *
 * `ellipsis` 는 무조건 `true`로 설정한다.
 *
 * @param title
 * @param size
 * @param colProps
 */
export function createCol<T>(
  title: ColumnProps<T>['title'],
  size?: ColSize | string | number,
  colProps?: Omit<ColumnTypeProps<T>, 'title' | 'width' | 'ellipsis'>
): ColumnTypeProps<T> {
  const props = colProps ? colProps : {}
  return {
    ...props,
    title,
    width: size,
    align: props.align ? props.align : 'center',
    ellipsis: true,
  }
}
