import { action } from 'typesafe-actions'
import { ActionTypes, ThemeColors } from './types'
import { GeneratorModalIndex } from 'common'

export const actions = {
  setTheme: (theme: ThemeColors) => action(ActionTypes.SET_THEME, theme),

  setNaviWidth: (width: number) => action(ActionTypes.SET_NAVI_WIDTH, width),

  setToggleGeneratorModal: (modalIndex: GeneratorModalIndex) =>
    action(ActionTypes.SET_GENERATORMODAL_TOGGLE, modalIndex),

  setHideGeneratorModal: (modalIndex: GeneratorModalIndex) =>
    action(ActionTypes.SET_GENERATORMODAL_HIDE, modalIndex),
}
