import {
  generateSort,
  toStringFileSize,
  toTimeFormat,
  ui,
  Manufacturer,
} from 'common'
import { Media$ as DB } from 'modules/store'
import moment from 'moment'
import React from 'react'
import { COLUMN_KEY_REMOVE } from '../../../Config'
import { EditCellType } from '../../../EditTable'
import {
  generateFilterColumn,
  generateNumberColumn,
  generateSearchColumn,
} from '../../../GenerateColumn'
import { generatorPickEditColumns, PickColumnProps } from '../../../PickColumn'
import { SearchState } from '../../../util'
import RemoveButtonOnlyMedia from './RemoveButtonOnlyMedia'
import { isMobile } from 'react-device-detect'

type Options = {
  manufacturer?: Manufacturer
  manufacturerList?: string[]
} & PickColumnProps<DB>

const toFileCodec = (data: any) => {
  if (typeof data === 'string' && data !== 'null') {
    return data
  }
  return 'unknown'
}

export default function createColumns(
  searchState: SearchState<DB>,
  setSearchedColumn: React.Dispatch<React.SetStateAction<SearchState<DB>>>,
  options: Options
): EditCellType<DB>[] {
  const { manufacturerList = [], ...rest } = options

  const columns = generatorPickEditColumns(rest)
  columns.push('fileName', {
    fixed: isMobile ? undefined : 'left',
    ...generateSearchColumn<DB>(
      ui.createCol<DB>('파일명', ui.ColSize.xxl, {
        dataIndex: 'fileName',
        align: 'left',
      }),
      searchState,
      {
        onSearch: option => {
          setSearchedColumn(option)
        },
        onClear: option => {
          setSearchedColumn(option)
        },
      }
    ),
  })

  columns.push(
    'manufacturerTitle',
    generateFilterColumn<DB>(
      ui.createCol<DB>('제조사명', ui.ColSize.md, {
        dataIndex: 'manufacturerTitle',
        align: 'center',
      }),
      manufacturerList
    )
  )

  columns.push('videoCodec', {
    editType: 'string',
    ...generateSearchColumn<DB>(
      ui.createCol<DB>('비디오 코덱', ui.ColSize.md, {
        dataIndex: 'videoCodec',
        align: 'center',
        render: toFileCodec,
      }),
      searchState,
      {
        onSearch: option => {
          setSearchedColumn(option)
        },
        onClear: option => {
          setSearchedColumn(option)
        },
      }
    ),
  })
  columns.push('audioCodec', {
    editType: 'string',
    ...generateSearchColumn<DB>(
      ui.createCol<DB>('오디오 코덱', ui.ColSize.md, {
        dataIndex: 'audioCodec',
        align: 'center',
        render: toFileCodec,
      }),
      searchState,
      {
        onSearch: option => {
          setSearchedColumn(option)
        },
        onClear: option => {
          setSearchedColumn(option)
        },
      }
    ),
  })

  columns.push(
    'size',
    generateNumberColumn<DB>(
      ui.createCol<DB>('파일크기', ui.ColSize.md, {
        dataIndex: 'size',
        render: toStringFileSize,
      })
    )
  )
  columns.push('duration', {
    editType: 'time',
    ...generateNumberColumn<DB>(
      ui.createCol<DB>('재생시간', ui.ColSize.md, {
        dataIndex: 'duration',
        render: toTimeFormat,
      })
    ),
  })

  columns.push('licenseEnd', {
    editType: 'date',
    sorter: generateSort<DB>('licenseEnd'),
    ...ui.createCol<DB>('사용기간', ui.ColSize.md, {
      dataIndex: 'licenseEnd',
      align: 'center',
      render(licenseEnd) {
        if (licenseEnd === undefined || licenseEnd === null) {
          return ''
        }
        return moment(licenseEnd).format('YYYY/MM/DD')
      },
    }),
  })

  columns.push('createAt', {
    sorter: generateSort<DB>('createAt'),
    ...ui.createCol<DB>('등록일', ui.ColSize.md, {
      dataIndex: 'createAt',
      align: 'center',
      render(createAt) {
        if (createAt === undefined || createAt === null) {
          return ''
        }
        return moment(createAt).format('YYYY/MM/DD')
      },
    }),
  })

  columns.pushDelete({
    fixed: isMobile ? undefined : 'right',
    ...ui.createCol<DB>('삭제', ui.ColSize.sm, {
      key: COLUMN_KEY_REMOVE,
      render(data: DB) {
        return <RemoveButtonOnlyMedia id={data.id} />
      },
    }),
  })

  return columns.toColumns()
}
