import { CloudUploadOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { ReactMemoEx } from 'modules/view'
import React from 'react'

export type ButtonUploadProps = Pick<ButtonProps, 'onClick'>

const ButtonUpload = (props: ButtonUploadProps) => {
  const { onClick } = props
  return (
    <Tooltip title="미디어 업로드">
      <Button
        type="default"
        icon={<CloudUploadOutlined />}
        shape="circle"
        onClick={onClick}
        style={{ marginRight: '1rem' }}
      />
    </Tooltip>
  )
}

export default ReactMemoEx(ButtonUpload)
