import { ActionTypes, State } from './types'
import { actions } from './actions'
import { $GetActionType, withReducer } from 'modules/store/util'
import produce from 'immer'
import { toStatId } from 'common'

export const initialState: State = {}

export const reducer = withReducer<State, $GetActionType<typeof actions>>(
  initialState,
  (state = initialState, action) => {
    if (action.type === ActionTypes.ERROR_STATS) {
      const { standard, unit } = action.payload
      const id = toStatId(action.payload)
      const beforData = state[id]
      if (beforData && beforData.isError) {
        return state
      }
      return produce(state, draft => {
        if (beforData === undefined) {
          draft[id] = {
            loading: false,
            logList: [],
            standard,
            unit,
            updateAt: -1,
            isError: true,
          }
        } else {
          draft[id] = {
            ...beforData,
            loading: false,
            isError: true,
          }
        }
      })
    }
    if (action.type === ActionTypes.REQUEST_STATS) {
      const { standard, unit } = action.payload
      const id = toStatId(action.payload)
      const beforStat = state[id]
      if (beforStat && beforStat.loading) {
        return state
      }
      return produce(state, draft => {
        if (beforStat === undefined) {
          draft[id] = {
            loading: true,
            logList: [],
            standard,
            unit,
            updateAt: -1,
          }
        } else {
          draft[id] = {
            ...beforStat,
            loading: true,
            updateAt: Date.now(),
          }
        }
      })
    }
    if (action.type === ActionTypes.RESPONSE_STATS) {
      const { standard, unit, logList } = action.payload
      const id = toStatId(action.payload)
      return produce(state, draft => {
        draft[id] = {
          loading: false,
          logList,
          standard,
          unit,
          updateAt: Date.now(),
        }
      })
    }
    return state
  }
)
