import React, { useMemo } from 'react'
import { ReactMemoEx } from 'modules/view'
import { Manufacturer$, useReduxSelector } from 'modules/store'
import { Typography, Badge, Divider } from 'antd'
import numeral from 'numeral'
import styled from 'styled-components'
import './SaleChannelTootip.less'
import { toStringPercent } from 'common'
import { toStringFileSize } from 'Utils'

type SaleChannelChartProps = {
  manufacturer: Manufacturer$
}
const { Paragraph } = Typography
const SParagraph = styled(Paragraph)`
  color: white;
`

const SaleChannelTooltipUpdate = (props: SaleChannelChartProps) => {
  const { manufacturer } = props
  const color = useReduxSelector(state =>
    state.layout.theme === 'light' ? 'white' : 'black'
  )
  const saleChannelList$ = useReduxSelector(state => state.saleChannelList$)
  const { id } = manufacturer
  const saleChannelList = useMemo(
    () =>
      saleChannelList$.filter(({ manufacturerId }) => manufacturerId === id),
    [saleChannelList$, id]
  )

  return (
    <div className="salechannel-tooltip">
      <SParagraph style={{ color }}>
        <ul>
          <li>
            <Badge status="success" />
            <span className="label">TV 총 갯수:</span>
            <span className="value">
              {`${numeral(manufacturer.tvTotalCount)
                .format(',')
                .toString()}대`}
            </span>
          </li>
          <li>
            <Badge status="processing" />
            <span className="label">미디어:</span>
            <span className="value">
              {`${toStringFileSize(manufacturer.mediaSize)}(${
                manufacturer.mediaCount
              }개)`}
            </span>
          </li>
          <Divider style={{ margin: '0.25rem 0 0.25rem 0' }} />
          {saleChannelList
            .filter(({ isNoneData }) => !isNoneData)
            .map(({ id, title, totalMediaPercent, isNoneData }) => {
              return (
                <li key={id}>
                  <span className="label">{`${title}:`}</span>
                  <span className="value">
                    {toStringPercent(totalMediaPercent)}
                  </span>
                  <span>{isNoneData}</span>
                </li>
              )
            })}
        </ul>
      </SParagraph>
    </div>
  )
}

export default ReactMemoEx(SaleChannelTooltipUpdate)
