import { Media } from 'common'
import { reducerDatabase, actionsDatabase } from '../util'

type Base = Media
export type State = Base[]

export enum ActionTypes {
  ADD = '@@mediaList/ADD',
  ADDS = '@@mediaList/ADDS',
  REMOVE = '@@mediaList/REMOVE',
  REMOVES = '@@mediaList/REMOVES',
  SET_LIST = '@@mediaList/SET_LIST',
  UPDATE = '@@mediaList/UPDATE',
  UPDATES = '@@mediaList/UPDATES',
}

const actionDB = actionsDatabase<Base>(ActionTypes)

export const actions = {
  /**
   * Media 추가
   */
  addMedia: actionDB.add,
  addMedias: actionDB.adds,
  /**
   * Media 삭제
   */
  removeMedia: actionDB.remove,
  removeMedias: actionDB.removes,
  /**
   * Media List 셋팅
   */
  setMediaList: actionDB.setList,
  /**
   * Media 업데이트
   */
  updateMedia: actionDB.update,
  updateMedias: actionDB.updates,
}

export const initialState: State = []

export const reducer = reducerDatabase(initialState, ActionTypes)
