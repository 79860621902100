import { Layout } from 'antd'
import { SideNavigation } from 'components'
import { $Actions, useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import PanelGroup, { PanelGroupProp } from 'react-panelgroup'
import { useDispatch } from 'react-redux'
import { PanelRouter } from './src/PanelRouter'

const { Content } = Layout

const LayoutContentBrowser = ReactMemoEx(() => {
  const panelList = useReduxSelector(state => state.panelList)

  const dispatch = useDispatch()

  const onUpdatePanelWidth: Required<PanelGroupProp>['onUpdate'] = useCallback(
    sizes => {
      const [navi] = sizes
      if (navi && navi.size !== undefined) {
        dispatch($Actions.setNaviWidth(navi.size))
      }
    },
    [dispatch]
  )

  const onBackPanel = useCallback(
    (index: number) => () => {
      dispatch($Actions.removePanel(index))
    },
    [dispatch]
  )

  const drawPannel = useMemo(() => {
    const disableCloseBtn = panelList.length <= 1
    return panelList.map((panelIndex, i) => (
      <PanelRouter
        paenlIndex={panelIndex}
        key={i}
        disableCloseBtn={disableCloseBtn}
        onClose={onBackPanel(i)}
      />
    ))
  }, [panelList, onBackPanel])

  return (
    <Content>
      <PanelGroup
        onUpdate={onUpdatePanelWidth}
        borderColor="#E0E0E0"
        panelWidths={[
          { size: 256, minSize: 80, maxSize: 356, resize: 'dynamic' },
          { minSize: 320 },
          { minSize: 320 },
        ]}
      >
        <SideNavigation />
        {drawPannel}
      </PanelGroup>
    </Content>
  )
})

const LayoutContentMobile = ReactMemoEx(() => {
  const panelIndex = useReduxSelector(state => state.panelList[0])

  // <MobileView style={{ overflow: 'auto' }}>
  //   </MobileView>
  return (
    <Content>
      <PanelRouter paenlIndex={panelIndex} disableCloseBtn />
    </Content>
  )
})

export const LayoutContent = ReactMemoEx(() => {
  return <>{isMobile ? <LayoutContentMobile /> : <LayoutContentBrowser />}</>
})
