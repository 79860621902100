import numeral from 'numeral'

export const toComma = (n: any) => numeral(n).format('0,0')
export const toEA = (count: any) => `${numeral(count).format('0,0')} 대`

export const capitalize = (text: string) => {
  if (text.length <= 0) {
    return text
  }
  return text.charAt(0).toUpperCase() + text.slice(1)
}

const sufixes: string[] = [
  'Bytes',
  'KB',
  'MB',
  'GB',
  'TB',
  'PB',
  'EB',
  'ZB',
  'YB',
]

export const toStringFileSize = (
  bytes: number | string | undefined | null
): string => {
  if (bytes === undefined || bytes === null) {
    return '0Bytes'
  }
  if (typeof bytes === 'string') {
    const n = parseInt(bytes, 10)
    if (!isNaN(n)) {
      return toStringFileSize(n)
    }
    return '0Bytes'
  }
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return (
    (!bytes && '0Bytes') || (bytes / Math.pow(1024, i)).toFixed(0) + sufixes[i]
  )
}

export const parseTimeByTimeFormat = (time?: string) => {
  if (time === undefined) {
    return 0
  }
  let h = 0
  let m = 0
  let s = 0
  const split = time.split(':')
  if (split.length <= 3) {
    h = parseInt(split[0], 10) * 1000 * 60 * 60
    m = parseInt(split[1], 10) * 1000 * 60
    s = parseInt(split[2], 10) * 1000
  } else if (split.length <= 2) {
    m = parseInt(split[0], 10) * 1000 * 60
    s = parseInt(split[1], 10) * 1000
  } else if (split.length <= 1) {
    s = parseInt(split[0], 10) * 1000
  }

  return h + m + s
}

export const toTimeFormat = (time?: number | string): string => {
  if (time === undefined || time === null) {
    return '00:00:00'
  }
  if (typeof time === 'string') {
    const n = parseInt(time, 10)
    if (!isNaN(n)) {
      return toTimeFormat(n)
    }
  }
  const numTime = Number(time)

  let hr = Math.floor(numTime / 3600)
  let min = Math.floor((numTime - hr * 3600) / 60)
  let sec = Math.floor(numTime - hr * 3600 - min * 60)
  const output = `${hr < 10 ? `0${hr}` : hr}:${min < 10 ? `0${min}` : min}:${
    sec < 10 ? `0${sec}` : sec
  }`
  return output
}

export const toStringPercent = (data?: any) => numeral(data).format('0%')
