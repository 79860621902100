import { AdminEntity } from 'common'
import { reducerDatabase, actionsDatabase } from '../util'

type Base = AdminEntity
export type State = Base[]

export enum ActionTypes {
  ADD = '@@adminList/ADD',
  ADDS = '@@adminList/ADDS',
  REMOVE = '@@adminList/REMOVE',
  REMOVES = '@@adminList/REMOVES',
  SET_LIST = '@@adminList/SET_LIST',
  UPDATE = '@@adminList/UPDATE',
  UPDATES = '@@adminList/UPDATES',
}

const actionDB = actionsDatabase<Base>(ActionTypes)

export const actions = {
  /**
   * FKGS 추가
   */
  addAdmin: actionDB.add,
  addAdmins: actionDB.adds,
  /**
   * FKGS 삭제
   */
  removeAdmin: actionDB.remove,
  removeAdmins: actionDB.removes,
  /**
   * FKGS List 셋팅
   */
  setAdminList: actionDB.setList,
  /**
   * FKGS 업데이트
   */
  updateAdmin: actionDB.update,
  updateAdmins: actionDB.updates,
}

export const initialState: State = []

export const reducer = reducerDatabase(initialState, ActionTypes)
