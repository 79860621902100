import { Typography } from 'antd'
import { Config, toStringFileSize } from 'common'
import { ReactMemoEx } from 'modules/view'
import React, { CSSProperties, useMemo } from 'react'
import { createSelectable, TSelectableItemProps } from 'react-selectable-fast'
import { CardTypeMedia } from './types'

const { Paragraph } = Typography

type TAlbumProps = TSelectableItemProps & {
  media: CardTypeMedia
}

const Card: React.FC<TAlbumProps> = ReactMemoEx(props => {
  const { selectableRef, isSelected, isSelecting, media } = props
  const classNames = useMemo(
    () =>
      ['item', isSelecting && 'selecting', isSelected && 'selected']
        .filter(Boolean)
        .join(' '),
    [isSelected, isSelecting]
  )

  const style = useMemo((): CSSProperties => {
    if (media.thumbnailPath) {
      return {
        backgroundImage: `url("${Config.httpMedia}${media.thumbnailPath}")`,
      }
    }
    return {}
  }, [media.thumbnailPath])

  return (
    <div ref={selectableRef} className={classNames}>
      <div className="thumbnail" style={style} />
      <div className="infoContainer">
        <Paragraph ellipsis>{media.fileName}</Paragraph>
        <Paragraph ellipsis className="subinfo">
          {toStringFileSize(media.size)}
        </Paragraph>
      </div>
    </div>
  )
})

export default createSelectable(Card)
