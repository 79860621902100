import { action } from 'typesafe-actions'
import { ActionTypes, State } from './types'

export const actions = {
  /**
   * Group 추가
   */
  // addGroup: (group: Group) => action(ActionTypes.log, group),
  login: (adminData: State['admin']) => action(ActionTypes.LOGIN, adminData),
  logout: () => action(ActionTypes.LOGOUT),
}
