import { PlayList } from 'common'
import produce from 'immer'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { useContextSelector } from 'use-context-selector'
import { ActionByPLMTypes, ContextByPLM } from '../../StorePLM'
import EventReservation from './EventReservation'
import KanbanListItem from './KanbanListItem'
import KanbanTitle from './KanbanTitle'

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 16rem;
  border-radius: 0.1875rem;
  box-sizing: border-box;
  background: white;
  border: var(--color-border) 1px solid;
  overflow: hidden;
  & + & {
    margin-left: 1rem;
  }
`

const ListBody = styled.div<{ isDraggingOver?: boolean }>`
  flex: 1;
  overflow: auto;
  ${props =>
    Boolean(props.isDraggingOver) ? 'background: rgba(83,146,240,0.125)' : ''}
`

export type KanbanListProps = {
  playList: PlayList
  onUpdatePlayList: (playList: PlayList) => void
}

const KanbanList: React.FC<KanbanListProps> = props => {
  const dispatch = useContextSelector(ContextByPLM, state => state[1])
  const { playList, onUpdatePlayList } = props
  const { id, mediaIds } = playList

  const onClickExceptionMedia = useCallback(
    (mediaId: string) => {
      const index = playList.mediaIds.indexOf(mediaId)
      if (index <= -1) {
        return
      }
      onUpdatePlayList(
        produce(playList, draft => {
          if (index <= -1) {
            return
          }
          draft.mediaIds.splice(index, 1)
        })
      )
    },
    [playList, onUpdatePlayList]
  )

  const onClickInfoMedia = useCallback(
    (mediaId: string) => {
      dispatch({ type: ActionByPLMTypes.SHOW_MEDIAINFO, payload: mediaId })
    },
    [dispatch]
  )

  return (
    <ListContainer className="shadow-lite">
      <KanbanTitle playList={playList} />
      <Droppable droppableId={id}>
        {(provided, snapshot) => (
          <ListBody
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
          >
            <>
              {mediaIds.map((item, i) => (
                <KanbanListItem
                  key={item}
                  playListId={id}
                  mediaId={item}
                  index={i}
                  onClickExceptionMedia={onClickExceptionMedia}
                  onClickInfoMedia={onClickInfoMedia}
                />
              ))}
            </>
            {provided.placeholder}
          </ListBody>
        )}
      </Droppable>
      <EventReservation playList={playList} />
    </ListContainer>
  )
}

export default ReactMemoEx(KanbanList)
