import * as colors from '@ant-design/colors'
import { Divider, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

const { Paragraph } = Typography
type ActiveOfflineErrorProps = {
  active: number
  error: number
  offline: number
}

const GroupSubParagraph = styled(Paragraph)`
  margin-bottom: 0 !important;
  font-size: 0.5rem;
`
const SDivider = styled(Divider)`
  margin-left: 0.25rem;
  margin-right: 0.25rem;
`

const SapnActive = styled.span`
  color: ${colors.geekblue.primary};
`

const SapnError = styled.span`
  color: ${colors.red.primary};
`
const ActiveOfflineError = (props: ActiveOfflineErrorProps) => {
  const { active, offline, error } = props
  return (
    <GroupSubParagraph>
      <SapnActive>{active}</SapnActive>
      <SDivider type="vertical" />
      <span>{offline}</span>
      <SDivider type="vertical" />
      <SapnError>{error}</SapnError>
    </GroupSubParagraph>
  )
}

export default ActiveOfflineError
