export const MC_ERROR = '검토'
export const TOTAL_CONNECT_RATE = '전체 접속률'
export const ConnectStatistics = '접속 통계'
export const Daily = '일별'
export const Yearly = '연별'
export const Monthly = '월별'
export const ConnectRate = '접속률'
export const DisConnectRate = '미접속률'
export const ErrorRate = '장애율'
export const Offline = '오프라인'
export const TITLE_DISCONNECT = '비접속 상세'
export const Connected = '접속'
export const Active = '활성'
export const DeviceEA = '대'
export const TVTotalCount = 'TV 총 갯수'
export const BTN_SAVE = '저장'
export const BTN_CANCEL = '취소'
export const BTN_EDIT = '수정'
export const Mac = 'Mac'
export const MacAddress = `${Mac} Address`
export const Manufacturer = '제조사'
export const SaleChannel = '판매채널'
export const Country = '지역'
export const Shop = '지점'
export const BTN_DELETE = '삭제'
export const Group = '그룹'
export const Update = '업데이트'
export const LastUpdate = `최종 ${Update}`
export const Status = '상태'
export const AMCode = 'AM 코드'
export const CountryCode = `${Country} 코드`
export const UpdateProgress = `${Update} 진행`
export const UpdateStatus = `${Update} 현황`
export const FileSize = '파일 크기'
export const MediaCount = '콘텐츠(파일) 갯수'
export const ShopCode = '매장코드'
export const ShopTitle = `${Shop}명`

export const REQUIRED_INPUT_0 = '을 입력하세요.'
export const REQUIRED_INPUT_1 = '를 입력하세요.'
export const REQUIRED_SELECT_0 = '을 선택하세요.'
export const REQUIRED_SELECT_1 = '를 선택하세요.'

export const InputRules = {
  notMacAddress: `${Mac} 형식이 유효하지 않습니다.`,
  requiredMacAddress: `${MacAddress}${REQUIRED_INPUT_1}`,
  fnMessageLengthByShopCode: (num: number) =>
    `${ShopCode}는 숫자 ${num}자리로 입력하세요.`,
}

const duplicateTitleMessage = (text: string) => `동일한 ${text}명이 존재합니다.`

export const ErrorMessages = {
  duplicateGroupTitle: duplicateTitleMessage(Group),
  duplicateCountryCodeTitle: duplicateTitleMessage(CountryCode),
  duplicateSaleChannelTitle: duplicateTitleMessage(SaleChannel),
}

export const GenratorModalText = {
  messages: {
    select: {
      manufacturer: `${Manufacturer}${REQUIRED_SELECT_1}`,
      amCode: `${AMCode}${REQUIRED_SELECT_1}`,
    },
    input: {
      countyCode: `${CountryCode}${REQUIRED_SELECT_1}`,
    },
    placeholder: {
      countyCode: `${CountryCode}${REQUIRED_INPUT_1}`,
    },
  },
  titles: (title: string) => {
    const MODAL_TITLE = `${title} 생성`

    return {
      MODAL_TITLE,
      PERMISSION_DENIED: `${MODAL_TITLE}(사용권한 없음)`,
      TYPE_TITLE: title,
      ERROR_TITLE: `${title} 실패`,
      toNotiTitle: (text: string) => `${MODAL_TITLE} 완료(${text})`,
    }
  },
}
