export const safeNum = (n: any): number => {
  if (typeof n === 'string') {
    return safeNum(parseInt(n, 10))
  }

  if (typeof n !== 'number') {
    return 0
  }

  if (isNaN(n)) {
    return 0
  }
  return n
}
