import { SubscriptionEntity } from 'common'
import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'
import queryFindOneMap, {
  FRAG_GROUP,
  FRAG_MEDIA,
  FRAG_PLAYLIST,
  FRAG_MEDIACENTER,
  FRAG_FKGS,
  FRAG_FKGSS,
  FRAG_SALECHANNEL,
  FRAG_SHOP,
  FRAG_COUNTRY_CODE,
  FRAG_MANUFACTURER,
  FRAG_ADMIN,
  FRAG_EVENT,
  FRAG_AMCODE,
} from './queryFindOneMap'

const cacheDocumentNodes = new Map<string, DocumentNode>()

const findOne = (entity: SubscriptionEntity) => {
  return queryFindOneMap.get(entity)!
}

const removeOneById = (entity: SubscriptionEntity) => {
  const key = `removeOne${entity}`
  const q = cacheDocumentNodes.get(key)
  if (q) {
    return q
  }
  const query = gql`
    mutation Remove($id: String!) {
      isRemove: remove${entity}(id: $id)
    }
  `
  cacheDocumentNodes.set(key, query)
  return query
}

const queryLoadInitData = gql`
  query LoadInitData {
    groupList: findGroup {
      ${FRAG_GROUP}
    }
    mediaCenterList: findMediaCenter {
      ${FRAG_MEDIACENTER}
    }
    mediaList: findMedia {
      ${FRAG_MEDIA}
    }
    plsList: findPlayList {
      ${FRAG_PLAYLIST}
    }
    fkGSList: findFKGS {
      ${FRAG_FKGS}
    }
    fkGSShopList: findFKGSS {
      ${FRAG_FKGSS}
    }
    manufacturerList: findManufacturer {
      ${FRAG_MANUFACTURER}
    }
    saleChannelList: findSaleChannel {
      ${FRAG_SALECHANNEL}
    }
    countryCodeList: findCountryCode {
      ${FRAG_COUNTRY_CODE}
    }
    shopList: findShop {
      ${FRAG_SHOP}
    }
    adminList: findAdmin {
      ${FRAG_ADMIN}
    }
    eventList: findEvent {
      ${FRAG_EVENT}
    }
    amCodeList: findAMCode {
      ${FRAG_AMCODE}
    }
  }
`

const findMediaCenter = gql`
query FindMediaCenter {
  mediaCenterList: findMediaCenter {
      ${FRAG_MEDIACENTER}
    }
}
`

export const QueryHelper = {
  subscription: {
    mediaCenterUpdate: gql`
      subscription OnSubsMCLog {
        onSubsMCLog {
          topic
        }
      }
    `,
    dynamicEntity: gql`
      subscription OnSubsEntity($entity: SubscriptionEntity!) {
        onSubsEntity(entity: $entity) {
          id
          topic
        }
      }
    `,
  },
  findOne: findOne,
  findMediaCenter,
  removeOneById,
  queryLoadInitData,
}
