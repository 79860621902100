import React, { useMemo, CSSProperties } from 'react'
import { ReactMemoEx } from 'modules/view'
import styled from 'styled-components'
import { get } from 'lodash'
import { EditOutlined } from '@ant-design/icons'

const ThumbnailDiv = styled.div`
  min-width: 15rem;
  width: 17.5rem;
  position: relative;
  background-color: gray;
  padding-top: 56.25%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 0.125rem;
  border: 1px solid var(--color-border) ;
`

const IconAdd = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  background-size: cover;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
  transition: all 0.5s;
`

export type ImageThumbnailProps = {
  image?: string | File
}

const ImageThumbnail = (props: ImageThumbnailProps) => {
  const { image } = props

  const style = useMemo((): CSSProperties => {
    if (image === undefined) {
      return {}
    }
    if (typeof image === 'string') {
      return { backgroundImage: `url("${image}")` }
    }
    const url = get(image, 'path', undefined) as string | undefined
    if (url === undefined) {
      return {}
    }
    return { backgroundImage: `url("${url}")` }
  }, [image])

  return (
    <ThumbnailDiv style={style}>
      <IconAdd>
        <EditOutlined
          style={{ color: '#fff', fontSize: '3rem', opacity: 0.8 }}
        ></EditOutlined>
      </IconAdd>
    </ThumbnailDiv>
  )
}

export default ReactMemoEx(ImageThumbnail)
