import { useState, useRef } from 'react'
import axios, { AxiosRequestConfig } from 'axios'

export type StateByAxios<T> = {
  loading: boolean
  data?: T
  error?: Error
}

type CallAxios<T> = (
  axiosConfig: AxiosRequestConfig
) => Promise<StateByAxios<T>>

function callAxios<T>(
  setState: React.Dispatch<React.SetStateAction<StateByAxios<T>>>
): CallAxios<T> {
  return async (axiosConfig: AxiosRequestConfig): Promise<StateByAxios<T>> => {
    setState({ loading: true })
    let state: StateByAxios<T> = { loading: false }
    try {
      const { data } = await axios.request<T>(axiosConfig)
      state.data = data
      setState(state)
      return state
    } catch (e) {
      state.error = e
      setState(state)
      return state
    }
  }
}

export function useAxios<T>(): [CallAxios<T>, StateByAxios<T>] {
  const [state, setState] = useState<StateByAxios<T>>({ loading: false })
  const requestAxios = useRef(callAxios<T>(setState))
  return [requestAxios.current, state]
}
