import { ui, generateSort, SubscriptionEntity } from 'common'
import { Shop$ as DB } from 'modules/store'
import React from 'react'
import { EditCellType } from '../../../EditTable'
import {
  generateSearchColumn,
  genrateDeleteColumn,
} from '../../../GenerateColumn'
import { SearchState } from '../../../util'
import { QueryHelper } from 'modules/graphql'
// import {} from
// const setSearchedColumn:

export default function createColumns(
  searchState: SearchState<DB>,
  setSearchedColumn: React.Dispatch<React.SetStateAction<SearchState<DB>>>
): EditCellType<DB>[] {
  const output: EditCellType<DB>[] = []

  output.push(
    {
      editType: 'shopTitle',
      ...generateSearchColumn<DB>(
        ui.createCol<DB>('지점명', ui.ColSize.xxl, {
          dataIndex: 'title',
          align: 'left',
        }),
        searchState,
        {
          onSearch: option => {
            setSearchedColumn(option)
          },
          onClear: option => {
            setSearchedColumn(option)
          },
        }
      ),
    },
    {
      editType: 'shopCode',
      ...generateSearchColumn<DB>(
        ui.createCol<DB>('매장코드', ui.ColSize.lg, {
          dataIndex: 'shopCode',
          align: 'center',
          render(shopCode?: string) {
            if (shopCode === undefined || shopCode === null) {
              return ''
            }
            return shopCode
          },
        }),
        searchState,
        {
          onSearch: option => {
            setSearchedColumn(option)
          },
          onClear: option => {
            setSearchedColumn(option)
          },
        }
      ),
    },
    {
      ...ui.createCol<DB>('판매채널', ui.ColSize.xxl, {
        sorter: generateSort<DB>('saleChannelTitle'),
        dataIndex: 'saleChannelTitle',
        align: 'center',
      }),
    },
    {
      ...ui.createCol<DB>('제조사', ui.ColSize.xxl, {
        sorter: generateSort<DB>('manufacturerTitle'),
        dataIndex: 'manufacturerTitle',
        align: 'center',
      }),
    },
    {
      editType: 'country',
      ...generateSearchColumn<DB>(
        ui.createCol<DB>('지역코드', ui.ColSize.md, {
          dataIndex: 'countryCodeId',
          align: 'center',
        }),
        searchState,
        {
          onSearch: option => {
            setSearchedColumn(option)
          },
          onClear: option => {
            setSearchedColumn(option)
          },
        },
        { dataIndex: 'countryCodeId', displayIndex: 'countryTitle' }
      ),
    },
    genrateDeleteColumn(QueryHelper.removeOneById(SubscriptionEntity.Shop))
  )
  return output
}
