import React, { FC, useCallback } from 'react'
import { ReactMemoEx } from 'modules/view'
import { FkGasAndShop, Shop, MutationRemoveFkgssArgs } from 'common'
import { Dropdown, Menu, Spin } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'
import { red } from '@ant-design/colors'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
type TitleShopProps = {
  fkGSS: FkGasAndShop
  shop: Shop
}

const MUTATION_REMOVE = gql`
  mutation RemoveFKGSS($id: String!) {
    isRemove: removeFKGSS(id: $id)
  }
`
const ContextMenu = ReactMemoEx((props: TitleShopProps) => {
  const [removeFKGSS, { loading }] = useMutation<
    { isRemove: boolean },
    MutationRemoveFkgssArgs
  >(MUTATION_REMOVE)
  const { fkGSS } = props

  const onClick = useCallback(() => {
    removeFKGSS({ variables: { id: fkGSS.id } })
  }, [fkGSS.id, removeFKGSS])

  return (
    <Spin spinning={loading}>
      <Menu>
        <Menu.Item onClick={onClick}>
          <MinusCircleOutlined style={{ color: red.primary }} />
          예외 제거
        </Menu.Item>
      </Menu>
    </Spin>
  )
})

const ShopTitle: FC<TitleShopProps> = props => {
  const { children, ...rest } = props
  return (
    <>
      <Dropdown
        overlay={<ContextMenu {...rest} />}
        trigger={['contextMenu']}
        overlayClassName="dropdown-comp"
      >
        <div>{children}</div>
      </Dropdown>
    </>
  )
}

export default ReactMemoEx(ShopTitle)
