import {
  CountryCode,
  FindOutput,
  Group,
  Manufacturer,
  MediCenterAndLog,
  SaleChannel,
} from 'common'
import { Dictionary } from 'lodash'
import { TVTotalStatus } from '../types'

export const DEFAULT_TV_STATUS: TVTotalStatus = {
  tvActiveCount: 0,
  tvOfflineCount: 0,
  tvErrorCount: 0,
  tvGroupCount: {},
  tvTotalCount: 0,
  tvActivePercent: 0,
  totalMediaPercent: 0,
  isNoneData: true,
}

export type Finders = {
  findManufacturer: FindOutput<Manufacturer>
  findSaleChannel: FindOutput<SaleChannel>
  findCountryCode: FindOutput<CountryCode>
  groupByGroupId: Dictionary<Group['id'][]>
}

const safeNaN = (percent: number) => Math.max(isNaN(percent) ? 0 : percent, 0)

/**
 * TVTotalStatus List 의 평균/더한 값을 내보낸다.
 * @param tvStatus
 */
export const getTVStatusByList = (
  tvStatus: TVTotalStatus[],
  groupIds: string[]
): TVTotalStatus => {
  let isNoneData = true
  let tvActiveCount = 0
  let tvOfflineCount = 0
  let tvErrorCount = 0
  let totalMediaPercent = 0
  const tvGroupCount: { [key: string]: number } = {}

  const tvStatusFilterList = tvStatus.filter(({ isNoneData }) => !isNoneData)
  tvStatusFilterList.forEach(item => {
    if (isNoneData) {
      isNoneData = false
    }
    tvActiveCount += item.tvActiveCount
    tvOfflineCount += item.tvOfflineCount
    tvErrorCount += item.tvErrorCount
    totalMediaPercent += item.totalMediaPercent
    groupIds.forEach(gId => {
      if (tvGroupCount[gId] === undefined) {
        tvGroupCount[gId] = item.tvGroupCount[gId] || 0
      } else {
        tvGroupCount[gId] += item.tvGroupCount[gId] || 0
      }
    })
  })
  const tvTotalCount = tvActiveCount + tvErrorCount + tvOfflineCount
  const tvActivePercent = safeNaN(tvActiveCount / tvTotalCount)
  totalMediaPercent = safeNaN(totalMediaPercent / tvStatusFilterList.length)
  return {
    totalMediaPercent,
    tvActiveCount,
    tvActivePercent,
    tvErrorCount,
    tvGroupCount,
    tvOfflineCount,
    tvTotalCount,
    isNoneData,
  }
}

/**
 * MediaCenter 를 가지고 TVTotalStatus 를 만들어준다.
 * @param mc
 * @param group
 */
export const getTVStatusBYMediaCenter = (
  mc: MediCenterAndLog | undefined,
  group: Group[] = []
): TVTotalStatus => {
  if (mc === undefined) {
    return { ...DEFAULT_TV_STATUS }
  }
  let tvActiveCount = 0
  let tvErrorCount = 0
  let tvOfflineCount = 0
  // let totalMediaPercent = 0
  const tvGroupCount: { [key: string]: number } = {}

  // let groupCount = 0
  group.forEach(({ id }) => {
    if (mc.tvInfoToNum) {
      const log = mc.tvInfoToNum.find(({ groupId }) => groupId === id)
      if (log) {
        const { active, error, offline } = log
        const count = active + error + offline
        // groupCount += 1
        tvActiveCount += active
        tvErrorCount += error
        tvOfflineCount += offline
        if (tvGroupCount[id] === undefined) {
          tvGroupCount[id] = count
        } else {
          tvGroupCount[id] = tvGroupCount[id] + count
        }
      }
    }
  })

  const tvTotalCount = tvActiveCount + tvErrorCount + tvOfflineCount
  const percent = tvActiveCount / tvTotalCount
  return {
    tvActiveCount,
    tvErrorCount,
    tvOfflineCount,
    tvTotalCount,
    tvActivePercent: safeNaN(percent),
    tvGroupCount,
    totalMediaPercent: mc.updateRate || 0,
    isNoneData: false,
  }
}
