import { DownOutlined } from '@ant-design/icons'
import { Divider, Input, Tree } from 'antd'
import { SearchProps } from 'antd/lib/input'
import { useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import { TreeProps } from 'rc-tree'
import { EventDataNode } from 'rc-tree/lib/interface'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useContextSelector } from 'use-context-selector'
import { ActionByPLMTypes, ContextByPLM } from '../../StorePLM'
import { selectorManufacturer } from './selector'
import { getTreeKeys, ParsTreeKey } from './util'

const { Search } = Input

const SSearch = styled(Search)`
  margin-bottom: 0.5rem;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const Body = styled.div`
  flex: 1;
  position: relative;
`
const BodyInnder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
`

const SDivider = styled(Divider)`
  margin-top: 0;
  margin-bottom: 0.5rem;
`

export type PlayListTreeProps = {
  onClickTreeItem?: (id: string, treeKey: ParsTreeKey | undefined) => void
} & React.HtmlHTMLAttributes<HTMLDivElement>

export const PlayListTree = ReactMemoEx((props: PlayListTreeProps) => {
  const dispatch = useContextSelector(ContextByPLM, state => state[1])
  const { onClickTreeItem, ...rest } = props

  const [search, setSearch] = useState<string | undefined>()
  const state = useReduxSelector(state => state)

  const treeData = useMemo(() => selectorManufacturer(search)(state), [
    state,
    search,
  ])

  const onClick: Required<TreeProps>['onClick'] = useCallback(
    (_: React.MouseEvent, treeNode: EventDataNode) => {
      const { key } = treeNode
      // console.log('PlayListTree -> key', key)
      if (typeof key === 'string') {
        const treeResult = getTreeKeys(key)
        if (treeResult === undefined) {
          return
        }
        dispatch({ type: ActionByPLMTypes.SET_TREE, payload: treeResult.data })

        if (onClickTreeItem) {
          onClickTreeItem(key, treeResult)
        }
      }
    },
    [onClickTreeItem, dispatch]
  )

  const onChangeSearch: Required<SearchProps>['onChange'] = useCallback(e => {
    setSearch(e.target.value)
    const { value } = e.target
    const sValue = value.trim()
    if (sValue === '') {
      setSearch(undefined)
      return
    }
    setSearch(sValue)
  }, [])

  return (
    <Container {...rest}>
      <SSearch placeholder="Search" onChange={onChangeSearch} allowClear />
      <SDivider />
      <Body>
        <BodyInnder>
          <Tree
            showLine
            defaultExpandAll
            switcherIcon={<DownOutlined />}
            treeData={treeData}
            onClick={onClick}
          />
        </BodyInnder>
      </Body>
    </Container>
  )
})
