import * as ChartContentSize from './features/ChartContentSize'
import * as ChartMap from './features/ChartMap'
import * as ChartTotalSCConnect from './features/ChartTotalSCConntect'
import * as ChartTotalSCConnectRT from './features/ChartTotalSCCConnectRT'
import * as ChartUpdate from './features/ChartUpdate'
import * as ChartTypes from './features/charts.type'

export const Charts = {
  ...ChartContentSize,
  ...ChartMap,
  ...ChartTotalSCConnect,
  ...ChartTotalSCConnectRT,
  ...ChartUpdate,
  ...ChartTypes,
}
