import React, { useMemo } from 'react'
import { ReactMemoEx } from 'modules/view'
import { Manufacturer$, useReduxSelector } from 'modules/store'
import { Typography, Badge, Divider } from 'antd'
import numeral from 'numeral'
import styled from 'styled-components'
import './SaleChannelTootip.less'
import { toStringPercent, Language } from 'common'

type SaleChannelChartProps = {
  manufacturer: Manufacturer$
}
const { Paragraph } = Typography
const SParagraph = styled(Paragraph)`
  color: white;
`

const SaleChannelTooltipConnet = (props: SaleChannelChartProps) => {
  const { manufacturer } = props
  const color = useReduxSelector(state =>
    state.layout.theme === 'light' ? 'white' : 'black'
  )
  const saleChannelList$ = useReduxSelector(state => state.saleChannelList$)
  const { id } = manufacturer
  const saleChannelList = useMemo(
    () =>
      saleChannelList$.filter(({ manufacturerId }) => manufacturerId === id),
    [saleChannelList$, id]
  )

  return (
    <div className="salechannel-tooltip">
      <SParagraph style={{ color }}>
        <ul>
          <li>
            <Badge status="success" />
            <span className="label">{`${Language.TVTotalCount}:`}</span>
            <span className="value">
              {`${numeral(manufacturer.tvTotalCount)
                .format(',')
                .toString()}${Language.DeviceEA}`}
            </span>
          </li>
          <li>
            <Badge status="processing" />
            <span className="label">{`${Language.Active}:`}</span>
            <span className="value">
              {`${numeral(manufacturer.tvActiveCount)
                .format(',')
                .toString()}${Language.DeviceEA}`}
            </span>
          </li>
          <li>
            <Badge status="default" />
            <span className="label">{`${Language.Offline}:`}</span>
            <span className="value">
              {`${numeral(manufacturer.tvOfflineCount)
                .format(',')
                .toString()}${Language.DeviceEA}`}
            </span>
          </li>
          <li>
            <Badge status="error" />
            <span className="label">{`${Language.MC_ERROR}:`}</span>
            <span className="value">
              {`${numeral(manufacturer.tvErrorCount)
                .format(',')
                .toString()}${Language.DeviceEA}`}
            </span>
          </li>
          <Divider style={{ margin: '0.25rem 0 0.25rem 0' }} />
          {saleChannelList
            .filter(({ isNoneData }) => !isNoneData)
            .map(
              ({ id, title, tvActivePercent, tvErrorCount, tvTotalCount }) => {
                return (
                  <li key={id}>
                    <span className="label">{`${title}:`}</span>
                    <span className="value">
                      {`${toStringPercent(tvActivePercent)}(${toStringPercent(
                        tvErrorCount / tvTotalCount
                      )})`}
                    </span>
                  </li>
                )
              }
            )}
        </ul>
      </SParagraph>
    </div>
  )
}

export default ReactMemoEx(SaleChannelTooltipConnet)
