export enum ActionTypes {
  // LOADINITDATA = '@@auth/LOADINITDATA',
  LOGIN = '@@auth/LOGIN',
  LOGOUT = '@@auth/LOGOUT',
}

type Admin =
  | {
      isLogin: false
    }
  | {
      isLogin: true
      token?: string
      manufacturerId?: string
      displayName: string
    }

export type State = {
  admin: Admin
  isLoadInitData?: boolean
}
