import { Form, Select } from 'antd'
import { ReactMemoEx } from 'modules/view'
import React, { useMemo } from 'react'
import { useContextSelector } from 'use-context-selector'
import { ContextByMCTable } from './mediaCenter.context'
import SearchText from './SearchText'
import { EditCellDefaultProps } from './types'

const EditCellShop: React.FC<EditCellDefaultProps> = props => {
  const { originData, dataIndex } = props
  const { id, shopTitle } = originData
  const shopFinder = useContextSelector(
    ContextByMCTable,
    state => state.shopFinder
  )
  const editRow = useContextSelector(ContextByMCTable, state => state.editRow)

  const isEditCurrentRow = useMemo(() => {
    if (editRow === undefined) {
      return false
    }
    if (id !== editRow.id) {
      return false
    }
    return true
  }, [editRow, id])

  const Draw = useMemo(() => {
    return <SearchText searchKey="shopTitle">{shopTitle}</SearchText>
  }, [shopTitle])

  return useMemo(() => {
    if (!isEditCurrentRow) {
      return Draw
    }
    return (
      <Form.Item
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `지점을 선택하세요.`,
          },
        ]}
      >
        <Select
          showSearch
          filterOption={(input, option) => {
            if (option) {
              return (
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              )
            }
            return false
          }}
        >
          {shopFinder.shopList.map(({ id, title }) => {
            return <Select.Option value={id} key={id} children={title} />
          })}
        </Select>
      </Form.Item>
    )
  }, [isEditCurrentRow, Draw, dataIndex, shopFinder.shopList])
}

export default ReactMemoEx(EditCellShop)
