import { Statistic } from 'antd'
import styled from 'styled-components'

export default styled(Statistic)`
  .ant-statistic-title {
    font-size: 0.5rem;
    margin-bottom: 0;
  }
  .ant-statistic-content {
    font-size: 1rem;
    .ant-statistic-content-value {
      .ant-statistic-content-value-decimal {
        font-size: 0.75rem;
      }
    }
    .ant-statistic-content-suffix {
      font-size: 0.75rem;
    }
  }
`
