import { Col, Form, Input, Row, Select } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { useDidMount } from 'beautiful-react-hooks'
import {
  FieldData,
  GeneratorModalIndex,
  isErrorByNetwork,
  Language,
  MutationAddCountryCodeArgs,
} from 'common'
import { useMutationAddCountryCode } from 'modules/graphql'
import { isSystemUser, useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ContainerFormLayout from './ContainerFormLayout'
import EmptyContainer from './EmptyContainer'
import { isEqualTitle, notiError, notiErrorNetwork, notiSuccess } from './util'

const {
  MODAL_TITLE,
  ERROR_TITLE,
  PERMISSION_DENIED,
  toNotiTitle,
} = Language.GenratorModalText.titles(Language.CountryCode)
const NAME_AMCODE = 'amCodeId'
const NAME_TITLE = 'title'
const NAME_MANUFACTURER = 'manufacturerId'

const FormCountryCodeBody = () => {
  const [addCountryCode, { loading }] = useMutationAddCountryCode()
  const [form] = Form.useForm()
  const isMounted = useRef(false)

  const [manufacturerId, setManufacturerId] = useState<string | undefined>(
    undefined
  )
  const countryCodeList = useReduxSelector(
    ({ countryCodeList }) => countryCodeList
  )

  const amCodeList = useReduxSelector(({ amCodeList }) => amCodeList)
  const manufacturerList = useReduxSelector(
    ({ manufacturerList }) => manufacturerList
  )

  const filterCountryCode = useMemo(() => {
    if (manufacturerId === undefined) {
      return countryCodeList
    }
    return countryCodeList.filter(
      ({ manufacturerId: mId }) => mId === manufacturerId
    )
  }, [countryCodeList, manufacturerId])

  useDidMount(() => {
    isMounted.current = true
  })

  useEffect(() => {
    if (isMounted.current) {
      const amCodeId = String(form.getFieldValue(NAME_AMCODE))
      if (amCodeId !== 'undefined' && amCodeId !== 'null') {
        if (amCodeList.findIndex(({ id }) => id === amCodeId) <= -1) {
          form.resetFields([NAME_AMCODE])
        }
      }
    }
  }, [amCodeList, form])

  const filterAMCode = useMemo(() => {
    if (manufacturerId === undefined) {
      return amCodeList
    }
    return amCodeList.filter(
      ({ manufacturerId: mId }) => mId === manufacturerId
    )
  }, [amCodeList, manufacturerId])

  // 제조사 선택
  const onSelectManufacturer = useCallback(
    (value: SelectValue) => {
      const id = value.toString()
      if (manufacturerId !== id) {
        setManufacturerId(id)
        form.resetFields([NAME_AMCODE])
      }
    },
    [manufacturerId, form]
  )

  // amCode 선택
  const onSelectAMCode = useCallback(
    (value: SelectValue) => {
      const amCodeId = value.toString()
      const amCode = amCodeList.find(({ id }) => id === amCodeId)
      if (amCode) {
        if (manufacturerId !== amCode.manufacturerId) {
          setManufacturerId(amCode.manufacturerId)
          form.setFields([
            { name: NAME_MANUFACTURER, value: amCode.manufacturerId },
          ])
        }
      }
    },
    [manufacturerId, amCodeList, form]
  )

  const fields = useMemo(() => {
    return (
      <Row gutter={[32, 16]}>
        <Col xxl={8} xl={8} md={12} sm={24} xs={24}>
          <Form.Item
            name={NAME_MANUFACTURER}
            label={Language.Manufacturer}
            rules={[
              {
                required: true,
                message:
                  Language.GenratorModalText.messages.select.manufacturer,
              },
            ]}
          >
            <Select onChange={onSelectManufacturer}>
              {manufacturerList.map(({ id, title }) => (
                <Select.Option key={id} value={id} children={title} />
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xxl={8} xl={8} md={12} sm={24} xs={24}>
          <Form.Item
            name={NAME_AMCODE}
            label={Language.AMCode}
            rules={[
              {
                required: true,
                message: Language.GenratorModalText.messages.select.amCode,
              },
            ]}
          >
            <Select onChange={onSelectAMCode}>
              {filterAMCode.map(({ id, code }) => (
                <Select.Option key={id} value={id} children={code} />
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xxl={8} xl={8} md={12} sm={24} xs={24}>
          <Form.Item
            name={NAME_TITLE}
            label={Language.CountryCode}
            rules={[
              {
                required: true,
                message: Language.GenratorModalText.messages.input.countyCode,
              },
            ]}
          >
            <Input
              placeholder={
                Language.GenratorModalText.messages.placeholder.countyCode
              }
            />
          </Form.Item>
        </Col>
      </Row>
    )
  }, [manufacturerList, filterAMCode, onSelectAMCode, onSelectManufacturer])

  const onFinish = useCallback(async () => {
    const variables = form.getFieldsValue() as MutationAddCountryCodeArgs

    if (isEqualTitle(filterCountryCode, variables.title)) {
      const files: FieldData[] = [
        {
          name: NAME_TITLE,
          errors: [Language.ErrorMessages.duplicateCountryCodeTitle],
        },
      ]
      form.setFields(files)
      return
    }

    try {
      await addCountryCode({ variables })
      notiSuccess(toNotiTitle(variables.title))
      form.resetFields()
    } catch (e) {
      if (isErrorByNetwork(e)) {
        notiErrorNetwork(ERROR_TITLE)
      } else {
        notiError(ERROR_TITLE, String(e))
      }
    }
  }, [filterCountryCode, addCountryCode, form])

  return (
    <ContainerFormLayout
      modalIndex={GeneratorModalIndex.Shop}
      loading={loading}
      title={MODAL_TITLE}
      form={form}
      formChildren={fields}
      onFinish={onFinish}
    />
  )
}

const FormCountryCode = () => {
  const adminInfo = useReduxSelector(({ auth }) => auth.admin)

  return isSystemUser(adminInfo) ? (
    <FormCountryCodeBody />
  ) : (
    <EmptyContainer
      index={GeneratorModalIndex.Group}
      title={PERMISSION_DENIED}
    />
  )
}

export default ReactMemoEx(FormCountryCode)
