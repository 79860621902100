import { useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useEffect, useReducer, useRef } from 'react'
import styled from 'styled-components'
import {
  ActionByPLMTypes,
  initialStateByPLM,
  LiteCard,
  MediaInfoDrawer,
  PlayListTree,
  ProviderPLM,
  reducerByPLM,
} from '../features'
import PlayListManagerBody from './PlayListManagerBody'

const SContainer = styled.div`
  display: flex;
  height: calc(100% - 1rem);
  flex-direction: row;
  margin-bottom: 1rem;
`
const Container = ReactMemoEx(SContainer)

const Nav = styled(LiteCard)`
  /* width: 20rem; */
  min-width: 18rem;
  .ant-card-body {
    padding: 1rem;
    height: 100%;
  }
`

const Body = styled.div`
  flex: 1;
  display: flex;
  margin-left: 1rem;
  flex-direction: column;
`

const PlayListManager = () => {
  const refIsMount = useRef(false)
  const mediaList = useReduxSelector(state => state.mediaList)
  const groupList = useReduxSelector(state => state.groupList)
  const fkGSList = useReduxSelector(state => state.fkGSList)
  const fkGSSList = useReduxSelector(state => state.fkGSShopList)
  const manufacturerList = useReduxSelector(state => state.manufacturerList)

  const [state, disaptch] = useReducer(reducerByPLM, {
    ...initialStateByPLM,
    manufacturerId: '1bdd8b5d-2239-4d5e-8840-bcbd49efd53c',
  })
  useEffect(() => {
    refIsMount.current = true
  }, [])

  useEffect(() => {
    if (!refIsMount.current) {
      return
    }
    disaptch({
      type: ActionByPLMTypes.REDUX_UPDATE_MANUFACTURER,
      payload: manufacturerList,
    })
  }, [manufacturerList])

  useEffect(() => {
    if (!refIsMount.current) {
      return
    }
    disaptch({
      type: ActionByPLMTypes.REDUX_UPDATE_GROUP,
      payload: groupList,
    })
  }, [groupList])

  useEffect(() => {
    if (!refIsMount.current) {
      return
    }
    disaptch({
      type: ActionByPLMTypes.REDUX_UPDATE_FKGS,
      payload: fkGSList,
    })
  }, [fkGSList])

  useEffect(() => {
    if (!refIsMount.current) {
      return
    }
    disaptch({
      type: ActionByPLMTypes.REDUX_UPDATE_FKGSS,
      payload: fkGSSList,
    })
  }, [fkGSSList])

  useEffect(() => {
    if (!refIsMount.current) {
      return
    }
    disaptch({ type: ActionByPLMTypes.REDUX_UPDATE_MEDIA, payload: mediaList })
  }, [mediaList])

  return (
    <ProviderPLM value={[state, disaptch]}>
      <>
        <Container>
          <Nav bordered hoverable>
            <PlayListTree />
          </Nav>
          <Body>
            <PlayListManagerBody />
          </Body>
        </Container>
        <MediaInfoDrawer />
      </>
    </ProviderPLM>
  )
}

export default ReactMemoEx(PlayListManager)
