import { Shop } from 'common'
import { reducerDatabase, actionsDatabase } from '../util'

type Base = Shop
export type State = Base[]

export enum ActionTypes {
  ADD = '@@shopList/ADD',
  ADDS = '@@shopList/ADDS',
  REMOVE = '@@shopList/REMOVE',
  REMOVES = '@@shopList/REMOVES',
  SET_LIST = '@@shopList/SET_LIST',
  UPDATE = '@@shopList/UPDATE',
  UPDATES = '@@shopList/UPDATES',
}

const actionDB = actionsDatabase<Base>(ActionTypes)

export const actions = {
  /**
   * Shop 추가
   */
  addShop: actionDB.add,
  addShops: actionDB.adds,
  /**
   * Shop 삭제
   */
  removeShop: actionDB.remove,
  removeShops: actionDB.removes,
  /**
   * Shop List 셋팅
   */
  setShopList: actionDB.setList,
  /**
   * Shop 업데이트
   */
  updateShop: actionDB.update,
  updateShops: actionDB.updates,
}

export const initialState: State = []

export const reducer = reducerDatabase(initialState, ActionTypes)
