import { combineReducers } from 'redux'
//
import * as auth from './auth'
import * as panelList from './panelList'
import * as layout from './layout'
import * as stats from './stats'
//
import * as adminList from './adminList'
import * as amCodeList from './amCodeList'
import * as countryCodeList from './countryCodeList'
import * as fkGSList from './fkGSList'
import * as fkGSShopList from './fkGSShopList'
import * as groupList from './groupList'
import * as manufacturerList from './manufacturerList'
import * as mediaCenterList from './mediaCenterList'
import * as mediaList from './mediaList'
import * as plsList from './plsList'
import * as saleChannelList from './saleChannelList'
import * as shopList from './shopList'
import * as mediaUpload from './mediaUpload'
import * as eventList from './eventList'

export const INITIAL_APPLICATION_STATE = {
  auth: auth.initialState,
  layout: layout.initialState,
  panelList: panelList.initialState,
  stats: stats.initialState,
  //
  adminList: adminList.initialState,
  amCodeList: amCodeList.initialState,
  countryCodeList: countryCodeList.initialState,
  eventList: eventList.initialState,
  fkGSList: fkGSList.initialState,
  fkGSShopList: fkGSShopList.initialState,
  groupList: groupList.initialState,
  manufacturerList: manufacturerList.initialState,
  mediaCenterList: mediaCenterList.initialState,
  mediaList: mediaList.initialState,
  mediaUpload: mediaUpload.initialState,
  plsList: plsList.initialState,
  saleChannelList: saleChannelList.initialState,
  shopList: shopList.initialState,
}

export type ApplicationState = typeof INITIAL_APPLICATION_STATE

export type ApplicationStateDatabase = Pick<
  ApplicationState,
  | 'adminList'
  | 'amCodeList'
  | 'countryCodeList'
  | 'eventList'
  | 'fkGSList'
  | 'fkGSShopList'
  | 'groupList'
  | 'manufacturerList'
  | 'mediaCenterList'
  | 'mediaList'
  | 'plsList'
  | 'saleChannelList'
  | 'shopList'
>

export const combinedReducer = combineReducers({
  auth: auth.reducer,
  layout: layout.reducer,
  panelList: panelList.reducer,
  stats: stats.reducer,
  //
  adminList: adminList.reducer,
  amCodeList: amCodeList.reducer,
  countryCodeList: countryCodeList.reducer,
  eventList: eventList.reducer,
  fkGSList: fkGSList.reducer,
  fkGSShopList: fkGSShopList.reducer,
  groupList: groupList.reducer,
  manufacturerList: manufacturerList.reducer,
  mediaCenterList: mediaCenterList.reducer,
  mediaList: mediaList.reducer,
  plsList: plsList.reducer,
  saleChannelList: saleChannelList.reducer,
  shopList: shopList.reducer,
  //
  mediaUpload: mediaUpload.reducer,
})

/**
 * action type 모음
 */
export const ApplicationActionTypes = {
  auth: auth.ActionTypes,
  amCodeList: amCodeList.ActionTypes,
  stats: stats.ActionTypes,
  adminList: adminList.ActionTypes,
  countryCodeList: countryCodeList.ActionTypes,
  eventList: eventList.ActionTypes,
  fkGSList: fkGSList.ActionTypes,
  fkGSShopList: fkGSShopList.ActionTypes,
  groupList: groupList.ActionTypes,
  manufacturerList: manufacturerList.ActionTypes,
  mediaCenterList: mediaCenterList.ActionTypes,
  mediaList: mediaList.ActionTypes,
  plsList: plsList.ActionTypes,
  saleChannelList: saleChannelList.ActionTypes,
  shopList: shopList.ActionTypes,
  mediaUpload: mediaUpload.ActionTypes,
}

export const Actions = {
  ...auth.actions,
  ...layout.actions,
  ...panelList.actions,
  ...stats.actions,
  //
  ...adminList.actions,
  ...amCodeList.actions,
  ...countryCodeList.actions,
  ...eventList.actions,
  ...fkGSList.actions,
  ...fkGSShopList.actions,
  ...groupList.actions,
  ...manufacturerList.actions,
  ...mediaCenterList.actions,
  ...mediaList.actions,
  ...plsList.actions,
  ...saleChannelList.actions,
  ...shopList.actions,
  ...mediaUpload.actions,
}
