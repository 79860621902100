// import { GeocodingResult } from '@google/maps'
import { Col, Form, Input, Row, Select } from 'antd'
import {
  findDataById,
  GeneratorModalIndex,
  isErrorByNetwork,
  MutationAddShopArgs,
  inputRuleConfig,
} from 'common'
import { useMutationAddShop } from 'modules/graphql'
import { useReduxSelector } from 'modules/store'
import { MapData } from 'modules/useLibs'
import React, { useCallback, useMemo, useState } from 'react'
import { defaultColLargeProps, defaultColProps } from './Config'
import ContainerFormLayout from './ContainerFormLayout'
import SelectAddress from './SelectAddress'
import { notiError, notiErrorNetwork, notiSuccess } from './util'

const ERROR_TITLE = '지점 생성 실패'

const FormShop = () => {
  const [form] = Form.useForm()
  const admin = useReduxSelector(({ auth }) => auth.admin)
  const reduxSaleChannelList = useReduxSelector(
    ({ saleChannelList }) => saleChannelList
  )
  const reduxCountryCodeList = useReduxSelector(
    ({ countryCodeList }) => countryCodeList
  )
  const reduxShopList = useReduxSelector(({ shopList }) => shopList)

  const [addShop, { loading }] = useMutationAddShop()
  const [manufacturerId, setManufacturerId] = useState<string | undefined>()
  const [geocode, setGeocoding] = useState<MapData>()

  const onChangeSaleChannel = useCallback(
    (saleChannelId: string) => {
      const saleChannel = reduxSaleChannelList.find(
        ({ id }) => id === saleChannelId
      )
      if (saleChannel === undefined) {
        return
      }
      const countryCodeId = form.getFieldValue('countryCodeId') as
        | undefined
        | string

      if (countryCodeId) {
        const fieldCountry = findDataById(reduxCountryCodeList, countryCodeId)
        if (fieldCountry) {
          if (fieldCountry.manufacturerId !== manufacturerId) {
            form.setFields([{ name: 'countryCodeId', value: undefined }])
          }
        }
      }
      setManufacturerId(saleChannel.manufacturerId)
    },
    [form, reduxSaleChannelList, reduxCountryCodeList, manufacturerId]
  )

  const onChangeCountryCode = useCallback(
    (countryCodeId: string) => {
      const countryCode = reduxCountryCodeList.find(
        ({ id }) => id === countryCodeId
      )
      if (countryCode === undefined) {
        return
      }
      const { manufacturerId } = countryCode

      const saleChannelId = form.getFieldValue('saleChannelId') as
        | undefined
        | string

      if (saleChannelId) {
        const fieldSaleChannel = findDataById(
          reduxSaleChannelList,
          saleChannelId
        )
        if (fieldSaleChannel) {
          if (fieldSaleChannel.manufacturerId !== manufacturerId) {
            form.setFields([{ name: 'saleChannelId', value: undefined }])
          }
        }
      }
      setManufacturerId(countryCode.manufacturerId)
    },
    [form, reduxCountryCodeList, reduxSaleChannelList]
  )

  const saleChannelList = useMemo(() => {
    if (admin.isLogin && admin.manufacturerId) {
      const authManufacturerId = admin.manufacturerId
      return reduxSaleChannelList.filter(({ manufacturerId: mId }) => {
        return mId === authManufacturerId
      })
    }
    return reduxSaleChannelList
  }, [reduxSaleChannelList, admin])

  const countryCodeList = useMemo(() => {
    if (admin.isLogin && admin.manufacturerId) {
      const authManufacturerId = admin.manufacturerId
      return reduxCountryCodeList.filter(({ manufacturerId: mId }) => {
        return mId === authManufacturerId
      })
    }
    if (manufacturerId === undefined) {
      return reduxCountryCodeList
    }
    return reduxCountryCodeList.filter(({ manufacturerId: mId }) => {
      return mId === manufacturerId
    })
  }, [manufacturerId, reduxCountryCodeList, admin])

  const onChangeAddress = useCallback(
    (geocodingResult: MapData) => setGeocoding(geocodingResult),
    []
  )

  const fields = useMemo(() => {
    return (
      <Row gutter={[16, 16]}>
        <Col {...defaultColProps}>
          <Form.Item
            name={inputRuleConfig.saleChanel.name}
            label={inputRuleConfig.saleChanel.label}
            rules={inputRuleConfig.saleChanel.rules}
          >
            <Select onChange={onChangeSaleChannel}>
              {saleChannelList.map(({ id, title }) => (
                <Select.Option key={id} value={id} children={title} />
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col {...defaultColProps}>
          <Form.Item
            name={inputRuleConfig.countryCodeId.name}
            label={inputRuleConfig.countryCodeId.label}
          >
            <Select onChange={onChangeCountryCode}>
              {countryCodeList.map(({ id, title }) => (
                <Select.Option key={id} value={id} children={title} />
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col {...defaultColProps}>
          <Form.Item
            name={inputRuleConfig.shopCode.name}
            label={inputRuleConfig.shopCode.label}
            rules={inputRuleConfig.shopCode.rules}
          >
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col {...defaultColLargeProps}>
          <Form.Item
            name={inputRuleConfig.shopTitle.name}
            label={inputRuleConfig.shopTitle.label}
            rules={inputRuleConfig.shopTitle.rules}
          >
            <Input placeholder={inputRuleConfig.shopTitle.label} />
          </Form.Item>
        </Col>
        <Col {...defaultColLargeProps}>
          <SelectAddress
            name="address"
            label="주소"
            onChange={onChangeAddress}
          />
        </Col>
      </Row>
    )
  }, [
    countryCodeList,
    saleChannelList,
    onChangeSaleChannel,
    onChangeCountryCode,
    onChangeAddress,
  ])

  const onFinish = useCallback(async () => {
    const values = form.getFieldsValue() as MutationAddShopArgs
    if (geocode) {
      values.address = geocode.address
      values.lng = geocode.lng
      values.lat = geocode.lat
    }

    const { saleChannelId, title } = values

    // check -1 shopName
    if (
      reduxShopList.findIndex(
        ({ saleChannelId: scId, title: shopTitle }) =>
          saleChannelId === scId && shopTitle === title
      ) >= 0
    ) {
      form.setFields([
        { name: 'title', errors: ['동일한 지점명이 존재합니다.'] },
      ])
      return
    }

    // check -2 shopCode
    const shopCodeList = reduxShopList.reduce<string[]>((list, shop) => {
      const { shopCode } = shop
      if (shopCode) {
        list.push(shopCode)
      }
      return list
    }, [])

    if (values.shopCode && shopCodeList.indexOf(values.shopCode) >= 0) {
      form.setFields([
        { name: 'shopCode', errors: ['동일한 매장코드가 존재합니다.'] },
      ])
      return
    }

    // createShop
    try {
      await addShop({ variables: values })
      notiSuccess('지점 생성 완료')
      form.resetFields()
      setGeocoding(undefined)
    } catch (e) {
      if (isErrorByNetwork(e)) {
        notiErrorNetwork(ERROR_TITLE)
      } else {
        notiError(ERROR_TITLE, String(e))
      }
    }
  }, [geocode, form, addShop, reduxShopList])

  return (
    <ContainerFormLayout
      loading={loading}
      modalIndex={GeneratorModalIndex.Shop}
      title="지점 생성"
      form={form}
      formChildren={fields}
      onFinish={onFinish}
    />
  )
}

export default FormShop
