import { FilePdfTwoTone } from '@ant-design/icons'
import { Spin, Typography } from 'antd'
import classnames from 'classnames'
import { toStringFileSize } from 'common'
import React, { useCallback } from 'react'
import { createSelectable, TSelectableItemProps } from 'react-selectable-fast'
import { CardTypeUpload } from './types'
import { ReactMemoEx } from 'modules/view'
const { Paragraph } = Typography

type TAlbumProps = TSelectableItemProps & {
  media: CardTypeUpload
}

const CardUpload: React.FC<TAlbumProps> = ReactMemoEx((props: TAlbumProps) => {
  const { selectableRef, media } = props
  // not-selectable
  const onDoubleClick = useCallback(() => {
    console.log('doubleClick!')
  }, [])

  return (
    <Spin>
      <div
        ref={selectableRef}
        className={classnames('item', 'not-selectable')}
        onDoubleClick={onDoubleClick}
      >
        <div className="thumbnail">
          <FilePdfTwoTone style={{ fontSize: 48 }} />
        </div>
        <div className="infoContainer">
          <Paragraph ellipsis>{media.name}</Paragraph>
          <Paragraph ellipsis className="subinfo">
            {toStringFileSize(media.size)}
          </Paragraph>
        </div>
      </div>
    </Spin>
  )
})

export default createSelectable(CardUpload)
