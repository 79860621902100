import { ColumnType } from 'antd/lib/table'
import { omit } from 'lodash'

export type InputType =
  | 'string'
  | 'number'
  | 'country'
  | 'mc-mac'
  | 'date'
  | 'time'
  | 'shopCode'
  | 'shopTitle'
  | 'amCode'
// export type EditSelectValue =
//   | {
//       key: string
//       value: string | number
//       disabled?: boolean
//       title: string
//     }
//   | string

export type EditCellMode = {
  editable?: true
  editType?: InputType
  // select?: EditSelectValue[]
  isNullable?: boolean
}

export type EditCellColumnType<T> = ColumnType<T> & EditCellMode
export type EditCellType<T> = EditCellColumnType<T> & {
  children?: EditCellColumnType<T>[]
}

const OMIT_KEYS: Array<keyof EditCellMode> = ['editable', 'editType']

export function omitCellEdit<T>(col: EditCellType<T>): ColumnType<T> {
  return omit(col, OMIT_KEYS)
}
