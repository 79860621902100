import gql from 'graphql-tag'
import { SubscriptionEntity } from 'common'
import { DocumentNode } from 'graphql'

const QueryFindOneMap = new Map<SubscriptionEntity, DocumentNode>()

function setFindOneGQL(entity: SubscriptionEntity, frag: string) {
  const findOne = gql`
  query FindOne${entity}($id: String!) {
    ${entity}: findOne${entity}(id: $id) {
      ${frag}
    }
  }
`
  QueryFindOneMap.set(entity, findOne)
}

export const FRAG_MANUFACTURER = `
  id
  title
`
setFindOneGQL(SubscriptionEntity.Manufacturer, FRAG_MANUFACTURER)

// GROUP
export const FRAG_GROUP = `
  id
  title
  deviceRegexr
  manufacturerId
`
setFindOneGQL(SubscriptionEntity.Group, FRAG_GROUP)

// FKGS
export const FRAG_FKGS = `
  id
  groupId
  saleChannelId
`
setFindOneGQL(SubscriptionEntity.Fkgs, FRAG_FKGS)

// FKGSS
export const FRAG_FKGSS = `
  id
  fkGSId
  shopId
`
setFindOneGQL(SubscriptionEntity.Fkgss, FRAG_FKGSS)

// SaleChannel
export const FRAG_SALECHANNEL = `
  id
  title
  manufacturerId
`
setFindOneGQL(SubscriptionEntity.SaleChannel, FRAG_SALECHANNEL)

// Media
export const FRAG_MEDIA = `
  audioCodec
  bitRate
  duration
  fileName
  filePath
  height
  id
  manufacturerId
  parseResult
  size
  thumbnailPath
  videoCodec
  width
  createAt
  licenseEnd
`
setFindOneGQL(SubscriptionEntity.Media, FRAG_MEDIA)

// SHOP
export const FRAG_SHOP = `
  countryCodeId
  id
  lat
  lng
  saleChannelId
  shopCode
  title
`
setFindOneGQL(SubscriptionEntity.Shop, FRAG_SHOP)

// CountryCode
export const FRAG_COUNTRY_CODE = `
  id
  title
  manufacturerId
  amCodeId
`
setFindOneGQL(SubscriptionEntity.CountryCode, FRAG_COUNTRY_CODE)

// PlayList
export const FRAG_PLAYLIST = `
  id
  isParentBlock
  parentRole
  manufacturerId
  groupId
  fkGSId
  fkGSSId
  mediaIds
`
setFindOneGQL(SubscriptionEntity.PlayList, FRAG_PLAYLIST)

export const FRAG_MEDIACENTER = `
  id
  mac
  isOnline
  shopId
  updateAt
  tvInfoToNum {
    active
    error
    groupId
    media
    offline
    mediaDone
    mediaTotal
  }
  mediaDone
  mediaTotal
  updateRate
`
setFindOneGQL(SubscriptionEntity.MediaCenter, FRAG_MEDIACENTER)

export const FRAG_ADMIN = `
  id
  email
  userName
  phoneNumber
  adminLevel
  manufacturerId
  description
`
setFindOneGQL(SubscriptionEntity.Admin, FRAG_ADMIN)

export const FRAG_EVENT = `
  id
  startTime
  type
  isComplete
  createAt
  updateAt
  playListId
  mediaIds
`

setFindOneGQL(SubscriptionEntity.Event, FRAG_EVENT)

export default QueryFindOneMap

export const FRAG_AMCODE = `
  id
  code
  manufacturerId
`
setFindOneGQL(SubscriptionEntity.AmCode, FRAG_AMCODE)
