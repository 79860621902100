import gql from 'graphql-tag'
import { FRAG_MEDIA } from './queryFindOneMap'
import {
  SubscriptionEntity,
  MutationUpdateMediaArgs,
  Media,
  CountryCode,
  MutationUpdateCountryCodeArgs,
} from 'common'
import { useMutation } from '@apollo/react-hooks'

export const MUTATION_UPDATE_MEDIA = gql`
  mutation UpdateMedia(
    $licenseEnd: Timestamp,
    $videoCodec: String,
    $audioCodec: String,
    $duration: Float,
    $id: String!
  ) {
    ${SubscriptionEntity.Media}: updateMedia(
      licenseEnd: $licenseEnd,
      videoCodec: $videoCodec,
      audioCodec: $audioCodec,
      duration: $duration,
      id: $id
    ) {
      ${FRAG_MEDIA}
    }
  }
`
export type ResultUpdateMedia = {
  Media: Media
}

/**
 * MediaUpdate
 */
export const useMutationUpdateMedia = () =>
  useMutation<ResultUpdateMedia, MutationUpdateMediaArgs>(
    MUTATION_UPDATE_MEDIA,
    { fetchPolicy: 'no-cache' }
  )

export const MUTATION_UPDATE_COUNTRYCODE = gql`
  mutation UpdateCountryCode(
    $id: String!
    $title: String!
    $manufacturerId: String!
    $amCodeId: String
  ) {
    CountryCode: updateCountryCode(
      title: $title
      manufacturerId: $manufacturerId
      id: $id
      amCodeId: $amCodeId
    ) {
      id
      title
      amCodeId
      manufacturerId
    }
  }
`
export type ResultUpdateCountryCode = {
  CountryCode: CountryCode
}

export const useMutationUpdateCountryCode = () =>
  useMutation<ResultUpdateCountryCode, MutationUpdateCountryCodeArgs>(
    MUTATION_UPDATE_COUNTRYCODE,
    {
      fetchPolicy: 'no-cache',
    }
  )
