import { Modal, Timeline } from 'antd'
import { Event, PlayList } from 'common'
import { ReactMemoEx } from 'modules/view'
import React from 'react'
import TimeLineItem from './TimeLineItem'
import { useReduxSelector } from 'modules/store'
import { getPLSTitle } from '../../util'

type ModalCalendarProps = {
  playList: PlayList
  visible: boolean
  eventList: Event[]
  onCancel?: () => void
}

// const Ev
// TODO 개별 Media 추가

const ModalCalendar = (props: ModalCalendarProps) => {
  const { eventList, visible, onCancel, playList } = props

  const title = useReduxSelector(state => getPLSTitle(state, playList))

  return (
    <Modal visible={visible} onCancel={onCancel} title={title} footer={null}>
      {visible && (
        <Timeline
          style={{ maxHeight: 580, overflow: 'auto', paddingTop: '0.5rem' }}
        >
          {eventList.map(event => {
            const { id: eventId } = event
            return <TimeLineItem key={eventId} event={event} />
          })}
        </Timeline>
      )}
    </Modal>
  )
}

export default ReactMemoEx(ModalCalendar)
