import { Reducer, AnyAction } from 'redux'
import { $Values } from 'utility-types'
import produce, { Draft } from 'immer'
import { findIndexById } from 'common'
import isEqual from 'react-fast-compare'
import { get } from 'lodash'
import { action } from 'typesafe-actions'

type ActionCall = {
  [key: string]: (...args: any) => { type: any }
}

export type $GetActionType<T extends ActionCall> = ReturnType<$Values<T>>

export function withReducer<State, Action extends { type: any }>(
  initData: State,
  reducer: (state: State, action: Action) => State
): Reducer<State, AnyAction> {
  return function(state = initData, action) {
    const mAction = action as Action
    return reducer(state, mAction)
  }
}

type ActionTypes = {
  ADD: string
  ADDS: string
  UPDATE: string
  UPDATES: string
  REMOVE: string
  REMOVES: string
  SET_LIST: string
}

/**
 * Database 기반 reducer 개발
 * @param initialState
 * @param actionTypes
 */
export function reducerDatabase<T extends { id: string }>(
  initialState: T[],
  actionTypes: ActionTypes
) {
  return withReducer(initialState, (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.ADD:
      case actionTypes.UPDATE: {
        const payload = get(action, 'payload') as T
        return produce(state, draft => {
          const index = findIndexById(draft, payload.id)
          if (index >= 0) {
            const beforeData = draft[index]
            if (!isEqual(beforeData, payload)) {
              draft[index] = payload as Draft<T>
            }
            return
          }
          draft.push(payload as Draft<T>)
        })
      }
      case actionTypes.ADDS:
      case actionTypes.UPDATES: {
        const payload = get(action, 'payload') as T[]
        return produce(state, draft => {
          payload.forEach(newItem => {
            const index = findIndexById(draft, newItem.id)
            if (index >= 0) {
              const beforeData = draft[index]
              if (!isEqual(beforeData, payload)) {
                draft[index] = newItem as Draft<T>
              }
              return
            }
            draft.push(newItem as Draft<T>)
          })
        })
      }
      case actionTypes.REMOVE: {
        const payload = get(action, 'payload') as string
        return produce(state, draft => {
          const index = findIndexById(draft, payload)
          if (index >= 0) {
            draft.splice(index, 1)
          }
        })
      }
      case actionTypes.REMOVES: {
        const payload = get(action, 'payload') as string[]
        return produce(state, draft => {
          payload.forEach(removeItem => {
            const index = findIndexById(draft, removeItem)
            if (index >= 0) {
              draft.splice(index, 1)
            }
          })
        })
      }
      case actionTypes.SET_LIST: {
        const payload = get(action, 'payload') as T[]
        return [...payload]
      }
      default: {
        return state
      }
    }
  })
}

/**
 * action Database를 만들어준다.
 * @param actionTypes
 */
export function actionsDatabase<T extends { id: string }>(
  actionTypes: ActionTypes
) {
  return {
    /**
     * Media 추가
     */
    add: (data: T) => action(actionTypes.ADD, data),
    adds: (dataList: Array<T>) => action(actionTypes.ADDS, dataList),
    /**
     * Media 삭제
     */
    remove: (id: T['id']) => action(actionTypes.REMOVE, id),
    removes: (idList: Array<T['id']>) => action(actionTypes.REMOVES, idList),
    /**
     * Media List 셋팅
     */
    setList: (dataList: Array<T>) => action(actionTypes.SET_LIST, dataList),
    /**
     * Media 업데이트
     */
    update: (data: T) => action(actionTypes.UPDATE, data),
    updates: (dataList: Array<T>) => action(actionTypes.UPDATES, dataList),
  }
}
