import React, {
  ComponentType,
  ComponentProps,
  MemoExoticComponent,
  useRef,
  useEffect,
} from 'react'
import isEqual from 'react-fast-compare'

export function ReactMemoEx<T extends ComponentType<any>>(
  Component: T,
  propsAreEqual: (
    prevProps: Readonly<ComponentProps<T>>,
    nextProps: Readonly<ComponentProps<T>>
  ) => boolean = isEqual
): MemoExoticComponent<T> {
  return React.memo(Component, propsAreEqual)
}

export type SFC<T> = (props: T) => JSX.Element

export const useComponentDidMount = () => {
  const ref = useRef<boolean>(false)
  useEffect(() => {
    ref.current = true
  }, [])
  return ref.current
}
