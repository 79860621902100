import { ReactMemoEx } from 'modules/view'
import React, { useMemo } from 'react'
import { useContextSelector } from 'use-context-selector'
import HighlighterText from '../../../HighlighterText'
import { ContextByMCTable } from './mediaCenter.context'
import { MediaCenterAlpha } from './types'

type SearchTextProps = {
  searchKey: keyof MediaCenterAlpha
}
const SearchText: React.FC<SearchTextProps> = props => {
  const { children, searchKey } = props
  const search = useContextSelector(ContextByMCTable, state => state.search)

  const searchWords = useMemo(() => {
    const value = search.find(
      ({ searchedColumn }) => searchedColumn === searchKey
    )
    if (value === undefined) {
      return undefined
    }
    return String(value.value)
  }, [search, searchKey])

  return useMemo(
    () => (
      <HighlighterText children={String(children)} searchWords={searchWords} />
    ),
    [children, searchWords]
  )
}

export default ReactMemoEx(SearchText)
