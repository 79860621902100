import { Table, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { findCacheById, Language, safeNum, ui, toStringPercent } from 'common'
import { useReduxSelector, ApplicationState } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useMemo, useState } from 'react'
import { MCUpdate, UpdateStatus } from './mediaCenterUpdate.type'
import {
  generateFilterColumn,
  generateNumberColumn,
  generateSearchColumn,
} from '../../../GenerateColumn'
import { SyncOutlined } from '@ant-design/icons'
import dateFormat from 'dateformat'
import { SearchState } from '../../../util'
function filterManufacturer<T extends { manufacturerId: string }>(
  list: T[],
  admin: ApplicationState['auth']['admin']
) {
  if (admin.isLogin) {
    const userMId = admin.manufacturerId
    if (userMId === undefined) {
      // 관리자
      return list
    }
    return list.filter(({ manufacturerId: mId }) => userMId === mId)
  }
  return []
}

const updateStatusFilter = [
  {
    value: 'ready',
    text: '대기',
  },
  {
    value: 'process',
    text: '진행중',
  },
  {
    value: 'done',
    text: '완료',
  },
]

const TagUpdateStatus = ReactMemoEx((props: { updateStatus: UpdateStatus }) => {
  const { updateStatus } = props
  const [color, text, icon] = useMemo((): [
    string | undefined,
    string,
    undefined | React.ReactNode
  ] => {
    if (updateStatus === 'ready') {
      return [undefined, '대기', undefined]
    }
    if (updateStatus === 'done') {
      return ['green', '완료', undefined]
    }
    return ['blue', '진행중', <SyncOutlined spin />]
  }, [updateStatus])

  return (
    <Tag color={color} icon={icon}>
      {text}
    </Tag>
  )
})

export const TableMediaCenterUpdate = ReactMemoEx(() => {
  const [searchState, setSearchedColumn] = useState<SearchState<MCUpdate>>([])

  const auth = useReduxSelector(state => state.auth.admin)
  const saleChannelList = useReduxSelector(state => state.saleChannelList)
  const manufacturerList = useReduxSelector(state => state.manufacturerList)
  const mediaCenterList = useReduxSelector(state => state.mediaCenterList)
  const groupList = useReduxSelector(state => state.groupList)
  const shopList$ = useReduxSelector(state => state.shopList$)

  const filterSaleChannelList = useMemo(
    () => filterManufacturer(saleChannelList, auth),
    [auth, saleChannelList]
  )

  const filterGroupList = useMemo(() => filterManufacturer(groupList, auth), [
    auth,
    groupList,
  ])

  const filterShopList$ = useMemo(() => filterManufacturer(shopList$, auth), [
    auth,
    shopList$,
  ])

  const dataSource = useMemo(() => {
    const outputList: Array<MCUpdate> = []
    const findGroup = findCacheById(filterGroupList)
    const findShop = findCacheById(filterShopList$)

    mediaCenterList.forEach(mc => {
      const { id, shopId, tvInfoToNum, updateAt, isOnline } = mc
      const shop = findShop(shopId)
      if (shop === undefined) {
        return
      }

      if (tvInfoToNum) {
        tvInfoToNum.forEach(tvInfo => {
          const { media, groupId, mediaTotal, mediaDone } = tvInfo
          const group = findGroup(groupId)
          if (group === undefined) {
            return
          }
          const mediaUpdate = safeNum(media)
          let updateStatus: UpdateStatus = 'ready'
          if (mediaUpdate >= 1) {
            updateStatus = 'done'
          } else if (isOnline && mediaUpdate > 0) {
            updateStatus = 'process'
          }

          outputList.push({
            id: `${id}#${groupId}`,
            manufacturerTitle: shop.manufacturerTitle,
            countryTitle: shop.countryTitle,
            groupTitle: group.title,
            saleChannelTitle: shop.saleChannelTitle,
            shopTitle: shop.title,
            mediaDone,
            mediaTotal,
            mediaUpdate,
            updateAt,
            updateStatus,
          })
        })
      }
    })

    return outputList
  }, [filterGroupList, filterShopList$, mediaCenterList])

  const [columns, x] = useMemo(() => {
    if (!auth.isLogin) {
      return []
    }

    const output: ColumnsType<MCUpdate> = []

    if (auth.manufacturerId === undefined) {
      // 시스템 user
      output.push(
        generateFilterColumn(
          ui.createCol<MCUpdate>(Language.Manufacturer, ui.ColSize.md, {
            dataIndex: 'manufacturerTitle',
          }),
          manufacturerList.map(({ title }) => title)
        )
      )
    }

    output.push(
      generateFilterColumn(
        ui.createCol<MCUpdate>(Language.SaleChannel, ui.ColSize.md, {
          dataIndex: 'saleChannelTitle',
        }),
        filterSaleChannelList.map(({ title }) => title)
      ),
      generateSearchColumn<MCUpdate>(
        ui.createCol<MCUpdate>(Language.Country, ui.ColSize.md, {
          dataIndex: 'countryTitle',
        }),
        searchState,
        {
          onSearch: option => {
            setSearchedColumn(option)
          },
          onClear: option => {
            setSearchedColumn(option)
          },
        }
      ),
      generateSearchColumn<MCUpdate>(
        ui.createCol(Language.Shop, ui.ColSize.xl, {
          dataIndex: 'shopTitle',
        }),
        searchState,
        {
          onSearch: option => {
            setSearchedColumn(option)
          },
          onClear: option => {
            setSearchedColumn(option)
          },
        }
      ),
      generateFilterColumn(
        ui.createCol<MCUpdate>(Language.Group, ui.ColSize.xs, {
          dataIndex: 'groupTitle',
        }),
        filterGroupList.map(({ title }) => title)
      ),
      generateFilterColumn(
        ui.createCol<MCUpdate>(Language.UpdateStatus, ui.ColSize.md, {
          dataIndex: 'updateStatus',
          render(updatyeStatus: UpdateStatus) {
            return <TagUpdateStatus updateStatus={updatyeStatus} />
          },
        }),
        updateStatusFilter
      ),
      generateNumberColumn(
        ui.createCol<MCUpdate>(Language.UpdateProgress, ui.ColSize.sm, {
          dataIndex: 'mediaUpdate',
          render(mediaUpdate, recod) {
            return `${recod.mediaDone}/${recod.mediaTotal} (${toStringPercent(
              mediaUpdate
            )})`
          },
        })
      ),
      generateNumberColumn(
        ui.createCol<MCUpdate>(Language.LastUpdate, ui.ColSize.md, {
          dataIndex: 'updateAt',
          render(updateAt: number) {
            return dateFormat(updateAt, 'yyyy-mm-dd')
          },
        })
      )
    )

    return [
      output,
      output.reduce((width, currentData) => {
        if (typeof currentData.width === 'number') {
          return width + currentData.width
        }
        return width
      }, 0),
    ]
  }, [
    manufacturerList,
    filterSaleChannelList,
    filterGroupList,
    auth,
    searchState,
  ])

  return (
    <Table
      bordered
      dataSource={dataSource}
      rowKey="id"
      columns={columns}
      size="small"
      scroll={{
        x,
      }}
    />
  )
})
