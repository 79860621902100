import { useState, useRef, useCallback } from 'react'

export type ResponseNaverGeoCode = {
  loading: boolean
  result: MapData[]
}

export type MapData = {
  address: string
  lat: number
  lng: number
}
type RequestSingin = (address: string) => Promise<MapData[]>

const loadGetCode = (
  timeOutId: React.MutableRefObject<number>,
  setState: React.Dispatch<React.SetStateAction<ResponseNaverGeoCode>>
): RequestSingin => {
  return (address: string) => {
    setState({ loading: true, result: [] })
    clearTimeout(timeOutId.current)
    return new Promise(resolve => {
      timeOutId.current = setTimeout(() => {
        window.naver.maps.Service.geocode({ query: address }, (_, response) => {
          if (response) {
            const {
              v2: { addresses },
            } = response
            const result = addresses.map(
              (item): MapData => {
                return {
                  address: item.roadAddress,
                  lat: parseInt(item.y, 10),
                  lng: parseInt(item.x, 10),
                }
              }
            )
            setState({ loading: false, result: result })
            resolve(result)
            return
          } else {
            setState({ loading: false, result: [] })
            resolve([])
          }
        })
      }, 1000)
    })
  }
}

export const useNaverGeocode = (): [
  RequestSingin,
  ResponseNaverGeoCode,
  () => void
] => {
  const refTimeOut = useRef<number>(-1)
  const [mapData, setMapData] = useState<ResponseNaverGeoCode>({
    loading: false,
    result: [],
  })
  const useAPI = useRef(loadGetCode(refTimeOut, setMapData))
  const reset = useCallback(() => {
    setMapData({ loading: false, result: [] })
  }, [])
  return [useAPI.current, mapData, reset]
}
