import { Card, Row, Col } from 'antd'
import { Charts, TablePlayList, styleByFull } from 'components'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo } from 'react'
import GridLayout, { Responsive, WidthProvider } from 'react-grid-layout'
import ReactResizeDetector from 'react-resize-detector'
import { BrowserView, MobileView } from 'react-device-detect'
import { CSSProperties } from 'styled-components'
const ResponsiveReactGridLayout = WidthProvider(Responsive)

// https://github.com/STRML/react-grid-layout/blob/master/test/examples/7-localstorage.jsx
// https://strml.github.io/react-grid-layout/examples/8-localstorage-responsive.html
const KEY = 'rgl-8'

const layouts: GridLayout.Layouts = {
  lg: [
    { w: 10, h: 11, x: 7, y: 0, i: '1' },
    { w: 7, h: 11, x: 0, y: 0, i: '2' },
    { w: 7, h: 11, x: 17, y: 0, i: '3' },
    { w: 24, h: 12, x: 0, y: 11, i: '4', isResizable: false },
  ],
}

function getFromLS() {
  let ls: GridLayout.Layouts = layouts
  if (localStorage) {
    try {
      const value = localStorage.getItem(KEY)
      if (value === null) {
        ls = layouts
      } else {
        ls = JSON.parse(value) || layouts
      }
    } catch (e) {}
  }
  return ls
}

function saveToLS(value: GridLayout.Layouts) {
  if (localStorage) {
    localStorage.setItem(KEY, JSON.stringify(value))
  }
}

// https://github.com/STRML/react-grid-layout/pull/101
const PanelDashboardBrowser = () => {
  const layouts = useMemo(() => getFromLS(), [])
  const onLayoutChange = useCallback(
    (_: GridLayout.Layout[], allLayouts: GridLayout.Layouts) => {
      saveToLS(allLayouts)
    },
    []
  )
  const onClickBlock = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()
    },
    []
  )
  return (
    <ResponsiveReactGridLayout
      cols={{ lg: 24, md: 10, sm: 6, xs: 4, xxs: 2 }}
      rowHeight={30}
      layouts={layouts}
      onLayoutChange={onLayoutChange}
    >
      <div key="1" data-grid={{ w: 2, h: 3, x: 0, y: 0, minW: 2, minH: 3 }}>
        <Charts.CardChartTotalSCConnect disabledTitle={true} />
      </div>
      <div key="2" data-grid={{ w: 2, h: 3, x: 2, y: 0, minW: 2, minH: 3 }}>
        <Charts.ChartTotalSCConnectRT />
      </div>
      <div key="3" data-grid={{ w: 2, h: 3, x: 4, y: 0, minW: 2, minH: 3 }}>
        <Charts.ChartUpdate />
      </div>
      <div
        key="4"
        data-grid={{
          w: 2,
          h: 3,
          x: 6,
          y: 0,
          minW: 2,
          minH: 3,
          isResizable: false,
        }}
        style={{ overflow: 'hidden' }}
      >
        <Card
          title="편성 리스트"
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
          bodyStyle={{ position: 'relative', flex: 1 }}
        >
          <div
            onClick={onClickBlock}
            style={{
              position: 'absolute',
              left: '1rem',
              top: '1rem',
              right: '1rem',
              bottom: '1rem',
            }}
          >
            <ReactResizeDetector handleHeight>
              {({ height = 0 }: { height: number }) => (
                <TablePlayList height={Math.max(height - 86, 0)} />
              )}
            </ReactResizeDetector>
          </div>
        </Card>
      </div>
    </ResponsiveReactGridLayout>
  )
}

const styleByCard: CSSProperties = { width: '100%', height: '30rem' }
const styleByBody: CSSProperties = { ...styleByFull, padding: 0 }

const PaenlDashboardMobile = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card style={styleByCard} bodyStyle={styleByBody}>
          <Charts.CardChartTotalSCConnect disabledTitle={true} />
        </Card>
      </Col>
      <Col span={24}>
        <Card style={styleByCard} bodyStyle={styleByBody}>
          <Charts.ChartTotalSCConnectRT />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12}>
        <Card style={styleByCard} bodyStyle={styleByBody}>
          <Charts.ChartUpdate />
        </Card>
      </Col>
      <Col>
        <Card style={styleByCard} bodyStyle={styleByFull}>
          <TablePlayList height={360} />
        </Card>
      </Col>
    </Row>
  )
}

const PanelDashboard = () => {
  return (
    <>
      <BrowserView>
        <PanelDashboardBrowser />
      </BrowserView>
      <MobileView>
        <PaenlDashboardMobile />
      </MobileView>
    </>
  )
}

export default ReactMemoEx(PanelDashboard)
//
