import isEqual from 'react-fast-compare'
import { useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import * as Features from '../features'
import * as Cross from './src/crossSliceReducer'
import * as CrossState from './src/state'
import { pick } from 'lodash'
import { $Values } from 'utility-types'
export * from './src/types'

export const $Actions = {
  ...Features.Actions,
  ...Cross.Actions,
}

export type ApplicationActionTypes = ReturnType<$Values<typeof $Actions>>

export const INITIAL_APPLICATION_STATE = CrossState.INITIAL_APPLICATION_STATE

export type ApplicationState = typeof INITIAL_APPLICATION_STATE

const picks: Array<keyof Features.ApplicationState> = [
  'adminList',
  'auth',
  'amCodeList',
  'countryCodeList',
  'eventList',
  'fkGSList',
  'fkGSShopList',
  'groupList',
  'layout',
  'manufacturerList',
  'mediaCenterList',
  'mediaList',
  'mediaUpload',
  'panelList',
  'plsList',
  'saleChannelList',
  'shopList',
  'stats',
]
// const reducers:
export const createRootReducer = () => (
  state: Cross.ApplicationState = INITIAL_APPLICATION_STATE,
  action: AnyAction
) => {
  const nextState = {
    ...state,
    ...(Features.combinedReducer(
      pick(state, picks),
      action
    ) as ApplicationState),
  }
  return Cross.crossSliceReducer(nextState, action)
}

export function useReduxSelector<T>(
  selector: (state: ApplicationState) => T,
  equalityFn: (left: T, right: T) => boolean = (left: T, right: T) =>
    isEqual(left, right)
) {
  return useSelector<ApplicationState, T>(selector, equalityFn)
}
