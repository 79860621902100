import { SubscriptionEntity, ui, toEA, Language, generateSort } from 'common'
import { QueryHelper } from 'modules/graphql'
import React from 'react'
import { EditCellType } from '../../../EditTable'
import {
  generateSearchColumn,
  genrateDeleteColumn,
  generateNumberColumn,
  generateFilterColumn,
} from '../../../GenerateColumn'
import { SearchState } from '../../../util'
import { CountryCode$ as DB } from './countryCode.types'
import numeral from 'numeral'
import { isMobile } from 'react-device-detect'

type Options = {
  filterManufacturer: string[]
  filterAmCode: string[]
}

export default function createColumns(
  searchState: SearchState<DB>,
  setSearchedColumn: React.Dispatch<React.SetStateAction<SearchState<DB>>>,
  option: Options
): EditCellType<DB>[] {
  const { filterAmCode, filterManufacturer } = option
  const columns: EditCellType<DB>[] = [
    {
      editType: 'string',
      width: ui.ColSize.xxl,
      fixed: isMobile ? undefined : 'left',
      ...generateSearchColumn<DB>(
        ui.createCol<DB>(Language.CountryCode, ui.ColSize.xxl, {
          dataIndex: 'title',
          align: 'left',
        }),
        searchState,
        {
          onSearch: option => {
            setSearchedColumn(option)
          },
          onClear: option => {
            setSearchedColumn(option)
          },
        }
      ),
    },
    generateFilterColumn<DB>(
      ui.createCol<DB>(Language.Manufacturer, ui.ColSize.md, {
        dataIndex: 'manufacturerTitle',
        align: 'center',
      }),
      filterManufacturer
    ),

    {
      editType: 'amCode',
      ...ui.createCol<DB>(Language.AMCode, ui.ColSize.md, {
        dataIndex: 'amCodeId',
        align: 'center',
        render(_, record) {
          return record.amCodeTitle
        },
      }),
      filters: filterAmCode.map(item => {
        if (typeof item === 'string') {
          return { text: item, value: item }
        }
        return item
      }),
      onFilter: (filterValue: any, record: DB) => {
        const data = String(record['amCodeTitle'])
        return data === filterValue
      },
      sorter: generateSort<DB>('amCodeTitle'),
      sortDirections: ['descend', 'ascend'],
    },

    generateNumberColumn({
      dataIndex: 'shopCount',
      title: '지점 수',
      align: 'center',
      width: ui.ColSize.xs,
      render(data: number = 0) {
        return numeral(data)
          .format(',')
          .toString()
      },
    }),
    generateNumberColumn({
      dataIndex: 'totalCount',
      title: 'TV 전체 수',
      align: 'center',
      width: ui.ColSize.md,
      render(data: number = 0) {
        return toEA(data)
      },
    }),
    generateNumberColumn({
      dataIndex: 'activeCount',
      title: 'TV 활성',
      align: 'center',
      width: ui.ColSize.md,
      render(data: number = 0) {
        return toEA(data)
      },
    }),
    generateNumberColumn({
      dataIndex: 'offlineCount',
      title: 'TV 장애',
      align: 'center',
      width: ui.ColSize.md,
      render(data: number = 0) {
        return toEA(data)
      },
    }),
    generateNumberColumn({
      dataIndex: 'errorCount',
      title: 'TV 장애',
      align: 'center',
      width: ui.ColSize.md,
      render(data: number = 0) {
        return toEA(data)
      },
    }),
  ]

  // if (disabledManufacturer === false) {
  // columns.push(
  //   generateFilterColumn<DB>(
  //     ui.createCol<DB>('제조사명', ui.ColSize.md, {
  //       dataIndex: 'manufacturerTitle',
  //       align: 'center',
  //     }),
  //     manufacturerList
  //   )
  // )
  // }

  const resultColumns = columns.concat([
    // generateNumberColumn<DB>(
    //   ui.createCol<DB>('TV 수', ui.ColSize.md, {
    //     dataIndex: 'tvTotalCount',
    //     render: toEA,
    //   })
    // ),

    // generateNumberColumn<DB>(
    //   ui.createCol<DB>('활성 TV', ui.ColSize.md, {
    //     dataIndex: 'tvActiveCount',
    //     render: toEA,
    //   })
    // ),

    // generateNumberColumn<DB>(
    //   ui.createCol<DB>('TV접속률', ui.ColSize.md, {
    //     dataIndex: 'tvActivePercent',
    //     render: (percent: number) => (
    //       <ActiveRender percent={percent}>
    //         {toStringPercent(percent)}
    //       </ActiveRender>
    //     ),
    //   })
    // ),
    genrateDeleteColumn<DB>(
      QueryHelper.removeOneById(SubscriptionEntity.CountryCode)
    ),
  ])

  return resultColumns
}
