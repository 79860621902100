import { uniq, groupBy } from 'lodash'
import produce from 'immer'
type BaseIDObject = {
  id: string
}
export type FindOutput<T> = (id: string | null | undefined) => T | undefined

export function findDataById<T extends BaseIDObject>(
  dataList: Array<T>,
  currentId?: string
): T | undefined {
  if (currentId === undefined) {
    return undefined
  }
  return dataList.find(({ id }) => currentId === id)
}

export function findIndexById<T extends BaseIDObject>(
  dataList: Array<T>,
  currentId?: string
): number {
  if (currentId === undefined) {
    return -1
  }
  return dataList.findIndex(({ id }) => currentId === id)
}

export function findGroupById<T extends BaseIDObject>(
  list: Array<T>,
  groupKey: keyof T
): (id: string | null | undefined) => T[] {
  if (list.length <= 0) {
    return _ => []
  }
  const idByMap = groupBy(list, groupKey)

  return foundId => {
    if (foundId === undefined || foundId === null) {
      return []
    }
    return idByMap[foundId] || []
  }
}

export function findFastById<T extends BaseIDObject>(
  list: Array<T>
): FindOutput<T> {
  if (list.length <= 0) {
    return _ => undefined
  }
  const idList = list.map(({ id }) => id)
  return foundId => {
    if (foundId === undefined || foundId === null) {
      return undefined
    }
    const index = idList.indexOf(foundId)
    if (index >= 0) {
      return list[index]
    }
    return undefined
  }
}

/**
 * find 에 Cache를 해서 찾는다.
 * @param list
 */
export function findCacheById<T extends BaseIDObject>(
  list: Array<T>
): FindOutput<T> {
  if (list.length <= 0) {
    return _ => undefined
  }

  const cache = new Map<string, T>()
  const idList = list.map(({ id }) => id)

  return foundId => {
    if (foundId === undefined || foundId === null) {
      return undefined
    }
    if (cache.has(foundId)) {
      return cache.get(foundId)
    }
    const index = idList.indexOf(foundId)
    if (index >= 0) {
      const value = list[index]
      cache.set(foundId, value)
      return value
    }
    return undefined
  }
}

/**
 * object 내 value 값을 array 형식으로 변경해준다.
 * @param list
 * @param key
 */
export function toArrayKeyValue<T>(list: T[], key: keyof T) {
  if (list.length <= 0) {
    return []
  }
  return uniq(
    list.map(item => {
      return item[key]
    })
  )
}

export function generateSort<T>(dataIndex: keyof T) {
  return (a: T, b: T) => {
    const aV = a[dataIndex]
    const bV = b[dataIndex]
    if (typeof aV === 'string' && typeof bV === 'string') {
      return aV.localeCompare(bV)
    }
    if (typeof aV === 'number' && typeof bV === 'number') {
      return aV - bV
    }
    if (typeof aV === 'boolean' && typeof bV === 'boolean') {
      return aV === bV ? 0 : aV ? -1 : 1
    }

    const aSt = String(aV)
    const bSt = String(bV)
    return aSt.localeCompare(bSt)
  }
}

export function getOf<T extends object, Tkey extends keyof T>(
  data: T | undefined,
  key: Tkey,
  defaultData: T[Tkey]
): T[Tkey] {
  if (data === undefined) {
    return defaultData
  }
  const v = data[key]
  if (v === undefined) {
    return defaultData
  }
  return v
}

type ObjectNum = { [key: string]: number }

const getNum = (data: any) => {
  if (typeof data === 'number') {
    return data
  }
  return 0
}

export function sumObjectData(beforeData: ObjectNum = {}, addData: ObjectNum) {
  const keys = Object.keys(addData)
  if (keys.length <= 0) {
    return beforeData
  }
  return produce(beforeData, draft => {
    keys.forEach(key => {
      const value = getNum(addData[key])
      draft[key] = getNum(beforeData[key]) + value
    })
  })
}

export function getDataList<T, K extends keyof T>(
  data: T[],
  key: K
): Array<T[K]> {
  return data.map(item => item[key])
}

export function isErrorByNetwork(error: any) {
  if (typeof error === 'object' && typeof error.message === 'string') {
    const message = String(error.message).toLowerCase()
    return message.indexOf('network') >= 0
  }
  return false
}

export function toObjectByArray<T>(
  object: T[],
  key: keyof T
): { [key: string]: T } {
  const output: { [key: string]: T } = {}
  object.forEach(data => {
    const inKey = String(data[key])
    output[inKey] = data
  })
  return output
}

/**
 * 재 정렬 할때 사용한다.
 */
export function reorder<T>(
  list: Array<T>,
  startIndex: number,
  endIndex: number
): Array<T> {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

/**
 * 사용 중인 제목인지 체크
 * @param list
 * @param title
 * @param id
 */
export function isDuplicateTitle<T extends { id: string; title: string }>(
  list: T[],
  title: string,
  id?: string
) {
  return (
    list.findIndex(({ id: mId, title: mTitle }) => {
      if (mTitle === title) {
        if (id !== undefined && mId === id) {
          return false
        }
        return true
      }
      return false
    }) >= 0
  )
}
