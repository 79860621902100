import { useMutation } from '@apollo/react-hooks'
import { Manufacturer, MutationUpdateManufacturerArgs } from 'common'
import gql from 'graphql-tag'
import { Manufacturer$ as DB, useReduxSelector } from 'modules/store'
import React, { useCallback, useMemo, useState } from 'react'
import { EditTable } from '../../../EditTable'
import { SearchState } from '../../../util'
import generateColumn from './manufacturer.columns'
import { TableSaleChannel } from '../../saleChannel.table'
import { ReactMemoEx } from 'modules/view'

const MUTATION = gql`
  mutation UpdateManufacturer($title: String!, $id: String!) {
    result: updateManufacturer(title: $title, id: $id) {
      id
    }
  }
`

/**
 * 제조사 테이블
 */
export const TableManufacturer = ReactMemoEx(() => {
  const [updateMutation, { loading }] = useMutation<
    { result: Manufacturer },
    MutationUpdateManufacturerArgs
  >(MUTATION)

  const manufacturerList = useReduxSelector(state => state.manufacturerList$)
  const [searchState, setSearchedColumn] = useState<SearchState<DB>>([])
  const columns = useMemo(
    () => generateColumn(searchState, setSearchedColumn),
    [searchState, setSearchedColumn]
  )

  const onEdit = useCallback(
    (data: DB) => {
      const { id, title } = data
      updateMutation({ variables: { id, title } })
    },
    [updateMutation]
  )

  return (
    <EditTable<DB>
      columns={columns}
      dataSource={manufacturerList}
      rowKey="id"
      onEdit={onEdit}
      loading={loading}
      expandable={{
        expandedRowRender: record => (
          <TableSaleChannel manufacturer={record} />
        ),
      }}
    />
  )
})
