import ApolloClient from 'apollo-client'
import { PlayList, MutationUpdatePlayListArgs } from 'common'
import { AppThunkAction } from './asyncAction.type'
import gql from 'graphql-tag'
import { $Actions } from 'modules/store/crossSliceReducer'

const MUTATION_UPDATEPLS = gql`
  mutation UpdatePlayList(
    $isParentBlock: Boolean!
    $mediaIds: [String!]!
    $playListId: String!
  ) {
    PlayList: updatePlayList(
      isParentBlock: $isParentBlock
      mediaIds: $mediaIds
      playListId: $playListId
    ) {
      id
      isParentBlock
      manufacturerId
      groupId
      fkGSId
      fkGSSId
      mediaIds
    }
  }
`

type Result = {
  PlayList: PlayList
}

export const asyncActions = {
  updatePLS: (
    gqlClient: ApolloClient<object>,
    nextPlayList: PlayList,
    callback?: (idList: string[]) => void
  ): AppThunkAction => async (dispatch) => {
    dispatch($Actions.updatePls(nextPlayList))
    await gqlClient.mutate<Result, MutationUpdatePlayListArgs>({
      mutation: MUTATION_UPDATEPLS,
      variables: {
        mediaIds: nextPlayList.mediaIds,
        isParentBlock: nextPlayList.isParentBlock,
        playListId: nextPlayList.id,
      },
    })
    if (callback) {
      callback([nextPlayList.id])
    }
  },
  updatePLSMulti: (
    gqlClient: ApolloClient<object>,
    nextPlayListList: PlayList[],
    callback?: (idList: string[]) => void
  ): AppThunkAction => async (dispatch) => {
    nextPlayListList.forEach((item) => {
      dispatch($Actions.updatePls(item))
    })

    await Promise.all(
      nextPlayListList.map((updatePls) =>
        gqlClient.mutate<Result, MutationUpdatePlayListArgs>({
          mutation: MUTATION_UPDATEPLS,
          variables: {
            mediaIds: updatePls.mediaIds,
            isParentBlock: updatePls.isParentBlock,
            playListId: updatePls.id,
          },
        })
      )
    )
    if (callback) {
      callback(nextPlayListList.map(({ id }) => id))
    }
  },
}
