import { FilterDropdownProps } from 'antd/lib/table/interface'
import produce, { Draft } from 'immer'
import { ReactMemoEx } from 'modules/view'
import React from 'react'
import { useContextSelector } from 'use-context-selector'
import FilterSearch from '../../../FilterSearch'
import { ContextByMCTable } from './mediaCenter.context'
import { MediaCenterAlpha } from './types'

type SearchFilterDropDownProps = FilterDropdownProps & {
  dataIndex: keyof MediaCenterAlpha
}

export const onFilterBySearch = (dataIndex: keyof MediaCenterAlpha) => (
  value: any,
  record: MediaCenterAlpha
) =>
  record[dataIndex]
    .toString()
    .toLowerCase()
    .includes(value.toLowerCase())

const SearchFilterDropDown = (props: SearchFilterDropDownProps) => {
  const { dataIndex, ...filterDropdown } = props
  const search = useContextSelector(ContextByMCTable, state => state.search)
  const setSearch = useContextSelector(
    ContextByMCTable,
    state => state.setSearch
  )
  return (
    <FilterSearch<MediaCenterAlpha>
      filterSearch={filterDropdown}
      dataIndex={dataIndex}
      handleSearch={se => {
        const value = se[0]
        const searchOption = produce(search, draft => {
          const sIndex = draft.findIndex(
            sOption => sOption.searchedColumn === dataIndex
          )
          if (sIndex >= 0) {
            draft[sIndex].value = value
          } else {
            draft.push({
              searchedColumn: dataIndex as Draft<keyof MediaCenterAlpha>,
              value,
            })
          }
        })
        setSearch(searchOption)
      }}
      onClear={() => {
        const sIndex = search.findIndex(
          sOption => sOption.searchedColumn === dataIndex
        )
        if (sIndex <= -1) {
          return
        }
        const searchOption = produce(search, draft => {
          draft.splice(sIndex, 1)
        })
        setSearch(searchOption)
      }}
    />
  )
}

export default ReactMemoEx(SearchFilterDropDown)
