import { Tabs, Typography } from 'antd'
import Spin, { SpinProps } from 'antd/lib/spin'
import { TabPaneProps, TabsProps } from 'antd/lib/tabs'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo } from 'react'
import { TabItem as TypeTabItem } from '../../charts.type'
import './comps.less'

const { Paragraph } = Typography

const { TabPane } = Tabs

export type TabItem = TypeTabItem

const mouseParentBlock = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>
) => {
  event.preventDefault()
  event.stopPropagation()
}

export type CustomCardProps = {
  tabOptions?: Omit<TabsProps, 'renderTabBar' | 'tabPosition'>
} & Pick<SpinProps, 'spinning'> &
  (
    | {
        title?: string
        childrenType: 'panel'
        children:
          | React.ClassicComponentClass<TabPaneProps>
          | Array<React.ClassicComponentClass<TabPaneProps>>
      }
    | {
        title?: string
        childrenType: 'element'
        tabs: Array<TabItem>
        children: React.ReactNode
      }
  )

export const CustomCard = (props: CustomCardProps) => {
  const { title, tabOptions, spinning = false } = props
  const onMouseDown = useCallback(mouseParentBlock, [])

  const renderTabBar: Required<TabsProps>['renderTabBar'] = useCallback(
    (inProps, DefaultTabBar) => (
      <div
        className="c-card-header"
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <div className="c-card-header-title" style={{ flex: 1 }}>
          <Paragraph strong ellipsis>
            {title}
          </Paragraph>
        </div>
        <div>
          <div className="c-card-header-tabs" onMouseDown={onMouseDown}>
            <DefaultTabBar {...inProps} />
          </div>
        </div>
      </div>
    ),
    [title, onMouseDown]
  )

  return (
    <div className="c-card">
      {props.childrenType === 'panel' ? (
        <Tabs {...tabOptions} tabPosition="top" renderTabBar={renderTabBar}>
          {props.children}
        </Tabs>
      ) : (
        <>
          <Tabs {...tabOptions} tabPosition="top" renderTabBar={renderTabBar}>
            {props.tabs.map(tabItem => (
              <TabPane key={tabItem.key} tab={tabItem.title} />
            ))}
          </Tabs>
          <div className="c-card-body" onMouseDown={onMouseDown}>
            <>{props.children}</>
          </div>
        </>
      )}
      {spinning && (
        <div className="c-spin">
          <Spin />
        </div>
      )}
    </div>
  )
}

type CustomSimpleCardProps = {
  title?: string | React.ReactNode
} & Pick<SpinProps, 'spinning'>

export const CustomSimpleCard: React.FC<CustomSimpleCardProps> = ReactMemoEx(
  props => {
    const { title, children, spinning } = props

    const drawHeader = useMemo(() => {
      if (typeof title === 'string') {
        return (
          <div className="c-card-header-title">
            <Paragraph strong ellipsis>
              {title}
            </Paragraph>
          </div>
        )
      } else {
        return title
      }
    }, [title])

    const onMouseDown = useCallback(mouseParentBlock, [])

    return (
      <div className="c-card">
        <div className="c-card-header">{drawHeader}</div>
        <div className="c-card-body" onMouseDown={onMouseDown}>
          {children}
        </div>
        {spinning && (
          <div className="c-spin">
            <Spin />
          </div>
        )}
      </div>
    )
  }
)
