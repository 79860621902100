import {
  Col,
  DatePicker,
  Descriptions,
  notification,
  Row,
  Spin,
  Typography,
} from 'antd'
import { Config, Media, toStringFileSize } from 'common'
import { useMutationUpdateMedia } from 'modules/graphql'
import { useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import moment, { Moment } from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import Dropzone from 'react-dropzone'
import isEqual from 'react-fast-compare'
import styled from 'styled-components'
import ImageThumbnail from './ImageThumbnail'
import { useAxios } from 'modules/useLibs'

const { Title, Paragraph } = Typography

type MediaFormProps = {
  mediaId: string
}

const readURL = (file: File): Promise<string> => {
  return new Promise((res, rej) => {
    const reader = new FileReader()
    reader.onload = e => {
      if (e.target && e.target.result) {
        res(String(e.target.result))
      }
    }
    reader.onerror = e => rej(e)
    reader.readAsDataURL(file)
  })
}

const STitle = styled(Title)`
  margin: 0;
`
const SParagraph = styled(Paragraph)`
  margin: 0 !important;
`

type InfoProps = {
  data: Media
  dataKey: keyof Media | 'videoScale'
}

const SDescriptions = styled(Descriptions)`
  margin-top: 0.5rem;
`

const Info = ReactMemoEx((props: InfoProps) => {
  const { data, dataKey } = props
  if (dataKey === 'videoScale') {
    const { width, height } = data
    return (
      <SParagraph
        copyable
        children={`${typeof width === 'number' ? width : 'unknow'} x ${
          typeof height === 'number' ? height : 'unknow'
        }`}
      />
    )
  }
  const value = data[dataKey]
  if (value === undefined || value === null) {
    return <>unknow</>
  }
  let text = value
  if (dataKey === 'bitRate' || dataKey === 'size') {
    text = toStringFileSize(value)
  } else if (dataKey === 'createAt' || dataKey === 'updateAt') {
    text = moment(value).format('YYYY-MM-DD')
  } else if (typeof value === 'string') {
    text = value
  } else {
    text = String(value)
  }

  return <SParagraph copyable children={text} />
})

function disabledDate(current: Moment) {
  return current && current < moment().endOf('day')
}

const SRow = styled(Row)`
  & + & {
    border-top: 1px solid var(--color-border);
    padding-top: 0.75rem;
  }
  margin-bottom: 1.5rem;
`

const MediaForm = (props: MediaFormProps) => {
  const { mediaId } = props
  const [requestFileUpload, { loading: uploadLoading }] = useAxios<{
    code: number
  }>()
  const [updateMedia, { loading }] = useMutationUpdateMedia()
  const [image, setImage] = useState<string>()

  const media = useReduxSelector(state =>
    state.mediaList.find(({ id }) => id === mediaId)
  )

  const onDropAccepted = useCallback(
    async (fileList: File[]) => {
      const file = fileList[0]
      const form = new FormData()
      form.append('thumb', file)
      try {
        const path = await readURL(file)
        setImage(path)
        const response = await requestFileUpload({
          method: 'POST',
          url: `${Config.httpGraphql.replace(
            /\/graphql/,
            ''
          )}/file/thumbupload`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          params: { mediaId },
          data: form,
        })
        if (response.error) {
          notification.error({
            message: '통신에러',
            description: '섬네일 변경 실패',
          })
        }
      } catch (e) {}
    },
    [mediaId, requestFileUpload]
  )

  const thumbnailURL = useMemo(() => {
    if (image) {
      return image
    }
    if (media && media.thumbnailPath) {
      if (media.thumbnailPath)
        return `${Config.httpMedia}${media.thumbnailPath}`
    }
    return undefined
  }, [media, image])

  /**
   * 일자 변경
   */
  const onChangeDate = useCallback(
    async value => {
      let licenseEnd: number | undefined = undefined
      if (value === null) {
        // clear Date
        licenseEnd = undefined
        return
      } else if (!moment.isMoment(value)) {
        return
      } else {
        const mmt: Moment = moment(value)
        licenseEnd = mmt
          .hour(23)
          .minute(59)
          .second(59)
          .millisecond(999)
          .toDate()
          .getTime()
      }
      const beforeTime = media ? media.licenseEnd : undefined
      if (isEqual(licenseEnd, beforeTime)) {
        return
      }
      try {
        await updateMedia({ variables: { id: mediaId, licenseEnd } })
      } catch (e) {
        notification.error({
          message: '통신에러',
          description: '라이센스 업데이트 실패',
        })
      }
    },
    [updateMedia, mediaId, media]
  )

  const defaultValue = useMemo(() => {
    if (media && media.licenseEnd !== undefined && media.licenseEnd !== null) {
      return moment(media.licenseEnd)
    }
    return undefined
  }, [media])

  return media === undefined ? null : (
    <Spin spinning={uploadLoading || loading} tip="업데이트 중입니다..">
      <SRow>
        <Col span={24}>
          <STitle style={{ marginBottom: '0.5rem' }} level={4} copyable>
            {media.fileName}
          </STitle>
        </Col>
        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <Dropzone
            noKeyboard
            onDropAccepted={onDropAccepted}
            multiple={false}
            accept="image/*"
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <ImageThumbnail image={thumbnailURL} />
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </Col>
        <Col span={24}>
          <SDescriptions
            size="small"
            bordered
            column={1}
            style={{ marginTop: '0.5rem' }}
          >
            <Descriptions.Item label="라이센스 기간">
              ~
              <DatePicker
                defaultValue={defaultValue}
                bordered={false}
                onChange={onChangeDate}
                placeholder="무제한"
                disabledDate={disabledDate}
                style={{ paddingLeft: 0 }}
              />
            </Descriptions.Item>
            <Descriptions.Item label="해상도">
              <Info data={media} dataKey="videoScale" />
            </Descriptions.Item>
            <Descriptions.Item label="비디오 코덱">
              <Info data={media} dataKey="videoCodec" />
            </Descriptions.Item>
            <Descriptions.Item label="오디오 코덱">
              <Info data={media} dataKey="audioCodec" />
            </Descriptions.Item>
            <Descriptions.Item label="비트레이트">
              <Info data={media} dataKey="bitRate" />
            </Descriptions.Item>
            <Descriptions.Item label="파일 용량">
              <Info data={media} dataKey="size" />
            </Descriptions.Item>
            <Descriptions.Item label="생성일">
              <Info data={media} dataKey="createAt" />
            </Descriptions.Item>
          </SDescriptions>
        </Col>
      </SRow>
    </Spin>
  )
}

export default ReactMemoEx(MediaForm)
