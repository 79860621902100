import { useMutation } from '@apollo/react-hooks'
import {
  Alert,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Typography,
} from 'antd'
import { useForm } from 'antd/lib/form/util'
import { AdminEntity, MutationUpdateAdminArgs } from 'common'
import gql from 'graphql-tag'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { useContextSelector } from 'use-context-selector'
import { ContextByAdmin } from './admin.context'
const { Paragraph } = Typography

const SParagraph = styled(Paragraph)`
  margin-bottom: 0 !important;
  strong {
    color: #ad4e00;
  }
`

const MUTATION_PWD_EDIT_ADMIN = gql`
  mutation UpdateAdmin($password: String!, $id: String!) {
    Admin: updateAdmin(id: $id, password: $password) {
      id
      email
      userName
      phoneNumber
      adminLevel
      manufacturerId
      description
    }
  }
`

const EditPwdPopup = () => {
  const [form] = useForm()
  const [updateAdmin, { loading }] = useMutation<
    { Admin: AdminEntity },
    MutationUpdateAdminArgs
  >(MUTATION_PWD_EDIT_ADMIN)

  const { setPwdEditAdmin, pwdEditAdmin } = useContextSelector(
    ContextByAdmin,
    state => state
  )

  const visible = useMemo(() => {
    return pwdEditAdmin !== undefined
  }, [pwdEditAdmin])

  const onCacnel = useCallback(() => {
    setPwdEditAdmin(undefined)
    form.resetFields()
  }, [setPwdEditAdmin, form])

  const onOk = useCallback(async () => {
    if (pwdEditAdmin === undefined) {
      return
    }
    try {
      const data = (await form.validateFields()) as {
        password: string
        passwordConfirm: string
      }

      if (data.password !== data.passwordConfirm) {
        form.setFields([
          {
            name: 'passwordConfirm',
            errors: ['패스워드가 일치하지 않습니다!'],
          },
        ])
        return
      }
      await updateAdmin({
        variables: { id: pwdEditAdmin.id, password: data.password },
      })
      notification.success({
        message: '비밀번호 변경 완료',
        description: `(${pwdEditAdmin.email})`,
      })
      onCacnel()
    } catch (e) {}
  }, [form, onCacnel, pwdEditAdmin, updateAdmin])

  return (
    <Modal
      visible={visible}
      onCancel={onCacnel}
      title="관리자 비밀번호 변경"
      okText="비밀번호 변경"
      cancelText="변경 취소"
      confirmLoading={loading}
      onOk={onOk}
    >
      <Alert
        type="warning"
        message={
          <SParagraph>
            관리자 비밀번호는 암호화 후 저장되어 비밀번호 확인은 불가능합니다.
            <br />
            {pwdEditAdmin && (
              <>
                (<strong>{pwdEditAdmin.email}</strong>비밀번호 변경)
              </>
            )}
          </SParagraph>
        }
      />

      <Form form={form}>
        <Row style={{ marginTop: '1rem' }}>
          <Col span={24}>
            <Form.Item
              label="신규 비밀번호"
              name="password"
              rules={[
                { min: 4, message: '최소 4글자 이상을 입력해주세요.' },
                { required: true, message: '비밀번호를 입력해주세요.' },
              ]}
            >
              <Input type="password" placeholder="신규 비밀번호 입력" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="비밀번호 확인"
              name="passwordConfirm"
              rules={[
                { min: 4, message: '최소 4글자 이상을 입력해주세요.' },
                { required: true, message: '비밀번호를 입력해주세요.' },
              ]}
            >
              <Input type="password" placeholder="신규 비밀번호 확인" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default ReactMemoEx(EditPwdPopup)
