import produce from 'immer'
import { $GetActionType, withReducer } from 'modules/store/util'
import { actions } from './actions'
import { ActionTypes, State } from './types'

export const initialState: State = {
  theme: 'light',
  naviSize: 256,
}

export const reducer = withReducer(
  initialState,
  (state = initialState, action: $GetActionType<typeof actions>) => {
    switch (action.type) {
      case ActionTypes.SET_THEME: {
        return produce(state, draft => {
          const { payload } = action
          if (draft.theme === payload) {
            return
          }
          draft.theme = action.payload
        })
      }
      case ActionTypes.SET_NAVI_WIDTH: {
        return produce(state, draft => {
          const { payload } = action
          if (draft.naviSize === payload) {
            return
          }
          draft.naviSize = payload
        })
      }
      case ActionTypes.SET_GENERATORMODAL_HIDE: {
        return produce(state, draft => {
          if (draft.generatorModal === undefined) {
            return
          }
          draft.generatorModal = undefined
        })
      }
      case ActionTypes.SET_GENERATORMODAL_TOGGLE: {
        const { payload } = action
        return produce(state, draft => {
          if (draft.generatorModal === payload) {
            draft.generatorModal = undefined
            return
          }
          draft.generatorModal = payload
        })
      }
      default: {
        return state
      }
    }
  }
)
