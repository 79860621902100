import React from 'react'
import { Row, Col, Card } from 'antd'
import { TableSaleChannel } from 'components'
import { ReactMemoEx } from 'modules/view'

const PanelBySaleChannel = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card>
          <TableSaleChannel />
        </Card>
      </Col>
    </Row>
  )
}

export default ReactMemoEx(PanelBySaleChannel)
