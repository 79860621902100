import { Form, Select } from 'antd'
import { useReduxSelector } from 'modules/store'
import React, { useMemo } from 'react'

type SelectorCountryProps<T> = {
  manufacturerId?: string
  dataIndex: keyof T
}

function SelectorCountry<T>(props: SelectorCountryProps<T>) {
  const { dataIndex, manufacturerId } = props
  const countryList = useReduxSelector(({ countryCodeList }) => countryCodeList)

  const mCountryList = useMemo(() => {
    if (manufacturerId === undefined) {
      return countryList
    }
    return countryList.filter(
      ({ manufacturerId: mId }) => mId === manufacturerId
    )
  }, [countryList, manufacturerId])

  return (
    <Form.Item
      name={String(dataIndex)}
      style={{ margin: 0 }}
      rules={[
        {
          required: true,
          message: `지역코드를 입력하세요`,
        },
      ]}
    >
      <Select>
        {mCountryList.map((countryItem, i) => {
          return (
            <Select.Option value={countryItem.id} key={countryItem.id}>
              {countryItem.title}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

export default SelectorCountry
