import React, { useCallback } from 'react'
import { ReactMemoEx } from 'modules/view'
import { Button } from 'antd'
import { Admin$ as DB } from 'modules/store'
import { useContextSelector } from 'use-context-selector'
import { ContextByAdmin } from './admin.context'

type EditPwdButtonProps = {
  data: DB
}

const EditPwdButton = (props: EditPwdButtonProps) => {
  const { data } = props
  const setPwdEditAddmin = useContextSelector(
    ContextByAdmin,
    state => state.setPwdEditAdmin
  )

  const onClick = useCallback(() => {
    setPwdEditAddmin(data)
  }, [data, setPwdEditAddmin])

  return (
    <Button type="link" onClick={onClick}>
      비밀번호 수정
    </Button>
  )
}

export default ReactMemoEx(EditPwdButton)
