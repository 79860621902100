import { applyMiddleware, compose, createStore } from 'redux'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import {
  ApplicationActionTypes,
  ApplicationState,
  createRootReducer,
  INITIAL_APPLICATION_STATE,
} from './crossSliceReducer'

export const configureStore = () => {
  const middleware = [
    thunk as ThunkMiddleware<ApplicationState, ApplicationActionTypes>,
  ]
  const enhancers: any[] = []

  if (process.env.NODE_ENV === 'development' && window) {
    const devToolsExtension =
      ((window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__()) ||
      compose
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension)
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  )

  const store = createStore(
    createRootReducer(),
    INITIAL_APPLICATION_STATE,
    composedEnhancers
  )
  return store
}
