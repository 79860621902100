import { Col, Form, Input, Row, Select } from 'antd'
import {
  FieldData,
  GeneratorModalIndex,
  isErrorByNetwork,
  MutationAddAmCodeArgs,
} from 'common'
import { useMutationAddAmCode } from 'modules/graphql'
import { isSystemUser, useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React, { useCallback, useMemo } from 'react'
import ContainerFormLayout from './ContainerFormLayout'
import EmptyContainer from './EmptyContainer'
import { notiError, notiErrorNetwork, notiSuccess } from './util'

const ERROR_TITLE = 'AM 코드 생성 실패'

const FormAMCodeBody = () => {
  const [form] = Form.useForm()
  const [addAmCode, { loading }] = useMutationAddAmCode()
  const amCodeList = useReduxSelector(({ amCodeList }) => amCodeList)
  const manufacturerList = useReduxSelector(
    ({ manufacturerList }) => manufacturerList
  )

  const fields = useMemo(() => {
    return (
      <Row gutter={[32, 16]}>
        <Col xxl={8} xl={8} md={12} sm={24} xs={24}>
          <Form.Item
            name="manufacturerId"
            label="제조사"
            rules={[
              {
                required: true,
                message: '제조사를 선택해주세요.',
              },
            ]}
          >
            <Select>
              {manufacturerList.map(({ id, title }) => (
                <Select.Option key={id} value={id} children={title} />
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xxl={8} xl={8} md={12} sm={24} xs={24}>
          <Form.Item
            name="code"
            label="AM 코드명"
            rules={[
              {
                required: true,
                message: 'AM 코드명을 입력하세요!',
              },
            ]}
          >
            <Input placeholder="AM 코드" />
          </Form.Item>
        </Col>
      </Row>
    )
  }, [manufacturerList])

  const onFinish = useCallback(async () => {
    const variables = form.getFieldsValue() as MutationAddAmCodeArgs
    // const amCode =
    const isDuplicateCodeName =
      amCodeList.findIndex(({ code, manufacturerId }) => {
        return (
          variables.manufacturerId === manufacturerId && variables.code === code
        )
      }) >= 0
    if (isDuplicateCodeName) {
      const files: FieldData[] = [
        { name: 'code', errors: ['동일한 코드명이 존재합니다.'] },
      ]
      form.setFields(files)
      return
    }

    try {
      await addAmCode({ variables })
      notiSuccess(`AM 코드 생성 완료(${variables.code})`)
      form.resetFields()
    } catch (e) {
      if (isErrorByNetwork(e)) {
        notiErrorNetwork(ERROR_TITLE)
      } else {
        notiError(ERROR_TITLE, String(e))
      }
    }
  }, [amCodeList, addAmCode, form])

  return (
    <ContainerFormLayout
      modalIndex={GeneratorModalIndex.Shop}
      loading={loading}
      title="AM 코드 생성"
      form={form}
      formChildren={fields}
      onFinish={onFinish}
    />
  )
}

const FormAMCode = () => {
  const adminInfo = useReduxSelector(({ auth }) => auth.admin)

  return isSystemUser(adminInfo) ? (
    <FormAMCodeBody />
  ) : (
    <EmptyContainer
      index={GeneratorModalIndex.Group}
      title="AM 코드 생성(사용 권한 없음)"
    />
  )
}

export default ReactMemoEx(FormAMCode)
