import { ReactMemoEx, ViewContainer } from 'modules/view'
import React from 'react'
import PanelGroup from 'react-panelgroup'
import { MediaFinder, PlayListEditor } from '../features'

const PlayListManagerBody = () => {
  return (
    <PanelGroup
      direction="column"
      panelWidths={[
        { minSize: 80, size: 1080, resize: 'stretch' },
        { minSize: 60 },
      ]}
    >
      <ViewContainer marginRem={[0, 0.5, 0.5, 0.5]} isSizeFull={true}>
        <PlayListEditor />
      </ViewContainer>
      <ViewContainer
        marginRem={[0, 0.5, 0, 0.5]}
        isSizeFull={true}
        className="shadow-lite"
      >
        <MediaFinder />
      </ViewContainer>
    </PanelGroup>
  )
}

export default ReactMemoEx(PlayListManagerBody)
