import { reducerDatabase, actionsDatabase } from '../util'
import { MediCenterAndLog } from 'common'

type Base = MediCenterAndLog
export type State = Base[]

export enum ActionTypes {
  ADD = '@@mediaCenterList/ADD',
  ADDS = '@@mediaCenterList/ADDS',
  REMOVE = '@@mediaCenterList/REMOVE',
  REMOVES = '@@mediaCenterList/REMOVES',
  SET_LIST = '@@mediaCenterList/SET_LIST',
  UPDATE = '@@mediaCenterList/UPDATE',
  UPDATES = '@@mediaCenterList/UPDATES',
}

const actionDB = actionsDatabase<Base>(ActionTypes)

export const actions = {
  /**
   * MediaCenter 추가
   */
  addMediaCenter: actionDB.add,
  addMediaCenters: actionDB.adds,
  /**
   * MediaCenter 삭제
   */
  removeMediaCenter: actionDB.remove,
  removeMediaCenters: actionDB.removes,
  /**
   * MediaCenter List 셋팅
   */
  setMediaCenterList: actionDB.setList,
  /**
   * MediaCenter 업데이트
   */
  updateMediaCenter: actionDB.update,
  updateMediaCenters: actionDB.updates,
}

export const initialState: State = []

export const reducer = reducerDatabase(initialState, ActionTypes)
