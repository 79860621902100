import {
  COLOR_ERROR,
  COLOR_PRIMARY,
  COLOR_SUB,
  SimplePieGraph,
} from 'components/src/SimplePieGraph'
import { useReduxSelector } from 'modules/store'
import { ReactMemoEx } from 'modules/view'
import React from 'react'
import { Typography, Tooltip, Divider } from 'antd'
import TinyStatistic from './TinyStatistic'
import styled from 'styled-components'
import SaleChannelTooltipConnet from './SaleChannelTooltipConnet'
import SaleChannelTooltipUpdate from './SaleChannelTooltipUpdate'

const { Paragraph } = Typography

const SParagraph = styled(Paragraph)`
  margin: 0 1rem 0 0;
  margin-bottom: 0 !important;
`

const colorsMonitoring = [COLOR_PRIMARY, COLOR_ERROR, COLOR_SUB]
export const HeaderGraph = ReactMemoEx(() => {
  const manufacturerList = useReduxSelector(state => state.manufacturerList$)

  return (
    <>
      {manufacturerList
        .filter(manufacturer => {
          const { tvActiveCount, tvErrorCount, tvOfflineCount } = manufacturer
          return tvActiveCount + tvErrorCount + tvOfflineCount >= 1
        })
        .map((manufacturer, i) => {
          const {
            tvActiveCount,
            tvErrorCount,
            tvOfflineCount,
            tvActivePercent,
            totalMediaPercent,
          } = manufacturer
          const isOneManufacturer = manufacturerList.length <= 1
          return (
            <React.Fragment key={manufacturer.id}>
              {i >= 1 && (
                <Divider
                  type="vertical"
                  style={{ marginLeft: '0.5rem', marginRight: '1rem' }}
                />
              )}
              {!isOneManufacturer && (
                <SParagraph>{manufacturer.title}</SParagraph>
              )}
              <TinyStatistic
                title="활성TV"
                value={tvActivePercent * 100}
                precision={2}
                suffix="%"
              />

              <Tooltip title={<SaleChannelTooltipConnet manufacturer={manufacturer} />}>
                <div>
                  <SimplePieGraph
                    size="2rem"
                    data={[tvActiveCount, tvErrorCount, tvOfflineCount]}
                    color={colorsMonitoring}
                  />
                </div>
              </Tooltip>
              <TinyStatistic
                title="업데이트"
                style={{ marginLeft: '1rem' }}
                value={totalMediaPercent * 100}
                precision={2}
                suffix="%"
              />
              <Tooltip
                title={<SaleChannelTooltipUpdate manufacturer={manufacturer} />}
              >
                <div>
                  <SimplePieGraph
                    size="2rem"
                    data={[totalMediaPercent, 1 - totalMediaPercent]}
                    style={{ marginRight: '1rem' }}
                  />
                </div>
              </Tooltip>
            </React.Fragment>
          )
        })}
    </>
  )
})
